import { Component, OnInit } from '@angular/core';
import { ServicesService } from '../services/services.service';
import { showNotification } from '../services/notification';
import { environment } from '../../environments/environment';
import { HttpClient, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators, AbstractControl } from '@angular/forms';

@Component({
	selector: 'app-profile',
	templateUrl: './profile.component.html',
	styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

	public botID: number = parseInt(localStorage.getItem('botID'), 10);
	public customerID: number = parseInt(localStorage.getItem('Customer_ID'), 10);
	public profileDetails;
	public isFetching: boolean = false;
	public isChanging: boolean = false;
	public uploading: boolean = false;
	public profileForm: FormGroup;
	public static_url = environment.staticUrl;
	uploaded_kb = 0;
	total_kb = 0;


	constructor(private dataService: ServicesService, private _fb: FormBuilder) { }

	ngOnInit(): void {
		this.getProfileData();
		this.profileForm = this._fb.group({
			'full_name': ['', [Validators.required]],
			'phone': ['', [Validators.required]],
			'email': ['', [Validators.required]],
			'image_url': ['', [Validators.required]],
		});
	}

	getProfileData() {
		this.isFetching = true;
		this.dataService.getProfileDetails({ CustomerID: this.customerID }).subscribe(
			res => {
				if (res.status_code == 200) {
					this.profileDetails = res.data;
					this.profileForm = this._fb.group({
						'full_name': [this.profileDetails.full_name, [Validators.required]],
						'phone': [this.profileDetails.phone, [Validators.required]],
						'email': [this.profileDetails.email, [Validators.required]],
						'image_url': [this.profileDetails.image_url, [Validators.required]],
					});
				} else if (res.status_code == 401) {
					this.dataService.logout();
				} else {
					showNotification('warning', res.status);
				}
				this.isFetching = false;
			}, error => {
				console.log(error);
				this.isFetching = false;
				showNotification('danger', error);
			})
	}

	changeProfile(profile) {
		profile.CustomerID = this.customerID;
		this.isChanging = true;
		this.dataService.setProfileDetails(profile).subscribe(
			res => {
				if (res.status_code == 200) {
					this.profileDetails = profile;
					localStorage.setItem('agent_imgurl', this.profileDetails.image_url);
					showNotification('success', 'Updated successfully!');
				} else if (res.status_code == 401) {
					this.dataService.logout();
				} else {
					showNotification('warning', res.status);
				}
				this.isChanging = false;
			}, error => {
				this.isChanging = false;
				console.log(error);
				showNotification('danger', error);
			})
	}

	imageUpload = () => {
		let element: HTMLElement = document.getElementById('imgupload') as HTMLElement;
		element.click();
	}

	getFile(event) {
		this.uploading = true;
		this.uploaded_kb = 0;
		this.total_kb = 0;
		const epochNow = (new Date).getTime();
		const filename = this.customerID;
		let ext = event.target.files[0].name.split('?')[0].split('.').pop()
		this.dataService.getPresignedURL(filename + '.' + ext, this.botID, 'profile').subscribe(
			data => {
				let url = data.text();
				this.dataService.uploadfileAWSS3(url, event.target.files[0].type, event.target.files[0]).subscribe((event: HttpEvent<any>) => {
					let progress;
					progress = event;
					if (event.type == 1) {
						this.uploaded_kb = event['loaded'];
						this.total_kb = event['total'];
					}
					if (progress.statusText == "OK") {
						setTimeout(() => {
							(<FormControl>this.profileForm.controls['image_url']).setValue(this.static_url + this.botID + '/support/data/profile/' + filename + '.' + ext + '?' + epochNow);
							this.uploading = false;
							showNotification('success', '<span>Uploaded Successfully!</span>');
						}, 2000);
					}
				},
					error => {
						showNotification('danger', '<span>Error! Try Again.</span>');
					});
			},
			error => {
				showNotification('danger', error);
				this.uploading = false;
			}
		);
	}

}
