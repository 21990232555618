<div class="modal-content">
    <div class="modal-header">
        <div class="header">
            <h4 class="title mg0">FIlter Rule</h4>
            <button type="button" class="close" (click)="closeModal()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
    <div class="modal-body">
        <form [formGroup]="addRulesForm" (ngSubmit)="submitRule(addRulesForm);" ngNativeValidate>
            <div class="modal-body m10">
                <div *ngIf="!memoryVars.length" class="text-center text-warning mb-3">
                    Add Starred Variable from Canvas to Add Filter Rules!.
                </div>
                <div *ngIf="memoryVars.length">
                    <div class="row d-flex">
                        <div class="col-md-12" formArrayName="condition" class="condition-div">
                            <div
                                *ngFor="let condition of conditions.controls; let i=index; let isLast=last; let isFirst=first;">
                                <div [formGroupName]="i" class="row pt-2">
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label>Memory Variable</label>
                                            <select class="form-control" formControlName="mem_var" required>
                                                <option *ngFor="let item of memoryVars" [value]="item.internal_name">
                                                    {{item.display_name}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label class="w-100 text-center">Condition</label>
                                            <select formControlName="operator" class="form-control" required>
                                                <option [value]="null" [disabled]="true">Select Operator
                                                </option>
                                                <option *ngFor="let symbol of operations" [value]="symbol">{{symbol}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-4 d-flex align-items-center">
                                        <div class="form-group">
                                            <label class="w-100 text-right">Value</label>
                                            <input class="form-control" placeholder="Value" type="text"
                                                formControlName="value" required>
                                        </div>
                                        <a (click)="removeCondition(i)" class="text-danger close-btn p-1"
                                            *ngIf="!isFirst">
                                            <span aria-hidden="true">&times;</span>
                                        </a>
                                    </div>
                                    <div class="col-md-12" [ngClass]="{'next-condition mt-2': !isLast}">
                                        <div class="col-md-offset-3 col-md-6" *ngIf="!isLast">
                                            <div class="form-group">
                                                <label class="w-100 text-center">Next Condition</label>
                                                <select formControlName="next_operator" class="form-control"
                                                    [required]="!isLast ? '' : null">
                                                    <option [value]="null" [disabled]="true">Select Condition
                                                    </option>
                                                    <option [value]="'OR'">OR</option>
                                                    <option [value]="'AND'">AND</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="w-100 text-center text-danger" *ngIf="addRulesForm.invalid && errorMessage">
                        {{errorMessage}}
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button *ngIf="memoryVars.length" type="button" class="btn btn-green" (click)="addCondition()">Add
                    Condition</button>
                <button type="button" class="btn" (click)="closeModal();isEdit=false;"><i class="fa fa-times"
                        aria-hidden="true"></i>Cancel</button>
                <button *ngIf="memoryVars.length" type="submit" class="btn btn-gradient"
                    [disabled]="addRulesForm.value.hold_time>15">
                    <i class="fa fa-check"></i>
                    Apply
                </button>
            </div>
        </form>
    </div>
</div>