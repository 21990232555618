import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ServicesService } from '../../services/services.service';
import { showNotification } from '../../services/notification';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators, AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-memory',
  templateUrl: './memory.component.html',
  styleUrls: ['./memory.component.scss','../tags/tags.component.scss']
})
export class MemoryComponent implements OnInit {

  public isGettingMem:boolean = false;
  public memVarList:any = [];
  public loadingBots:boolean = false;
  public isEdit:boolean = false;
  public selectedMemId;
  public isAddingMemVar:boolean = false;
  public isAdding:boolean = false;
  public botList = [];
  public addMemory: FormGroup;
  public botID:number = parseInt(localStorage.getItem('botID'), 10);
  public customerID:number = parseInt(localStorage.getItem('Customer_ID'), 10);
  public customerType:number = parseInt(localStorage.getItem('Customer_Type'), 10);
  @ViewChild('addMemoryModal', { static: false }) addMemoryModal: ModalDirective;
  memVarTypes=[
  {name:'String',value:'string'},
  {name:'Integer',value:'integer'},
  {name:'Decimal',value:'decimal'},
  {name:'Boolean',value:'boolean'},
  {name:'Dictionary',value:'dictionary'},
  {name:'List',value:'list'},
  ];

  constructor(private dataService: ServicesService, private _fb:FormBuilder) { }

  ngOnInit(): void {
    this.getBotList();
    this.clearAddMem();
  }

  clearAddMem(){
    this.addMemory = this._fb.group({
      'bot_id': this.botID,
      'AgentID': new FormControl(this.customerID),
      'AgentType': new FormControl(this.customerType),
      'display_name': new FormControl('', Validators.required),
      'expiry': new FormControl('', Validators.required),
      'variable_type': new FormControl('string', Validators.required)
    });

    // {
      //       first: new FormControl({value: 'Nancy', disabled: true}, Validators.required),
      //       last: new FormControl('Drew', Validators.required)
      //     }
    }

    getBotList(){
      this.loadingBots = true;
      this.dataService.getBotList({
        CustomerID:this.customerID,
        CustomerType:this.customerType})
      .subscribe((res) =>{
        if(res.status_code==200){
          this.botList = res.data;
        }
        this.loadingBots = false;
      }
      ,
      (error)=>{
        this.loadingBots = false;
        console.log("Bot list error");
      }
      );
    }

    changeBot(botid){
      localStorage.setItem('botID', botid);
      this.botID = botid;
      this.getMemVars();
    }

    getMemVars(){
      this.isGettingMem = true;
      this.dataService.getMemVarList({
        "bot_id":this.botID,
        "AgentID":this.customerID,
        "AgentType":this.customerType
      }).subscribe(
      res=>{
        this.isGettingMem = false;
        this.memVarList = res.data;
      },
      error=>{
        console.log(error);
        this.isGettingMem = false;
        showNotification('warning',error);
      })
    }

    editMemory(item){
      this.isEdit = true;
      this.selectedMemId = item.variable_id;
      this.addMemory = this._fb.group({
        'bot_id': this.botID,
        'AgentID': new FormControl(this.customerID),
        'AgentType': new FormControl(this.customerType),
        'variable_id': new FormControl(item.variable_id),
        'display_name': new FormControl(item.display_name, Validators.required),
        'expiry': new FormControl(item.expiry, Validators.required),
        'variable_type': new FormControl(item.variable_type, Validators.required),
      });
      this.addMemoryModal.show();
    }

    submitMem(form){
      this.isAddingMemVar = true;
      if(this.checkMemRegex(form.value.display_name) && !this.isUniqueMem(form.value.display_name)){
        if(!this.isEdit){
          this.dataService.createMemVar(form.value).subscribe(
            res=>{
              if(res.status_code==200){
                showNotification('success','<span>Added Successfully!</span>');
                this.getMemVars();
                this.addMemoryModal.hide();
              } else {
                showNotification('warning',res.status);
              }
              this.isAddingMemVar = false;
            },
            error=>{
              console.log(error);
              showNotification('warning','Oops! Error while adding memory variables');
            })
        } else {
          console.log(form.value);
          this.dataService.editMemVar(form.value).subscribe(
            res=>{
              if(res.status_code==200){
                showNotification('success','<span>Edited Successfully!</span>');
                this.addMemoryModal.hide();
                this.isEdit = false;
              }
              this.isAddingMemVar = false;
              this.getMemVars();
            },
            error=>{
              console.log(error);
              showNotification('warning','Oops! Error while adding memory variables');
            })
        }
      } else {
        this.isAddingMemVar = false;
        showNotification('warning','Variable name must be unique, Atleast 3 characters & First character must be an Alphabet');
      }
    }

    checkMemRegex(mem_name){
      let memRegex=/^[A-Za-z][a-zA-Z0-9_ ]{2,}$/;
      return memRegex.test(mem_name);
    }

    isUniqueMem(name){
      let isDuplicate = false;
      if(this.isEdit){
        let mems = this.memVarList.filter(ae=>ae.display_name==name);
        for(let i in mems){
          if(mems[i].variable_id != this.selectedMemId){isDuplicate = true;}
        }
      } else {
        for(let i in this.memVarList){
          if(this.memVarList[i].display_name===name){isDuplicate = true;}
        }
      }
      return isDuplicate;
    }

  }
