import { Routes } from '@angular/router';

import { HomeComponent } from '../../home/home.component';
import { UserComponent } from '../../user/user.component';
import { SpectateComponent } from '../../spectate/spectate.component';
import { ManagementComponent } from '../../management/management.component';
import { PeopleComponent } from '../../people/people.component';
import { TicketsComponent } from '../../tickets/tickets.component';
import { ProfileComponent } from '../../profile/profile.component';
import { MemoryComponent } from '../../management/memory/memory.component';
import { ServicesService } from '../../services/services.service';
import { UnifiedInboxComponent } from '../../unified-inbox/unified-inbox.component';
import { AssignRulesComponent } from 'app/assign-rules/assign-rules.component';

export const AdminLayoutRoutes: Routes = [
    { path: 'dashboard', component: HomeComponent },
    { path: 'user', component: UserComponent },
    { path: 'profile', component: ProfileComponent },
    { path: 'spectate', component: SpectateComponent, canActivate: [ServicesService] },
    { path: 'manage', component: ManagementComponent, canActivate: [ServicesService] },
    { path: 'people', component: PeopleComponent, canActivate: [ServicesService] },
    { path: 'tickets', component: TicketsComponent, canActivate: [ServicesService] },
    { path: 'memory', component: MemoryComponent, canActivate: [ServicesService] },
    { path: 'unified-inbox', component: UnifiedInboxComponent },
    { path: 'assign-rules', component: AssignRulesComponent },
];
