<div class="container-fluid">
    <div class="row">
        <button type="button" class="btn btn-green btn-sm add-agent-btn" (click)="addDepartmentModal.show()"><i
                *ngIf="!isAdding" class="fa fa-plus"></i>&nbsp;Add Department</button>
        <div class="col-md-6 col-sm-6">
            <div class="manage-table">
                <div class="card">
                    <div class="content table-responsive table-full-width scroll-table">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th>Department</th>
                                    <th>Assistant</th>
                                    <th>Created by</th>
                                    <th>Support-agents</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let dept of departments; let i=index" [ngClass]="{'selected':selected[i]}"
                                    (click)="selectDept(dept,i)">
                                    <td>{{dept.DepartmentName}}</td>
                                    <td>{{dept.BotName}}</td>
                                    <td>{{dept.CreatedByName}}</td>
                                    <td class="text-center">{{dept.LiveAgentCount}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6 col-sm-6">
            <div class="department-details row" *ngIf="(deptDetails | json) != '{}' && !isFetching">

                <div class="col-md-12">
                    <div class="card card-stats">
                        <div class="card-body ">
                            <div class="w-100 text-right">
                                <a (click)="editDept(deptDetails)"><i class="fa fa-pencil-square-o text-primary"
                                        aria-hidden="true"></i></a>
                                <a *ngIf="deptDetails.DepartmentName!='ADMIN'" (click)="deleteDepartmentModal.show()"><i
                                        class="fa fa-trash text-danger" aria-hidden="true"></i></a>
                            </div>
                            <div class="row">
                                <div class="col-xs-6">
                                    <div class="numbers">
                                        <p class="card-category cat"><b>{{selectedDept}}</b></p>
                                        <p class="card-category"><label>Admins :</label>
                                            {{deptDetails?.admins.length}}&nbsp;&nbsp;<label>Agents :</label>
                                            <b>&nbsp;{{deptDetails?.agents.length}}</b>
                                        </p>
                                        <p class="card-category"><label>Created By :&nbsp; </label>
                                            <b>{{deptDetails?.CreatedByName}}</b>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-xs-6">
                                    <span class="card-category"><label>Away Message :</label>
                                    </span>
                                    <div class="htmlshow" [innerHTML]="deptDetails?.AwayMessage"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 business-hours">
                    <app-business-hours [selectedDept]="selectedDeptDetails.DepartmentID"></app-business-hours>
                </div>
                <div class="col-md-12 agent-list" *ngIf="deptDetails.Admins?.length!=0">
                    <div class="card card-stats" *ngIf="deptDetails['admins'].length!=0">
                        <div class="card-header">
                            <h5 class="text-center text-uppercase pdt10 label-agent py-2"><i class="fa fa-certificate"
                                    aria-hidden="true" title="Admin"></i>Admins</h5>
                        </div>
                        <div class="card-body">
                            <div class="row" *ngIf="deptDetails.admins.length!=0">
                                <div class="col-md-6" *ngFor="let item of deptDetails.admins">
                                    <div class="agents">
                                        <img src="{{item.image_url}}" width="50" height="50">
                                        <div>{{item.full_name}}</div>
                                    </div>
                                    <a class="red is-active" title="Inactive" *ngIf="!item.is_active"><i
                                            class="fa fa-circle" aria-hidden="true"></i></a>
                                    <a class="delete-agents" *ngIf="item.type!=1"
                                        (click)="deleteDepartmentAgentModal.show();deptAgentDetails=item"><i
                                            class="fa fa-trash text-danger" aria-hidden="true"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card card-stats" *ngIf="deptDetails['agents'].length!=0">
                        <div class="card-header">
                            <h5 class="text-center pdt10 label-agent"> <i class="fa fa-user-circle-o" aria-hidden="true"
                                    title="Agent"></i>Agents</h5>
                        </div>
                        <div class="card-body ">
                            <div class="row">
                                <div class="col-md-6 text-center" *ngFor="let item of deptDetails.agents">
                                    <div class="agents">
                                        <img src="{{item.image_url}}" width="50" height="50">
                                        <div>
                                            {{item.full_name}}</div>
                                    </div>
                                    <a class="red is-active" title="Inactive" *ngIf="!item.is_active"><i
                                            class="fa fa-circle" aria-hidden="true"></i></a>
                                    <a class="delete-agents"
                                        (click)="deleteDepartmentAgentModal.show();deptAgentDetails=item"><i
                                            class="fa fa-trash text-danger" aria-hidden="true"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="agent-loading text-center" *ngIf="isFetching">
                <i class="fa fa-spinner fa-pulse"></i>
            </div>
        </div>
    </div>
</div>

<div bsModal #addDepartmentModal="bs-modal" [config]="{backdrop: 'static'}" class="modal fade fbmsg" tabindex="-1"
    role="dialog" aria-labelledby="dialog-auto-name">
    <div class="modal-dialog modal-md">
        <div class="modal-content">
            <form [formGroup]="addDepartmentForm" (ngSubmit)="addDepartment(addDepartmentForm.value);" ngNativeValidate>
                <div class="modal-header">
                    <div class="header">
                        <h4 class="title mg0"><span *ngIf="!isEdit">Add</span><span *ngIf="isEdit">Edit</span>
                            Department</h4>
                        <button type="button" class="close"
                            (click)="addDepartmentModal.hide();isEdit=false;clearDepartmentForm();">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Department Name</label>
                                <input class="form-control" (input)="checkDeptName()" placeholder="Name" type="text"
                                    formControlName="DepartmentName"
                                    [attr.disabled]="addDepartmentForm.value.DepartmentName=='ADMIN' ? '': null"
                                    required>
                                <small class="text-danger" *ngIf="isDuplicateDeptName">Department Already
                                    Exists!</small>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Assistant</label>
                                <select class="form-control" (change)="checkDeptName()" formControlName="BotID"
                                    [attr.disabled]="isEdit ? '': null" required>
                                    <option *ngFor="let item of botList" value="{{item.BotID}}">{{item.BotName}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>Away Message</label>
                                <!-- <textarea class="form-control" placeholder="Message" type="text" formControlName="AwayMessage" rows="4" required></textarea>  -->
                                <ckeditor [formControl]="addDepartmentForm.controls['AwayMessage']" type="inline"
                                    class="form-control" [config]="config" debounce="500" required>
                                </ckeditor>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>CSAT Question</label>
                                <!-- <textarea class="form-control" placeholder="Message" type="text" formControlName="feedback_question" rows="4" required></textarea>  -->
                                <ckeditor [formControl]="addDepartmentForm.controls['feedback_question']" type="inline"
                                    class="form-control" [config]="config" debounce="500" required>
                                </ckeditor>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>Hold Message</label>
                                <ckeditor [formControl]="addDepartmentForm.controls['hold_message']" type="inline"
                                    class="form-control" [config]="config" debounce="500" required>
                                </ckeditor>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Hold Time (in Min)</label>
                                <input class="form-control" placeholder="Minutes" type="number" min="1" max="15"
                                    formControlName="hold_time" required>
                                <small class="text-danger" *ngIf="addDepartmentForm.value.hold_time>15">Must not above
                                    15 min</small>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Auto Close Time (in Min)</label>
                                <input class="form-control" placeholder="Minutes" type="number" min="1"
                                    formControlName="auto_close" required>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Auto Reject Time (in Min)</label>
                                <input class="form-control" placeholder="Minutes" type="number" min="1"
                                    formControlName="auto_reject" required>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Idle Chat Time (in Min)</label>
                                <input class="form-control" placeholder="Minutes" type="number" min="1"
                                    formControlName="idle_chat_time" required>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <div class="form-group">
                                    <div class="checkbox mt-2">
                                        <input id="isAutoAssign" type="checkbox" formControlName="isAutoAssign">
                                        <label for="isAutoAssign"><b>Auto Assign</b></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <div class="form-group">
                                    <div class="checkbox mt-2">
                                        <input id="ask_feedback" type="checkbox" formControlName="ask_feedback">
                                        <label for="ask_feedback"><b>Ask Feedback</b></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn"
                        (click)="addDepartmentModal.hide();isEdit=false;clearDepartmentForm();"><i class="fa fa-times"
                            aria-hidden="true"></i>Cancel</button>
                    <button type="submit" class="btn btn-gradient"
                        [disabled]="addDepartmentForm.value.hold_time>15 || isDuplicateDeptName">
                        <i *ngIf="isAdding" class="fa fa-spinner fa-spin fa-pulse"></i>
                        <i *ngIf="!isAdding" class="fa fa-check"></i>
                        <span *ngIf="!isEdit">Save</span><span *ngIf="isEdit">Edit</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>

<div bsModal #deleteDepartmentModal="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1"
    role="dialog" aria-labelledby="dialog-auto-name">
    <div class="modal-dialog modal-md">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Delete Department</h5>
                <button type="button" class="close" (click)="deleteDepartmentModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body text-center">
                <p>Are you sure to delete <b>{{selectedDeptDetails?.DepartmentName}}</b> department?</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn" (click)="deleteDept()">
                    <i *ngIf="!isDeleting" class="fa fa-trash-o"></i>
                    <i *ngIf="isDeleting" class="fa fa-spin fa-spinner fa-pulse"></i>
                    Yes</button>
                <button type="button" class="btn btn-gradient" (click)="deleteDepartmentModal.hide()"><i
                        class="fa fa-times" aria-hidden="true"></i>No</button>
            </div>
        </div>
    </div>
</div>

<div bsModal #deleteDepartmentAgentModal="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1"
    role="dialog" aria-labelledby="dialog-auto-name">
    <div class="modal-dialog modal-md">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Delete Agent</h5>
                <button type="button" class="close" (click)="deleteDepartmentAgentModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body text-center">
                <p>Are you sure to delete <b>{{deptAgentDetails.full_name}}</b> under
                    <b>{{selectedDeptDetails?.DepartmentName}}</b> department?
                </p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn" (click)="deleteAgentDept()">
                    <i *ngIf="!isDeletingAgent" class="fa fa-trash-o"></i>
                    <i *ngIf="isDeletingAgent" class="fa fa-spin fa-spinner fa-pulse"></i>
                    Yes</button>
                <button type="button" class="btn btn-gradient" (click)="deleteDepartmentAgentModal.hide()"><i
                        class="fa fa-times" aria-hidden="true"></i>No</button>
            </div>
        </div>
    </div>
</div>