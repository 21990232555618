import { Component, OnInit, ElementRef, ViewChild, Renderer2 } from '@angular/core';
import { ServicesService } from '../services/services.service';
import { showNotification } from '../services/notification';
import { trigger, style, animate, transition, state, group } from '@angular/animations';
// import { Router } from '@angular/router';
import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import * as data from '../../assets/js/countrycodes.json';
import { DomSanitizer } from '@angular/platform-browser';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import moment from 'moment';
import { DaterangepickerDirective } from 'ngx-daterangepicker-material';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { NotificationService } from 'app/services/notification.service';
import { SendTemplatesComponent } from './send-templates/send-templates.component';
import { Subscription } from 'rxjs';
import { FilterRuleComponent } from './filter-rule/filter-rule.component';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Console } from 'console';

// import { PopoverDirective } from 'ngx-bootstrap/popover';

@Component({
	selector: 'user',
	templateUrl: './user.component.html',
	styleUrls: ['./user.component.scss'],
	animations: [
		trigger('slideInOut', [
			state('in', style({ height: '*', opacity: 0 })),
			transition(':leave', [
				style({ height: '*', opacity: 1 }),

				group([
					animate(50, style({ height: 0 })),
					animate('50ms ease-in-out', style({ 'opacity': '0' }))
				])

			]),
			transition(':enter', [
				style({ height: '0', opacity: 0 }),

				group([
					animate(50, style({ height: '*' })),
					animate('100ms ease-in-out', style({ 'opacity': '1' }))
				])

			])
		])
	]
})
export class UserComponent implements OnInit {

	newMessage: string;
	chatlog: any[] = [];
	chatlogList: any = {};
	agentProfiles = [];
	unreadCount: any = {};
	leadDetails: any = {};
	userDetails: any = {};
	userDetail: any = {};
	usersList: any[] = [];
	usersListDetails: any[] = [];
	seluserList: any[] = [];
	selHandedOverUsersList: any[] = [];
	selClosedUserList: any[] = [];
	userTags = [];
	selectedTags = [];
	selectedUser: boolean[] = [false];
	current_user: string = localStorage.getItem('Customer_Email');
	botID: number = parseInt(localStorage.getItem('botID'), 10);
	selected_bot = localStorage.getItem('botName');
	agent_img = localStorage.getItem('agent_imgurl');
	customerID: number = parseInt(localStorage.getItem('Customer_ID'), 10);
	customerType: number = parseInt(localStorage.getItem('Customer_Type'), 10);
	customerName: string = localStorage.getItem('Customer_Name');
	departments: any = [];
	handoverDepartments: any = [];
	selected_user: any = 0;
	selected_channel: any = 1;
	channelsList = [];
	startChat: boolean = false;
	isfetchingLog: boolean = false;
	isaddTags: boolean = false;
	showDetails: boolean = false;
	showHold: boolean = false;
	// showLeadDetails:boolean=false;
	showTagDetails: boolean = false;
	tagsList = [];
	isAdminDepartment: boolean = false;
	mentionConfig = {};
	selectedDeptID: number;
	activeDeptID: number;
	selected_nodeid: number;
	replies: any[];
	pingTime;
	pingCounter: number = 0;
	pingTimeMilli: number = 180000;
	leadForm: FormGroup;
	leadFields = [false];
	addMemForm: FormGroup;
	holdForm: FormGroup;
	starredMemVarsList = [];
	country: any = (data as any).default;
	selectedStatus = null;
	selectedStatusType = 'agent';
	static_url = environment.staticUrl;
	showClearBox = false;
	// setMuteAudio = true;
	memVarTypes = [
		{ name: 'String', value: 'string' },
		{ name: 'Integer', value: 'integer' },
		{ name: 'Decimal', value: 'decimal' },
		{ name: 'Boolean', value: 'boolean' },
		{ name: 'Dictionary', value: 'dictionary' },
		{ name: 'List', value: 'list' },
	];
	@ViewChild('chatBox') private chatBox: ElementRef;
	@ViewChild('pvtnote') private pvtnote: ElementRef;
	@ViewChild('closeChatModal', { static: false }) closeChatModal: ModalDirective;
	@ViewChild('holdChatModal', { static: false }) holdChatModal: ModalDirective;
	@ViewChild('resolveModal', { static: false }) resolveModal: ModalDirective;
	// @ViewChild('pvtNotePop') pvtNotePop: PopoverDirective;
	agents: any;
	showEmojiPicker = false;
	@ViewChild('emojiButton') emojiButton: ElementRef;
	@ViewChild('emojiBox') emojiBox: ElementRef;
	@ViewChild(DaterangepickerDirective, { static: false }) filterPicker: DaterangepickerDirective;
	@ViewChild('replyTabs', { static: false }) replyTabs?: TabsetComponent;
	modalRef?: BsModalRef;
	onHideSubscription: Subscription;
	filterFormData: any;


	constructor(private dataService: ServicesService, private _fb: FormBuilder,
		private sanitizer: DomSanitizer, private renderer: Renderer2,
		private notificationService: NotificationService, private modalService: BsModalService) {
		this.renderer.listen('window', 'click', (e: Event) => {
			if (e.target !== this.emojiButton?.nativeElement && e.target !== this.emojiBox?.nativeElement &&
				!this.emojiBox?.nativeElement.contains(e.target as Node)) {
				this.showEmojiPicker = false;
			}
		});
	}

	leadDataList = [];
	leadmoreForm: FormGroup;
	showmoreDetails: boolean = false;
	leadloader: boolean = false;
	isLeadEdit: boolean = false;
	isaddLead: boolean = false;
	isaddMemory: boolean = false;
	isPvtNoteAdd: boolean = false;
	categoriesSettings;
	isAssigned = false;
	filterLead: string = '';
	filterTicketName: string = '';
	filterTicketPriority: string = null;
	filterChannel: number = 0;
	filterTags: any = [];
	filterDate: any;
	filterOn = false;
	isUploading = false;
	priorityList = [
		{ "id": 1, "name": "General" },
		{ "id": 2, "name": "Low" },
		{ "id": 3, "name": "Medium" },
		{ "id": 4, "name": "High" },
		{ "id": 5, "name": "Critical" }]

	ranges: any = {
		'Today': [moment(), moment()],
		'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
		'Last 7 Days': [moment().subtract(6, 'days'), moment()],
		'Last 30 Days': [moment().subtract(29, 'days'), moment()]
	}
	locale: any = {
		applyLabel: 'Select',
		format: 'MM/DD/YYYY',
		daysOfWeek: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
		monthNames: ["January", "February", "March", "April", "May", "June",
			"July", "August", "September", "October", "November", "December"],
	}

	searchFilter = 'progress';
	showFilter = false;

	ngOnInit(): void {
		this.notificationService.requestPermission();
		this.categoriesSettings = {
			text: "Select tags",
			enableCheckAll: true,
			enableSearchFilter: true,
			lazyLoading: true
		};
		this.getAllReplies();
		this.getDepartments();
		this.getHandoverDepartments();
		this.getStarMemVars();
		this.getAllTags();
		this.getAgents();
		this.leadForm = this._fb.group({
			'name': ['', [Validators.required]],
			'email': ['', [Validators.required]],
			'phone': ['', [Validators.required]],
			'company': ['', [Validators.required]],
			'location': ['', [Validators.required]],
		});
		this.holdForm = this._fb.group({
			'hold_message': ['', [Validators.required]],
			'hold_time': ['', [Validators.required]]
		});

		this.getChannels()

	}

	getSafeUrl(url) {
		return this.sanitizer.bypassSecurityTrustResourceUrl(url)
	}

	changeIframe(url) {
		return this.getSafeUrl(url);
	}

	connectChat() {
		this.dataService.onMessageLA(this.botID, this.current_user, this.selectedDeptID, this.customerID, this.selected_channel).subscribe((message: any) => {
			// console.log("LA USER--->", message);
			if (message?.play_sound) this.playAudio();
			if (message.pong) {
				this.pingCounter = 0;
				clearInterval(this.pingTime);
				this.pingTime = setInterval(() => this.sendPing(), this.pingTimeMilli);
			} else {
				if (this.pingTime) {
					clearInterval(this.pingTime);
					this.pingTime = setInterval(() => this.sendPing(), this.pingTimeMilli);
				}
				if (message.isQueue && message.disconnect) { //when a user in userlist diconnects
					// const index = this.usersList.indexOf(message.userid);
					// this.usersListDetails = this.usersListDetails.filter(ae => ae.userid != message.userid);
					// if (index > -1) { this.usersList.splice(index, 1); }
					// Remove user from seluserList
					this.seluserList = this.seluserList.filter(user => user.userid !== message.userid);

					// Remove user from usersList
					const index = this.usersList.indexOf(message.userid);
					if (index !== -1) {
						this.usersList.splice(index, 1);
					}

				} else if (message.isQueue) { //when new user connects
					// if (!this.setMuteAudio) this.playAudio();
					var exist = -1;
					for (let i in this.seluserList) {
						if (this.seluserList[i].userid == message.userid) { exist = parseInt(i); }
					}
					// if (!this.isAssigned && this.filterOn) return;
					if (exist == -1) {
						this.usersList.push(message.userid);
						// this.usersListDetails.push(message);
						this.addOrUpdateUserList(this.usersListDetails, message);
						this.setTitleIcon('New Message!');
					} else {

						//when a user in seluserlist diconnects
						// this.seluserList[exist].disconnect = false;
						// this.dataService.sendMessageLA({
						// 	selected: true,
						// 	userid: this.seluserList[exist].userid,
						// 	ticket_id: this.getUserSpec('ticket_id', this.seluserList[exist].userid),
						// 	channelid: this.getUserSpec('channelid', this.seluserList[exist].userid),
						// 	channel_details: this.getUserSpec('channel_details', this.seluserList[exist].userid)
						// });
					}

					var uexist = -1;
					for (let i in this.usersList) {
						if (this.usersList[i].userid == message.userid) { exist = parseInt(i); }
					}
					if (exist == -1) {
						for (let i = 0; i < this.usersListDetails.length; i++) {
							if (this.usersListDetails[i].userid == message.userid) this.usersListDetails[i] = message;
						}
					}
				}

				if (message.clear_all && !this.isAssigned) this.usersList = [];

				if (message.type == 'log' && !message.reconnect) {//get log of selected user
					this.showDetails = true;
					// this.showLeadDetails = true;
					this.userDetails[message.userid] = message.message.userdetails;
					this.leadDetails[message.userid] = message.message.lead;
					this.userDetail = message.message.userdetails;
					this.leadForm = this._fb.group({
						'name': [this.leadDetails[message.userid]?.name, [Validators.required]],
						'email': [this.leadDetails[message.userid]?.email, [Validators.required]],
						'phone': [this.leadDetails[message.userid]?.phone, [Validators.required]],
						'company': [this.leadDetails[message.userid]?.company, [Validators.required]],
						'location': [this.leadDetails[message.userid]?.location, [Validators.required]],
					});
					this.isfetchingLog = false;
					this.chatlog = [...message.message.log];
					this.agentProfiles = message.message.agent_profiles;
					for (let i in this.chatlog) {
						if (this.chatlog[i].message?.cardtypeid == 1 && this.chatlog[i].message?.feedbacktypeid == 2) {
							var imgs = this.chatlog[i].message.message.split("_$_");
							this.chatlog[i].message.message = imgs;
						}
					}
					this.chatlogList[message.userid] = this.chatlog;
					this.unreadCount[message.userid] = 0; //unread count initialise

					for (let i in this.seluserList) { //new
						if (this.seluserList[i].userid == message.userid) {
							this.seluserList[i].disconnect = !message.isLive;
						}
					}
				} else if (message.type == 'log' && message.reconnect) {
					this.showDetails = true;
					// this.showLeadDetails = true;
					this.userDetails[message.userid] = message.message.userdetails;
					this.leadDetails[message.userid] = message.message.lead;
					this.leadForm = this._fb.group({
						'name': [this.leadDetails[message.userid]?.name, [Validators.required]],
						'email': [this.leadDetails[message.userid]?.email, [Validators.required]],
						'phone': [this.leadDetails[message.userid]?.phone, [Validators.required]],
						'company': [this.leadDetails[message.userid]?.company, [Validators.required]],
						'location': [this.leadDetails[message.userid]?.location, [Validators.required]],
					}); //reconecting user log
					var log = [...message.message.log];
					for (let i in log) {
						if (log[i].message.cardtypeid == 1 && log[i].message.feedbacktypeid == 2) {
							var imgs = log[i].message.message.split("_$_");
							log[i].message.message = imgs;
						}
					}
					this.chatlogList[message.userid] = log;
					for (let i in this.seluserList) {
						if (this.seluserList[i].userid == message.userid) {
							this.seluserList[i].disconnect = message.isLive;
						}
					}
				}

				if (message.auto_close) {
					this.closeChatTrigger(true, true);
				}

				if (message.isLive && message.disconnect) { //if selected user disconnects
					for (let i in this.seluserList) {
						if (this.seluserList[i].userid == message.userid) {
							this.seluserList[i].disconnect = true;
						}
					}
				}

				if (message.forced_disconnect) { //if selected user reconnects with new ticket
					let uidx = this.usersList.indexOf(message.userid);
					while (uidx > -1) {
						this.usersList.splice(uidx, 1);
						uidx = this.usersList.indexOf(message.userid);
					}

					let suidx = this.seluserList.findIndex(user => user.userid == message.userid);
					while (suidx > -1) {
						this.seluserList.splice(suidx, 1);
						suidx = this.seluserList.findIndex(user => user.userid == message.userid);
					}

				}


				if (message.isLive && message.type == 'text') { //live chat message
					let date = new Date();
					let data = {
						// 'time': {$date: date},
						'time': date,
						'sender': "user",
						'isLive': true,
						'message': message.message,
						'message_type': message.message_type
					}
					this.chatlogList[message.userid].push(data);
					if (message.userid != this.selected_user) {
						this.unreadCount[message.userid]++; //unread comment increment 
					}
				}

				if (message.assigned_users) {
					if (message.status == 'progress') {
						const existingUserIds = new Set(this.seluserList.map(user => user.userid));

						message.users.forEach(user => {
							if (!existingUserIds.has(user.userid)) {
								const newUser = {
									userid: user.userid,
									selected: false,
									disconnect: true,
									is_new: false
								};

								// this.usersListDetails.push(user);
								this.addOrUpdateUserList(this.usersListDetails, user);
								this.seluserList.push(newUser);
							}
						});
					} else if (message.status == 'closed') {
						this.selClosedUserList = [];
						message.users.forEach(user => {
							const newUser = {
								userid: user.userid,
								selected: false,
								disconnect: true,
								is_new: false
							};

							// this.usersListDetails.push(user);
							this.addOrUpdateUserList(this.usersListDetails, user);
							this.selClosedUserList.push(newUser);
						});
					} else if (message.status == 'handed_over') {
						this.selHandedOverUsersList = [];
						message.users.forEach(user => {
							const newUser = {
								userid: user.userid,
								selected: false,
								disconnect: true,
								is_new: false
							};

							// this.usersListDetails.push(user);
							this.addOrUpdateUserList(this.usersListDetails, user);
							this.selHandedOverUsersList.push(newUser);
						});
					}
				}

				if (message.connected) this.getAssignedUsers();


			}
			this.scrollToBottom();
		});
	}

	addOrUpdateUserList(array: any[], newObject: any) {
		const index = array.findIndex(obj => obj.userid === newObject.userid);
		if (index !== -1) {
			array[index] = newObject; // Replace existing object
		} else {
			array.push(newObject); // Add new object
		}
	}

	sendMessage() {
		let date = new Date();
		if (typeof this.newMessage === 'string' && this.newMessage.trim() !== '') {
			this.dataService.sendMessageLA({
				'isLive': true,
				'userid': this.selected_user,
				'message': this.newMessage,
				'node_id': this.getUserSpec('node_id', this.selected_user),
				'ticket_id': this.getUserSpec('ticket_id', this.selected_user),
				// 'agentName': 'KRUZALMIGHTY',
				'agentName': this.customerName,
				'agent_img': this.agent_img,
				'channelid': this.getUserSpec('channelid', this.selected_user),
				'channel_details': this.getUserSpec('channel_details', this.selected_user)
			});
			let data = {
				'time': date,
				// 'time': {$date: date},
				'sender': "live_agent",
				'isLive': true,
				'message': this.newMessage
			}
			this.chatlogList[this.selected_user].push(data);
			// this.chatlog.push(data);
			this.newMessage = '';
			this.scrollToBottom();
			this.resetTitleIcon();
		} else {
			showNotification('warning', '<span>Enter something and send</span>');
		}
	}

	selectUser(uid) {
		this.chatlog = [];
		this.isfetchingLog = true;
		this.selected_user = uid;
		this.getLeadData(this.selected_user);
		this.getTags(this.selected_user);
		this.startChat = true;
		this.resetTitleIcon();

		this.dataService.sendMessageLA({
			selected: true,
			userid: uid,
			agentName: this.customerName,
			agent_img: this.agent_img,
			// assigned: this.isAssigned,
			ticket_id: this.getUserSpec('ticket_id', this.selected_user),
			channelid: this.getUserSpec('channelid', this.selected_user),
			channel_details: this.getUserSpec('channel_details', this.selected_user)
		});


		const uniqueIds = this.seluserList.map(ae => parseInt(ae.userid));
		if (!uniqueIds.includes(parseInt(uid))) {
			this.seluserList.push({
				userid: uid,
				selected: true,
				disconnect: false,
				is_new: true
			});
		}

		for (let i in this.seluserList) {
			if (parseInt(this.seluserList[i].userid) != parseInt(uid)) {
				this.seluserList[i].selected = false;
			}
		}
		const index = this.usersList.indexOf(uid);
		if (index > -1) { this.usersList.splice(index, 1); }

		this.isAssigned = true;
	}

	scrollToBottom(): void {
		if (this.chatlog.length > 0) {
			setTimeout(() => {
				this.chatBox.nativeElement.scrollTop = this.chatBox.nativeElement.scrollHeight;
			}, 50);
		}
	}

	checkIfOnline(uid) {
		let user_details: any = this.seluserList.filter(ae => ae.userid == uid);
		return user_details.length > 0 ? !user_details.disconnected : false;
	}

	selectSelUser(uid, isNew) {
		this.chatlog = [];
		this.isfetchingLog = true;
		this.selected_user = uid;
		this.getLeadData(uid);
		this.getTags(uid);
		this.unreadCount[uid] = 0; //unread count reset
		this.seluserList.forEach(user => { user.selected = user.userid === uid; });

		if (isNew) {
			this.isfetchingLog = false;
			this.chatlog = this.chatlogList[uid]
			this.leadForm = this._fb.group({
				'name': [this.leadDetails[uid]?.name, [Validators.required]],
				'email': [this.leadDetails[uid]?.email, [Validators.required]],
				'phone': [this.leadDetails[uid]?.phone, [Validators.required]],
				'company': [this.leadDetails[uid]?.company, [Validators.required]],
				'location': [this.leadDetails[uid]?.location, [Validators.required]],
			});
			this.userDetail = this.userDetails[uid];
		} else {
			this.dataService.sendMessageLA({
				selected_from_assigned: true,
				status: this.searchFilter,
				selected: true,
				userid: uid,
				agentName: this.customerName,
				agent_img: this.agent_img,
				ticket_id: this.getUserSpec('ticket_id', this.selected_user),
				channelid: this.getUserSpec('channelid', this.selected_user),
				channel_details: this.getUserSpec('channel_details', this.selected_user)
			});
		}
		this.startChat = true;
		this.seluserList.forEach(user => {
			if (user.userid === uid) { user.isNew = true; }
		});

		this.scrollToBottom();
	}

	getAgents() {
		this.dataService.getAllAgentsList(this.botID).subscribe(
			res => {
				this.agents = res.filter(ae => ae.liveagent_id != this.customerID);
			}, error => {
				showNotification('danger', error);
			})
	}

	closeChat(resolve) {
		if (resolve == 'resolved') {
			// this.closeChatTrigger(true, auto_close);
			this.resolveModal.show();
		}
	}

	resolveChat() {
		this.closeChatTrigger(true, false);
		this.resolveModal.hide();
	}

	handoverChat(auto_close, agentId?, groupId?) {
		this.closeChatTrigger(false, auto_close, agentId, groupId);
	}

	closeChatTrigger(resolve, auto_close, agentId?, groupId?) {
		let data = {
			auto_close: auto_close,
			disconnect: true,
			userid: this.selected_user,
			isResolved: resolve,
			handover_to: null,
			handover_group: null,
			agentName: this.customerName,
			agent_img: this.agent_img,
			ticket_id: this.getUserSpec('ticket_id', this.selected_user),
			channelid: this.getUserSpec('channelid', this.selected_user),
			channel_details: this.getUserSpec('channel_details', this.selected_user)
		}
		if (agentId) { data.handover_to = agentId; this.selectedStatus = null; }
		if (groupId) { data.handover_group = parseInt(groupId); this.selectedStatus = null; }
		this.dataService.sendMessageLA(data);
		this.selectedStatus = null

		if (auto_close) {
			showNotification('warning', '<span>User ' + this.selected_user + ' has been disconnected due to no response from live agent!</span>');
		} else {
			if (resolve) showNotification('success', 'Ticket will be Closed after receiving feedback from the User!');
			else showNotification('success', 'Ticket has been handed over successfully!');
		}
		this.resetChatBox(this.selected_user);
		if (this.closeChatModal) this.closeChatModal.hide();
	}

	resetChatBox(user?) {
		this.startChat = false;
		this.chatlog = [];
		if (user) this.seluserList = this.seluserList.filter(ae => ae.userid != user);
		else this.seluserList = !this.isAssigned ? this.seluserList : [];
		this.selClosedUserList = [];
		this.selHandedOverUsersList = [];
		this.unreadCount[this.selected_user] = 0;
		this.chatlogList[this.selected_user] = [];
		this.leadDetails[this.selected_user] = {};
		this.leadForm = this._fb.group({
			'name': ['', [Validators.required]],
			'email': ['', [Validators.required]],
			'phone': ['', [Validators.required]],
			'company': ['', [Validators.required]],
			'location': ['', [Validators.required]],
		});
		this.userDetails[this.selected_user] = {};
		this.userDetail = {};
		this.selected_user = 0;
		this.selected_nodeid = 0;
	}

	seperateString(string) {
		var splitted = string.split("_$_");
		return splitted
	}

	playAudio() {
		let audio = new Audio();
		audio.src = "../../assets/audio/notification.mp3";
		audio.load();
		audio.play();
	}

	onLeadSubmit(value) {
		this.dataService.sendMessageLA({
			userid: this.selected_user,
			channelid: this.getUserSpec('channelid', this.selected_user),
			channel_details: this.getUserSpec('channel_details', this.selected_user),
			lead: value
		});
		this.leadFields = [false];
		showNotification('success', '<span>Details updated successfully</span>');
	}

	getAllReplies() {
		this.dataService.getAllQuickReplies({
			BotID: this.botID,
			AgentID: this.customerID
		}).subscribe(
			res => {
				if (res.status_code == 200) {
					this.replies = res.data;

					this.mentionConfig = new Object();
					this.mentionConfig = { mentions: [] };

					for (let i in this.replies) {
						this.mentionConfig['mentions'].push({
							items: this.replies[i].quick_value,
							triggerChar: this.replies[i].quick_key,
							maxItems: 10,
							returnTrigger: false,
							dropUp: true,
							mentionSelect: this.onMentionSelect
						});
					}
				} else if (res.status_code == 401) {
					this.dataService.logout();
				} else {
					showNotification('warning', res.status);
				}
			}, error => {
				showNotification('danger', error);
			})
	}

	onMentionSelect(selection) {
		// return selection.label;
		return `${selection.label}`;
	}

	getDepartments() {
		this.dataService.getDepartmentsList({
			CustomerID: this.customerID,
			CustomerType: this.customerType
		}).subscribe(
			res => {
				if (res.status_code == 200) {
					this.departments = res.data.filter(ae => ae.BotID == this.botID);
					this.selectedDeptID = this.departments[0].DepartmentID;
					if (this.departments[0].DepartmentName == 'ADMIN') { this.isAdminDepartment = true; }
					else { this.isAdminDepartment = false; }
					this.holdForm = this._fb.group({
						'hold_message': [this.departments[0].hold_message, [Validators.required]],
						'hold_time': [this.departments[0].hold_time, [Validators.required]]
					});
					this.showHold = true;
					this.activeDeptID = this.selectedDeptID;
					// this.handoverDepartments = this.departments.filter(ae => ae.DepartmentID != this.selectedDeptID);
					this.selected_channel = this.departments.length ? this.departments[0].channelid : 1;
					this.connectChat();
					this.pingTime = setInterval(() => this.sendPing(), this.pingTimeMilli);
				} else if (res.status_code == 401) {
					this.dataService.logout();
				} else {
					showNotification('warning', res.status);
				}
			}, error => {
				showNotification('danger', error);
			})
	}

	getHandoverDepartments() {
		this.dataService.getHandoverDepartmentList({
			CustomerID: this.customerID,
			CustomerType: this.customerType
		}).subscribe(
			res => {
				if (res.status_code == 200) {
					this.handoverDepartments = res.data.filter(ae => ae.BotID == this.botID);
				} else if (res.status_code == 401) {
					this.dataService.logout();
				} else {
					showNotification('warning', res.status);
				}
			}, error => {
				showNotification('danger', error);
			})
	}

	listHandoverDepartments() {
		let depts = this.handoverDepartments.filter(ae => ae.DepartmentID != this.selectedDeptID) || [];
		return depts.filter(ae => (ae.DepartmentName == 'ADMIN' && ![1, 2].includes(this.customerType)) || ae.DepartmentName != 'ADMIN');
	}

	changeDepartment() {
		this.terminateSocket();
		let selDept = this.departments.filter(ae => ae.DepartmentID == this.selectedDeptID);
		if (selDept[0].DepartmentName == 'ADMIN') { this.isAdminDepartment = true; }
		else { this.isAdminDepartment = false; }
		this.holdForm = this._fb.group({
			'hold_message': [selDept[0].hold_message, [Validators.required]],
			'hold_time': [selDept[0].hold_time, [Validators.required]]
		});
		setTimeout(() => {
			this.chatlog = [];
			this.chatlogList = [];
			this.usersList = [];
			this.usersListDetails = [];
			this.seluserList = [];
			this.startChat = false;
			this.selHandedOverUsersList = [];
			this.selClosedUserList = [];
			this.isAssigned = false;
			this.searchFilter = 'progress';
			this.showFilter = false;
			this.showClearBox = false;
			this.clearFIlters();
			this.connectChat();
			this.activeDeptID = this.selectedDeptID;
		}, 1000);
	}

	getTile(uid) {
		let user = this.usersListDetails.find(ae => ae.userid == uid);
		return ' - ' + user.requested_department_name ?? '';
	}

	terminateSocket() {
		this.dataService.sendMessageLA({
			terminate: true,
			botID: this.botID,
			department: this.activeDeptID
		});
	}

	getCountryCode(uid) {
		let user = this.usersListDetails.find(ae => ae.userid === uid);

		if (user && user.country) {
			let place = this.country.find(ae => ae.name === user.country);

			if (place) {
				return place.code;
			}
		}

		return "";
	}


	getUserSpec(param, uid) {
		const user = this.usersListDetails.find(ae => ae.userid == uid);

		switch (param) {
			case 'node_id':
				return user ? user.node_id : null;
			case 'name':
				return user ? (user.cf_name !== '' ? user.cf_name : user.userid) : null;
			case 'email':
				return user ? user.cf_email : null;
			case 'phone':
				return user ? user.cf_phone : null;
			case 'department':
				return user ? ' - ' + user.requested_department_name : null;
			case 'ticket_id':
				return user ? user.ticket_id : null;
			case 'channelid':
				return user ? user.channelid : null;
			case 'channel_details':
				return user ? user.channel_details : null;
			case 'timestamp':
				return user ? user.timestamp : null;
			case 'ticket_name':
				return user ? user.ticket_name : null;
			case 'ticket_colour':
				return user ? user.ticket_colour : null;
			case 'ticket_priority':
				return user ? user.ticket_priority : null;
			default:
				return null;
		}
	}


	sendPing() {
		if (this.pingCounter == 3) {
			this.connectChat();
			this.pingCounter = 0;
		} else {
			this.dataService.sendMessageLA({
				ping: true,
			});
			this.pingCounter++;
		}
	}

	getLeadData(userid) {
		this.dataService.getTempStoreValues({ bot_id: this.botID, user_id: userid }).subscribe(
			data => {
				this.leadDataList = data.data;
				if (this.leadDataList.length > 0) { this.showmoreDetails = true; }
			})
	}

	clearMoreLeadForm() {
		this.leadmoreForm = this._fb.group({
			storage_variable: ['null', Validators.required],
			value: ['', Validators.required]
		})
	}

	clearMemForm() {
		this.addMemForm = this._fb.group({
			display_name: ['', Validators.required],
			variable_type: ['null', Validators.required]
		})
	}

	leadMoreSubmit(form) {
		form.value.user_id = this.selected_user;
		form.value.bot_id = this.botID;
		this.leadloader = true;
		if (form.valid) {
			if (!this.isLeadEdit) {
				this.dataService.addTempStoreValues(form.value).subscribe(
					data => {
						this.leadloader = false;
						this.isaddLead = false;
						this.getLeadData(this.selected_user);
						this.clearMoreLeadForm();
						showNotification('success', "Lead Added!");
					},
					error => {
						showNotification('warning', "Error Adding Lead Data");
						this.leadloader = false;
					})
			} else {
				this.dataService.editTempStoreValues(form.value).subscribe(
					data => {
						this.leadloader = false;
						this.isaddLead = false;
						this.isLeadEdit = false;
						this.getLeadData(this.selected_user);
						this.clearMoreLeadForm();
						showNotification('success', "Lead Edited!");
					},
					error => {
						showNotification('warning', "Error Adding Lead Data");
						this.leadloader = false;
					})
			}
		}
	}

	addMemSubmit(form) {
		form.value.bot_id = this.botID;
		form.value.expiry = 2;
		this.leadloader = true;
		if (form.valid) {
			this.dataService.addStarredMemVars(form.value).subscribe(
				data => {
					this.leadloader = false;
					this.isaddMemory = false;
					this.getStarMemVars();
					this.clearMemForm();
					showNotification('success', "Storage Variable Added!");
				},
				error => {
					showNotification('warning', "Error Adding Storage Variables!");
					this.leadloader = false;
				})
		}
	}

	getStarMemVars() {
		this.dataService.getStarRatedStorageVars(this.botID).subscribe(
			data => {
				if (data.status_code) {
					this.starredMemVarsList = data.data;
				} else {
					showNotification('warning', "Error Getting Storage Variables!");
				}
			},
			error => {
				showNotification('warning', "Error Getting Storage Variables!");
			})
	}

	editData(data) {
		this.isaddLead = true;
		this.isLeadEdit = true;
		this.leadmoreForm = this._fb.group({
			storage_variable: [data.storage_variable, Validators.required],
			value: [data.value, Validators.required]
		})
	}

	getAllTags() {
		let data = {
			bot_id: this.botID,
			AgentID: this.customerID,
			AgentType: this.customerType
		}
		this.dataService.getTagsList(data).subscribe(
			res => {
				if (res.status_code == 200) {
					this.tagsList = [];
					for (let i in res.data) {
						if (res.data[i].is_active) {
							this.tagsList.push({
								id: res.data[i].tag_id,
								itemName: res.data[i].tag_name,
								color: res.data[i].color
							})
						}
					}
				}
			},
			error => {
				showNotification('warning', "Oops! Error while loading Tag List!");
			})
	}

	getTags(userid) {
		this.dataService.getUserTags({
			bot_id: this.botID,
			user_id: userid,
			AgentID: this.customerID,
			AgentType: this.customerType
		}).subscribe(
			data => {
				this.userTags = data.cf_tags;
				this.showTagDetails = true;
				this.selectedTags = [];
				for (let i in data.cf_tags) {
					for (let j in this.tagsList) {
						if (data.cf_tags[i] == this.tagsList[j].id) {
							this.selectedTags.push({
								id: this.tagsList[j].id,
								itemName: this.tagsList[j].itemName
							});
						}
					}
				}
			},
			error => {
				showNotification('warning', "Error Adding Storage Variable!");
				this.leadloader = false;
			})

	}

	saveUserTags() {
		this.isaddTags = true;
		let cf_tags = [];
		for (let i in this.selectedTags) {
			cf_tags.push(this.selectedTags[i].id);
		}
		this.dataService.addUserTags({
			bot_id: this.botID,
			user_id: this.selected_user,
			agent_id: this.customerID,
			cf_tags: cf_tags,
			ticket_id: this.getUserSpec('ticket_id', this.selected_user)
		}).subscribe(
			res => {
				if (res.status_code == 200) {
					showNotification('success', "Tags Changed Successfully!");
					this.chatlog.push({
						cardtypeid: 27,
						feedbacktypeid: 1,
						message: {
							include: cf_tags,
							exclude: []
						},
						sender: "live_agent",
						time: new Date()
					});
					this.replyTabs.tabs[0].active = true;
					this.scrollToBottom();
				} else {
					showNotification('warning', "Oops! Error while saving tags!");
				}
				this.isaddTags = false;
			},
			error => {
				this.isaddTags = false;
				showNotification('warning', "Oops! Error while saving tags!");
			})
	}

	getLAImage(agent_id: any, attr: string): string {
		const la_detail = this.agentProfiles.find(ae => ae.agent_id === agent_id);
		if (la_detail) {
			return attr === 'img' ? la_detail.image_url : la_detail.agent_name;
		} else {
			return attr === 'img' ? '/assets/img/default-avatar.png' : 'Unknown Agent';
		}
	}


	getTagDetails(id, attr) {
		let tags = [];
		tags = this.tagsList.filter(ae => ae.id == id);
		if (tags.length > 0) {
			if (attr == 'name') {
				return tags[0].itemName;
			} else {
				return tags[0].color;
			}
		} else {
			if (attr == 'name') {
				return 'No tag found';
			} else {
				return '#ccc';
			}
		}
	}

	onHoldSubmit(data) {
		this.dataService.sendMessageLA({
			hold_message: data.hold_message,
			hold_time: data.hold_time,
			userid: this.selected_user,
			ticket_id: this.getUserSpec('ticket_id', this.selected_user),
			channelid: this.getUserSpec('channelid', this.selected_user),
		});
		this.holdChatModal.hide();
	}

	getFirstLetter(uid) {
		const user = this.usersListDetails.find(ae => ae.userid === uid);
		const string = user?.cf_name || uid;
		if (typeof string === "string" && string.length > 0) {
			return string[0];
		} else {
			return "!";
		}
	}

	checkFilter(filter) {
		this.dataService.sendMessageLA({
			search_text: filter,
			type: "search_users"
		});
	}

	addPvtNote(note: string) {
		this.isPvtNoteAdd = true;
		this.dataService.sendMessageLA({
			note: note,
			is_private_note: true,
			userid: this.selected_user
		});
		setTimeout(() => {
			this.isPvtNoteAdd = false;
			let date = new Date();
			this.pvtnote.nativeElement.value = '';
			let data = {
				time: date,
				sender: "live_agent",
				note: note,
				is_private_note: true
			}
			this.chatlogList[this.selected_user].push(data);
			this.scrollToBottom();
		}, 500);
	}

	getAssignedUsers() {
		this.dataService.sendMessageLA({
			type: 'assigned_users',
			customer_id: this.customerID,
			department_id: this.selectedDeptID
		});
	}

	filterUsers(filter) {
		this.resetChatBox();
		this.clearFIlters();
		this.dataService.sendMessageLA({
			status: filter,
			type: 'filter',
			customer_id: this.customerID,
			department_id: this.selectedDeptID
		});
	}

	setTitleIcon(title) {
		document.title = `${title} (${this.usersList.length})`;
		let link: any = document.querySelector("link[rel~='icon']");
		if (!link) {
			link = document.createElement('link');
			link.rel = 'icon';
			document.getElementsByTagName('head')[0].appendChild(link);
		}
		link.href = 'assets/new_msg_favicon.ico';
	}

	resetTitleIcon() {
		document.title = 'Support | Creyoface';
		let link: any = document.querySelector("link[rel~='icon']");
		if (!link) {
			link = document.createElement('link');
			link.rel = 'icon';
			document.getElementsByTagName('head')[0].appendChild(link);
		}
		link.href = 'assets/img/creyoface.gif';
	}

	addEmoji(event) {
		const { newMessage } = this;
		const text = `${newMessage || ''}${event.emoji.native}`;

		this.newMessage = text;
		this.showEmojiPicker = false;
	}

	toggleEmojiPicker() {
		this.showEmojiPicker = !this.showEmojiPicker;
	}

	checkFilters() {
		this.showClearBox = false;
		this.showFilter = !this.showFilter;
		setTimeout(() => {
			if (!this.filterDate?.startDate && !this.filterDate?.endDate && this.showFilter) {
				this.filterPicker.clear();
			};
		}, 100);
	}

	checkClearBox() {
		this.showFilter = false;
		this.showClearBox = !this.showClearBox;
	}

	clearFIlters() {
		this.filterLead = '';
		this.filterDate = null;
		this.filterTags = [];
		this.showFilter = false;
		this.showClearBox = false;

		this.filterTicketName = null;
		this.filterTicketPriority = null;

		const filter = {
			filter_lead: null,
			filter_date: {
				startDate: null,
				endDate: null
			},
			filter_tags: [],
			is_engaged: this.isAssigned,
			status: this.isAssigned ? this.searchFilter : null,
			filter_ticket_name: null,
			filter_ticket_priority: null,
			department_id: this.selectedDeptID,
			agent_id: this.customerID

		};
		this.filterOn = false;
		this.filterFormData = null;
		this.filterChannel = 0;
		this.dataService.sendMessageLA(filter);
	}

	applyFilter() {
		this.resetChatBox();
		const filter = {
			filter_lead: this.filterLead || null,
			filter_date: {
				startDate: Date.parse(this.filterDate.startDate) || null,
				endDate: Date.parse(this.filterDate.endDate) || null
			},
			filter_tags: this.filterTags,
			is_engaged: this.isAssigned,
			filter_ticket_name: this.filterTicketName,
			filter_ticket_priority: this.filterTicketPriority,
			status: this.isAssigned ? this.searchFilter : null,
			department_id: this.selectedDeptID,
			filter_condition: this.filterFormData?.condition ?? [],
			agent_id: this.customerID,
			channel: this.filterChannel
		};
		this.filterOn = true;
		this.dataService.sendMessageLA(filter);
		this.showFilter = false;
	}

	isFilterOn(): boolean {
		return (this.isAssigned && (this.filterLead || this.filterDate?.startDate || this.filterDate?.endDate || this.filterTags.length > 0)) ||
			(!this.isAssigned && (this.filterLead || this.filterDate?.startDate || this.filterDate?.endDate));
	}

	openTemplateModal() {
		const modalConfig = {
			ignoreBackdropClick: true,
			animated: true,
			class: 'modal-dialog-centered w-75',
			initialState: {
				botID: this.botID
			}
		};

		if (this.onHideSubscription) {
			this.onHideSubscription.unsubscribe();
		}

		this.modalRef = this.modalService.show(SendTemplatesComponent, modalConfig);

		this.onHideSubscription = this.modalService.onHide.subscribe((reason: string) => {
			if (reason) this.sendTemplate(Object.assign(reason, { mobile_number: this.leadForm.value.phone }));
		});
	}

	openFilterRuleModal() {
		const modalConfig = {
			ignoreBackdropClick: true,
			animated: true,
			class: 'modal-dialog-centered w-50',
			initialState: {
				botID: this.botID,
				memoryVars: this.starredMemVarsList,
				filterData: this.filterFormData
			}
		};

		if (this.onHideSubscription) {
			this.onHideSubscription.unsubscribe();
		}

		this.modalRef = this.modalService.show(FilterRuleComponent, modalConfig);

		this.onHideSubscription = this.modalService.onHide.subscribe((data: string) => {
			this.filterFormData = data ?? null;
		});
	}

	sendTemplate(data: any) {
		this.dataService.sendSupportTemplate(data).subscribe(() => showNotification('success', "Message sent!"),
			() => showNotification('error', "Error! Try Again!"));
	}

	getChannels() {
		this.dataService.getChannelList().subscribe(
			channels => {
				this.channelsList = channels;
			}
		)
	}

	uploadImg() {
		let element: HTMLElement = document.getElementById('attach-file') as HTMLElement;
		element.click();
	}

	getFile(event) {
		this.isUploading = true;
		if (event.target.files && event.target.files[0]) {
			const epochNow = (new Date).getTime();
			let ext = event.target.files[0].name.split('?')[0].split('.').pop();
			let file_name = event.target.files[0].name.substring(0, event.target.files[0].name.lastIndexOf('.'));
			this.dataService.getPresignedURL(file_name + '_$_' + epochNow + '.' + ext, this.botID, 'template').subscribe(
				data => {
					let url = data.text();
					let type = event.target.files[0].type;
					this.dataService.uploadfileAWSS3(url, event.target.files[0].type, event.target.files[0]).subscribe((event: HttpEvent<any>) => {
						if (event.type === HttpEventType.Response && event.status === 200) {
							this.isUploading = false;
							this.sendAttachment(this.static_url + this.botID + '/support/data/template/' + file_name + '_$_' + epochNow + '.' + ext, type);
						}
					}, () => { this.isUploading = false; });
				},
				() => { this.isUploading = false; });
		}
	}

	sendAttachment(file, type) {
		this.dataService.sendMessageLA({
			'type': "attachment",
			'link': file,
			'channel_details': this.getUserSpec('channel_details', this.selected_user),
			'user_id': this.selected_user,
			'bot_id': this.botID
		});
		this.chatlogList[this.selected_user].push({
			'time': new Date(),
			'sender': 'live_agent',
			'isLive': true,
			'link': file,
			'isImage': type.includes('image')
		});
	}

	clearUsers() {
		const filteredUsersList = this.usersListDetails
			.filter(user => this.usersList.includes(user.userid))
			.map(user => (user.ticket_id));
		this.dataService.sendMessageLA({
			disconnect: true,
			bot_id: this.botID,
			close_not_engaged_tickets: true,
			ticket_ids: filteredUsersList
		});
		this.showClearBox = false;
	}

	ngOnDestroy() {
		this.terminateSocket();
		if (this.onHideSubscription) {
			this.onHideSubscription.unsubscribe();
		}
	}

}
