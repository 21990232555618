import { Component, OnInit } from '@angular/core';
import { AuthService } from '../account/login/auth.service';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';

declare const $: any;
declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}

export var ROUTES: RouteInfo[] = [
  { path: '/dashboard', title: 'DASHBOARD', icon: 'fa fa-tachometer', class: '' },
  { path: '/user', title: 'UNIFIED INBOX', icon: 'fa fa-comments', class: '' },
  // { path: '/spectate', title: 'SPECTATE', icon: 'fa fa-binoculars', class: '' },
  { path: '/people', title: 'PEOPLE', icon: 'fa fa-users', class: '' },
  { path: '/tickets', title: 'TICKETS', icon: 'fa fa-ticket', class: '' },
  { path: '/manage', title: 'MANAGE', icon: 'fa fa-address-card', class: '' },
  { path: '/assign-rules', title: 'ASSIGN RULES', icon: 'fa fa-plus-square-o', class: '' },
  // { path: '/unified-inbox', title: 'UNIFIED INBOX', icon: 'fa fa-comments', class: '' },
];


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {
  menuItems: any[];
  CustomerType: number = parseInt(localStorage.getItem('Customer_Type'), 10);

  constructor(public authService: AuthService, private router: Router, private activatedRouter: ActivatedRoute) { }

  ngOnInit() {
    this.routePermissions();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (event.url == '/user') { }
      }
    })
  }

  routePermissions() {
    var show_admin_management_page = JSON.parse(localStorage.getItem('show_admin_management_page')) === true;
    var show_contacts_page = JSON.parse(localStorage.getItem('show_contacts_page')) === true;
    var show_tickets_page = JSON.parse(localStorage.getItem('show_tickets_page')) === true;
    var show_reports_page = JSON.parse(localStorage.getItem('show_reports_page')) === true;
    var show_spectate_page = JSON.parse(localStorage.getItem('show_spectate_page')) === true;
    var ROUTES_UPDATED = [];
    if (!show_admin_management_page || !show_spectate_page || !show_contacts_page || !show_tickets_page) {
      if (show_admin_management_page == false) {
        if (ROUTES_UPDATED.length == 0) {
          ROUTES_UPDATED = ROUTES.filter(ae => ae.title != 'MANAGE');
        } else {
          ROUTES_UPDATED = ROUTES_UPDATED.filter(ae => ae.title != 'MANAGE');
        }
      }
      if (show_spectate_page == false) {
        if (ROUTES_UPDATED.length == 0) {
          ROUTES_UPDATED = ROUTES.filter(ae => ae.title != 'SPECTATE');
        } else {
          ROUTES_UPDATED = ROUTES_UPDATED.filter(ae => ae.title != 'SPECTATE');
        }
      }
      if (show_contacts_page == false) {
        if (ROUTES_UPDATED.length == 0) {
          ROUTES_UPDATED = ROUTES.filter(ae => ae.title != 'PEOPLE');
        } else {
          ROUTES_UPDATED = ROUTES_UPDATED.filter(ae => ae.title != 'PEOPLE');
        }
      }
      if (show_tickets_page == false) {
        if (ROUTES_UPDATED.length == 0) {
          ROUTES_UPDATED = ROUTES.filter(ae => ae.title != 'TICKETS');
        } else {
          ROUTES_UPDATED = ROUTES_UPDATED.filter(ae => ae.title != 'TICKETS');
        }
      }
      this.menuItems = ROUTES_UPDATED.filter(menuItem => menuItem);
    } else {
      this.menuItems = ROUTES.filter(menuItem => menuItem);
    }
    if (this.CustomerType != 1) this.menuItems = ROUTES.filter(ae => ae.title != 'ASSIGN RULES');
  }

  isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  };

  logOut() {
    this.authService.logout();
  }

  attrPath(path) {
    // if(path=='/user'){disabled}
    return path;
  }
}
