import { Component, OnInit, ViewChild } from '@angular/core';
import { ServicesService } from '../../services/services.service';
import { showNotification } from '../../services/notification';
import { BsModalService, BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators, AbstractControl } from '@angular/forms';
declare const CKEDITOR;
declare var $: any;

@Component({
	selector: 'app-departments',
	templateUrl: './departments.component.html',
	styleUrls: ['./departments.component.scss'],
})
export class DepartmentsComponent implements OnInit {

	public botID: number = parseInt(localStorage.getItem('botID'), 10);
	public customerID: number = parseInt(localStorage.getItem('Customer_ID'), 10);
	public customerType: number = parseInt(localStorage.getItem('Customer_Type'), 10);
	public departments: any = [];
	public selected: boolean[] = [false];
	public isFetching: boolean = false;
	public deptDetails: any = {};
	public deptAgentDetails: any = {};
	public selectedDept: string = '';
	public selectedDeptDetails;
	public addDepartmentForm: FormGroup;
	public botList;
	public isAdding: boolean = false;
	public isDeleting: boolean = false;
	public isDeletingAgent: boolean = false;
	public isEdit: boolean = false;
	isDuplicateDeptName = false;

	public config = {
		uiColor: '#ffffff',
		forcePasteAsPlainText: true,
		basicEntities: false,
		title: 'Style your message',
		entities: false,
		disableAutoInline: true,
		allowedContent: false,
		forceSimpleAmpersand: false,
		toolbarGroups: [
			{ name: 'links' }, { name: 'insert' },
			{ name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
			{ name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align'] },
			{ name: 'styles' },
			{ name: 'colors' }
		],
		resize_enabled: false,
		extraPlugins: 'autocomplete,textmatch,textwatcher,sourcedialog,divarea,justify,indentblock,colordialog,emoji',
		removePlugins: 'elementspath,save,magicline,smiley,sourcearea,copyformatting',
		colorButton_foreStyle: {
			element: 'font',
			attributes: { 'color': '#(color)' }
		},
		emoji_emojiListUrl: '/assets/js/ckeditor/emoji/emoji.json',
		emoji_minChars: 0,
		skin: 'moono-lisa',
		height: 250,
		width: '100%',
		removeDialogTabs: 'image:advanced;link:advanced;',
		removeButtons: 'Anchor,Flash,Indent,Source,Table,BidiLtr,BidiRtl,Iframe,PageBreak,HorizontalRule,RemoveFormat,CreateDiv,Styles',
		format_tags: 'p;h1;h2;h3;h4;h5;h6;pre;div',
	}
	@ViewChild('addDepartmentModal', { static: false }) addDepartmentModal: ModalDirective;
	@ViewChild('deleteDepartmentModal', { static: false }) deleteDepartmentModal: ModalDirective;
	@ViewChild('deleteDepartmentAgentModal', { static: false }) deleteDepartmentAgentModal: ModalDirective;

	constructor(private dataService: ServicesService, private _fb: FormBuilder) { }

	ngOnInit(): void {
		this.clearDepartmentForm();
		CKEDITOR.on('instanceCreated', function (event) {
			var editor = event.editor;
			editor.on('instanceReady', function (e) {
				$(e.editor.element.$).removeAttr("title");
			});
		});
	}

	clearDepartmentForm() {
		this.addDepartmentForm = this._fb.group({
			'CustomerID': [this.customerID, [Validators.required]],
			'DepartmentName': ['', [Validators.required]],
			'isAutoAssign': [false, [Validators.required]],
			'ask_feedback': [false, [Validators.required]],
			'BotID': ['', [Validators.required]],
			'auto_close': [3, [Validators.required]],
			'auto_reject': [3, [Validators.required]],
			'idle_chat_time': [1440, [Validators.required]],
			'hold_message': ['[[Agent]] has put your chat on hold for [[Time]] mins!', [Validators.required]],
			'hold_time': [1, [Validators.required]],
			'AwayMessage': ['It seems like our agents are busy! We will connect with you as soon as they are available.', [Validators.required]],
			'feedback_question': ['How would you rate your experience with us (1-10)?', [Validators.required]],
		});
	}

	getDepartments() {
		this.dataService.getDepartmentsList({
			CustomerID: this.customerID,
			CustomerType: this.customerType
		}).subscribe(
			res => {
				if (res.status_code == 200) {
					this.departments = res.data;
				} else if (res.status_code == 401) {
					this.dataService.logout();
				} else {
					showNotification('warning', res.status);
				}
			}, error => {
				console.log(error);
				showNotification('danger', error);
			})
	}

	selectDept(dept, i?) {
		this.isFetching = true;
		this.selectedDept = dept.DepartmentName;
		this.selectedDeptDetails = dept;
		if (i >= 0) {
			this.selectedDeptDetails['row'] = i;
			for (let j in this.departments) {
				if (i == j) { this.selected[j] = true; }
				else { this.selected[j] = false; }
			}
		}
		this.dataService.getDepartmentDetails({
			DepartmentID: dept.DepartmentID,
			CustomerID: this.customerID,
			CustomerType: this.customerType,
		}).subscribe(
			res => {
				if (res.status_code == 200) {
					this.deptDetails = res.data;
					this.deptDetails['admins'] = this.deptDetails['Admins'].filter(ae => ae.type == 1 || ae.type == 2);
					this.deptDetails['agents'] = this.deptDetails['Admins'].filter(ae => ae.type == 3);
				} else {
					showNotification('warning', res.status);
				}
				this.isFetching = false;
			},
			error => {
				console.log(error);
				this.isFetching = false;
				showNotification('danger', error);
			})
	}

	getBotList() {
		this.dataService.getBotList({
			CustomerID: this.customerID,
			CustomerType: this.customerType
		})
			.subscribe((res) => {
				if (res.status_code == 200) {
					this.botList = res.data;
				} else if (res.status_code == 401) {
					this.dataService.logout();
				}
			}
				,
				(error) => {
					console.log("Bot list error");
				}
			);
	}

	addDepartment(dept) {
		dept.BotID = parseInt(dept.BotID);
		this.isAdding = true;
		if (!this.isEdit) {
			this.dataService.createDepartment(dept).subscribe(
				res => {
					if (res.status_code == 200) {
						showNotification('success', 'Added successfully!');
						this.getDepartments();
						this.clearDepartmentForm();
						this.addDepartmentModal.hide();
						this.isAdding = false;
					} else {
						this.isAdding = false;
						showNotification('warning', res.status);
					}
				},
				error => {
					console.log(error);
					this.isAdding = false;
					showNotification('danger', error);
				})
		} else {
			dept.DepartmentID = this.selectedDeptDetails.DepartmentID;
			this.dataService.editDepartment(dept).subscribe(
				res => {
					if (res.status_code == 200) {
						showNotification('success', 'Updated successfully!');
						console.log(dept);
						for (let i in this.departments) {
							if (this.departments[i].DepartmentID == dept.DepartmentID) this.departments[i].DepartmentName = dept.DepartmentName;
						}
						this.selectDept(dept);
						console.log(this.departments, this.deptDetails);
						this.clearDepartmentForm();
						this.addDepartmentModal.hide();
						this.isAdding = false;
						this.isEdit = false;
					} else {
						this.isAdding = false;
						showNotification('warning', res.status);
					}
				},
				error => {
					console.log(error);
					this.isAdding = false;
					showNotification('danger', error);
				})
		}
	}

	editDept(details) {
		console.log(details);
		this.isEdit = true;
		this.addDepartmentForm = this._fb.group({
			'CustomerID': [this.customerID, [Validators.required]],
			'DepartmentName': [details.DepartmentName, [Validators.required]],
			'isAutoAssign': [details.IsAutoAssign, [Validators.required]],
			'ask_feedback': [details.ask_feedback ?? false, [Validators.required]],
			'BotID': [details.BotID, [Validators.required]],
			'AwayMessage': [details.AwayMessage, [Validators.required]],
			'feedback_question': [details.feedback_question, [Validators.required]],
			'hold_message': [details.hold_message, [Validators.required]],
			'hold_time': [details.hold_time, [Validators.required]],
			'auto_close': [details.auto_close ?? 3, [Validators.required]],
			'auto_reject': [details.auto_reject ?? 3, [Validators.required]],
			'idle_chat_time': [details.idle_chat_time ?? 1440, [Validators.required]]
		});
		this.addDepartmentModal.show();
	}

	deleteDept() {
		let data = {
			CustomerID: this.customerID,
			DepartmentID: this.selectedDeptDetails.DepartmentID,
			DepartmentName: this.selectedDeptDetails.DepartmentName,
			BotID: this.selectedDeptDetails.BotID
		}
		if (this.deptDetails.agents?.length > 0) {
			var txt;
			var r = confirm("Agents assigned to this department will be inactive. Do you want to continue?");
			if (r) {
				this.deleteDepartment(data);
			}
		} else if (this.deptDetails.agents?.length == 0) {
			this.deleteDepartment(data);
		}

	}

	deleteDepartment(data) {
		this.isDeleting = true;
		this.dataService.deleteDepartment(data).subscribe(
			res => {
				if (res.status_code == 200) {
					showNotification('success', 'Deleted successfully!');
					this.getDepartments();
					this.deleteDepartmentModal.hide();
					this.isDeleting = false;
					this.deptDetails = {};
					this.selectedDeptDetails = {};
					this.selected = [false];
				} else {
					this.isDeleting = false;
					showNotification('warning', res.status);
				}
			},
			error => {
				console.log(error);
				this.isDeleting = false;
				showNotification('danger', error);
			})
	}

	deleteAgentDept() {
		this.isDeletingAgent = true;
		let data = {
			liveagent_id: this.deptAgentDetails.liveagent_id,
			DepartmentID: this.selectedDeptDetails.DepartmentID
		}
		this.dataService.deleteDepartmentAgent(data).subscribe(
			res => {
				if (res.status_code == 200) {
					showNotification('success', 'Agent Deleted successfully!');
					this.getDepartments();
					this.selectDept(this.selectedDeptDetails['row'], this.selectedDeptDetails);
					this.deleteDepartmentAgentModal.hide();
					this.isDeletingAgent = false;
					this.deptAgentDetails = {};
				} else {
					this.isDeletingAgent = false;
					showNotification('warning', res.status);
				}
			},
			error => {
				console.log(error);
				this.isDeletingAgent = false;
				showNotification('danger', error);
			})
	}

	checkDeptName() {
		const deptName = this.addDepartmentForm.value.DepartmentName;
		const botid = this.addDepartmentForm.value.BotID;
		this.isDuplicateDeptName = this.departments
			.filter(ae => ae.BotID == botid)
			.map(ae => ae.DepartmentName.toLowerCase())
			.includes(deptName.toLowerCase());
	}
}
