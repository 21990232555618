 <div class="row department">
  <div class="col-md-12">
    <div class="row">
     <div class="col-md-12 col-sm-12">
       <div class="numbers dflex">
        <div class="form-group mauto">
          <label>Date</label>
          <input type="text"
          class="form-control"
          ngxDaterangepickerMd
          [(ngModel)]="selected_date"
          (change)="getChartData()"
          [alwaysShowCalendars]="true"
          [ranges]="ranges"
          [showClearButton]="true"
          [locale]="locale"
          placeholder="Select date range..."/>
        </div>
        <div class="form-group mauto"  *ngIf="departmentList?.length>0">
          <label>Departments</label>
          <select class="form-control" #agent [(ngModel)]="deptID" (change)="getChartData()">
            <option *ngFor="let item of departmentList" [value]="item.department_id">{{item.department_name}} ({{item.bot_name}})</option>
          </select>
        </div>
      </div>
    </div>
    <div class="col-md-2 col-sm-2 num-card">
      <div class="card card-stats">
        <div class="card-body graph">
          <div class="row">
            <div class="col-xs-8 pd0">
              <div class="content">
                <div class="count">{{current_perfomance_total}}</div>
                <p class="category">Total Tickets</p>
              </div>
            </div>
            <div class="col-xs-4 icon"><i class="fa fa-ticket blue" aria-hidden="true"></i></div>
          </div>
        </div>
        <div class="footer">
          <div class="legend" *ngIf="percent_perfomance_total>0"><span class="green">{{percent_perfomance_total | number : '1.0-0'}}%</span> <i class="fa fa-level-up green" aria-hidden="true"></i> from previous ( <b>{{previous_perfomance_total}}</b> )</div>
          <div class="legend" *ngIf="percent_perfomance_total<0"> <span class="red">{{percent_perfomance_total | number : '1.0-0'}}%</span> <i class="fa fa-level-down red" aria-hidden="true"></i> from previous ( <b>{{previous_perfomance_total}}</b> )</div>
            <div class="legend" *ngIf="percent_perfomance_total==0"> <span class="orange">No change</span> from previous</div>
          </div>
        </div>
      </div>
      <div class="col-md-2 col-sm-2 num-card">
        <div class="card card-stats">
          <div class="card-body graph">
            <div class="row">
              <div class="col-xs-8 pd0">
                <div class="content">
                  <div class="count">{{current_perfomance_attend}}</div>
                  <p class="category">Attended Tickets</p>
                </div>
              </div>
              <div class="col-xs-4 icon"><i class="fa fa-ticket yellowish" aria-hidden="true"></i></div>
            </div>
          </div>
          <div class="footer">
            <div class="legend" *ngIf="percent_perfomance_attend>0"><span class="green">{{percent_perfomance_attend | number : '1.0-0'}}%</span> <i class="fa fa-level-up green" aria-hidden="true"></i> from previous ( <b>{{previous_perfomance_attend}}</b> )</div>
            <div class="legend" *ngIf="percent_perfomance_attend<0"> <span class="red">{{percent_perfomance_attend | number : '1.0-0'}}%</span> <i class="fa fa-level-down red" aria-hidden="true"></i> from previous ( <b>{{previous_perfomance_attend}}</b> )</div>
              <div class="legend" *ngIf="percent_perfomance_attend==0"> <span class="orange">No change</span> from previous</div>
            </div>
          </div>
        </div>
        <div class="col-md-2 col-sm-2 num-card">
          <div class="card card-stats">
            <div class="card-body graph">
              <div class="row">
                <div class="col-xs-8 pd0">
                  <div class="content">
                    <div class="count">{{current_perfomance_closed}}</div>
                    <p class="category">Closed Tickets</p>
                  </div>
                </div>
                <div class="col-xs-4 icon"><i class="fa fa-ticket green" aria-hidden="true"></i></div>
              </div>
            </div>
            <div class="footer">
              <div class="legend" *ngIf="percent_perfomance_closed>0"><span class="green">{{percent_perfomance_closed | number : '1.0-0'}}%</span> <i class="fa fa-level-up green" aria-hidden="true"></i> from previous ( <b>{{previous_perfomance_closed}}</b> )</div>
              <div class="legend" *ngIf="percent_perfomance_closed<0"> <span class="red">{{percent_perfomance_closed | number : '1.0-0'}}%</span> <i class="fa fa-level-down red" aria-hidden="true"></i> from previous ( <b>{{previous_perfomance_closed}}</b> )</div>
                <div class="legend" *ngIf="percent_perfomance_closed==0"> <span class="orange">No change</span> from previous</div>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-sm-6">
            <div class="card card-stats">
              <div class="card-body graph">
                <div class="row">
                  <!-- <h5 class="text-center">Agent CSAT</h5> -->
                  <div class="col-7 col-md-7 pd0">
                    <div class="row text-center count_div2">

                      <div class="col-xs-12 pd6-0"><b class="head">Department CSAT</b><hr></div>
                      <span class="col-xs-3"><i class="fa fa-envelope purple" aria-hidden="true"></i> <b class="num">{{responded_count_agent}}</b><br>Responded</span>
                      <span class="col-xs-3"><i class="fa fa-thumbs-o-up green" aria-hidden="true"></i> <b class="num">{{positive_count_agent}}</b><br>Positive</span>
                      <span class="col-xs-3"><i class="fa fa-thumbs-o-up rotate orange" aria-hidden="true"></i><b class="num">{{passive_count_agent}}</b><br>Passive</span>
                      <span class="col-xs-3"><i class="fa fa-thumbs-o-down red" aria-hidden="true"></i> <b class="num">{{negative_count_agent}}</b><br>Negative</span>

                      <div class="col-xs-12 pd6-0"><b class="head">Assistant CSAT</b><hr></div>
                      <span class="col-xs-3"><!-- <i class="fa fa-envelope orange" aria-hidden="true"></i> --> <b class="num">{{responded_count_bot}}</b><br>Responded</span>
                      <span class="col-xs-3"><!-- <i class="fa fa-thumbs-o-up green" aria-hidden="true"></i> --> <b class="num">{{positive_count_bot}}</b><br>Positive</span>
                      <span class="col-xs-3"><!-- <i class="fa fa-arrow-circle-o-right blue" aria-hidden="true"></i> --><b class="num">{{passive_count_bot}}</b><br>Passive</span>
                      <span class="col-xs-3"><!-- <i class="fa fa-thumbs-o-down red" aria-hidden="true"></i> --> <b class="num">{{negative_count_bot}}</b><br>Negative</span>

                    </div>
                  </div>
                  <div class="col-5 col-md-5 pd0 dflex">
                    <div class="semi-pie">
                      <div class="chartDiv h200" #gChart6></div>
                      <p>Agent {{agentPercent}}%</p>
                    </div>
                    <div class="semi-pie">
                      <div class="chartDiv h200" #gChart7></div>
                      <p>ChatBot {{botPercent}}%</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
    <!-- <div class="col-md-4 col-sm-4">
      <div class="card card-stats">
        <div class="card-body graph">
          <div class="row">
            <h5 class="text-center mb0">Closed Tickets Split </h5>
            <div class="col-12 col-md-12 pd0">
              <div class="chartDiv" #gChart1></div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="col-md-4 col-sm-4">
      <div class="card card-stats">
        <div class="card-body graph">
          <div class="row">
            <div class="col-12 col-md-12">
              <div class="numbers">
                <p class="card-category">Chat Timings</p>
                <div class="select-chart">
                  <select class="form-control" (change)="loadChart(selchart.value)" #selchart>
                    <option value="rt">Response Times</option>
                    <option value="cd">Chat Duration</option>
                    <option value="wts">Wait Time (Served)</option>
                    <option value="wtm">Wait Time (Missed)</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-12 pd0">
              <div class="chartDiv1" #gChart2></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4 col-sm-4">
      <div class="card card-stats">
        <div class="card-body graph">
          <div class="row">
            <h5 class="text-center mb0">Channels Split </h5>
            <div class="col-12 col-md-12 pd0">
              <div class="chartDiv" #gChart3></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4 col-sm-4">
      <div class="card card-stats">
        <div class="card-body graph">
          <div class="row">
            <h5 class="text-center mb0">Weekday Response Times</h5>
            <div class="col-12 col-md-12 pd0">
              <div class="chartDiv" #gChart4></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 col-sm-12">
      <div class="card card-stats">
        <div class="card-body graph">
          <div class="row">
            <h5 class="text-center mb0">Weekday Ticket Counts</h5>
            <div class="col-12 col-md-12 pd0">
              <div class="chartDiv" #gChart5></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
