<div class="modal-content">
    <form [formGroup]="addRulesForm" (ngSubmit)="submitRule(addRulesForm);" ngNativeValidate>
        <div class="modal-header">
            <div class="header">
                <h4 class="title mg0"><span *ngIf="!isEdit">Add</span><span *ngIf="isEdit">Edit</span> Department
                </h4>
                <button type="button" class="close" (click)="closeModal();">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>
        <div class="modal-body mb10">
            <div class="row">
                <div class="col-md-offset-4 col-md-4">
                    <div class="form-group">
                        <label class="w-100 text-center">Rule Name</label>
                        <input class="form-control text-center" placeholder="Rule Name" type="text"
                            formControlName="name" required>
                    </div>
                </div>
            </div>
            <div class="row d-flex">
                <div class="col-md-3">
                    <div class="form-group">
                        <label class="w-100 text-center">Department</label>
                        <select class="form-control" formControlName="department_id" required
                            (change)="filterAgentByDept(addRulesForm.value.department_id)">
                            <option *ngFor="let item of departments" [value]="item.DepartmentID">
                                {{item.DepartmentName}}</option>
                        </select>
                    </div>

                    <div class="form-group">
                        <label class="w-100 text-center">Channel</label>
                        <select class="form-control" formControlName="channel_id">
                            <option [value]=null>No Channel</option>
                            <option *ngFor="let item of channelList" [value]="item.id">
                                {{item.name}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-6" formArrayName="condition" class="condition-div">
                    <div
                        *ngFor="let condition of conditions.controls; let i=index; let isLast=last; let isFirst=first;">
                        <div [formGroupName]="i" class="row pt-2">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Memory Variable</label>
                                    <select class="form-control" formControlName="mem_var" required>
                                        <option *ngFor="let item of memoryVars" [value]="item.internal_name">
                                            {{item.display_name}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="w-100 text-center">Condition</label>
                                    <select formControlName="operator" class="form-control" required>
                                        <option [value]="null" [disabled]="true">Select Operator
                                        </option>
                                        <option *ngFor="let symbol of operations" [value]="symbol">{{symbol}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-4 d-flex align-items-center">
                                <div class="form-group">
                                    <label class="w-100 text-right">Value</label>
                                    <input class="form-control" placeholder="Value" type="text" formControlName="value"
                                        required>
                                </div>
                                <a (click)="removeCondition(i)" class="text-danger close-btn p-1" *ngIf="!isFirst">
                                    <span aria-hidden="true">&times;</span>
                                </a>
                            </div>
                            <div class="col-md-12" [ngClass]="{'next-condition mt-2': !isLast}">
                                <div class="col-md-offset-3 col-md-6" *ngIf="!isLast">
                                    <div class="form-group">
                                        <label class="w-100 text-center">Next Condition</label>
                                        <select formControlName="next_operator" class="form-control"
                                            [required]="!isLast ? '' : null">
                                            <option [value]="null" [disabled]="true">Select Condition
                                            </option>
                                            <option [value]="'OR'">OR</option>
                                            <option [value]="'AND'">AND</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label class="w-100 text-center">Agent</label>
                        <select class="form-control" formControlName="liveagent_id" required>
                            <option [value]="null" disabled>Select Agent</option>
                            <option *ngFor="let item of filteredAgents" [value]="item.AgentID">
                                {{item.AgentName}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="w-100 text-center text-danger mt-2" *ngIf=" addRulesForm.invalid && errorMessage">
                {{errorMessage}}
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-green" (click)="addCondition()">Add Condition</button>
            <button type="button" class="btn" (click)="closeModal();isEdit=false;"><i class="fa fa-times"
                    aria-hidden="true"></i>Cancel</button>
            <button type="submit" class="btn btn-gradient" [disabled]="addRulesForm.value.hold_time>15">
                <i *ngIf="isAdding" class="fa fa-spinner fa-spin fa-pulse"></i>
                <i *ngIf="!isAdding" class="fa fa-check"></i>
                <span *ngIf="!isEdit">Save</span><span *ngIf="isEdit">Edit</span>
            </button>
        </div>
    </form>
</div>