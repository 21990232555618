import { Component, OnInit, ElementRef } from '@angular/core';
import { ROUTES } from '../../sidebar/sidebar.component';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { AuthService } from '../../account/login/auth.service';
import { ServicesService } from '../../services/services.service';
import { showNotification } from '../../services/notification';
import { Router } from '@angular/router';

@Component({
  // moduleId: module.id,
  selector: 'navbar-cmp',
  templateUrl: 'navbar.component.html'
})

export class NavbarComponent implements OnInit {
  private listTitles: any[];
  notifications: any[];
  location: Location;
  private toggleButton: any;
  private sidebarVisible: boolean;
  agent_img: string = localStorage.getItem('agent_imgurl');
  botID: number = parseInt(localStorage.getItem('botID'), 10);
  customerID: number = parseInt(localStorage.getItem('Customer_ID'), 10);
  customerType: number = parseInt(localStorage.getItem('Customer_Type'), 10);
  customerChar = (localStorage.getItem('Customer_Name') || 'Anonymous').charAt(0);
  notifyCount: number = 0;
  botList;
  loadingBots: boolean = false;
  showBots: boolean = true;

  constructor(location: Location, private element: ElementRef,
    private authService: AuthService, private dataService: ServicesService, private router: Router) {
    this.location = location;
    this.sidebarVisible = false;
  }

  ngOnInit() {
    this.listTitles = ROUTES.filter(listTitle => listTitle);
    const navbar: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];
    this.getBotList();
    this.router.events.subscribe((event) => {
      this.botID = parseInt(localStorage.getItem('botID'), 10);
    });
  }


  sidebarOpen() {
    const toggleButton = this.toggleButton;
    const body = document.getElementsByTagName('body')[0];
    setTimeout(function () {
      toggleButton.classList.add('toggled');
    }, 500);
    body.classList.add('nav-open');

    this.sidebarVisible = true;
  };

  sidebarClose() {
    const body = document.getElementsByTagName('body')[0];
    this.toggleButton.classList.remove('toggled');
    this.sidebarVisible = false;
    body.classList.remove('nav-open');
  };

  sidebarToggle() {
    if (this.sidebarVisible === false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
  };

  getTitle() {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === '#') {
      titlee = titlee.slice(1);
    }
    if (titlee == '/dashboard' || titlee == '/manage') {
      this.showBots = false;
    } else {
      this.showBots = true;
    }
    for (var item = 0; item < this.listTitles.length; item++) {
      if (this.listTitles[item].path === titlee) {
        return this.listTitles[item].title;
      }
    }
    return 'Home';
  }

  getBotList() {
    this.loadingBots = true;
    this.dataService.getBotList({
      CustomerID: this.customerID,
      CustomerType: this.customerType
    })
      .subscribe((res) => {
        if (res.status_code == 200) {
          this.botList = res.data;
          if (this.botList.length == 0) {
            this.logOut();
            showNotification('warning', '<span>Oops! You must create atleast one Bot to login this section.');
          }
          let inList = false;
          for (let i in this.botList) {
            if (this.botList[i].BotID == this.botID) {
              inList = true;
            }
          }
          if (!inList) {
            this.botID = this.botList[0].BotID;
            localStorage.setItem('botID', this.botList[0].BotID);
          }
        } else if (res.status_code == 401) {
          this.logOut();
        }
        this.loadingBots = false;
      }
        ,
        (error) => {
          this.loadingBots = false;
          console.log("Bot list error");
        }
      );
  }

  selectBot = (botidname) => {
    let botid = botidname.split(/,(.+)/)[0];
    let botname = botidname.split(/,(.+)/)[1];
    localStorage.setItem('botID', botid);
    localStorage.setItem('botName', botname);
    this.botID = botid;
    let currentURL = this.router.url.substr(1);
    this.router.navigateByUrl('/people', { skipLocationChange: true }).then(() =>
      this.router.navigate([currentURL]));
  }

  logOut() {
    this.authService.logout();
  }
}
