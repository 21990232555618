    <div class="row">
      <div class="col-md-12">
        <div class="row">
         <div class="col-md-12 col-sm-12">
           <div class="numbers dflex">
            <p class="card-category">Chats Counts</p>
            <div class="form-group mauto">
              <label>Date</label>
              <input type="text"
              class="form-control"
              ngxDaterangepickerMd
              [(ngModel)]="selected_date"
              (change)="getChartData()"
              [alwaysShowCalendars]="true"
              [ranges]="ranges"
              [showClearButton]="true"
              [locale]="locale"
              placeholder="Select date range..."/>
            </div>
            <div class="form-group mauto"  *ngIf="botList?.length>0">
              <label>Assistants</label>
              <select class="form-control" #agent [(ngModel)]="botID" (change)="getChartData()">
                <option *ngFor="let item of botList" [value]="item.BotID">{{item.BotName}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-md-2 col-sm-2 num-card">
          <div class="card card-stats">
            <div class="card-body graph">
              <div class="row">
                <div class="col-xs-8 pd0">
                  <div class="content">
                    <div class="count">{{current_perfomance_total}}</div>
                    <p class="category">Total Tickets</p>
                  </div>
                </div>
                <div class="col-xs-4 icon"><i class="fa fa-ticket blue" aria-hidden="true"></i></div>
              </div>
            </div>
            <div class="footer">
              <div class="legend" *ngIf="percent_perfomance_total>0"><span class="green">{{percent_perfomance_total | number : '1.0-0'}}%</span> <i class="fa fa-level-up green" aria-hidden="true"></i> from previous ( <b>{{previous_perfomance_total}}</b> )</div>
              <div class="legend" *ngIf="percent_perfomance_total<0"> <span class="red">{{percent_perfomance_total | number : '1.0-0'}}%</span> <i class="fa fa-level-down red" aria-hidden="true"></i> from previous ( <b>{{previous_perfomance_total}}</b> )</div>
              <div class="legend" *ngIf="percent_perfomance_total==0"> <span class="orange">No change</span> from previous</div>
            </div>
          </div>
        </div>
        <div class="col-md-2 col-sm-2 num-card">
          <div class="card card-stats">
            <div class="card-body graph">
              <div class="row">
                <div class="col-xs-8 pd0">
                  <div class="content">
                    <div class="count">{{current_perfomance_attend}}</div>
                    <p class="category">Attended Tickets</p>
                  </div>
                </div>
                <div class="col-xs-4 icon"><i class="fa fa-ticket yellowish" aria-hidden="true"></i></div>
              </div>
            </div>
            <div class="footer">
              <div class="legend" *ngIf="percent_perfomance_attend>0"><span class="green">{{percent_perfomance_attend | number : '1.0-0'}}%</span> <i class="fa fa-level-up green" aria-hidden="true"></i> from previous ( <b>{{previous_perfomance_attend}}</b> )</div>
              <div class="legend" *ngIf="percent_perfomance_attend<0"> <span class="red">{{percent_perfomance_attend | number : '1.0-0'}}%</span> <i class="fa fa-level-down red" aria-hidden="true"></i> from previous ( <b>{{previous_perfomance_attend}}</b> )</div>
              <div class="legend" *ngIf="percent_perfomance_attend==0"> <span class="orange">No change</span> from previous</div>
            </div>
          </div>
        </div>
        <div class="col-md-2 col-sm-2 num-card">
          <div class="card card-stats">
            <div class="card-body graph">
              <div class="row">
                <div class="col-xs-8 pd0">
                  <div class="content">
                    <div class="count">{{current_perfomance_closed}}</div>
                    <p class="category">Closed Tickets</p>
                  </div>
                </div>
                <div class="col-xs-4 icon"><i class="fa fa-ticket green" aria-hidden="true"></i></div>
              </div>
            </div>
            <div class="footer">
              <div class="legend" *ngIf="percent_perfomance_closed>0"><span class="green">{{percent_perfomance_closed | number : '1.0-0'}}%</span> <i class="fa fa-level-up green" aria-hidden="true"></i> from previous ( <b>{{previous_perfomance_closed}}</b> )</div>
              <div class="legend" *ngIf="percent_perfomance_closed<0"> <span class="red">{{percent_perfomance_closed | number : '1.0-0'}}%</span> <i class="fa fa-level-down red" aria-hidden="true"></i> from previous ( <b>{{previous_perfomance_closed}}</b> )</div>
              <div class="legend" *ngIf="percent_perfomance_closed==0"> <span class="orange">No change</span> from previous</div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-6 num-card">
          <div class="card card-stats">
            <div class="card-body graph">
              <div class="row">
                <div class="col-9 col-xs-9 pd0">
                  <h5 class="text-center">Assistant CSAT</h5>
                  <div class="row text-center count_div">
                    <!-- <div class="col-xs-12"><b>Bot</b><hr></div> -->
                    <span class="col-xs-3"><i class="fa fa-envelope purple" aria-hidden="true"></i><b class="num">{{responded_count_bot}}<br></b>Responded</span>
                    <span class="col-xs-3"><i class="fa fa-thumbs-o-up green" aria-hidden="true"></i><b class="num">{{positive_count_bot}}</b><br>Positive</span>
                    <span class="col-xs-3"><i class="fa fa-thumbs-o-up rotate orange" aria-hidden="true"></i> <b class="num">{{passive_count_bot}}</b><br>Passive</span>
                    <span class="col-xs-3"><i class="fa fa-thumbs-o-down red" aria-hidden="true"></i><b class="num">{{negative_count_bot}}</b><br>Negative</span>
                  </div>
                </div>
                <div class="col-3 col-xs-3 pd0">
                  <div class="semi-pie">
                    <div class="chartDiv2 pie1" #gChart7></div>
                    <p class="pie1">ChatBot {{botPercent}}%</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
   <!--      <div class="col-md-4 col-sm-4">
          <div class="card card-stats">
            <div class="card-body graph">
              <div class="row">
                <h5 class="text-center mb0">Closed Tickets Split </h5>
                <div class="col-12 col-md-12 pd0">
                  <div class="chartDiv" #gChart1></div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <div class="col-md-4 col-sm-4">
          <div class="card card-stats">
            <div class="card-body graph">
              <div class="row">
                <div class="col-12 col-md-12">
                  <div class="numbers">
                    <p class="card-category">Chat Timings</p>
                    <div class="select-chart">
                      <select class="form-control" (change)="loadChart(selchart.value)" #selchart>
                        <option value="rt">Response Times</option>
                        <option value="cd">Chat Duration</option>
                        <option value="wts">Wait Time (Served)</option>
                        <option value="wtm">Wait Time (Missed)</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-12 pd0">
                  <div class="chartDiv1" #gChart2></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-4">
          <div class="card card-stats">
            <div class="card-body graph">
              <div class="row">
                <h5 class="text-center mb0">Channels Split </h5>
                <div class="col-12 col-md-12 pd0">
                  <div class="chartDiv" #gChart3></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-4">
          <div class="card card-stats">
            <div class="card-body graph">
              <div class="row">
                <h5 class="text-center mb0">Weekday Response Times</h5>
                <div class="col-12 col-md-12 pd0">
                  <div class="chartDiv" #gChart4></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-4">
          <div class="card card-stats">
            <div class="card-body graph">
              <div class="row">
                <h5 class="text-center mb0">Weekday Ticket Counts</h5>
                <div class="col-12 col-md-12 pd0">
                  <div class="chartDiv" #gChart5></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-4">
          <div class="card card-stats">
            <div class="card-body graph">
              <div class="row">
                <h5 class="text-center mb0">Departments Split </h5>
                <div class="col-12 col-md-12 pd0">
                  <div class="chartDiv" #gChart6></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>