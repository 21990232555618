<div class="modal-content">
    <div class="modal-header">
        <div class="header">
            <h4 class="title mg0">Send Template</h4>
            <button type="button" class="close" (click)="closeModal()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
    <div class="modal-body">
        <form [formGroup]="templateForm">
            <div class="row">
                <div class="col-md-3">
                    <div class="mb-3">
                        <label for="formFile" class="form-label">Template List</label>
                        <select class="form-control" [ngModelOptions]="{standalone: true}" #templateSelection
                            [(ngModel)]="selectedTemplate" (change)="updateTemplate(selectedTemplate?.template_id)">
                            <option [value]="null" hidden selected disabled>Select Template</option>
                            <option *ngFor="let template of templatesList" [ngValue]="template">
                                {{template.name}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-md-6">
                    <div *ngIf="!selectedTemplate?.template_id" class="text-center text-warning mt-4">
                        Select a Template to Continue.
                    </div>
                    <div class="wa-bg" *ngIf="selectedTemplate?.template_id">
                        <div class="preview">
                            <div *ngIf="selectedTemplate?.template_id">
                                <div *ngIf="templateForm.value.header_type=='Image'" class="text-center">
                                    <img [src]="templateForm.value.header_url" width="200">
                                </div>
                                <div *ngIf="templateForm.value.header_type=='Video'" class="text-center">
                                    <i *ngIf="!templateForm.value.header_url"
                                        class="fa fa-file-video-o fa-5x text-primary" aria-hidden="true"></i>
                                    <video width="200" controls *ngIf="templateForm.value.header_url">
                                        <source [src]="templateForm.value.header_url">
                                    </video>

                                </div>
                                <div *ngIf="templateForm.value.header_type=='Document'" class="text-center">
                                    <a [href]="templateForm.value.header_url" target="_blank"><i
                                            class="fa fa-file fa-5x text-primary" aria-hidden="true"></i></a>
                                </div>
                                <div class="header">{{formatText(displayText.header)}}</div>
                                <div class="body" [innerHTML]="formatText(displayText.body)"></div>
                                <div class="footer">{{formatText(templateForm.value.footer)}}</div>
                            </div>
                        </div>
                        <div class="list_buttons">
                            <button class="btn" type="button" *ngFor="let button of templateForm.value.buttons"
                                (click)="goToWeb(displayText['button_link']);"><i
                                    *ngIf="templateForm.value.button_type=='Call to action'" class="fa"
                                    aria-hidden="true"
                                    [ngClass]="{ 'fa-external-link' : button.type=='Url', 'fa-phone-square' : button.type=='Phone Number'}"></i>
                                {{button.text}}</button>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div *ngIf="selectedTemplate">
                        <div class="w-100 mb-3" *ngIf="templateForm.value.header_type!='Text'">
                            <label class="mr-2">Header {{templateForm.value.header_type}} Url</label>
                            <div class="d-flex">
                                <input type="url" formControlName="header_url"
                                    placeholder="{{templateForm.value.header_type}} Url" class="form-control" required>
                                <input type="file" id="upload_img" (change)="getFile($event)" accept="image/*"
                                    style="display:none" />
                                <button type="button" name="imagefile" class="btn btn-sm text-info"
                                    (click)="uploadImg()"><i class="fa fa-upload" aria-hidden="true"></i></button>
                            </div>
                            <div class="mt-5" *ngIf="isUploading">
                                <progressbar [max]="uploadMaxSize" [value]="uploadedSize" type="success"
                                    [striped]="true" [animate]="true">
                                    <i>{{uploadedSize/1000| number:'1.0-0'}}kb / {{uploadMaxSize/1000|
                                        number:'1.0-0'}}kb</i>
                                </progressbar>
                            </div>
                        </div>
                        <div formArrayName="header_variables"
                            *ngFor="let item of templateForm.get('header_variables')['controls']; let i = index">
                            <div [formGroupName]="i" class="w-100 mb-3 preview-items"
                                *ngIf="templateForm.value.header_type=='Text'">
                                <label class="mr-2 main-label">Header {{
                                    '{'+'{'+templateForm.get('header_variables')['controls'][i].value.key+'}'+'}'
                                    }}</label>
                                <div class="pre-ips">
                                    <div>
                                        <input type="text"
                                            (keyup)="variableArray['header_variables'][i].value=templateForm.get('header_variables')['controls'][i].value.value; changeDisplayText(templateForm.value.header,'header','header_variables');"
                                            formControlName="value" class="form-control">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div formArrayName="body_variables"
                            *ngFor="let item of templateForm.get('body_variables')['controls']; let i = index">
                            <div [formGroupName]="i" class="w-100 mb-3 preview-items">
                                <label class="mr-2 main-label">Body {{
                                    '{'+'{'+templateForm.get('body_variables')['controls'][i].value.key+'}'+'}'
                                    }}</label>
                                <div class="pre-ips">
                                    <div>
                                        <input type="text" formControlName="value" class="form-control"
                                            (keyup)="variableArray['body_variables'][i].value=templateForm.get('body_variables')['controls'][i].value.value; changeDisplayText(templateForm.value.body,'body','body_variables');">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div formArrayName="button_variables"
                            *ngFor="let item of templateForm.get('button_variables')['controls']; let i = index">
                            <div [formGroupName]="i" class="w-100 mb-3 preview-items">
                                <label class="mr-2 main-label">Button {{
                                    '{'+'{'+templateForm.get('button_variables')['controls'][i].value.key+'}'+'}'
                                    }}</label>
                                <div class="pre-ips">
                                    <div>
                                        <input type="text" formControlName="value" class="form-control"
                                            (keyup)="this.variableArray['button_variables'][i].value=this.templateForm.get('button_variables')['controls'][i].value.value; changeDisplayText(templateForm.value.buttons[0].value,'button_link','button_variables');">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="empty_variables"
                            *ngIf="(variableArray['header_variables'].length==0 || templateForm.value.header_type!='Text') && variableArray['body_variables'].length==0 && variableArray['button_variables'].length==0">
                            <p>You haven't used any variable placeholders in your text.</p>
                            <p>In order to add a variable, go back to the editor and insert at the given position,
                                where
                                # represents the variable index, which needs to start at .</p>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeModal()"><i class="fa fa-times"
                aria-hidden="true"></i>Cancel</button>
        <button *ngIf="selectedTemplate" type="submit" class="btn btn-gradient"
            (click)="closeModal(templateForm.value)">
            <!-- <i class="fa fa-spinner fa-spin fa-pulse"></i> -->
            <i class="fa fa-check"></i>
            Send
        </button>
    </div>
</div>