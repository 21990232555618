<div class="main-content home">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12 tab-ag-dp">
        <tabset type="pills">
          <tab (selectTab)="isDashboard=false;isPerformance=true;isDepartment=false;performance.getAgentsDashboard();">
            <ng-template tabHeading>
              <button class="btn btn-sm" [ngClass]="{'btn-gradient':isPerformance}"><i class="fa fa-tachometer" aria-hidden="true"></i> My Performance</button>
            </ng-template>
            <app-performance #performance></app-performance>
          </tab>
          <tab *ngIf="customerType!=3" (selectTab)="isDashboard=false;isPerformance=false;isDepartment=true;department.initDate();">
            <ng-template tabHeading>
              <button class="btn btn-sm" [ngClass]="{'btn-gradient':isDepartment}"><i class="fa fa-pie-chart" aria-hidden="true"></i> Departments</button>
            </ng-template>
            <app-department #department></app-department>
          </tab>
          <tab *ngIf="customerType!=3" (selectTab)="isDashboard=true;isPerformance=false;isDepartment=false;dashboard.initBots();">
            <ng-template tabHeading>
              <button class="btn btn-sm" [ngClass]="{'btn-gradient':isDashboard}"><i class="fa fa-comments" aria-hidden="true"></i> CX</button>
            </ng-template>
            <app-dashboard #dashboard></app-dashboard>
          </tab>
        </tabset>
      </div>
    </div>
  </div>
</div>

