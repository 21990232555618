import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { showNotification } from 'app/services/notification';
import { ServicesService } from 'app/services/services.service';

@Component({
  selector: 'app-add-rules',
  templateUrl: './add-rules.component.html',
  styleUrls: ['./add-rules.component.scss']
})
export class AddRulesComponent implements OnInit {

  @Input() rule: any;
  @Input() departments: any;
  @Input() agents: any;
  @Input() memoryVars: any;
  @Output() close = new EventEmitter<Object>();
  addRulesForm: FormGroup;
  botID: number = parseInt(localStorage.getItem('botID'), 10);
  customerID: number = parseInt(localStorage.getItem('Customer_ID'), 10);
  customerType: number = parseInt(localStorage.getItem('Customer_Type'), 10);
  isEdit = false;
  isAdding = false;
  operations = ['<', '<=', '>', '>=', '==', '!=', 'in', '!in'];
  filteredAgents = [];
  channelList = [];
  errorMessage = '';

  constructor(private fb: FormBuilder, private dataService: ServicesService) { }

  ngOnInit(): void {
    this.resetForm(this.rule);
    this.getChannels();
  }

  resetForm(data?: any): void {
    this.isEdit = data ? true : false;
    this.addRulesForm = this.fb.group({
      'id': new FormControl(data?.id || null),
      'bot_id': new FormControl(this.botID || null),
      'name': new FormControl(data?.name || null, [Validators.required]),
      'department_id': new FormControl(data?.department_id || null, [Validators.required]),
      'channel_id': new FormControl(data?.channel_id || null),
      'liveagent_id': new FormControl(data?.liveagent_id, [Validators.required]),
      'condition': new FormArray([]),
    });
    data?.condition.forEach(ao => {
      (this.addRulesForm.get('condition') as FormArray).push(
        this.fb.group({
          'mem_var': [ao?.mem_var, [Validators.required]],
          'operator': [ao?.operator, [Validators.required]],
          'value': [ao?.value, [Validators.required]],
          'next_operator': [ao?.next_operator || null]
        }));
    });
    if (!data) this.addCondition();
    else this.filterAgentByDept(data?.department_id);

    this.addRulesForm.controls['liveagent_id'].setValue(data?.liveagent_id || null);
  }

  submitRule(form: FormGroup) {
    if (form.valid) {
      if (this.isEdit) {
        this.dataService.editAssignRules(form.value.id, form.value).subscribe(
          () => {
            this.close.emit(true);
            showNotification('success', '<span>Edited Successfully!</span>');
          },
          () => {
            showNotification('error', '<span>Try Again!</span>');
          })
      } else {
        this.dataService.addAssignRules(form.value).subscribe(
          () => {
            this.close.emit(true);
            showNotification('success', '<span>Added Successfully!</span>');
          },
          () => {
            showNotification('error', '<span>Try Again!</span>');
          })
      }
      this.errorMessage = '';
    } else {
      this.errorMessage = 'Fill all fields!';
    }
  }

  closeModal() {
    this.close.emit();
  }

  get conditions(): FormArray {
    return this.addRulesForm.get("condition") as FormArray
  }

  newCondition(): FormGroup {
    return this.fb.group({
      'mem_var': [null, [Validators.required]],
      'operator': [null, [Validators.required]],
      'value': [null, [Validators.required]],
      'next_operator': [null]
    })
  }

  addCondition() {
    this.conditions.push(this.newCondition());
  }

  removeCondition(i: number) {
    this.conditions.removeAt(i);
  }

  filterAgentByDept(deptId) {
    this.filteredAgents = this.agents.filter(agent =>
      agent.Departments.some(department => department.DepartmentID == parseInt(deptId))
    );
    this.addRulesForm.controls['liveagent_id'].setValue(null);
  }

  getChannels() {
    this.dataService.getChannelList().subscribe(
      channels => {
        this.channelList = channels;
      }
    )
  }


}
