<div class="main-content unified">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12 row">
				<div class="col-md-3 form-group">
					<select class="form-control" [(ngModel)]="selectedDeptID" (change)="changeDepartment()">
						<option [value]="null" disabled>Department Name</option>
						<option *ngFor="let item of departments" value="{{item.DepartmentID}}">{{item.DepartmentName}}
						</option>
					</select>
				</div>
				<div class="col-md-6 form-group">
					<div class="form-group tyn-pill">
						<div class="form-control-wrap">
							<div class="form-control-icon start">
								<!-- search -->
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
									class="bi bi-search" viewBox="0 0 16 16">
									<path
										d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z">
									</path>
								</svg>
							</div>
							<input type="text" class="form-control form-control-solid" id="search"
								placeholder="Search contact / chat">
						</div>
					</div>
				</div>
			</div>
			<div class="col-sm-3">
				<tabset type="pills">
					<tab (selectTab)="isAssigned=true;">
						<ng-template tabHeading>
							<button class="btn btn-sm d-flex" [ngClass]="{'btn-gradient':isAssigned}">Assigned <span
									class="list-count" *ngIf="usersList.length">{{usersList.length}}</span></button>
						</ng-template>
						<!-- <app-users-list [usersListDetails]="usersListDetails"
                            (selectUser)="selectUser($event)"></app-users-list> -->

						<div class="tyn-aside tyn-aside-base" *ngFor="let uid of usersList">
							<div class="tyn-aside-body" data-simplebar="init" (click)="selectUser(uid)">
								<div class="simplebar-wrapper" style="margin: 0px;">
									<div class="simplebar-height-auto-observer-wrapper">
										<div class="simplebar-height-auto-observer"></div>
									</div>
									<div class="simplebar-mask">
										<div class="simplebar-offset" style="right: 0px; bottom: 0px;">
											<div class="simplebar-content-wrapper" tabindex="0" role="region"
												aria-label="scrollable content"
												style="height: 100%; overflow: hidden scroll;">
												<div class="simplebar-content" style="padding: 0px;">
													<div class="tab-content">
														<div class="tab-pane show active" id="all-chats" tabindex="0"
															role="tabpanel">
															<ul class="tyn-aside-list">
																<li class="tyn-aside-item js-toggle-main active">
																	<div class="tyn-media-group">
																		<div class="tyn-media">
																			<span>{{getFirstLetter(uid)}}</span>
																			<i class="status fa fa-circle active"></i>
																		</div>
																		<div class="tyn-media-col">
																			<div class="tyn-media-row">
																				<h6 class="name">
																					{{getUserSpec('name', uid) ?
																					getUserSpec('name', uid) :
																					((getUserSpec('email', uid) ?
																					getUserSpec('email',uid) :
																					getUserSpec('userid', uid)))}}
																				</h6>
																			</div>
																			<div class="tyn-media-row has-dot-sap">
																				<p class="number">{{getUserSpec('phone',
																					uid)}}</p>
																			</div>
																		</div>
																		<div class="tyn-media-time">
																			<div class="tyn-media-date"
																				*ngIf="uid?.timestamp">
																				<h6 class="date">
																					{{getUserSpec('timestamp', uid)}}
																				</h6>
																			</div>
																			<div class="tyn-media-icons">
																				<img width="20"
																					src="https://canvas.creyoface.com/assets/images/countries/{{getCountryCode(uid) | lowercase}}.png">
																				<!-- <i class="fa fa-whatsapp"></i> -->
																				<img width="20"
																					src="/assets/img/channels/{{getUserSpec('channelid',uid)}}.png">
																			</div>
																		</div>
																	</div>
																</li>
															</ul>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div><!-- .tyn-aside-body -->
						</div>

					</tab>
					<tab (selectTab)="isAssigned=false;">
						<ng-template tabHeading>
							<button class="btn btn-sm d-flex" [ngClass]="{'btn-gradient':!isAssigned}">Unassigned <span
									class="list-count" *ngIf="seluserList.length">{{seluserList.length}}</span></button>
						</ng-template>
						<div class="selUsers" *ngFor="let item of seluserList.slice().reverse(); let i=index;">
							<!-- <a href="javascript:void(0)" [ngClass]="{ 'selected': item.selected}" class="seluser"
                            (click)="selectSelUser(item.userid)">{{getUserSpec('name',item.userid)}} <i class="fa fa-circle status"
                            [ngClass]="{ 'disconnected': item.disconnect}"></i></a> <span
                            *ngIf="unreadCount[item.userid]>0" class="count">{{unreadCount[item.userid]}}</span> -->

							<div class="tyn-aside tyn-aside-base" *ngFor="let item of seluserList.slice().reverse()">
								<div class="tyn-aside-body" data-simplebar="init" (click)="selectSelUser(item)">
									<div class="simplebar-wrapper" style="margin: 0px;">
										<div class="simplebar-height-auto-observer-wrapper">
											<div class="simplebar-height-auto-observer"></div>
										</div>
										<div class="simplebar-mask">
											<div class="simplebar-offset" style="right: 0px; bottom: 0px;">
												<div class="simplebar-content-wrapper" tabindex="0" role="region"
													aria-label="scrollable content"
													style="height: 100%; overflow: hidden scroll;">
													<div class="simplebar-content" style="padding: 0px;">
														<div class="tab-content">
															<div class="tab-pane show active" id="all-chats"
																tabindex="0" role="tabpanel">
																<ul class="tyn-aside-list">
																	<li class="tyn-aside-item js-toggle-main active">
																		<div class="tyn-media-group">
																			<div class="tyn-media">
																				<span>{{getFirstLetter(item.userid)}}</span>
																				<i class="fa fa-circle status"
																					[ngClass]="{ 'disconnected': item.disconnect}"></i>
																			</div>
																			<div class="tyn-media-col">
																				<div class="tyn-media-row">
																					<h6 class="name">
																						{{getUserSpec('name',
																						item.userid) ?
																						getUserSpec('name', item.userid)
																						: ((getUserSpec('email',
																						item.userid) ?
																						getUserSpec('email',item.userid)
																						: getUserSpec('userid',
																						item.userid)))}}
																					</h6>
																				</div>
																				<div class="tyn-media-row has-dot-sap"
																					*ngIf="getUserSpec('phone', item.userid)">
																					<p class="number">
																						{{getUserSpec('phone',
																						item.userid)}}</p>
																				</div>
																			</div>
																			<div class="tyn-media-time">
																				<div class="tyn-media-date"
																					*ngIf="getUserSpec('timestamp', item.userid)">
																					<h6 class="date">
																						{{getUserSpec('timestamp',
																						item.userid)}}</h6>
																				</div>
																				<div class="tyn-media-icons">
																					<img width="20"
																						src="https://canvas.creyoface.com/assets/images/countries/{{getCountryCode(item.userid) | lowercase}}.png">
																					<!-- <i class="fa fa-whatsapp"></i> -->
																					<img width="20"
																						src="/assets/img/channels/{{getUserSpec('channelid',item.userid)}}.png">
																				</div>
																			</div>
																		</div>
																	</li>
																</ul>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div><!-- .tyn-aside-body -->
							</div>
							<!-- <pre>{{item|json}}</pre> -->
						</div>
					</tab>
				</tabset>
			</div>
			<div class="col-sm-6">
				<div class="card">
					<main class="msger-chat nodata" *ngIf="chatlog.length==0">
						<div class="selUserMsg" *ngIf="selected_user==0" style="color: red;">Please select a ticket to access the chat!</div>
						<div class="selUserMsg" *ngIf="isfetchingLog"><i class="fa fa-spin fa-spinner fa-pulse"></i>
						</div>
					</main>
					<main class="msger-chat" *ngIf="chatlog.length>0" #chatBox>
						<a href="javascript:void(0)" class="close-chat" (click)="closeChatModal.show()"><i
								class="fa fa-times" aria-hidden="true"></i></a>
						<div *ngFor="let chat of chatlog">
							<div class="msg left-msg" *ngIf="chat.sender=='bot'">
								<div class="dflx mtop-25"
									*ngIf="chat.message.cardtypeid!=undefined && chat.message.cardtypeid!=16 && chat.message.cardtypeid != 8">
									<div class="msg-img"
										style="background-image: url(https://canvas.creyoface.com/assets/img/creyoface.gif)">
									</div>
									<!-- [style.background]="'url('+ userDetails.botlogo +')'" -->
									<div class="msg-bubble">
										<div class="msg-text">
											<div *ngIf="chat.message.cardtypeid == 1 && chat.message.feedbacktypeid == 1"
												[innerHTML]="chat.message.message"></div> <!-- msg card -->
											<div
												*ngIf="chat.message.cardtypeid == 39 && chat.message.feedbacktypeid == 1">
												<carousel>
													<slide *ngFor="let item of chat.message.message; let i=index;">
														<img src="{{ item }}" alt="slide">
													</slide>
												</carousel>
											</div> <!-- image card -->
											<div
												*ngIf="chat.message.cardtypeid == 38 && chat.message.feedbacktypeid == 1">
												<iframe height="120" [src]="changeIframe(chat.message.message)">
												</iframe>
											</div>
											<!-- video card -->
											<div
												*ngIf="chat.message.cardtypeid == 37 && chat.message.feedbacktypeid == 1">
												<audio controls>
													<source src="{{chat.message.message}}" type="audio/mpeg">
												</audio>
											</div> <!-- audio card -->
											<div *ngIf="chat.message.cardtypeid == 2"
												[innerHTML]="chat.message.message">
												<!-- choice card -->
												<div class="choices">
													<div *ngFor="let opt of chat.message.choices" [innerHTML]="opt.key">
													</div>
												</div>
											</div>
											<!-- <div *ngIf="chat.message.cardtypeid == 3 && chat.message.feedbacktypeid == 0"
											[innerHTML]="chat.message.message"></div> -->
											<div *ngIf="chat.message.cardtypeid == 3 && chat.message.feedbacktypeid <= 10"
												[innerHTML]="chat.message.message"></div>
											<!-- userip feedback = 0 card -->
											<div *ngIf="chat.message.cardtypeid == 3 && chat.message.feedbacktypeid > 10"
												class="formcard">
												<!-- userip feedback > 0 card -->
												<div *ngFor="let item of chat.message.message">
													<span><input type="text" placeholder="{{item.element}}"
															disabled></span>
												</div>
											</div> <!-- userip feedback = 0 card -->
											<!-- <div *ngIf="chat.message.cardtypeid == 3 && chat.message.feedbacktypeid > 0">{{chat.message.message}}</div> -->
											<div *ngIf="chat.message.cardtypeid == 4 && chat.message.feedbacktypeid == 1"
												[innerHTML]="chat.message.message">
												<br>
												<div class="dflx feedback">
													<i class="fa fa-star" aria-hidden="true"></i>
													<i class="fa fa-star" aria-hidden="true"></i>
													<i class="fa fa-star" aria-hidden="true"></i>
													<i class="fa fa-star" aria-hidden="true"></i>
													<i class="fa fa-star" aria-hidden="true"></i>
												</div>
											</div> <!-- feedback card -->
											<div *ngIf="chat.message.cardtypeid == 4 && chat.message.feedbacktypeid == 2"
												[innerHTML]="chat.message.message"></div> <!-- feedback card -->
											<div *ngIf="chat.message.cardtypeid == 4 && chat.message.feedbacktypeid == 3"
												[innerHTML]="chat.message.message"></div> <!-- feedback card -->
											<div *ngIf="chat.message.cardtypeid == 4 && chat.message.feedbacktypeid == 4"
												[innerHTML]="chat.message.message"></div> <!-- feedback card -->
											<div *ngIf="chat.message.cardtypeid == 14"
												[innerHTML]="chat.message.message"></div> <!-- date card -->
											<div *ngIf="chat.message.cardtypeid == 10">
												<!-- carousal card -->
												<carousel [noWrap]="noWrap" [interval]="2000">
													<slide *ngFor="let item of chat.message.data | keyvalue">
														<img src="{{chat.message.data[item.key].img_url}}" alt="slide">
														<div class="caption text-center">
															<span class="heading"
																[innerHTML]="chat.message.data[item.key].heading"></span><br>
															<span class="subheading"
																[innerHTML]="chat.message.data[item.key].subheading"></span>
														</div>
														<div class="car-buttons text-center"
															*ngFor="let btns of chat.message.data[item.key].choices">
															<span [innerHTML]="btns.key"></span>
														</div>
													</slide>
												</carousel>
											</div>

											<div class="pax-card" *ngIf="chat.message.cardtypeid == 31">
												<img class="bg_imgurl" width="100" src="/assets/img/ic_send_pax.svg"
													alt="bgimg">
												<p class="heading" [innerHTML]="chat.message.message[0].heading"></p>
												<p class="subheading" [innerHTML]="chat.message.message[0].sub_heading">
												</p>
												<p class="description"
													[innerHTML]="chat.message.message[0].description"></p>
											</div>
											<div class="seat-selection" *ngIf="chat.message.cardtypeid == 32">
												<img class="bg_imgurl" width="150"
													src="{{chat.message.message.message.bg_imgurl}}" alt="bgimg">
												<p class="heading" [innerHTML]="chat.message.message.message.heading">
												</p>
												<p class="subheading"
													[innerHTML]="chat.message.message.message.sub_heading"></p>
												<p class="description"
													[innerHTML]="chat.message.message.message.description"></p>
												<div class="seats">
													<div class="row" *ngFor="let item of [].constructor(5);">
														<div *ngFor="let item of [].constructor(6);" class="col-xs-2">
															<img src="/assets/img/seat.png" width="20">
														</div>
													</div>
												</div>
											</div>
											<div
												*ngIf="chat.message.cardtypeid == 18 && chat.message.feedbacktypeid==1">
												<span class="car-rep-button" [innerHTML]="chat.message.message"></span>
											</div>
											<div
												*ngIf="chat.message.cardtypeid == 18 && chat.message.feedbacktypeid==0">
												<a [href]="chat.message.message" target="_blank" download>Download
													File</a>
											</div>
										</div>
									</div>
								</div>
								<div class="tagsList" *ngIf="chat.cardtypeid == 27">
									<div class="include"
										[ngClass]="{'m-auto':chat.message.exclude.length==0, 'dnone':chat.message.include.length==0 }">
										<span *ngFor="let item of chat.message.include"
											[style.background]="getTagDetails(item,'color')">
											{{getTagDetails(item,'name')}}
										</span>
									</div>
									<div class="exclude"
										[ngClass]="{'m-auto':chat.message.include.length==0, 'dnone':chat.message.exclude.length==0 }">
										<span *ngFor="let item of chat.message.exclude"
											[style.background]="getTagDetails(item,'color')">
											{{getTagDetails(item,'name')}}
										</span>
									</div>
								</div>
								<!-- tags card -->
								<div class="msg-info"
									*ngIf="chat.message.cardtypeid!=undefined && chat.message.cardtypeid!=16 && chat.message.cardtypeid != 8">
									<div class="msg-info-time">{{chat.time | date:'medium'}}</div>
								</div>
							</div>
							<div *ngIf="chat.isgoal" class="w-100 text-center goal">
								<span *ngIf="chat.goalstatus=='start'" title="Goal Name" class="goalstart"> <img
										src="/assets/img/start.png" width="25"> &nbsp;{{chat.goalname}} <span
										class="status">Started</span></span>
								<span *ngIf="chat.goalstatus=='milestone'" title="Goal: {{chat.goalname}}"
									class="goalmilestone"> <img src="/assets/img/leader.png" width="25">
									&nbsp;{{chat.goalname}} - {{chat.milestone}}</span>
								<span *ngIf="chat.goalstatus=='end'" title="Goal Name" class="goalend"> <img
										src="/assets/img/target.png" width="25"> &nbsp;{{chat.goalname}} <span
										class="status">Completed</span></span>
							</div>

							<div class="msg left-msg" *ngIf="chat.sender=='live_agent' && chat.isLive==undefined">
								<div class="dflx mtop-25" *ngIf="chat.cardtypeid==undefined">
									<div class="msg-img"
										[style.background-image]="'url(' + getLAImage(chat.message.agent_id,'img') + ')'"
										[title]="getLAImage(chat.message.agent_id,'name')">
										<span>{{getLAImage(chat.message.agent_id,'name')}}</span>
									</div>
									<div class="msg-bubble liveagent" *ngIf="chat.message.agent_id">
										<div class="msg-text">
											<div [innerHTML]="chat.message.message"></div>
										</div>
									</div>
									<div class="tagsList w-100" *ngIf="chat.cardtypeid == 27">
										<div class="include m-auto">
											<span *ngFor="let item of chat.message.include"
												[style.background]="getTagDetails(item,'color')">
												{{getTagDetails(item,'name')}}
											</span>
										</div>
									</div>
								</div>
								<div class="mtop-25" *ngIf="chat.cardtypeid == 27">
									<div class="msg-img"
										[style.background-image]="'url(' + getLAImage(customerID,'img') + ')'"
										[title]="getLAImage(customerID,'name')">
										<span>{{getLAImage(customerID,'name')}}</span>
									</div>
									<div class="tagsList w-100" *ngIf="chat.cardtypeid == 27">
										<div class="include m-auto">
											<span *ngFor="let item of chat.message.include"
												[style.background]="getTagDetails(item,'color')">
												{{getTagDetails(item,'name')}}
											</span>
										</div>
									</div>
								</div>
								<div class="msg-info">
									<div class="msg-info-time">{{chat.time | date:'medium'}}</div>
								</div>
							</div>

							<div class="msg right-msg" *ngIf="chat.sender=='live_agent' && chat.isLive">
								<div class="dflxrev mtop-25">
									<div class="msg-img" style="background-image: url({{agent_img}})">
									</div>
									<div class="msg-bubble liveagent">
										<div class="msg-text">
											<div>{{chat.message}}</div>
										</div>
									</div>
								</div>
								<div class="msg-info">
									<div class="msg-info-time">{{chat.time | date:'medium'}}</div>
								</div>
							</div>

							<div class="msg left-msg" *ngIf="chat.sender=='user' && chat.isLive">
								<div class="dflx mtop-25">
									<div class="msg-img"
										style="background-image: url(https://canvas.creyoface.com/assets/img/creyoface.gif)">
									</div>
									<div class="msg-bubble">
										<div class="msg-text">
											<div>{{chat.message}}</div>
										</div>
									</div>
								</div>
								<div class="msg-info">
									<div class="msg-info-time">{{chat.time | date:'medium'}}</div>
								</div>
							</div>

							<div class="msg right-msg"
								*ngIf="chat.sender == 'user' && chat.message && chat.isLive==undefined">
								<div class="dflxrev mtop-25">
									<div class="msg-img"
										style="background-image: url(https://canvas.creyoface.com/assets/img/creyoface.gif)">
									</div>
									<!-- [style.background]="'url('+ userDetails.botlogouser +')'" -->
									<div class="msg-bubble">
										<div class="msg-text"
											*ngIf="chat.cardtypeid == 1 || chat.cardtypeid == 2 || (chat.cardtypeid == 3 && chat.feedbacktypeid ==0 || chat.cardtypeid == -1)"
											[innerHTML]="chat.message">
										</div>

										<div class="msg-text" *ngIf="chat.cardtypeid == 4 && chat.feedbacktypeid ==1"
											[innerHTML]="chat.message"><i class="fa fa-star" aria-hidden="true"></i>
										</div>
										<div class="msg-text"
											*ngIf="chat.cardtypeid == 4 && (chat.feedbacktypeid == 3 || chat.feedbacktypeid == 4)"
											[innerHTML]="chat.message">
										</div>

										<div class="msg-text" *ngIf="chat.cardtypeid == 26" [innerHTML]="chat.message">
										</div>

										<div class="msg-text" *ngIf="chat.cardtypeid == 14">
											<!-- date -->
											<span *ngIf="chat.message.from">{{chat.message.from}}</span>
											<span *ngIf="chat.message.to"> - {{chat.message.to}}</span>
										</div>

										<div class="msg-text" *ngIf="chat.cardtypeid == 4 && chat.feedbacktypeid == 2">
											<!-- date -->
											<i *ngIf="chat.message == '0'" class="fa fa-thumbs-down"
												aria-hidden="true"></i>
											<i *ngIf="chat.message == '1'" class="fa fa-thumbs-up"
												aria-hidden="true"></i>
										</div>

										<div class="car-reply" *ngIf="chat.cardtypeid == 10">
											<!-- carousal -->
											<span class="car-rep-head"
												[innerHTML]="chat.message.data.heading"></span><br>
											<span class="car-rep-subhead"
												[innerHTML]="chat.message.data.subheading"></span><br>
											<span class="car-rep-button" [innerHTML]="chat.message.choice"></span>
										</div>

										<div *ngIf="chat.cardtypeid == 3 && chat.feedbacktypeid >= 10">
											<div *ngFor="let item of chat.message | keyvalue">
												<span>{{item.key}} : <b>{{item.value}}</b></span>
											</div>
										</div>

										<div *ngIf="chat.cardtypeid == 3 && chat.feedbacktypeid < 10">
											<div [innerHTML]="chat.message">
											</div>
										</div>

										<div *ngIf="chat.cardtypeid == 18 && chat.feedbacktypeid == 1">
											<a [href]="chat.message" target="_blank" download>Uploaded File</a>
										</div>

										<div class="msg-text" *ngIf="chat.cardtypeid == 31">
											<div *ngFor="let item of chat.message; let i=index;" class="seat-selected">
												<span *ngFor="let pax of item | keyvalue;">{{pax.key}} :
													{{pax.value}}</span>
											</div>
										</div>

										<div class="msg-text" *ngIf="chat.cardtypeid == 32">
											<div *ngFor="let item of chat.message" class="seat-selected">
												<span>{{item.label_key}}</span>
											</div>
										</div>

									</div>

								</div>
								<div class="msg-info">
									<div class="msg-info-time">{{chat.time | date:'medium'}}</div>
								</div>
							</div>
						</div>
					</main>
					<div *ngIf="startChat" class="chat-container">
						<div class="chat-input">
							<textarea title="Type message & enter to send" [mentionConfig]="mentionConfig"
								[(ngModel)]="newMessage" (keyup)="$event.keyCode == 13 && sendMessage()" rows="3"
								[disabled]="isfetchingLog"></textarea>
							<button (click)="sendMessage()" class="send-btn"><i class="fa fa-paper-plane"
									aria-hidden="true"></i></button>
						</div>
					</div>
				</div>
			</div>
			<div class="col-sm-3">Details</div>
		</div>
	</div>
</div>