import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ServicesService } from '../../services/services.service';
import { showNotification } from '../../services/notification';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators, AbstractControl } from '@angular/forms';

@Component({
	selector: 'app-tags',
	templateUrl: './tags.component.html',
	styleUrls: ['./tags.component.scss']
})
export class TagsComponent implements OnInit {

	public botID:number = parseInt(localStorage.getItem('botID'), 10);
	public customerID:number = parseInt(localStorage.getItem('Customer_ID'), 10);
	public customerType:number = parseInt(localStorage.getItem('Customer_Type'), 10);
	@ViewChild('addTagsModal', { static: false }) addTagsModal: ModalDirective;

	public isFetchReplies:boolean = false;
	public isAdding:boolean = false;
	public isEdit:boolean = false;
	public isDeleting:boolean = false;
	public loadingBots:boolean = false;
	public toggle:boolean = false;
	public isTagCreate:boolean = false;

	public botList = [];
	public tagsList = [];
	public tagsListActive = [];
	public tagsListInactive = [];
	public selectedColor = '#ffffff';

	public addTag: FormGroup;

	constructor(private dataService: ServicesService, private _fb:FormBuilder) { }

	ngOnInit(): void {
		this.clearAddTag();
		this.getBotList();
	}

	clearAddTag(){
		this.addTag = this._fb.group({
			'bot_id': this.botID,
			'AgentID': new FormControl(this.customerID),
			'AgentType': new FormControl(this.customerType),
			'tag_name': new FormControl(''),
			'color': new FormControl(this.selectedColor)
		});
	}


	getBotList(){
		this.loadingBots = true;
		this.dataService.getBotList({
			CustomerID:this.customerID,
			CustomerType:this.customerType})
		.subscribe((res) =>{
			if(res.status_code==200){
				this.botList = res.data;
			}
			this.loadingBots = false;
		}
		,
		(error)=>{
			this.loadingBots = false;
			console.log("Bot list error");
		}
		);
	}

	showColor(){
		this.addTag.value.color = this.selectedColor;
	}

	submitTag(form){
		this.isAdding = true;
		if(!this.isEdit){
			this.dataService.createTag(form.value).subscribe(
				(res)=>{
					if(res.status_code==200){
						this.clearAddTag();
						this.addTagsModal.hide();
						this.getTagList();
						showNotification('success','<span>Added Successfully!</span>');
					} else{
						showNotification('warning',res.status);
					}
					this.isAdding = false
				},
				(error)=>{
					console.log(error);

					this.isAdding = false
				})
		} else {
			this.dataService.editTag(form.value).subscribe(
				(res)=>{
					if(res.status_code==200){
						this.clearAddTag();
						this.addTagsModal.hide();
						this.getTagList();
						this.isEdit = false;
						showNotification('success','<span>Edited Successfully!</span>');
					} else{
						showNotification('warning',res.status);
					}
					this.isAdding = false
				},
				(error)=>{
					console.log(error);

					this.isAdding = false
				})
		}
	}

	getTagList(){
		this.dataService.getTags({
			bot_id:this.botID,
			AgentID:this.customerID,
			AgentType:this.customerType
		}).subscribe(
		(res)=>{
			if(res.status_code==200){
				this.tagsList = res.data;
				this.tagsListActive = [];
				this.tagsListInactive = [];
				for(let i in this.tagsList){
					if(this.tagsList[i].is_active){
						this.tagsListActive.push(this.tagsList[i]);
					} else {
						this.tagsListInactive.push(this.tagsList[i]);
					}

				}
				this.isTagCreate = res.is_tag_create;
			}
			else {
				showNotification('danger',res.status);
			}
		},
		(error)=>{
			console.log(error);
			showNotification('danger',error);
		})
	}

	editTag(item){
		this.isEdit = true;
		this.addTag = this._fb.group({
			'bot_id': this.botID,
			'AgentID': new FormControl(this.customerID),
			'AgentType': new FormControl(this.customerType),
			'tag_name': new FormControl(item.tag_name),
			'color': new FormControl(item.color),
			'tag_id': new FormControl(item.tag_id)
		});
		this.selectedColor = item.color;
		this.addTagsModal.show();
	}

	deleteTag(id,status){
		this.isDeleting = true;
		this.dataService.deleteTag({
			tag_id: id,
			AgentID: this.customerID,
			AgentType: this.customerType,
			is_active: status
		}).subscribe(
		(res)=>{
			if(res.status_code==200){
				// this.tagsList = this.tagsList.filter(ae=>ae.tag_id!=id);
				showNotification('success','<span>Status Changed Successfully!</span>');
				this.getTagList();
			} else{
				showNotification('warning',res.status);
			}
			this.isDeleting = false
		},
		(error)=>{
			console.log(error);

			this.isDeleting = false
		})
	}

	changeBot(botid){
		localStorage.setItem('botID', botid);
		this.botID = botid;
		this.getTagList();
	}

	isTagActive(tag_id){
		return this.tagsList.filter(ae=>ae.tag_id==tag_id)[0].is_active;
	}

	isUniqueName(name){
		let isDuplicate = false;
		if(this.isEdit){
			let tags = this.tagsList.filter(ae=>ae.tag_name==name);
			for(let i in tags){
				if(tags[i].tag_id != this.addTag.value.tag_id){isDuplicate = true;}
			}
		} else {
			for(let i in this.tagsList){
				if(this.tagsList[i].tag_name===name){isDuplicate = true;}
			}
		}
		return isDuplicate;

	}
}
