import { Component, OnInit, ElementRef, ViewChild} from '@angular/core';
import { ServicesService } from '../services/services.service';
import { showNotification } from '../services/notification';
import { trigger, style, animate, transition,state,group } from '@angular/animations';
import * as data from '../../assets/js/countrycodes.json';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { DomSanitizer } from '@angular/platform-browser';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators, AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-spectate',
  templateUrl: './spectate.component.html',
  styleUrls: ['./spectate.component.scss','../user/user.component.scss'],
  animations: [
  trigger('slideInOut', [
    state('in', style({height: '*', opacity: 0})),
    transition(':leave', [
      style({height: '*', opacity: 1}),

      group([
        animate(50, style({height: 0})),
        animate('100ms ease-in-out', style({'opacity': '0'}))
        ])

      ]),
    transition(':enter', [
      style({height: '0', opacity: 0}),

      group([
        animate(50, style({height: '*'})),
        animate('200ms ease-in-out', style({'opacity': '1'}))
        ])

      ])
    ])
  ]
})
export class SpectateComponent implements OnInit {

  current_user:string = localStorage.getItem('Customer_Email');
  selected_bot = localStorage.getItem('botName');
  agent_img = localStorage.getItem('agent_imgurl');
  botID:number = parseInt(localStorage.getItem('botID'), 10);
  customerID:number = parseInt(localStorage.getItem('Customer_ID'), 10);
  customerName:string = localStorage.getItem('Customer_Name');
  customerType:number = parseInt(localStorage.getItem('Customer_Type'), 10);
  usersList: any[] = [];
  seluserList: any[] = [];
  agentProfiles = [];
  spectatingUser: any[] = [];
  spectDetails:  any = {};
  spectDetail:  any = {};
  spectleadDetails:  any = {};
  usersListDetails: any[] = [];
  leadForm: FormGroup;
  isfetchingLog:boolean=false;
  startChat:boolean=false;
  chatEnabled:boolean=false;
  showChatButton:boolean=false;
  showDetails:boolean=false;
  showLeadDetails:boolean=false;
  showSideboxes:boolean=false;
  userTags = [];
  selectedTags = [];
  chatlog:  any[] = [];
  unreadCount:  any = {};
  selected_user:any=0;
  newMessage: string;
  chatlogList:  any = {};
  tagsList = [];
  mentionConfig = {};
  replies:any[];
  selected_nodeid:number;
  pingTime;
  pingCounter:number = 0;
  pingTimeMilli:number = 180000;
  showTagDetails:boolean=false;
  isaddTags:boolean=false;
  country: any = (data as any).default;
  starredMemVarsList=[];
  memVarTypes=[
  {name:'String',value:'string'},
  {name:'Integer',value:'integer'},
  {name:'Decimal',value:'decimal'},
  {name:'Boolean',value:'boolean'},
  {name:'Dictionary',value:'dictionary'},
  {name:'List',value:'list'},
  ];
  @ViewChild('chatBox') private chatBox: ElementRef;
  @ViewChild('closeChatModal', { static: false }) closeChatModal: ModalDirective;

  public leadDataList=[];
  public leadmoreForm: FormGroup;
  public addMemForm: FormGroup;
  showmoreDetails:boolean=false;
  leadloader:boolean=false;
  isLeadEdit:boolean=false;
  isaddLead:boolean=false;
  isaddMemory: boolean = false;
  categoriesSettings;


  constructor(private dataService: ServicesService, private _fb:FormBuilder, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.connectChat();
    this.pingTime = setInterval(() => this.sendPing(),this.pingTimeMilli);
    this.getAllReplies();
    this.clearMoreLeadForm();
    this.getStarMemVars();
    this.getAllTags();
    this.categoriesSettings = { 
      text:"Select tags",
      enableCheckAll:true,
      enableSearchFilter: true,
      lazyLoading: true
    }; 
    this.leadForm = this._fb.group({
      'name': ['', [Validators.required]],
      'email': ['', [Validators.required]],
      'phone': ['', [Validators.required]],
      'company': ['', [Validators.required]],
      'location': ['', [Validators.required]],
    });
  }

  getSafeUrl(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }

  changeIframe(url) {
    return this.getSafeUrl(url);
  }


  connectChat(){
    this.dataService.onSpectateLA(this.botID,this.current_user,this.customerID).subscribe(
      (message:any) => {
        if (this.pingTime) {
          clearInterval(this.pingTime);
          this.pingTime = setInterval(() => this.sendPing(), this.pingTimeMilli);
        }
        if(message.pong){
          this.pingCounter = 0;
        } else{
          this.playAudio();
          if(message.isQueue){
            var exist = -1;
            for(let i in this.usersList){
              if(this.usersList[i].userid == message.userid){ exist = parseInt(i); }
            }
            if(exist==-1){
              this.usersList.push({
                userid:message.userid,
                inView:false,
                disconnected: false
              });
              this.usersListDetails.push(message);//new
            } 
            if(message.disconnect==true){
              this.usersList = this.usersList.filter(ae=>ae.userid!=message.userid);
              this.usersListDetails=this.usersListDetails.filter(ae=>ae.userid!=message.userid); //new
            }
            // write code to remove a user in queue disconnect=true
          } else if(message.isLive && message.type=='log'){//get log of selected user
            this.showDetails = true;
            this.showLeadDetails = true;
            if(!message.reconnect){
            } else if (message.reconnect){
              for(let i in this.seluserList){
                if(this.seluserList[i].userid==message.userid){
                  this.seluserList[i].disconnect = true;
                }
              }
            }
            this.selected_user = message.userid;
            this.spectDetails[message.userid] = message.message.userdetails;
            this.spectleadDetails[message.userid] = message.message.lead;
            this.spectDetail = message.message.userdetails;
            this.leadForm = this._fb.group({
              'name': [this.spectleadDetails[message.userid].name, [Validators.required]],
              'email': [this.spectleadDetails[message.userid].email, [Validators.required]],
              'phone': [this.spectleadDetails[message.userid].phone, [Validators.required]],
              'company': [this.spectleadDetails[message.userid].company, [Validators.required]],
              'location': [this.spectleadDetails[message.userid].location, [Validators.required]],
            });
            this.isfetchingLog = false;
            this.chatlog=[...message.message.log];
            this.agentProfiles = message.message.agent_profiles;
            for(let i in this.chatlog){
              if(this.chatlog[i].message?.cardtypeid==1 && this.chatlog[i].message?.feedbacktypeid==2){
                var imgs = this.chatlog[i].message.message.split("_$_");
                this.chatlog[i].message.message = imgs;
              }
            }
            // this.chatlogList[message.userid] = this.chatlog;
            this.startChat = false;
            this.unreadCount[message.userid] = 0; //unread count initialise
            this.showChatButton = true; //showChatbutton
            this.scrollToBottom();
          } else if(message.isLive && message.type=='text'){ //live chat message
            let date = new Date();
            let data = {
              'time': date,
              // 'time': {$date: date},
              'sender': "user",
              'isLive': true,
              'message': message.message
            }
            this.chatlogList[message.userid].push(data);
            if(message.userid != this.selected_user){
              this.unreadCount[message.userid]++; //unread comment increment 
            }
            this.scrollToBottom();
          } else if(message.isLive && message.disconnect){ //if selected user disconnects
            for(let i in this.seluserList){
              if(this.seluserList[i].userid==message.userid){
                this.seluserList[i].disconnect = true;
              }
            }
          }
          if(message.isSpectate && message.type=='text'){ //spectate live chat message
            // let date = new Date();
            // let data = {
              //   'time': {$date: date},
              //   'sender': "user",
              //   'isLive': true,
              //   'message': message.message
              // }
              if(message.message?.message?.cardtypeid==1 && message.message?.message?.feedbacktypeid==2){
                var imgs = message.message.message.message.split("_$_");
                message.message.message.message = imgs;
              }
              this.chatlog.push(message.message);
              if(message.userid != this.selected_user){
                this.unreadCount[message.userid]++; //unread comment increment 
              }
            }
            // if(message.isSpectate && message.disconnect){
              //   this.usersList = this.usersList.filter(ae=>ae.userid!=message.userid);
              //   this.usersListDetails=this.usersListDetails.filter(ae=>ae.userid!=message.userid); //new
              // }
            }
          },
          (error)=>{
            console.log(error);
          })
}

spectateUser(uid){
  this.selected_user = uid;
  this.getLeadData( this.selected_user);
  this.getTags(this.selected_user);
  this.spectatingUser.push(uid);
  this.chatEnabled = false;
  this.showSideboxes = true;
  this.dataService.sendMessageSpec({
    selected: true,
    userid: uid
  });
  for(let i in this.usersList){
    if(this.usersList[i].userid==this.selected_user){
      this.usersList[i].inView = true;
    } else {
      this.usersList[i].inView = false;
    }
  }
}

chatEnable(){
  this.chatEnabled = true;
  this.chatlogList[this.selected_user] = this.chatlog;
  this.startChat = true;
  if(this.seluserList.length<6){
    this.dataService.sendMessageSpec({
      isChat: true,
      agentName: this.customerName,
      agent_img: this.agent_img,
      userid: this.selected_user
    });
    this.seluserList.push({
      userid : this.selected_user,
      disconnected : false
    });
    for(let i in this.seluserList){
      if(this.seluserList[i].userid==this.selected_user){ this.seluserList[i].selected = true; } 
      else { this.seluserList[i].selected = false; }
    }
    this.usersList = this.usersList.filter(ae=>ae.userid!=this.selected_user);
  }
}

sendMessage() {
  let date = new Date();
  this.dataService.sendMessageSpec({
    'isLive': true,
    'userid': this.selected_user,
    'agentName': this.customerName,
    'agent_img': this.agent_img,
    'message': this.newMessage,
    'node_id': this.getNodeId('node_id',this.selected_user)
  });
  let data = {
    'time': date,
    // 'time': {$date: date},
    'sender': "live_agent",
    'isLive': true,
    'message': this.newMessage
  }
  this.chatlogList[this.selected_user].push(data);
  // this.chatlog.push(data);
  this.newMessage = '';
  this.scrollToBottom();
}

getNodeId(param,uid){
  var user = this.usersListDetails.filter(ae=>ae.userid==uid)[0];
  if(param=='node_id'){
    let nodeid = user.node_id;
    return nodeid
  }
}

scrollToBottom(): void {
  if(this.chatlog.length>0){
    setTimeout (() => {
      this.chatBox.nativeElement.scrollTop = this.chatBox.nativeElement.scrollHeight;
    }, 50);
  }
}

selectSelUser(uid){
  this.selected_user = uid;
  this.unreadCount[uid] = 0; //unread count reset
  this.chatEnabled = true;
  this.getLeadData(uid);
  this.getTags(uid);
  this.chatlog =this.chatlogList[uid]
  for(let i in this.seluserList){
    if(this.seluserList[i].userid==uid){ this.seluserList[i].selected = true; } 
    else { this.seluserList[i].selected = false; }
  }
  this.leadForm = this._fb.group({
    'name': [this.spectDetails[uid].name, [Validators.required]],
    'email': [this.spectDetails[uid].email, [Validators.required]],
    'phone': [this.spectDetails[uid].phone, [Validators.required]],
    'company': [this.spectDetails[uid].company, [Validators.required]],
    'location': [this.spectDetails[uid].location, [Validators.required]],
  });
  this.spectDetail = this.spectDetails[uid];
  this.startChat = true;
  this.scrollToBottom();
}

onLeadSubmit(value){
  this.dataService.sendMessageSpec({
    userid: this.selected_user,
    lead: value
  });
  showNotification('success','<span>Details updated successfully</span>');
}

closeChat(resolve){
  let status = false;
  if(resolve == 'resolved'){ status=true; }
  this.dataService.sendMessageSpec({
    disconnect: true,
    isResolved: status,
    userid: this.selected_user
  });
  this.startChat = false;
  this.showChatButton = false;
  this.chatlog = [];
  this.seluserList = this.seluserList.filter(ae=>ae.userid!=this.selected_user);
  this.unreadCount[this.selected_user] = 0;
  this.chatlogList[this.selected_user] = [];
  this.spectDetails[this.selected_user] = {};
  this.leadForm = this._fb.group({
    'name': ['', [Validators.required]],
    'email': ['', [Validators.required]],
    'phone': ['', [Validators.required]],
    'company': ['', [Validators.required]],
    'location': ['', [Validators.required]],
  });
  this.spectDetails[this.selected_user] = {};
  this.spectDetail = {};
  this.selected_user = 0;
  this.selected_nodeid = 0;
  this.closeChatModal.hide();
}

seperateString(string){
  var splitted = string.split("_$_"); 
  return splitted
}

onMentionSelect(selection) {
  // return selection.label;
  return `${selection.label}`;
}

getAllReplies(){
  this.dataService.getAllQuickReplies({
    BotID:this.botID,
    AgentID:this.customerID
  }).subscribe(
  res=>{
    if(res.status_code==200){
      this.replies = res.data;

      this.mentionConfig = new Object();
      this.mentionConfig = { mentions: [] };

      for(let i in this.replies){
        this.mentionConfig['mentions'].push({
          items: this.replies[i].quick_value,
          triggerChar: this.replies[i].quick_key,
          maxItems:10,
          returnTrigger:false,
          dropUp:true,
          mentionSelect: this.onMentionSelect
        });
      }
    } else if(res.status_code==401){
      this.dataService.logout();
    } else {
      showNotification('warning',res.status);
    }
  }, error=>{
    console.log(error);
    showNotification('danger',error);
  })
}

getCountryCode(uid){
  let user = this.usersListDetails.filter(ae=>ae.userid==uid)[0];
  let place = this.country.filter(ae=>ae.name==user.country);
  if(place.length==1){
    return place[0].code;
  } else{ return "" }
}

getUserSpec(param,uid){
  var user = this.usersListDetails.filter(ae=>ae.userid==uid)[0];
  if(param=='node_id'){
    let nodeid = user.node_id;
    return nodeid
  } else if (param=='name'){
    if(user.cf_name!=''){
      return user.cf_name
    } else {
      return user.userid
    } //new
  } else if (param=='email'){
    return user.cf_email
  }
}


sendPing(){
  if(this.pingCounter==3){
    this.connectChat();
    this.pingCounter = 0;
  } else {
    this.dataService.sendMessageSpec({
      ping: true,
    });
    this.pingCounter++;
  }
}

playAudio(){
  let audio = new Audio();
  audio.src = "../../assets/audio/notification.mp3";
  audio.load();
  audio.play();
}

terminateSocket(){
  this.dataService.sendMessageSpec({
    terminate: true,
    botID: this.botID,
  });
}

getLeadData(userid){
  this.dataService.getTempStoreValues({bot_id:this.botID,user_id:userid}).subscribe(
    data=>{
      this.leadDataList = data.data;
      if(this.leadDataList.length>0){this.showmoreDetails = true;}
    },
    error=>{
      console.log(error);
    })
}

clearMoreLeadForm(){
  // console.log("clearMoreLeadForm");
  this.leadmoreForm  =  this._fb.group({
    storage_variable: ['null', Validators.required],
    value: ['', Validators.required]
  })
}

clearMemForm(){
  // console.log("clearMemForm");
  this.addMemForm  =  this._fb.group({
    display_name: ['', Validators.required],
    variable_type: ['null', Validators.required]
  })
}

leadMoreSubmit(form){
  form.value.user_id = this.selected_user;
  form.value.bot_id = this.botID;
  this.leadloader = true;
  if(form.valid){
    if (!this.isLeadEdit) {
      this.dataService.addTempStoreValues(form.value).subscribe(
        data=>{
          this.leadloader = false;
          this.isaddLead = false;
          this.getLeadData(this.selected_user);
          this.clearMoreLeadForm();
          showNotification('success',"Lead Added!");
        },
        error=>{
          console.log(error);
          showNotification('warning',"Error Adding Lead Data");
          this.leadloader = false;
        })
    } else {
      this.dataService.editTempStoreValues(form.value).subscribe(
        data=>{
          this.leadloader = false;
          this.isaddLead = false;
          this.isLeadEdit = false;
          this.getLeadData(this.selected_user);
          this.clearMoreLeadForm();
          showNotification('success',"Lead Edited!");
        },
        error=>{
          console.log(error);
          showNotification('warning',"Error Adding Lead Data");
          this.leadloader = false;
        })
    }
  }
}

addMemSubmit(form){
  form.value.bot_id = this.botID;
  form.value.expiry = 2;
  this.leadloader = true;
  if(form.valid){
    this.dataService.addStarredMemVars(form.value).subscribe(
      data=>{
        this.leadloader = false;
        this.isaddMemory = false;
        this.getStarMemVars();
        this.clearMemForm();
        showNotification('success',"Storage Variable Added!");
      },
      error=>{
        console.log(error);
        showNotification('warning',"Error Adding Storage Variable");
        this.leadloader = false;
      })
  }
}

getStarMemVars(){
  this.dataService.getStarRatedStorageVars(this.botID).subscribe(
    data=>{
      if(data.status_code){
        this.starredMemVarsList = data.data;
      } else {
        showNotification('warning',"Error Getting Storage Variables!");
      }
    },
    error=>{
      console.log(error);
      showNotification('warning',"Error Getting Storage Variables");
    })
}

editData(data){
  this.isaddLead = true;
  this.isLeadEdit = true;
  this.leadmoreForm  =  this._fb.group({
    storage_variable: [data.storage_variable, Validators.required],
    value: [data.value, Validators.required]
  })
}

getAllTags(){
  let data = {
    bot_id: this.botID,
    AgentID:this.customerID,
    AgentType:this.customerType
  }
  this.dataService.getTagsList(data).subscribe(
    res=>{
      if(res.status_code==200){
        this.tagsList = [];
        for(let i in res.data){
          if(res.data[i].is_active){
            this.tagsList.push({
              id : res.data[i].tag_id,
              itemName : res.data[i].tag_name,
              color: res.data[i].color
            })
          }
        }
      }
    },
    error=>{
      console.log(error);
      showNotification('warning',"Oops! Error while loading Tag List!");
    })
}

getTags(userid){
  this.dataService.getUserTags({
    bot_id: this.botID,
    user_id: userid
  }).subscribe(
  data=>{
    this.userTags = data.cf_tags;
    this.selectedTags = [];
    this.showTagDetails = true;
    for(let i in data.cf_tags){
      for(let j in this.tagsList){
        if(data.cf_tags[i]==this.tagsList[j].id){
          this.selectedTags.push({
            id : this.tagsList[j].id,
            itemName : this.tagsList[j].itemName
          });
        }
      }
    }
  },
  error=>{
    console.log(error);
    showNotification('warning',"Error Adding Storage Variable!");
    this.leadloader = false;
  })

}

saveUserTags(){
  this.isaddTags = true;
  let cf_tags = [];
  for(let i in this.selectedTags){
    cf_tags.push(this.selectedTags[i].id);
  }
  this.dataService.addUserTags({
    bot_id: this.botID,
    user_id: this.selected_user,
    agent_id: this.customerID,
    cf_tags: cf_tags
  }).subscribe(
  res=>{
    if(res.status_code==200){
      this.chatlog.push({
        cardtypeid: 27,
        feedbacktypeid: 1,
        message: {
          include: cf_tags,
          exclude: []
        },
        sender: "live_agent",
        time: new Date()
      });
      this.scrollToBottom();
      showNotification('success',"Tags Changed Successfully!");
    } else {
      showNotification('warning',"Oops! Error while saving tags!");
    }
    this.isaddTags = false;
  },
  error=>{
    this.isaddTags = false;
    console.log(error);
    showNotification('warning',"Oops! Error while saving tags!");
  })
}

getLAImage(adent_id,attr){
  let la_detail = [];
  la_detail = this.agentProfiles.filter(ae=>ae.agent_id==adent_id);
  if(la_detail.length>0){
    if(attr == 'img'){
      return this.agentProfiles[0].image_url;
    } else {
      return this.agentProfiles[0].agent_name;
    }
  } else {
    if(attr == 'img'){
      return '/assets/img/default-avatar.png';
    } else {
      return 'Unknown Agent';
    }
  }
}

getTagDetails(id,attr){
  let tags = [];
  tags = this.tagsList.filter(ae=>ae.id==id);
  if(tags.length>0){
    if(attr == 'name'){
      return tags[0].itemName;
    } else {
      return tags[0].color;
    }
  } else {
    if(attr == 'name'){
      return 'No tag found';
    } else {
      return '#ccc';
    }
  }
}

ngOnDestroy() {
  this.terminateSocket();
}

}
