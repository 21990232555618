import { ServicesService } from 'app/services/services.service';
import { Component, OnInit, TemplateRef, ViewChild, Input } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
// import { ApiFormatComponent } from './api-format/api-format.component';
// import { HomeService } from 'app/shared/general/services/home.service';
import { HttpEvent } from '@angular/common/http';
import { environment } from 'environments/environment';
const regexCurly = new RegExp(/\{\{(.(100)|[1-9]\d?)\}\}/g);

@Component({
  selector: 'app-send-templates',
  templateUrl: './send-templates.component.html',
  styleUrls: ['./send-templates.component.scss']
})
export class SendTemplatesComponent implements OnInit {

  title: string;
  closeBtnName: string;
  @Input() botID: string;
  templatesList: any[] = [];
  memoryVariables = [];
  customerID: number = parseInt(localStorage.getItem('Customer_ID'), 10);
  customerType: number = parseInt(localStorage.getItem('Customer_Type'), 10);

  isChanging: boolean = false;
  uploading: boolean = false;
  uploaded_kb = 0;
  total_kb = 0;

  templateList = [];
  channelList = [];
  customerMail: string = localStorage.getItem('Customer_Email');
  templateId: number;
  categoryList = [];
  templateType = [];
  templateForm: FormGroup;
  isEdit = false;
  isPreview = false;
  showLoader = false;
  isSavingTemplate = false;
  isGettingTemplates = false;
  isSynchingTemplates = false;
  variableArray = {
    header_variables: [],
    body_variables: [],
    button_variables: []
  };
  displayText = {
    header: '',
    body: '',
    button_link: ''
  };
  flowList = [];
  prodFlowList = [];
  isDeleting = false;
  selectedTemplate = null;
  template_create_permission = false;
  @ViewChild('template') template: TemplateRef<any>;
  isUploading = false;
  uploadedSize: number = 0;
  uploadMaxSize: number = 0;
  static_url = environment.staticUrl;
  searchQuery = '';

  pageSize = 10;
  pageNumber = 1;
  page_details: any = {
    count: 0,
    next: '',
    previous: ''
  };

  constructor(public modalRef: BsModalRef, private _fb: FormBuilder, private api: ServicesService, private modalService: BsModalService) { }

  ngOnInit(): void {
    this.getMemVars();
    this.getTemplateList(this.botID);
    this.clearTemplateForm();
  }

  ngAfterViewInit() {
  }


  clearTemplateForm() {
    this.templateForm = this._fb.group({
      'bot_id': [this.botID, [Validators.required]],
      'customer_email': [this.customerMail, [Validators.required]],
      'name': ['', [Validators.required]],
      'channel': [5, [Validators.required]],
      'category': ['', [Validators.required]],
      'language': ['', [Validators.required]],
      'template_type': [this.templateType[0], [Validators.required]],
      'body': ['', [Validators.required]],
      'header': ['', [Validators.required]],
      'status': ['Submitted', [Validators.required]],
      'header_type': ['Text', [Validators.required]],
      'footer': ['', [Validators.required]],
      'is_body': [true, [Validators.required]],
      'is_header': [true, [Validators.required]],
      'is_footer': [true, [Validators.required]],
      'is_buttons': [true, [Validators.required]],
      'button_type': ['Call to action', [Validators.required]],
      'header_url': [''],
      'is_header_url_changed': [false],
      'buttons': new FormArray([]),
      'header_variables': new FormArray([]),
      'body_variables': new FormArray([]),
      'button_variables': new FormArray([]),
    });
    this.displayText = {
      header: '',
      body: '',
      button_link: ''
    };
    this.variableArray = {
      header_variables: [],
      body_variables: [],
      button_variables: []
    };

    const bodyVarArray = <FormArray>this.templateForm.get('body_variables');
    const headerVarArray = <FormArray>this.templateForm.get('header_variables');
    const buttonVarArray = <FormArray>this.templateForm.get('button_variables');

    bodyVarArray.clear();
    headerVarArray.clear();
    buttonVarArray.clear();

  }

  getTemplateList(botid) {
    this.api.getBCTemplates(botid).subscribe(data => { this.templatesList = data.data; },
      error => { console.log(error); });
  }

  closeModal(data?) {
    if (data) this.modalService.setDismissReason(Object.assign(data, { replacedText: this.displayText }));
    this.modalRef.hide();
  }

  formatText(text) {
    return text ? text.replace(/\n/g, '<br>') : '';
  }

  updateTemplate(id) {
    this.api.getBroadcastTemplateDetail({
      bot_id: this.botID,
      template_id: id
    }).subscribe(
      res => {
        this.changeTemplate(res?.data);
      })
  }

  changeTemplate(template) {
    this.clearTemplateForm();

    this.templateForm = this._fb.group({
      'bot_id': [this.botID, [Validators.required]],
      'customer_email': [this.customerMail, [Validators.required]],
      'name': [template.name, [Validators.required]],
      'channel': [template.channel, [Validators.required]],
      'category': [template.category, [Validators.required]],
      'language': [template.language, [Validators.required]],
      'template_type': [template.template_type, [Validators.required]],
      'body': [template.body, [Validators.required]],
      'status': [template.status, [Validators.required]],
      'header': [template.header, [Validators.required]],
      'header_type': [template.header_type, [Validators.required]],
      'footer': [template.footer, [Validators.required]],
      'is_body': [template.is_body, [Validators.required]],
      'is_header': [template.is_header, [Validators.required]],
      'is_footer': [template.is_footer, [Validators.required]],
      'is_buttons': [template.is_buttons, [Validators.required]],
      'button_type': [template.button_type, [Validators.required]],
      'header_url': [template.header_url],
      'is_header_url_changed': [false],
      'buttons': new FormArray([]),
      'header_variables': new FormArray([]),
      'body_variables': new FormArray([]),
      'button_variables': new FormArray([]),
    });
    this.variableArray = {
      header_variables: [],
      body_variables: [],
      button_variables: []
    };
    let link = '';
    if (this.templateForm.get('buttons')['controls'].length > 0) {
      link = this.templateForm.get('buttons')['controls'][0].value.value;
    }
    this.displayText = {
      header: this.templateForm.value.header_type == 'Text' ? this.templateForm.value.header : '',
      body: this.templateForm.value.body,
      button_link: link
    }
    for (let i in template['header_variables']) {
      this.headVarArray.push(this._fb.group({
        'key': [template['header_variables'][i].key],
        'value': [template['header_variables'][i].value],
        'mem_variable': [template['header_variables'][i].mem_variable],
      }));
      this.variableArray['header_variables'].push({
        key: template['header_variables'][i].key,
        value: template['header_variables'][i].value,
        mem_variable: template['header_variables'][i].mem_variable,
      })
      this.changeDisplayText(template.header, 'header', 'header_variables');
      this.checkDupVariable('header_variables');
    }
    for (let i in template['body_variables']) {
      this.bodyVarArray.push(this._fb.group({
        'key': [template['body_variables'][i].key],
        'value': [template['body_variables'][i].value],
        'mem_variable': [template['body_variables'][i].mem_variable],
      }));
      this.variableArray['body_variables'].push({
        key: template['body_variables'][i].key,
        value: template['body_variables'][i].value,
        mem_variable: template['body_variables'][i].mem_variable,
      })
      this.changeDisplayText(template.body, 'body', 'body_variables');
      this.checkDupVariable('body_variables');
    }
    for (let i in template['button_variables']) {
      this.btnVarArray.push(this._fb.group({
        'key': [template['button_variables'][i].key],
        'value': [template['button_variables'][i].value],
        'mem_variable': [template['button_variables'][i].mem_variable],
      }));
      this.variableArray['button_variables'].push({
        key: template['button_variables'][i].key,
        value: template['button_variables'][i].value,
        mem_variable: template['button_variables'][i].mem_variable,
      })
      this.changeDisplayText(template.buttons[0].value, 'button_link', 'button_variables');
      this.checkDupVariable('button_variables');
    }
    for (let i in template['buttons']) {
      this.btnArray.push(this._fb.group({
        'text': [template['buttons'][i].text],
        'type': [template['buttons'][i].type],
        'value': [template['buttons'][i].value],
        'flow': [template['buttons'][i].flow],
        'is_prod': [template['buttons'][i].is_prod],
      }));
    }

  }

  checkDupVariable(type) {
    const selectedVariables = this.templateForm.get(type)['controls'].reduce((acc, control) => {
      const variable = control.get('mem_variable').value;
      acc[variable] = true;
      return acc;
    }, {});

    this.memoryVariables = this.memoryVariables.map(ae => {
      ae.selected = !!selectedVariables[ae.internal_name];
      return ae;
    });
  }

  changeDisplayText(value, type, var_type) {
    if (type == 'button_link') {
      let showText = value;
      for (let i in this.variableArray[var_type]) {
        let replaceText = '{{' + this.variableArray[var_type][i].key + '}}';
        if (this.variableArray[var_type][i].value != '') {
          showText = showText.replaceAll(replaceText, this.variableArray[var_type][i].value);
        }
      }
      this.displayText[type] = showText;
    } else {
      let showText = this.templateForm.value[type];
      for (let i in this.variableArray[var_type]) {
        let replaceText = '{{' + this.variableArray[var_type][i].key + '}}';
        if (this.variableArray[var_type][i].value != '') {
          showText = showText.replaceAll(replaceText, this.variableArray[var_type][i].value);
        }
      }
      this.displayText[type] = showText;
    }
  }


  get btnArray() {
    return <FormArray>this.templateForm.get('buttons');
  }

  get headVarArray() {
    return <FormArray>this.templateForm.get('header_variables');
  }

  get bodyVarArray() {
    return <FormArray>this.templateForm.get('body_variables');
  }

  get btnVarArray() {
    return <FormArray>this.templateForm.get('button_variables');
  }

  getMemVars() {
    this.api.getMemVarList({
      bot_id: this.botID,
      AgentID: this.customerID,
      AgentType: this.customerType
    }).subscribe(
      res => {
        this.memoryVariables = res.data;
      })
  }

  getFile(event) {
    this.isUploading = true;
    if (event.target.files && event.target.files[0]) {
      const epochNow = (new Date).getTime();
      let ext = event.target.files[0].name.split('?')[0].split('.').pop();
      let file_name = event.target.files[0].name.substring(0, event.target.files[0].name.lastIndexOf('.'));
      this.api.getPresignedURL(file_name + '_$_' + epochNow + '.' + ext, this.botID, 'template').subscribe(
        data => {
          let url = data.text();
          this.api.uploadfileAWSS3(url, event.target.files[0].type, event.target.files[0]).subscribe((event: HttpEvent<any>) => {
            let progress;
            progress = event;
            this.uploadMaxSize = event['loaded'];
            this.uploadedSize = event['total'];
            if (progress.statusText == "OK") {
              (<FormControl>this.templateForm.controls['header_url']).setValue(this.static_url + this.botID + '/support/data/template/' + file_name + '_$_' + epochNow + '.' + ext);
              (<FormControl>this.templateForm.controls['is_header_url_changed']).setValue(true);
              this.isUploading = false;
            }
          });
          this.uploadMaxSize = 0;
          this.uploadedSize = 0;
        });
    }
  }

  uploadImg() {
    let element: HTMLElement = document.getElementById('upload_img') as HTMLElement;
    element.click();
  }

}
