import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { HttpRequest, HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Http, Response, RequestOptions, Headers } from '@angular/http';
import { environment } from '../../environments/environment';
import 'rxjs/add/operator/map';
import { Observable } from "rxjs";
import { throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';


@Injectable({
	providedIn: 'root'
})
export class ServicesService {

	private get_url = environment.apiUrl;
	private socket_url = environment.socketUrl;
	private socket;
	private sp_socket;


	constructor(private _http: Http, private router: Router, private httpClient: HttpClient) { }


	handleError(error: HttpErrorResponse) {
		let errorMessage = 'Unknown error!';
		if (error.error instanceof ErrorEvent) {
			errorMessage = `Error: ${error.error.message}`;
		} else {
			errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
		}
		console.log(errorMessage);
		return throwError(errorMessage);
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		var show_admin_management_page = JSON.parse(localStorage.getItem('show_admin_management_page')) === true;
		var show_contacts_page = JSON.parse(localStorage.getItem('show_contacts_page')) === true;
		var show_tickets_page = JSON.parse(localStorage.getItem('show_tickets_page')) === true;
		var show_reports_page = JSON.parse(localStorage.getItem('show_reports_page')) === true;
		var show_spectate_page = JSON.parse(localStorage.getItem('show_spectate_page')) === true;
		if (route.routeConfig.path == 'manage') {
			if (show_admin_management_page) {
				return true;
			} else { this.router.navigate(['']); }
		} else if (route.routeConfig.path == 'spectate') {
			if (show_spectate_page) {
				return true;
			} else { this.router.navigate(['']); }
		} else if (route.routeConfig.path == 'people') {
			if (show_contacts_page) {
				return true;
			} else { this.router.navigate(['']); }
		} else if (route.routeConfig.path == 'tickets') {
			if (show_tickets_page) {
				return true;
			} else { this.router.navigate(['']); }
		}
	}

	private createRequestOptions(): RequestOptions {
		const headers = new Headers({
			'Content-Type': 'application/json',
		});
		const token = localStorage.getItem('ACCESS_TOKEN');
		if (token) {
			headers.append('Authorization', `Bearer ${token}`);
		}
		const options = new RequestOptions({ headers: headers });
		return options;
	}

	getToken() {
		return localStorage.getItem('ACCESS_TOKEN');
	}

	public logout() {
		localStorage.removeItem('ACCESS_TOKEN');
		this.router.navigate(['login']);
	}

	// ---------------------Chat LA-------------------------
	connectSocketLA(bot, user) {
	}

	sendMessageLA(message) {
		this.socket ? this.socket.send(JSON.stringify(message)) : null;
	}

	onMessageLA(botid, user, deptid, customerid, channelid): Observable<Object> {
		this.socket = new WebSocket(
			this.socket_url + "/live/" + botid + "/" + deptid + "/" + user + "/" + customerid + "/" + channelid + "/"
			// "ws://36d110e90f9d.ngrok.io/ws/live/"+botid+"/"+user+"/"
		);
		return Observable.create(observer => {
			this.socket.onmessage = event => {
				observer.next(JSON.parse(event.data));
			};
		});
	}

	closeConnection() {
		console.log("Called disconnect function");
		// this.socket.close();
	}

	// ------------------Spectate----------------------
	onSpectateLA(botid, user, customerid): Observable<Object> {
		this.sp_socket = new WebSocket(
			this.socket_url + "/spectate/" + botid + "/" + user + "/" + customerid + "/"
			// "ws://36d110e90f9d.ngrok.io/ws/live/"+bot+"/"+user+"/"
		);
		return Observable.create(observer => {
			this.sp_socket.onmessage = event => {
				observer.next(JSON.parse(event.data));
			};
		});
	}

	sendMessageSpec(message) {
		this.sp_socket.send(JSON.stringify(message));
	}


	// -------------------------Dashboard----------------------------

	getBotList(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/get_bots/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	// -------------------------management agents----------------------------

	getAgentsList(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/get_agents/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	getAllAgentsList(botid): Observable<any> {
		const options = this.createRequestOptions();
		return this._http.get(this.get_url + `/get_handover_agents/${botid}`, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	getBotDepartmentsList(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/get_bot_departments/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	getAgentDetails(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/get_agent_details/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	saveAgentPermissions(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/save_agent_permissions/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	addAgent(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/add_agent/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError));
	}

	changeDepartmentAgent(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/change_department_agent/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError));
	}

	changeDepartmentAdmin(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/change_department_admin/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError));
	}

	resetPassword(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/reset_password/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError));
	}

	changeAgentStatus(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/change_agent_status/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError));
	}

	checkEmail(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/check_email/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError));
	}

	// -------------------------management departments----------------------------

	getDepartmentsList(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/get_departments/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	getHandoverDepartmentList(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/get_handover_departments/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	getDepartmentDetails(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/get_department_details/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	createDepartment(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/create_department/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError));
	}

	editDepartment(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/edit_department/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError));
	}

	deleteDepartment(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/delete_department/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError));
	}

	deleteDepartmentAgent(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/delete_department_admin/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError));
	}

	// -------------------------tickets----------------------------

	getTotalTicketDetails(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/get_total_ticket_details/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	getTicketCategoryList(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/get_ticket_category_list/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	getTicketDetails(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/get_ticket_details/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	getChannels(): Observable<any> {
		let data = {};
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/get_channels/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	changeTicketStatus(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/change_ticket_status/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	// -------------------------profile----------------------------

	getProfileDetails(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/get_profile_details/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	setProfileDetails(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/set_profile_details/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	getPresignedURL(file_name, botid, type): Observable<any> {
		const options = this.createRequestOptions();
		let env = environment.env == 'prod' ? 'Production' : (environment.env == 'stage' ? 'Staging' : 'Development');
		let token = localStorage.getItem('ACCESS_TOKEN');
		let data = { data: `${env}/${botid}/support/data/${type}/${file_name}` };
		data['token'] = token;
		let body = JSON.stringify(data);
		return this._http.post(this.get_url + '/s3_upload/', body, options).map((res: Response) => res);

	}

	uploadfileAWSS3(fileuploadurl, contenttype, file): Observable<any> {
		const headers = new HttpHeaders({ 'Content-Type': contenttype, 'x-amz-acl': 'public-read' });
		const req = new HttpRequest('PUT', fileuploadurl, file,
			{
				headers: headers,
				reportProgress: true, //This is required for track upload process
			});
		return this.httpClient.request(req);
	}

	// -------------------------People----------------------------

	getFilterData(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/get_filter_data/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	getPeopleDetails(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/get_customer_details/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	// -------------------------Dashboard----------------------------

	getResponseTimes(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/get_response_times/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	getChatDurations(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/get_chat_durations/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	getWaitTimeServed(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/get_wait_time_served/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	getWaitTimeMissed(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/get_wait_time_missed/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	getChatCounts(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/get_chat_counts/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	getTimeOnline(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/get_time_online/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	activityBreakdown(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/activity_breakdown/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	// -------------------------Quick Replies----------------------------

	createQuickReply(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/create_quick_reply/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError));
	}

	editQuickReply(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/edit_quick_reply/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	getQuickReply(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/get_quick_reply/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	getAllQuickReplies(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/get_all_quick_replies/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	deleteQuickReply(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/delete_quick_reply/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	// -------------------------Performance----------------------------

	getAgentsDashboard(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/get_agents_dashboard/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	getAgentsAttendedTickets(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/get_agents_attended_tickets/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	getAgentsClosedTickets(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/get_agents_closed_tickets/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	splitAgentsClosedTickets(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/split_agents_closed_tickets/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	getAgentsChatDuration(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/get_agents_chat_duration/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	getAgentsResponseTimes(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/get_agents_response_times/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	getAgentsWaitTimeServed(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/get_agents_wait_time_served/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	getAgentsWaitTimeMissed(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/get_agents_wait_time_missed/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	splitAgentsChannels(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/split_agents_channels/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	getAgentsWeekdayResponseTimes(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/get_agents_weekday_response_times/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	getAgentsWeekdayTicketCounts(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/get_agents_weekday_ticket_counts/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	getAgentsCsat(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/get_agents_csat/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	splitDepartments(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/split_departments/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	// -------------------------Department Dashboard----------------------------

	getDepartmentsDashboard(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/get_departments_dashboard/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	getDepartmentsAttendedTickets(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/get_departments_attended_tickets/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	getDepartmentsClosedTickets(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/get_departments_closed_tickets/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	splitDepartmentsClosedTickets(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/split_departments_closed_tickets/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	getDepartmentsChatDuration(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/get_departments_chat_duration/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	getDepartmentsResponseTimes(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/get_departments_response_times/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	getDepartmentsWaitTimeServed(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/get_departments_wait_time_served/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	getDepartmentsWaitTimeMissed(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/get_departments_wait_time_missed/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	splitDepartmentsChannels(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/split_departments_channels/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	getDepartmentsWeekdayResponseTimes(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/get_departments_weekday_response_times/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	getDepartmentsWeekdayTicketCounts(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/get_departments_weekday_ticket_counts/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	getDepartmentsCsat(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/get_departments_csat/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	// -------------------------CX Dashboard----------------------------

	getBotsClosedTickets(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/get_bots_closed_tickets/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	getBotsAttendedTickets(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/get_bots_attended_tickets/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	getDepartmentsTotalTickets(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/get_departments_total_tickets/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	getAgentsTotalTickets(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/get_agents_total_tickets/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	getBotsTotalTickets(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/get_bots_total_tickets/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	splitBotsClosedTickets(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/split_bots_closed_tickets/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	getBotsChatDuration(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/get_bots_chat_duration/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	getBotsResponseTimes(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/get_bots_response_times/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	getBotsWaitTimeServed(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/get_bots_wait_time_served/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	getBotsWaitTimeMissed(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/get_bots_wait_time_missed/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	splitBotsChannels(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/split_bots_channels/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	getBotsWeekdayResponseTimes(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/get_bots_weekday_response_times/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	getBotsWeekdayTicketCounts(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/get_bots_weekday_ticket_counts/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	getBotsCsat(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/get_bots_csat/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	splitBotsDepartments(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/split_bots_departments/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	// -------------------------Tags----------------------------

	createTag(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/create_tag/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError));
	}

	getTags(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/get_tags/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	editTag(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/edit_tag/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError));
	}

	deleteTag(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/delete_tag/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError));
	}

	addTag(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/create_user_tags/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError));
	}

	// -----------------------------MemVars-------------------------------------------

	getMemVarList(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/get_storage_variables/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	createMemVar(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data);
		return this._http.post(this.get_url + '/add_storage_variable/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	editMemVar(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data);
		return this._http.post(this.get_url + '/edit_storage_variable/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	// --------------------------------chat log lead------------------------------------

	getTempStoreValues(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data);
		return this._http.post(this.get_url + '/get_temp_store_values/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	addTempStoreValues(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data);
		return this._http.post(this.get_url + '/add_temp_store_values/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError));
	}

	editTempStoreValues(data): Observable<any> {
		data['token'] = this.getToken();
		const options = this.createRequestOptions();
		let body = JSON.stringify(data);
		return this._http.post(this.get_url + '/edit_temp_store_values/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError));
	}

	addStarredMemVars(data): Observable<any> {
		const options = this.createRequestOptions();
		data['token'] = this.getToken();
		let body = JSON.stringify(data);
		return this._http.post(this.get_url + '/add_star_rated_storage_variable/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	getStarRatedStorageVars(botid): Observable<any> {
		let data = { 'bot_id': botid };
		const options = this.createRequestOptions();
		data['token'] = this.getToken();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/get_star_rated_storage_variables/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	} //today

	getTagsList(data): Observable<any> {
		const options = this.createRequestOptions();
		data['token'] = this.getToken();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/get_tags/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	getUserTags(data): Observable<any> {
		const options = this.createRequestOptions();
		data['token'] = this.getToken();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/get_user_tags/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	addUserTags(data): Observable<any> {
		const options = this.createRequestOptions();
		data['token'] = this.getToken();
		let body = JSON.stringify(data)
		return this._http.post(this.get_url + '/add_user_tags/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	// --------------------------------chat log lead------------------------------------
	getAssignRules(botid): Observable<any> {
		const options = this.createRequestOptions();
		return this._http.get(`${this.get_url}/assign_rule/?bot_id=${botid}`, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	addAssignRules(data): Observable<any> {
		const options = this.createRequestOptions();
		return this._http.post(`${this.get_url}/assign_rule/`, data, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError));
	}

	editAssignRules(id, data): Observable<any> {
		const options = this.createRequestOptions();
		return this._http.patch(`${this.get_url}/assign_rule/${id}/`, data, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError));
	}

	deleteAssignRules(id): Observable<any> {
		const options = this.createRequestOptions();
		return this._http.delete(`${this.get_url}/assign_rule/${id}/`, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError));
	}

	getChannelList(): Observable<any> {
		const options = this.createRequestOptions();
		return this._http.get(`${this.get_url}/get_channel_data/`, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	getBCTemplates(botid): Observable<any> {
		return this._http.get(this.get_url + `/templates/${botid}/broadcast_templates_datas/?fetch_all=true`)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	sendSupportTemplate(data): Observable<any> {
		const options = this.createRequestOptions();
		return this._http.post(`${this.get_url}/send_support_template/`, data, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError));
	}

	getBroadcastTemplateDetail(data): Observable<any> {
		let body = JSON.stringify(data);
		const options = this.createRequestOptions();
		return this._http.post(this.get_url + '/get_broacast_template/', body, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	// --------------------------------Business Hours------------------------------------
	getBusinessHours(dept_id): Observable<any> {
		const options = this.createRequestOptions();
		return this._http.get(`${this.get_url}/business_hours/?department_id=${dept_id}`, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError), retry(2));
	}

	addBusinessHours(data): Observable<any> {
		const options = this.createRequestOptions();
		return this._http.post(`${this.get_url}/business_hours/`, data, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError));
	}

	editBusinessHours(id, data): Observable<any> {
		const options = this.createRequestOptions();
		return this._http.put(`${this.get_url}/business_hours/${id}/`, data, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError));
	}

	deleteBusinessHours(id): Observable<any> {
		const options = this.createRequestOptions();
		return this._http.delete(`${this.get_url}/business_hours/${id}/`, options)
			.pipe(map((res: Response) => res.json()), catchError(this.handleError));
	}
}
