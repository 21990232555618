
<div class="sidebar-wrapper">
    <div class="logo">
        <a href="javascript:void(0)" class="simple-text">
            <div class="logo-img">
                <img src="https://canvas.creyoface.com/assets/img/creyoface.gif"/><br>
            </div>
        </a>
        <span>Support</span>
        <!-- <br><sub>Beta</sub> -->
    </div>
    <ul class="nav responsive-nav">
        <li *ngIf="isMobileMenu()">
            <a>
                <p (click)="logOut()">Log out</p>
            </a>
        </li>
        <li class="separator hidden-lg hidden-md" *ngIf="isMobileMenu()"></li>
        <li routerLinkActive="active" *ngFor="let menuItem of menuItems" class="{{menuItem.class}}">
            <a  [routerLink]="attrPath(menuItem.path)">
                <i class="{{menuItem.icon}}"></i>
                <p>{{menuItem.title}}</p>
            </a>
        </li>
    </ul>
</div>
