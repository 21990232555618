<div class="container container-login">
	<div class="login-bg">
		<div class="row">
			<div class="col-md-7">
				<div class="login" *ngIf="isLoginForm">
					<div class="form-outer">
						<form class="card" [formGroup]="loginForm" (ngSubmit)="loginFn()">
							<h3>Sign in</h3>
							<div class="group">
								<input type="text" placeholder="Username" formControlName="email"><span class="highlight"></span><span class="bar"></span>
							</div>
							<div class="group pass-show">
								<input [type]="showPassword ? 'text' : 'password'" placeholder="Password" formControlName="password"><span class="highlight"></span><span class="bar"></span>
								<i class="fa fa-eye eyes"(click)="showPassword=!showPassword" *ngIf="!showPassword"></i>
								<i class="fa fa-eye-slash eyes"(click)="showPassword=!showPassword" *ngIf="showPassword"></i>
							</div>
							<a href="javascript:void(0)" (click)="isLoginForm=false;isForgetForm=true;isPasswordForm=false;">Forgot password?</a>
							<button type="submit" class="btn btn-primary"> 
								<i *ngIf="isLogging" class="fa fa-spin fa-spinner fa-pulse"></i> Sign in
							</button>
							<div class="checkmail" *ngIf="password_change_success">Please login with your new password</div>
						</form>
					</div>
				</div>
				<div *ngIf="isForgetForm" class="login">
					<h1>{{title}}</h1>
					<div class="form-outer">
						<form class="card" [formGroup]="forgetForm" (ngSubmit)="forgetFn()">
							<h3>Forgot Password</h3>
							<div class="group">
								<input type="text" placeholder="Email" formControlName="emailid"><span class="highlight"></span><span class="bar"></span>
							</div>
							<a href="javascript:void(0)" (click)="isLoginForm=true;isForgetForm=false;isPasswordForm=false;">Login</a>
							<button type="submit" class="btn btn-primary"> 
								<i *ngIf="isforgetSubmitted" class="fa fa-spin fa-spinner fa-pulse"></i> Submit
							</button>
							<div class="checkmail" *ngIf="isCheckMail">Mail sent to your registered mail. Please confirm to continue.</div>
							<div class="error_red" *ngIf="isErrorDiv">Oops token not valid. Try again!</div>
						</form>
					</div>
				</div>
				<div *ngIf="isPasswordForm" class="login">
					<h1>{{title}}</h1>
					<div class="form-outer">
						<form class="card" [formGroup]="resetPwdForm" (ngSubmit)="resetPwdFn(resetPwdForm)">
							<h3>New Password</h3>
							<div class="group">
								<input type="password" placeholder="Enter New Password" formControlName="password"><span class="highlight"></span><span class="bar"></span>
							</div>
							<div class="group">
								<input type="password" [ngModelOptions]="{standalone: true}"  placeholder="Confirm Password" [(ngModel)]="confirmed_password" ><span class="highlight"></span><span class="bar"></span>
							</div>
							<button type="submit" class="btn btn-primary" [attr.disabled]="resetPwdForm.value.password!=confirmed_password || resetPwdForm.value.password=='' ? '': null"> 
								<i *ngIf="isforgetSubmitted" class="fa fa-spin fa-spinner fa-pulse"></i> Submit
							</button>
						</form>
					</div>
				</div>
			</div>
			<div class="col-md-5">
				<div class="container container-img">
					<img src="../assets/img/login_img.svg" class="login_img">
					
				</div>
			</div>
			
		</div>
	</div>
</div>
