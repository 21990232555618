import { Component, OnInit, ViewChild,ElementRef} from '@angular/core';
import { ServicesService } from '../../services/services.service';
import { showNotification } from '../../services/notification';
import moment from 'moment';
declare var google: any;
@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {



	public tableData: any = [];
	options = {};
	public botID:number;
	botList = [];
	public loadingBots:boolean = false;
	public customerID:number = parseInt(localStorage.getItem('Customer_ID'), 10);
	public customerEmail:string = localStorage.getItem('Customer_Email');
	public customerType:number = parseInt(localStorage.getItem('Customer_Type'), 10);
	// @ViewChild('gChart1', { static: false }) gChart1: ElementRef;
	@ViewChild('gChart2', { static: false }) gChart2: ElementRef;
	@ViewChild('gChart3', { static: false }) gChart3: ElementRef;
	@ViewChild('gChart4', { static: false }) gChart4: ElementRef;
	@ViewChild('gChart5', { static: false }) gChart5: ElementRef;
	@ViewChild('gChart6', { static: false }) gChart6: ElementRef;
	@ViewChild('gChart7', { static: false }) gChart7: ElementRef;
	selected_date: any = {startDate:moment().subtract(7, 'days'),endDate:moment()};
	filter:string = 'all_agents';
	ranges: any = {
		'Today': [moment(), moment()],
		'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
		'Last 7 Days': [moment().subtract(6, 'days'), moment()],
		'Last 30 Days': [moment().subtract(29, 'days'), moment()]
	}
	locale: any = {
		applyLabel: 'Select', 
		format: 'MM/DD/YYYY',
		daysOfWeek: ["Su","Mo","Tu","We","Th","Fr","Sa"],
		monthNames: ["January","February","March","April","May","June",
		"July","August","September","October","November","December"],
	}
	current_perfomance_attend = 0;
	previous_perfomance_attend  = 0;
	percent_perfomance_attend  = 0;

	current_perfomance_closed  = 0;
	previous_perfomance_closed  = 0;
	percent_perfomance_closed  = 0;

	current_perfomance_total = 0;
	previous_perfomance_total = 0;
	percent_perfomance_total = 0;

	responded_count_bot = 0;
	positive_count_bot = 0;
	passive_count_bot = 0;
	negative_count_bot = 0;


	constructor(private dataService: ServicesService) { }

	ngOnInit() {

	}

	getChartData(){
		if(this.selected_date?.startDate !=null && this.selected_date?.endDate !=null){
			this.getAttendedTickets(this.botID);
			this.getClosedTickets(this.botID);
			this.getTotalTickets(this.botID);
			// this.splitClosedTickets(this.botID);
			this.getTimes(this.botID);
			this.splitChannels(this.botID);
			this.getWeekdayResponseTimes(this.botID);
			this.getWeekdayTicketCounts(this.botID);
			this.splitDepartments(this.botID);
			this.getCsat(this.botID);
		}
	}

	initBots(){
		if(this.selected_date?.startDate ==null && this.selected_date?.endDate ==null){
			this.selected_date = {startDate:moment().subtract(7, 'days'),endDate:moment()};
		}
		this.getBots();
	}

	getBots(){
		if(this.botList.length==0){
			this.loadingBots = true;
			this.dataService.getBotList({
				CustomerID:this.customerID,
				CustomerType:this.customerType})
			.subscribe((res) =>{
				if(res.status_code==200){
					this.botList = res.data;
					let inList = false;
					for(let i in this.botList){
						if(this.botList[i].BotID==this.botID){
							inList = true;
						}
					}
					if(!inList){
						this.botID = this.botList[0].BotID;
						localStorage.setItem('botID', this.botList[0].BotID);
					}
					this.getChartData();
				}
				this.loadingBots = false;
			}
			,
			(error)=>{
				this.loadingBots = false;
				console.log("Bot list error");
			});
		}
	}

	getAttendedTickets(botid){
		this.current_perfomance_attend = 0;
		this.previous_perfomance_attend = 0;
		this.dataService.getBotsAttendedTickets({
			BotID:parseInt(botid),
			from_date:moment(this.selected_date.startDate).format('YYYY-MM-DD'),
			to_date:moment(this.selected_date.endDate).format('YYYY-MM-DD'),
			time_offset:moment(this.selected_date.startDate).format('ZZ')
		}).subscribe(
		data=>{
			if(data.status_code == 200){
				this.current_perfomance_attend = data.data.current_perfomance;
				this.previous_perfomance_attend = data.data.previous_perfomance;
				if(this.previous_perfomance_attend==0&&this.current_perfomance_attend!=0){this.percent_perfomance_attend=100}
					else if(this.current_perfomance_attend==0&&this.previous_perfomance_attend!=0){this.percent_perfomance_attend=-100}
						else if(this.current_perfomance_attend == this.previous_perfomance_attend){this.percent_perfomance_attend = 0} 
							else { this.percent_perfomance_attend = ((this.current_perfomance_attend - this.previous_perfomance_attend)/this.previous_perfomance_attend)*100;}
					} else {
						showNotification('warning',data.status);
					}
				},
				error=>{
					console.log(error);
				})
	}

	getTotalTickets(botid){
		this.current_perfomance_total = 0;
		this.previous_perfomance_total = 0;
		this.dataService.getBotsTotalTickets({
			BotID:parseInt(botid),
			from_date:moment(this.selected_date.startDate).format('YYYY-MM-DD'),
			to_date:moment(this.selected_date.endDate).format('YYYY-MM-DD'),
			time_offset:moment(this.selected_date.startDate).format('ZZ')
		}).subscribe(
		data=>{
			if(data.status_code == 200){
				this.current_perfomance_total = data.data.current_total_tickets;
				this.previous_perfomance_total = data.data.previous_total_tickets;
				if(this.previous_perfomance_total==0&&this.current_perfomance_total!=0){this.percent_perfomance_total=100}
					else if(this.current_perfomance_total==0&&this.previous_perfomance_total!=0){this.percent_perfomance_total=-100}
						else if(this.current_perfomance_total == this.previous_perfomance_total){this.percent_perfomance_total = 0} 
							else { this.percent_perfomance_total = ((this.current_perfomance_total - this.previous_perfomance_total)/this.previous_perfomance_total)*100;}
					} else {
						showNotification('warning',data.status);
					}
				},
				error=>{
					console.log(error);
				})
	}

	getClosedTickets(botid){
		this.current_perfomance_attend = 0;
		this.previous_perfomance_attend = 0;
		this.dataService.getBotsClosedTickets({
			BotID:parseInt(botid),
			from_date:moment(this.selected_date.startDate).format('YYYY-MM-DD'),
			to_date:moment(this.selected_date.endDate).format('YYYY-MM-DD'),
			time_offset:moment(this.selected_date.startDate).format('ZZ')
		}).subscribe(
		data=>{
			if(data.status_code == 200){
				this.current_perfomance_closed = data.data.current_perfomance;
				this.previous_perfomance_closed = data.data.previous_perfomance;
				if(this.previous_perfomance_closed==0&&this.current_perfomance_closed!=0){this.percent_perfomance_closed=100}
					else if(this.current_perfomance_closed==0&&this.previous_perfomance_closed!=0){this.percent_perfomance_closed=-100}
						else if(this.current_perfomance_closed == this.previous_perfomance_closed){this.percent_perfomance_closed = 0} 
							else { this.percent_perfomance_closed = ((this.current_perfomance_closed - this.previous_perfomance_closed)/this.previous_perfomance_closed)*100;}
					} else {
						showNotification('warning',data.status);
					}
				},
				error=>{
					console.log(error);
				})
	}

	// splitClosedTickets(botid){
	// 	this.current_perfomance_attend = 0;
	// 	this.previous_perfomance_attend = 0;
	// 	this.dataService.splitBotsClosedTickets({
	// 		BotID:parseInt(botid),
	// 		from_date:moment(this.selected_date.startDate).format('YYYY-MM-DD'),
	// 		to_date:moment(this.selected_date.endDate).format('YYYY-MM-DD'),
	// 		time_offset:moment(this.selected_date.startDate).format('ZZ')
	// 	}).subscribe(
	// 	data=>{
	// 		if(data.status_code == 200){
	// 			if(data.data.length>0){
	// 				let options = {
	// 					is3D: true,
	// 					legend: { position: 'bottom', alignment: 'center' },
	// 					colors:['#2f9fdb','#e95b54','#85509a','#3caf84','#fbce4a']
	// 				};
	// 				setTimeout(()=>{   
	// 					let chartData = new google.visualization.PieChart(this.gChart1.nativeElement);
	// 					let dynaVisual = google.visualization.arrayToDataTable(data.data);
	// 					chartData.draw(dynaVisual, options);
	// 				}, 500);
	// 			} else {
	// 				this.gChart1.nativeElement.innerHTML = '<div class="noDataContainer"><div class="noData red"><i class="fa fa-exclamation-circle" aria-hidden="true"></i> Not enough data</div></div>';
	// 			}
	// 		} else {
	// 			showNotification('warning',data.status);
	// 		}
	// 	},
	// 	error=>{
	// 		console.log(error);
	// 	})
	// }

	getChatDurations(botid){
		this.dataService.getBotsChatDuration({
			BotID:parseInt(botid),
			from_date:moment(this.selected_date.startDate).format('YYYY-MM-DD'),
			to_date:moment(this.selected_date.endDate).format('YYYY-MM-DD'),
			time_offset:moment(this.selected_date.startDate).format('ZZ')
		}).subscribe(
		data=>{
			if(data.status_code == 200){
				if(data.data.length>0){
					let chartData = new google.visualization.BarChart(this.gChart2.nativeElement);
					let dynaVisual = google.visualization.arrayToDataTable(data.data);
					chartData.draw(dynaVisual, this.options);
				} else {
					this.gChart2.nativeElement.innerHTML = '<div class="noDataContainer1"><div class="noData red"><i class="fa fa-exclamation-circle" aria-hidden="true"></i> Not enough data</div></div>';
				}
			} else {
				showNotification('warning',data.status);
			}
		},
		error=>{
			console.log(error);
		})
	}

	getTimes(botid){
		this.dataService.getBotsResponseTimes({
			BotID:parseInt(botid),
			from_date:moment(this.selected_date.startDate).format('YYYY-MM-DD'),
			to_date:moment(this.selected_date.endDate).format('YYYY-MM-DD'),
			time_offset:moment(this.selected_date.startDate).format('ZZ')
		}).subscribe(
		data=>{
			if(data.status_code == 200){
				if(data.data.length>0){
					let chartData = new google.visualization.BarChart(this.gChart2.nativeElement);
					let dynaVisual = google.visualization.arrayToDataTable(data.data);
					chartData.draw(dynaVisual, this.options);
				} else {
					this.gChart2.nativeElement.innerHTML = '<div class="noDataContainer1"><div class="noData red"><i class="fa fa-exclamation-circle" aria-hidden="true"></i> Not enough data</div></div>';
				}
			} else {
				showNotification('warning',data.status);
			}
		},
		error=>{
			console.log(error);
		})
	}

	getWaitTimeServed(botid){
		this.dataService.getBotsWaitTimeServed({
			BotID:parseInt(botid),
			from_date:moment(this.selected_date.startDate).format('YYYY-MM-DD'),
			to_date:moment(this.selected_date.endDate).format('YYYY-MM-DD'),
			time_offset:moment(this.selected_date.startDate).format('ZZ')
		}).subscribe(
		data=>{
			if(data.status_code == 200){
				if(data.data.length>0){
					let chartData = new google.visualization.BarChart(this.gChart2.nativeElement);
					let dynaVisual = google.visualization.arrayToDataTable(data.data);
					chartData.draw(dynaVisual, this.options);
				} else {
					this.gChart2.nativeElement.innerHTML = '<div class="noDataContainer1"><div class="noData red"><i class="fa fa-exclamation-circle" aria-hidden="true"></i> Not enough data</div></div>';
				}
			} else {
				showNotification('warning',data.status);
			}
		},
		error=>{
			console.log(error);
		})
	}

	getWaitTimeMissed(botid){
		this.dataService.getBotsWaitTimeMissed({
			BotID:parseInt(botid),
			from_date:moment(this.selected_date.startDate).format('YYYY-MM-DD'),
			to_date:moment(this.selected_date.endDate).format('YYYY-MM-DD'),
			time_offset:moment(this.selected_date.startDate).format('ZZ')
		}).subscribe(
		data=>{
			if(data.status_code == 200){
				if(data.data.length>0){
					let chartData = new google.visualization.BarChart(this.gChart2.nativeElement);
					let dynaVisual = google.visualization.arrayToDataTable(data.data);
					chartData.draw(dynaVisual, this.options);
				} else {
					this.gChart2.nativeElement.innerHTML = '<div class="noDataContainer1"><div class="noData red"><i class="fa fa-exclamation-circle" aria-hidden="true"></i> Not enough data</div></div>';
				}
			} else {
				showNotification('warning',data.status);
			}
		},
		error=>{
			console.log(error);
		})
	}

	loadChart(chart){
		if(chart=='rt'){
			this.getTimes(this.botID);
		}
		if(chart=='cd'){
			this.getChatDurations(this.botID);
		}
		if(chart=='wts'){
			this.getWaitTimeServed(this.botID);
		}
		if(chart=='wtm'){
			this.getWaitTimeMissed(this.botID);
		}
	}

	splitChannels(botid){
		this.dataService.splitBotsChannels({
			BotID:parseInt(botid),
			from_date:moment(this.selected_date.startDate).format('YYYY-MM-DD'),
			to_date:moment(this.selected_date.endDate).format('YYYY-MM-DD'),
			time_offset:moment(this.selected_date.startDate).format('ZZ')
		}).subscribe(
		data=>{
			if(data.status_code == 200){
				if(data.data.length>0){
					let options = {
						is3D: true,
						legend: { position: 'bottom', alignment: 'center' },
						colors:['#fbce4a','#3caf84','#2f9fdb','#85509a','#e95b54']
					};
					let chartData = new google.visualization.PieChart(this.gChart3.nativeElement);
					let dynaVisual = google.visualization.arrayToDataTable(data.data);
					chartData.draw(dynaVisual, options);
				} else {
					this.gChart3.nativeElement.innerHTML = '<div class="noDataContainer"><div class="noData red"><i class="fa fa-exclamation-circle" aria-hidden="true"></i> Not enough data</div></div>';
				}
			} else {
				showNotification('warning',data.status);
			}
		},
		error=>{
			console.log(error);
		})
	}

	getWeekdayResponseTimes(botid){
		this.dataService.getBotsWeekdayResponseTimes({
			BotID:parseInt(botid),
			from_date:moment(this.selected_date.startDate).format('YYYY-MM-DD'),
			to_date:moment(this.selected_date.endDate).format('YYYY-MM-DD'),
			time_offset:moment(this.selected_date.startDate).format('ZZ')
		}).subscribe(
		data=>{
			if(data.status_code == 200){
				if(data.data.length>0){
					let chartData = new google.visualization.BarChart(this.gChart4.nativeElement);
					let dynaVisual = google.visualization.arrayToDataTable(data.data);
					chartData.draw(dynaVisual, this.options);
				} else {
					this.gChart4.nativeElement.innerHTML = '<div class="noDataContainer"><div class="noData red"><i class="fa fa-exclamation-circle" aria-hidden="true"></i> Not enough data</div></div>';
				}
			} else {
				showNotification('warning',data.status);
			}
		},
		error=>{
			console.log(error);
		})
	}

	getWeekdayTicketCounts(botid){
		this.dataService.getBotsWeekdayTicketCounts({
			BotID:parseInt(botid),
			from_date:moment(this.selected_date.startDate).format('YYYY-MM-DD'),
			to_date:moment(this.selected_date.endDate).format('YYYY-MM-DD'),
			time_offset:moment(this.selected_date.startDate).format('ZZ')
		}).subscribe(
		data=>{
			if(data.status_code == 200){
				if(data.data.length>0){
					let options = {
						legend: { position: 'top', maxLines: 3 },
						bar: { groupWidth: '75%' },
						isStacked: true,
						series: {
							0: {
								color: 'red'
							},
							1: {
								color: 'green'
							},
							2: {
								color: 'orange'
							}
						}
					}
					let chartData = new google.visualization.ColumnChart(this.gChart5.nativeElement);
					let dynaVisual = google.visualization.arrayToDataTable(data.data);
					chartData.draw(dynaVisual, options);
				} else {
					this.gChart5.nativeElement.innerHTML = '<div class="noDataContainer"><div class="noData red"><i class="fa fa-exclamation-circle" aria-hidden="true"></i> Not enough data</div></div>';
				}
			} else {
				showNotification('warning',data.status);
			}
		},
		error=>{
			console.log(error);
		})
	}

	splitDepartments(botid){
		this.dataService.splitBotsDepartments({
			BotID:parseInt(botid),
			from_date:moment(this.selected_date.startDate).format('YYYY-MM-DD'),
			to_date:moment(this.selected_date.endDate).format('YYYY-MM-DD'),
			time_offset:moment(this.selected_date.startDate).format('ZZ')
		}).subscribe(
		data=>{
			if(data.status_code == 200){
				if(data.data.length>0){
					let options = {
						is3D: true,
						legend: { position: 'bottom', alignment: 'center' },
						colors:['#fbce4a','#3caf84','#2f9fdb','#85509a','#e95b54']
					};
					let chartData = new google.visualization.PieChart(this.gChart6.nativeElement);
					let dynaVisual = google.visualization.arrayToDataTable(data.data);
					chartData.draw(dynaVisual, options);
				} else {
					this.gChart6.nativeElement.innerHTML = '<div class="noDataContainer"><div class="noData red"><i class="fa fa-exclamation-circle" aria-hidden="true"></i> Not enough data</div></div>';
				}
			} else {
				showNotification('warning',data.status);
			}
		},
		error=>{
			console.log(error);
		})
	}

	botPercent = 0;
	getCsat(botid){
		this.responded_count_bot = 0;
		this.positive_count_bot = 0;
		this.passive_count_bot = 0;
		this.negative_count_bot = 0;
		this.dataService.getBotsCsat({
			BotID:parseInt(botid),
			from_date:moment(this.selected_date.startDate).format('YYYY-MM-DD'),
			to_date:moment(this.selected_date.endDate).format('YYYY-MM-DD'),
			time_offset:moment(this.selected_date.startDate).format('ZZ')
		}).subscribe(
		data=>{
			if(data.status_code == 200){
				var options = {
					title: '',
					titleTextStyle:{ 
						color: '#333333',
						fontSize: 10,
						bold: true,
					},
					backgroundColor: "transparent",
					height: 125,
					chartArea: {
						top: 15
					},
					colors: ['#338106', '#bfbfbf'],
					legend:'none',
					pieHole: 0.5,
					pieStartAngle: 270,
					pieSliceText: 'none',
					tooltip : {
						trigger: 'none'
					},
					slices: {
						2: {
							color: 'transparent'
						}
					},
					theme: 'maximized',
					width: 150
				};

				var bot_data = [];
				if(Object.keys(data.data).length === 0){
					bot_data = [
					['Label', 'Value'],
					['Bot', 0],
					['', 100],
					['', 100]
					];
					this.botPercent = 0;
				} else {
					bot_data = [
					['Label', 'Value'],
					['Bot', data.data.average_rating_bot],
					[null, 100 - data.data.average_rating_bot],
					[null, 100]
					];
					this.botPercent = data.data.average_rating_bot;
					this.responded_count_bot = data.data.responded_count_bot;
					this.positive_count_bot = data.data.positive_count_bot;
					this.passive_count_bot = data.data.passive_count_bot;
					this.negative_count_bot = data.data.negative_count_bot;
				}
				setTimeout(()=>{  
					let chartData = new google.visualization.PieChart(this.gChart7.nativeElement);
					let dynaVisual = google.visualization.arrayToDataTable(bot_data);
					chartData.draw(dynaVisual, options);
				}, 500);
			} else {
				showNotification('warning',data.status);
			}
		},
		error=>{
			console.log(error);
		})
	}


	ngAfterViewInit() {
		google.charts.load('current', { 'packages': ['corechart','gauge']});
	}


}
