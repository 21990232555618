<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-2 col-xs-2 pdlr5">
				<div class="card panel panel-default listing">
					<div class="panel-heading text-center"><b>Queue</b></div>
					<div class="panel-body">
						<div class="usersList" *ngFor="let item of usersList">
							<a href="javascript:void(0)" class="user" (click)="spectateUser(item.userid)" [title]="getUserSpec('email',item.userid)">
								<span *ngIf="getUserSpec('name',item.userid)">
									<img class="countryflag" src="https://www.countryflags.io/{{getCountryCode(item.userid)}}/flat/32.png" onerror="this.src='/assets/img/flag.png';" height="20">&nbsp;{{getUserSpec('name',item.userid)}}
									<i *ngIf="item.inView && !item.disconnected" class="fa fa-eye"></i>
									<i *ngIf="item.inView && item.disconnected" class="fa fa-eye-slash"></i>
								</span>
							</a>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-7 col-xs-7 pdlr5">
				<div class="card listsel" *ngIf="seluserList.length>0">
					<div class="selUsers" *ngFor="let item of seluserList.slice().reverse(); let i=index;">
						<a href="javascript:void(0)" [ngClass]="{ 'selected': item.selected}" class="seluser"
						(click)="selectSelUser(item.userid)">{{getUserSpec('name',item.userid)}} <i class="fa fa-circle status"
						[ngClass]="{ 'disconnected': item.disconnect}"></i></a> <span
						*ngIf="unreadCount[item.userid]>0" class="count">{{unreadCount[item.userid]}}</span>
					</div>
				</div>
				<div class="card">
					<main class="msger-chat nodata" *ngIf="chatlog.length==0">
						<div class="selUserMsg" *ngIf="selected_user==0" style="color: red;">Please select a ticket to access the chat!</div>
						<div class="selUserMsg" *ngIf="isfetchingLog"><i class="fa fa-spin fa-spinner fa-pulse"></i>
						</div>
					</main>
					<main class="msger-chat" *ngIf="chatlog.length>0" #chatBox>
						<a *ngIf="chatEnabled" href="javascript:void(0)" class="close-chat" (click)="closeChatModal.show()"><i class="fa fa-times"
							aria-hidden="true"></i></a>
							<div *ngFor="let chat of chatlog">
								<div class="msg left-msg" *ngIf="chat.sender=='bot'">
									<div class="dflx mtop-25"
									*ngIf="chat.message.cardtypeid!=undefined && chat.message.cardtypeid!=16 && chat.message.cardtypeid != 8">
									<div class="msg-img" style="background-image: url(https://canvas.creyoface.com/assets/img/creyoface.gif)"></div>
									<!-- [style.background]="'url('+ userDetails.botlogo +')'" -->
									<div class="msg-bubble">
										<div class="msg-text">
											<div *ngIf="chat.message.cardtypeid == 1 && chat.message.feedbacktypeid == 1"
											[innerHTML]="chat.message.message"></div> <!-- msg card -->
											<div
											*ngIf="chat.message.cardtypeid == 36 && chat.message.feedbacktypeid == 1">
											<carousel [noWrap]="noWrap"
											[interval]="2000">
											<slide *ngFor="let item of chat.message.message; let i=index;">
												<img src="{{ item }}" alt="slide">
											</slide>
										</carousel>
									</div> <!-- image card -->
									<div
									*ngIf="chat.message.cardtypeid == 38 && chat.message.feedbacktypeid == 1">
									<!-- <iframe height="120" [src]="chat.message.message"> </iframe> -->
									<iframe height="120" [src]="changeIframe(chat.message.message)"> </iframe>


								</div>
								<!-- video card -->
								<div
								*ngIf="chat.message.cardtypeid == 37 && chat.message.feedbacktypeid == 1">
								<audio controls>
									<source src="{{chat.message.message}}" type="audio/mpeg"></audio>
									</div> <!-- audio card -->
									<div *ngIf="chat.message.cardtypeid == 2"
									[innerHTML]="chat.message.message">
									<!-- choice card -->
									<div class="choices">
										<div *ngFor="let opt of chat.message.choices" [innerHTML]="opt.key">
										</div>
									</div>
								</div>
									<!-- <div *ngIf="chat.message.cardtypeid == 3 && chat.message.feedbacktypeid == 0"
										[innerHTML]="chat.message.message"></div> -->
										<div *ngIf="chat.message.cardtypeid == 3 && chat.message.feedbacktypeid <= 10"
										[innerHTML]="chat.message.message"></div>
										<!-- userip feedback = 0 card -->
										<div *ngIf="chat.message.cardtypeid == 3 && chat.message.feedbacktypeid > 10"
											class="formcard">
											<!-- userip feedback > 0 card -->
											<div *ngFor="let item of chat.message.message">
												<span><input type="text" placeholder="{{item.element}}" disabled></span>
											</div>
										</div> <!-- userip feedback = 0 card -->
										<!-- <div *ngIf="chat.message.cardtypeid == 3 && chat.message.feedbacktypeid > 0">{{chat.message.message}}</div> -->
										<div *ngIf="chat.message.cardtypeid == 4 && chat.message.feedbacktypeid == 1"
										[innerHTML]="chat.message.message">
										<br>
										<div class="dflx feedback">
											<i class="fa fa-star" aria-hidden="true"></i>
											<i class="fa fa-star" aria-hidden="true"></i>
											<i class="fa fa-star" aria-hidden="true"></i>
											<i class="fa fa-star" aria-hidden="true"></i>
											<i class="fa fa-star" aria-hidden="true"></i>
										</div>
									</div> <!-- feedback card -->
									<div *ngIf="chat.message.cardtypeid == 4 && chat.message.feedbacktypeid == 2"
									[innerHTML]="chat.message.message"></div> <!-- feedback card -->
									<div *ngIf="chat.message.cardtypeid == 4 && chat.message.feedbacktypeid == 3"
									[innerHTML]="chat.message.message"></div> <!-- feedback card -->
									<div *ngIf="chat.message.cardtypeid == 4 && chat.message.feedbacktypeid == 4"
									[innerHTML]="chat.message.message"></div> <!-- feedback card -->
									<div *ngIf="chat.message.cardtypeid == 14"
									[innerHTML]="chat.message.message"></div> <!-- date card -->
									<div *ngIf="chat.message.cardtypeid == 10">
										<!-- carousal card -->
										<carousel [noWrap]="noWrap"
										[interval]="2000">
										<slide *ngFor="let item of chat.message.data | keyvalue">
											<img src="{{chat.message.data[item.key].img_url}}" alt="slide">
											<div class="caption text-center">
												<span class="heading"
												[innerHTML]="chat.message.data[item.key].heading"></span><br>
												<span class="subheading"
												[innerHTML]="chat.message.data[item.key].subheading"></span>
											</div>
											<div class="car-buttons text-center"
											*ngFor="let btns of chat.message.data[item.key].choices">
											<span [innerHTML]="btns.key"></span>
										</div>
									</slide>
								</carousel>
							</div>
							<div *ngIf="chat.message.cardtypeid == 18 && chat.message.feedbacktypeid==1">
								<span class="car-rep-button" [innerHTML]="chat.message.message"></span>
							</div>
							<div *ngIf="chat.message.cardtypeid == 18 && chat.message.feedbacktypeid==0">
								<a [href]="chat.message.message" target="_blank" download>Download File</a>
							</div>
							<div class="pax-card" *ngIf="chat.message.cardtypeid == 31">
								<img class="bg_imgurl" width="100" src="/assets/img/ic_send_pax.svg" alt="bgimg">
								<p class="heading"[innerHTML]="chat.message.message[0].heading"></p>
								<p class="subheading"[innerHTML]="chat.message.message[0].sub_heading"></p>
								<p class="description"[innerHTML]="chat.message.message[0].description"></p>
							</div>
							<div class="seat-selection" *ngIf="chat.message.cardtypeid == 32">
								<img class="bg_imgurl" width="150" src="{{chat.message.message.message.bg_imgurl}}" alt="bgimg">
								<p class="heading"[innerHTML]="chat.message.message.message.heading"></p>
								<p class="subheading"[innerHTML]="chat.message.message.message.sub_heading"></p>
								<p class="description"[innerHTML]="chat.message.message.message.description"></p>
								<div class="seats">
									<div class="row" *ngFor="let item of [].constructor(5);">
										<div *ngFor="let item of [].constructor(6);" class="col-xs-2"><img src="/assets/img/seat.png" width="20"></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="tagsList" *ngIf="chat.cardtypeid == 27">
					<div class="include" [ngClass]="{'m-auto':chat.message.exclude.length==0, 'dnone':chat.message.include.length==0 }">
						<span *ngFor="let item of chat.message.include" [style.background]="getTagDetails(item,'color')">
							{{getTagDetails(item,'name')}}
						</span>
					</div>
					<div class="exclude" [ngClass]="{'m-auto':chat.message.include.length==0, 'dnone':chat.message.exclude.length==0 }">
						<span *ngFor="let item of chat.message.exclude" [style.background]="getTagDetails(item,'color')">
							{{getTagDetails(item,'name')}}
						</span>
					</div>
				</div>
				<div class="msg-info"
				*ngIf="chat.message.cardtypeid!=undefined && chat.message.cardtypeid!=16 && chat.message.cardtypeid != 8">
				<div class="msg-info-time">{{chat.time | date:'medium'}}</div>
			</div>
		</div>
		<div *ngIf="chat.isgoal" class="w-100 text-center goal">
			<span *ngIf="chat.goalstatus=='start'" title="Goal Name" class="goalstart"> <img
				src="/assets/img/start.png" width="25"> &nbsp;{{chat.goalname}} <span
				class="status">Started</span></span>
				<span *ngIf="chat.goalstatus=='milestone'" title="Goal: {{chat.goalname}}"
				class="goalmilestone"> <img src="/assets/img/leader.png" width="25">
				&nbsp;{{chat.goalname}} - {{chat.milestone}}</span>
				<span *ngIf="chat.goalstatus=='end'" title="Goal Name" class="goalend"> <img
					src="/assets/img/target.png" width="25"> &nbsp;{{chat.goalname}} <span
					class="status">Completed</span></span>
				</div>

				<div class="msg left-msg" *ngIf="chat.sender=='live_agent' && chat.isLive==undefined">
					<div class="dflx mtop-25" *ngIf="chat.cardtypeid==undefined">
							<div class="msg-img"
							[style.background-image]="'url(' + getLAImage(chat.message.agent_id,'img') + ')'" [title]="getLAImage(chat.message.agent_id,'name')">
							<span>{{getLAImage(chat.message.agent_id,'name')}}</span>
						</div>
						<div class="msg-bubble liveagent">
							<div class="msg-text">
								<div [innerHTML]="chat.message.message"></div>
							</div>
						</div>
					</div>
					<div class="mtop-25" *ngIf="chat.cardtypeid == 27">
								<div class="msg-img"
								[style.background-image]="'url(' + getLAImage(customerID,'img') + ')'" [title]="getLAImage(customerID,'name')">
								<span>{{getLAImage(customerID,'name')}}</span>
							</div>
							<div class="tagsList w-100">
								<div class="include m-auto">
									<span *ngFor="let item of chat.message.include" [style.background]="getTagDetails(item,'color')">
										{{getTagDetails(item,'name')}}
									</span>
								</div>
							</div>
						</div>
					<div class="msg-info">
						<div class="msg-info-time">{{chat.time | date:'medium'}}</div>
					</div>
				</div>

				<div class="msg right-msg" *ngIf="chat.sender=='live_agent' && chat.isLive">
					<div class="dflxrev mtop-25">
						<div class="msg-img"
						style="background-image: url({{agent_img}})">
					</div>
					<div class="msg-bubble liveagent">
						<div class="msg-text">
							<div>{{chat.message}}</div>
						</div>
					</div>
				</div>
				<div class="msg-info">
					<div class="msg-info-time">{{chat.time | date:'medium'}}</div>
				</div>
			</div>

			<div class="msg left-msg" *ngIf="chat.sender=='user' && chat.isLive">
				<div class="dflx mtop-25">
					<div class="msg-img"
					style="background-image: url(https://canvas.creyoface.com/assets/img/creyoface.gif)">
				</div>
				<div class="msg-bubble">
					<div class="msg-text">
						<div>{{chat.message}}</div>
					</div>
				</div>
			</div>
			<div class="msg-info">
				<div class="msg-info-time">{{chat.time | date:'medium'}}</div>
			</div>
		</div>

		<div class="msg right-msg"
		*ngIf="chat.sender == 'user' && chat.message && chat.isLive==undefined">
		<div class="dflxrev mtop-25">
			<div class="msg-img" style="background-image: url(https://canvas.creyoface.com/assets/img/creyoface.gif)"></div>
			<!-- [style.background]="'url('+ userDetails.botlogouser +')'" -->
			<div class="msg-bubble">
				<div class="msg-text"
				*ngIf="chat.cardtypeid == 1 || chat.cardtypeid == 2 || (chat.cardtypeid == 3 && chat.feedbacktypeid ==0 || chat.cardtypeid == -1)"
				[innerHTML]="chat.message">
			</div>

			<div class="msg-text" *ngIf="chat.cardtypeid == 4 && chat.feedbacktypeid ==1"
			[innerHTML]="chat.message"><i class="fa fa-star" aria-hidden="true"></i>
		</div>
		<div class="msg-text"
		*ngIf="chat.cardtypeid == 4 && (chat.feedbacktypeid == 3 || chat.feedbacktypeid == 4)"
		[innerHTML]="chat.message">
	</div>

	<div class="msg-text" *ngIf="chat.cardtypeid == 26" [innerHTML]="chat.message"></div>

	<div class="msg-text" *ngIf="chat.cardtypeid == 14">
		<!-- date -->
		<span *ngIf="chat.message.from">{{chat.message.from}}</span>
		<span *ngIf="chat.message.to"> - {{chat.message.to}}</span>
	</div>

	<div class="msg-text" *ngIf="chat.cardtypeid == 4 && chat.feedbacktypeid == 2">
		<!-- date -->
		<i *ngIf="chat.message == '0'" class="fa fa-thumbs-down"
		aria-hidden="true"></i>
		<i *ngIf="chat.message == '1'" class="fa fa-thumbs-up"
		aria-hidden="true"></i>
	</div>

	<div class="car-reply" *ngIf="chat.cardtypeid == 10">
		<!-- carousal -->
		<span class="car-rep-head"
		[innerHTML]="chat.message.data.heading"></span><br>
		<span class="car-rep-subhead"
		[innerHTML]="chat.message.data.subheading"></span><br>
		<span class="car-rep-button" [innerHTML]="chat.message.choice"></span>
	</div>

	<div *ngIf="chat.cardtypeid == 3 && chat.feedbacktypeid >= 10">
		<div *ngFor="let item of chat.message | keyvalue">
			<span>{{item.key}} : <b>{{item.value}}</b></span>
		</div>
	</div>

	<div *ngIf="chat.cardtypeid == 3 && chat.feedbacktypeid < 10 && chat.feedbacktypeid != 0">
		<div [innerHTML]="chat.message">
		</div>
	</div>

	<div *ngIf="chat.cardtypeid == 18 && chat.feedbacktypeid == 1">
		<a [href]="chat.message" target="_blank" download>Uploaded File</a>
	</div> 

	<div class="msg-text" *ngIf="chat.cardtypeid == 31">
		<div *ngFor="let item of chat.message; let i=index;" class="seat-selected">
			<span *ngFor="let pax of item | keyvalue;">{{pax.key}} : {{pax.value}}</span>
		</div>
	</div>

	<div class="msg-text" *ngIf="chat.cardtypeid == 32">
		<div *ngFor="let item of chat.message" class="seat-selected">
			<span>{{item.label_key}}</span>
		</div>
	</div>

</div>

</div>
<div class="msg-info">
	<div class="msg-info-time">{{chat.time | date:'medium'}}</div>
</div>
</div>
</div>
</main>
<div *ngIf="!startChat && showChatButton" class="chat-container">
	<div class="chat-input-enable">
		<button (click)="chatEnable()" class="send-btn">Chat</button>
	</div>
</div>
<div *ngIf="startChat" class="chat-container">
	<div class="chat-input">
		<!-- [mention]="quick_replies"  -->
		<textarea 
		[mentionConfig]="mentionConfig"
		title="Type message & enter to send" 
		autocomplete="off" 
		style="white-space: pre-wrap;"
		[(ngModel)]="newMessage"
		(keyup)="$event.keyCode == 13 && sendMessage()" rows="3"
		[disabled]="isfetchingLog"></textarea>
		<button (click)="sendMessage()" class="send-btn"><i class="fa fa-paper-plane"
			aria-hidden="true"></i></button>
		</div>
	</div>
</div>
</div>
<div class="col-md-3 col-xs-3 pdlr5" *ngIf="showSideboxes">
	<div class="user-details">
		<div class="card panel panel-default">
			<div class="panel-heading"><b>Details</b>
				<a class="pull-right" (click)="showDetails=!showDetails">
					<i *ngIf="!showDetails" class="fa fa-bars" aria-hidden="true"></i>
					<i *ngIf="showDetails" class="fa fa-times" aria-hidden="true"></i>
				</a>
			</div>
			<div class="panel-body" *ngIf="showDetails" [@slideInOut]>
				<ul>
					<li *ngIf="spectDetail.channel">URL : <span>{{spectDetail.channel}}</span></li>
					<li *ngIf="spectDetail.browser">Browser : <span>{{spectDetail.browser}}</span></li>
					<li *ngIf="spectDetail.os">OS : <span>{{spectDetail.os}}</span></li>
					<li *ngIf="spectDetail.country">Country : <span>{{spectDetail.country}}</span></li>
					<li *ngIf="spectDetail.continent">Continent : <span>{{spectDetail.continent}}</span></li>
					<li *ngIf="!spectDetail.channel && !spectDetail.browser && !spectDetail.os && !spectDetail.country && !spectDetail.continent"
					class="text-center">Not provided</li>
					<!-- <li>Last Seen at : <span>{{spectDetail[selected_user].browser}}</span></li> -->
				</ul>
			</div>
		</div>
	</div>

	<div class="user-details">
		<div class="card panel panel-default">
			<div class="panel-heading"><b>Lead Details</b>
				<a class="pull-right" (click)="showLeadDetails=!showLeadDetails">
					<i *ngIf="!showLeadDetails" class="fa fa-bars" aria-hidden="true"></i>
					<i *ngIf="showLeadDetails" class="fa fa-times" aria-hidden="true"></i>
				</a>
			</div>
			<div class="panel-body" *ngIf="showLeadDetails" [@slideInOut]>
				<form class="form-horizontal" [formGroup]="leadForm"
				(ngSubmit)="onLeadSubmit(leadForm.value)">
				<div class="form-group">
					<div class="col-sm-12">
						<input type="text" class="form-control" formControlName="name"
						placeholder="Name" name="name" autocomplete="off">
					</div>
				</div>
				<div class="form-group">
					<div class="col-sm-12">
						<input type="text" class="form-control" formControlName="phone"
						placeholder="Phone" name="phone" autocomplete="off">
					</div>
				</div>
				<div class="form-group">
					<div class="col-sm-12">
						<input type="email" class="form-control" formControlName="email"
						placeholder="Email" name="email" autocomplete="off">
					</div>
				</div>
				<div class="form-group">
					<div class="col-sm-12">
						<input type="text" class="form-control" formControlName="company"
						placeholder="Company" name="company" autocomplete="off">
					</div>
				</div>
				<div class="form-group">
					<div class="col-sm-12">
						<input type="text" class="form-control" formControlName="location"
						placeholder="Location" name="location" autocomplete="off">
					</div>
				</div>
				<div class="form-group">
					<div class="col-sm-12 text-right">
						<button type="submit" class="btn-sm btn btn-gradient">Submit</button>
					</div>
				</div>
			</form>
		</div>
	</div>
</div>
<div class="user-details">
	<div class="card panel panel-default">
		<div class="panel-heading"><b>More Details</b>
			<!-- <a class="pull-right" (click)="showmoreDetails=!showmoreDetails">
				<i *ngIf="!showmoreDetails" class="fa fa-bars" aria-hidden="true"></i>
				<i *ngIf="showmoreDetails" class="fa fa-times" aria-hidden="true"></i>
			</a> -->
		<!-- 	<a (click)="isaddLead=!isaddLead;isLeadEdit=false;clearMoreLeadForm();" class="pull-right">
				<i class="fa fa-plus green" aria-hidden="true"></i> <span class="white">Lead</span>
			</a> -->
			<div class="pull-right">
				<a (click)="isaddLead=true;isaddMemory=false;isLeadEdit=false;clearMoreLeadForm();" class="add-lead-btn">
					<i class="fa fa-plus black" aria-hidden="true"></i> <span class="white">Lead</span>
				</a> &nbsp;
				<a (click)="isaddLead=false;isaddMemory=true;clearMemForm();" class="float-right add-lead-btn">
					<i class="fa fa-plus black" aria-hidden="true"></i> <span class="white">Variable</span>
				</a>
			</div>
		</div>
		<div class="panel-body" *ngIf="showmoreDetails" [@slideInOut]>
			<ul>
				
				<li *ngFor="let item of leadDataList">{{item.storage_variable}} : <span>{{item.value}}</span>
					<a (click)="editData(item)" class="pull-right">
						<i class="fa fa-pencil-square-o green" aria-hidden="true"></i>
					</a>
				</li>
				<form [formGroup]="leadmoreForm" (ngSubmit)="leadMoreSubmit(leadmoreForm)" *ngIf="isaddLead">
					<ul class="add-lead">
						<li class="dflx"> 
							<select class="form-control" formControlName="storage_variable">
								<option [value]="null" [selected]="true" disabled>Select Variable</option>
								<option *ngFor="let item of starredMemVarsList" [value]="item.internal_name">{{item.display_name}}</option>
							</select> : <input type="text" placeholder="Value" formControlName="value" class="w-100">
						</li>
					</ul>
					<button class="btn-sm btn btn-gradient pull-right" [disabled]="!leadmoreForm.valid" type="submit"><i *ngIf="leadloader" class="fa fa-spin fa-pulse fa-spinner" aria-hidden="true"></i> Save</button>
				</form>
				<form [formGroup]="addMemForm" (ngSubmit)="addMemSubmit(addMemForm)" *ngIf="isaddMemory">
					<ul class="add-lead">
						<li class="dflx"> 
							<input type="text" placeholder="Name" formControlName="display_name" class="w-100">:
							<select class="form-control" formControlName="variable_type">
								<option [value]="null" [selected]="true" disabled>Type</option>
								<option *ngFor="let item of memVarTypes" [value]="item.value">{{item.name}}</option>
							</select>
						</li>
					</ul>
					<button class="btn-sm btn btn-gradient pull-right" [disabled]="!addMemForm.valid" type="submit"><i *ngIf="leadloader" class="fa fa-spin fa-pulse fa-spinner" aria-hidden="true"></i> Save</button>
				</form>
			</ul>
		</div>
	</div>
</div>
<div class="user-details">
	<div class="card panel panel-default">
		<div class="panel-heading"><b>Tags</b>
			<a class="pull-right" (click)="showTagDetails=!showTagDetails">
				<i *ngIf="!showTagDetails" class="fa fa-bars" aria-hidden="true"></i>
				<i *ngIf="showTagDetails" class="fa fa-times" aria-hidden="true"></i>
			</a>
		</div>
		<div class="panel-body" *ngIf="showTagDetails" [@slideInOut]>
			<angular2-multiselect [data]="tagsList" 
			[settings]="categoriesSettings" 
			[(ngModel)]="selectedTags">
		</angular2-multiselect>
		<div class="mtop10 text-right">
			<button (click)="saveUserTags()" class="btn-sm btn btn-gradient" type="submit"><i *ngIf="isaddTags" class="fa fa-spin fa-pulse fa-spinner" aria-hidden="true"></i> Save</button>
		</div>
	</div>
</div>
</div>
</div>
</div>
</div>
</div>

<div bsModal #closeChatModal="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1"
role="dialog" aria-labelledby="dialog-auto-name">
<div class="modal-dialog modal-sm">
	<div class="modal-content">
		<div class="modal-header">
			<h4 class="modal-title text-center">Close Chat</h4>
			<button type="button" class="close" (click)="closeChatModal.hide()">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<div class="modal-body text-center">
			<p>Is the issue resolved?</p>
		</div>
		<div class="modal-footer">
			<button type="button" class="btn btn-red" (click)="closeChat('inprogress')">
				<i class="fa fa-times"></i> In-Progress</button>
				<button type="button" class="btn btn-green" (click)="closeChat('resolved')">Resolved</button>
			</div>
		</div>
	</div>
</div>