import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { showNotification } from 'app/services/notification';
import { ServicesService } from 'app/services/services.service';
import { BusinessHour } from './business-hours.model';
import { ModalDirective } from 'ngx-bootstrap/modal';
import moment from 'moment';

export interface Holiday {
  name: string;
  date: any;
}

@Component({
  selector: 'app-business-hours',
  templateUrl: './business-hours.component.html',
  styleUrls: ['./business-hours.component.scss']
})

export class BusinessHoursComponent implements OnInit {

  businessHours: BusinessHour[] = [];
  businessHoursForm: FormGroup;
  isEdit = false;
  @Input() selectedDept;
  isAdding = false;
  timezones = ['Asia/Kolkata (UTC +5:30)'];
  timeList = [];
  holidayList = [];
  holiday: Holiday = {
    name: '', date: ''
  };
  selectedBH: any = null;
  @ViewChild('addBusinessHoursModal', { static: false }) addBusinessHoursModal: ModalDirective;
  @ViewChild('deleteBusinessHoursModal', { static: false }) deleteBusinessHoursModal: ModalDirective;

  constructor(private _fb: FormBuilder, private dataService: ServicesService) { }

  ngOnInit(): void {
    this.clearBHForm();
    this.generateTimeList();
    this.getHours();
  }

  clearBHForm(data?) {
    this.holidayList = [];
    this.holiday = {
      name: '', date: ''
    };
    this.businessHoursForm = this._fb.group({
      name: [data?.name ?? '', [Validators.required]],
      department_id: [this.selectedDept ?? null],
      description: [data?.description ?? '', [Validators.required]],
      timezone: [data?.timezone ?? '', [Validators.required]],
      business_hours: [data?.business_hours ?? '24x7', [Validators.required]],
      hours_list: this._fb.group({
        Sunday: this._fb.group({
          active: [!!data?.hours_list?.Sunday?.from],
          from: [data?.hours_list?.Sunday?.from ?? null, []],
          to: [data?.hours_list?.Sunday?.to ?? null, []],
        }),
        Monday: this._fb.group({
          active: [!!data?.hours_list?.Monday?.from],
          from: [data?.hours_list?.Monday?.from ?? null, []],
          to: [data?.hours_list?.Monday?.to ?? null, []],
        }),
        Tuesday: this._fb.group({
          active: [!!data?.hours_list?.Tuesday?.from],
          from: [data?.hours_list?.Tuesday?.from ?? null, []],
          to: [data?.hours_list?.Tuesday?.to ?? null, []],
        }),
        Wednesday: this._fb.group({
          active: [!!data?.hours_list?.Wednesday?.from],
          from: [data?.hours_list?.Wednesday?.from ?? null, []],
          to: [data?.hours_list?.Wednesday?.to ?? null, []],
        }),
        Thursday: this._fb.group({
          active: [!!data?.hours_list?.Thursday?.from],
          from: [data?.hours_list?.Thursday?.from ?? null, []],
          to: [data?.hours_list?.Thursday?.to ?? null, []],
        }),
        Friday: this._fb.group({
          active: [!!data?.hours_list?.Friday?.from],
          from: [data?.hours_list?.Friday?.from ?? null, []],
          to: [data?.hours_list?.Friday?.to ?? null, []],
        }),
        Saturday: this._fb.group({
          active: [!!data?.hours_list?.Saturday?.from],
          from: [data?.hours_list?.Saturday?.from ?? null, []],
          to: [data?.hours_list?.Saturday?.to ?? null, []],
        }),
      }),
    });

    this.applyConditionalValidators();
    this.holidayList = data?.holidays ?? [];
  }

  applyConditionalValidators() {
    const hoursList = this.businessHoursForm.get('hours_list') as FormGroup;
    const businessHours = this.businessHoursForm.get('business_hours').value;

    Object.keys(hoursList.controls).forEach(day => {
      const dayGroup = hoursList.get(day) as FormGroup;
      const active = dayGroup.get('active').value;

      if (active && businessHours == 'hours') {
        dayGroup.get('from').setValidators([Validators.required]);
        dayGroup.get('to').setValidators([Validators.required]);
      } else {
        dayGroup.get('from').clearValidators();
        dayGroup.get('to').clearValidators();
      }

      dayGroup.get('from').updateValueAndValidity();
      dayGroup.get('to').updateValueAndValidity();
    });
  }

  generateTimeList() {
    for (let i = 0; i <= 24; i++) {
      const hour = i.toString().padStart(2, '0');
      this.timeList.push(`${hour}:00`);
    }
  }

  onCheckboxChange(day: string) {
    this.applyConditionalValidators();
  }

  markFormGroupTouched(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.get(key);

      if (control instanceof FormControl) {
        control.markAsTouched();
        control.updateValueAndValidity();
      } else if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }


  addHours(form) {
    if (this.businessHoursForm.invalid) {
      this.markFormGroupTouched(this.businessHoursForm);
      return;
    }
    if (form.valid) {
      let data = {
        ...form.value,
        holidays: this.holidayList
      }
      if (!this.isEdit) {
        this.dataService.addBusinessHours(data).subscribe(
          result => {
            console.log(result);
            this.getHours();
            this.addBusinessHoursModal.hide();
            showNotification('success', 'Business Hour Added Successfully!');
          },
          error => {
            console.log(error);
            showNotification('danger', error);
          }
        )
      } else {
        this.dataService.editBusinessHours(this.selectedBH.id, data).subscribe(
          result => {
            console.log(result);
            this.getHours();
            this.addBusinessHoursModal.hide();
            showNotification('success', 'Business Hour Updated Successfully!');
          },
          error => {
            console.log(error);
            showNotification('danger', error);
          }
        )
      }
    }
  }

  addHoliday() {
    if (this.holiday?.name && this.holiday?.date.startDate) {
      this.holidayList = [...this.holidayList, {
        name: this.holiday.name,
        date: moment(this.holiday.date.startDate).format('DD-MM-YYYY')
      }];
      this.holiday = { name: '', date: '' };
    } else {
      showNotification('warning', 'Fill name & date!');
    }

  }

  getHours() {
    this.dataService.getBusinessHours(this.selectedDept).subscribe(
      result => {
        console.log(result);
        this.businessHours = result;
      },
      error => {
        showNotification('danger', error);
      })
  }

  deleteBH(bh_id) {
    this.dataService.deleteBusinessHours(bh_id).subscribe(
      () => {
        this.getHours();
        this.deleteBusinessHoursModal.hide();
        showNotification('warning', 'Business Hour Deleted Successfully!');
      },
      error => {
        console.log(error);
        showNotification('danger', error);
      }
    )
  }

}
