import { Component, OnInit, ElementRef, ViewChild} from '@angular/core';
import moment from 'moment';
import { ServicesService } from '../services/services.service';
import { showNotification } from '../services/notification';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators, AbstractControl } from '@angular/forms';
import { trigger, transition, animate, style, state, group } from '@angular/animations';
import * as data from '../../assets/js/countrycodes.json';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
	selector: 'app-people',
	templateUrl: './people.component.html',
	styleUrls: ['./people.component.scss','../user/user.component.scss'],
	animations: [
	trigger('slideInOut', [
		transition(':enter', [
			style({transform: 'translateX(-100%)'}),
			animate('200ms ease-in', style({transform: 'translateX(0%)'}))
			]),
		transition(':leave', [
			animate('200ms ease-out', style({transform: 'translateX(-100%)'}))
			])
		]),
	trigger('slideDown', [
		state('in', style({height: '*', opacity: 0})),
		transition(':leave', [
			style({height: '*', opacity: 1}),

			group([
				animate(50, style({height: 0})),
				animate('100ms ease-in-out', style({'opacity': '0'}))
				])

			]),
		transition(':enter', [
			style({height: '0', opacity: 0}),

			group([
				animate(50, style({height: '*'})),
				animate('200ms ease-in-out', style({'opacity': '1'}))
				])

			])
		])
	]
})
export class PeopleComponent implements OnInit {

	public botID:number = parseInt(localStorage.getItem('botID'), 10);
	public customerID:number = parseInt(localStorage.getItem('Customer_ID'), 10);
	public customerType:number = parseInt(localStorage.getItem('Customer_Type'), 10);
	selectedDate: any = {startDate:moment().subtract(750, 'days'),endDate:moment()};
	ranges: any = {
		'Today': [moment(), moment()],
		'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
		'Last 7 Days': [moment().subtract(6, 'days'), moment()],
		'Last 30 Days': [moment().subtract(29, 'days'), moment()]
	}
	locale: any = {
		applyLabel: 'Select', 
		format: 'MM/DD/YYYY',
		daysOfWeek: ["Su","Mo","Tu","We","Th","Fr","Sa"],
		monthNames: ["January","February","March","April","May","June",
		"July","August","September","October","November","December"],
	}
	public searchForm: FormGroup;
	public isSearching:boolean = false;
	public isFetchDetails:boolean = false;
	public people:any[] = [];
	public selected:boolean[]=[false];
	public chatlog = [];
	public customerTickets = [];
	public agentProfiles = [];
	public showDetails:boolean = false;
	public userDetail:  any = {};
	public showLog:boolean = false;
	public showTickets:boolean = false;
	public isAdvSearch:boolean = false;
	public isAddTag:boolean = false;
	public isAddingTag:boolean = false;
	public pageNo = 1;
	public pages = 1;
	public offset = "36";
	public include = [];
	public exclude = [];
	public includeAdd = [];
	public relation = "or";
	public tagsList = [];
	public tagsListAll = [];
	public tagsListCopy = [];
	country: any = (data as any).default;
	@ViewChild('chatBox') private chatBox: ElementRef;
	@ViewChild("includeDiv") private includeDiv: ElementRef;
	@ViewChild("excludeDiv") private excludeDiv: ElementRef;
	@ViewChild("addTagDiv") private addTagDiv: ElementRef;


	constructor(private dataService: ServicesService, private _fb:FormBuilder, private sanitizer: DomSanitizer) { }

	ngOnInit(): void {
		this.searchForm = this._fb.group({
			'from_to_date': [this.selectedDate],
			'email': [''],
			'phone': [''],
			'keyword': [''],
			'BotID': [this.botID]
		});
		this.getTagList();
	}

	searchPeople(form){
		form.value.date_from= moment(form.value.from_to_date.startDate).format('YYYY-MM-DD');
		form.value.date_to = moment(form.value.from_to_date.endDate).format('YYYY-MM-DD');
		form.value.time_offset = moment(form.value.from_to_date.startDate).format('ZZ');
		form.value.page_no = this.pageNo;
		form.value.offset = this.offset;
		form.value.include = this.include;
		form.value.exclude = this.exclude;
		form.value.relation = this.relation;
		this.isSearching = true;
		this.dataService.getFilterData(form.value).subscribe(
			res=>{
				if(res.status_code==200){
					this.people = res.data;
					this.pages = res.no_of_pages;
					if(this.people.length==0){
						showNotification('warning','<span>No people found!</span>');
					}
				} else if(res.status_code==401){
					this.dataService.logout();
				} else {
					showNotification('warning',res.status);
				}
				this.isSearching = false;
			}, error=>{
				console.log(error);
				this.isSearching = false;
				showNotification('danger',error);
			})
	}

	customerDetails(i,cid){
		this.selected[i] = true;
		this.chatlog = [];
		this.isFetchDetails = true;
		this.dataService.getPeopleDetails({
			"CustomerID":cid,
			"BotID":this.botID
		}).subscribe(
		res=>{
			if(res.status_code==200){
				this.showLog = true;
				this.showTickets = true;
				this.showDetails = true;
				this.userDetail = res.data.CustomerDetails;
				this.customerTickets = res.data.CustomerTickets;
				this.chatlog = res.data.CustomerLog;
				this.agentProfiles = res.data.agent_profiles;
				this.includeAdd = [];
				for(let i in this.tagsListCopy){
					this.removeSelected(this.tagsListCopy[i].tag_id);
				}
				for(let i in res.data.CustomerTags){
					this.includeAdd.push(res.data.CustomerTags[i].tag_id);
					this.addSelected(res.data.CustomerTags[i].tag_id);
				}
				// this.chatlog = this.log; //test data
				// this.scrollToBottom();
			} else {
				showNotification('warning',res.status);
			}
			this.selected[i] = false;
			this.isFetchDetails = false;
		},
		error=>{
			console.log(error);
			this.selected[i] = false;
			this.isFetchDetails = false;
			showNotification('danger',error);
		})
	}

	ticketDetails(i,ticketid){
		this.chatlog = [];
		this.isFetchDetails = true;
		this.selected[i] = true;
		this.dataService.getTicketDetails({
			"TicketID":ticketid,
			"BotID":this.botID
		}).subscribe(
		res=>{
			if(res.status_code==200){
				// this.ticketDetail = res.data.TicketDetails;
				this.showLog = true;
				this.showTickets = true;
				this.userDetail = res.data.CustomerDetails[0];
				this.customerTickets = res.data.CustomerTickets;
				this.chatlog = res.data.CustomerLog;

				// this.scrollToBottom();
			} else {
				showNotification('warning',res.status);
			}
			this.selected[i] = false;
			this.isFetchDetails = false;
		},
		error=>{
			console.log(error);
			this.selected[i] = false;
			this.isFetchDetails = false;
			showNotification('danger',error);
		})
	}

	scrollToBottom(): void {
		if(this.chatlog.length>0){
			setTimeout (() => {
				this.chatBox.nativeElement.scrollTop = this.chatBox.nativeElement.scrollHeight;
			}, 1000);
		}
	}

	counter() {
		return new Array(this.pages);
	}

	seperateString(string){
		var splitted = string.split("_$_"); 
		return splitted
	}

	getCountryCode(country){
		let place = this.country.filter(ae=>ae.name==country);
		if(place.length==1){
			return place[0].code;
		} else{ return "" }
	}

	getTagList(){
		this.dataService.getTags({
			bot_id:this.botID,
			AgentID:this.customerID,
			AgentType:this.customerType
		}).subscribe(
		(res)=>{
			if(res.status_code==200){
				this.tagsListAll = res.data;
				for(let i in res.data){
					if(res.data[i].is_active){
						this.tagsList.push(res.data[i]);
					}
				}
				for(let i in this.tagsList){
					this.tagsList[i].selected = false;
				}
				this.tagsList.forEach(val => this.tagsListCopy.push(Object.assign({}, val)));
			}
			else {
				showNotification('danger',res.status);
			}
		},
		(error)=>{
			console.log(error);
			showNotification('danger',error);
		})
	}

	moveTag(type,tag_id){
		if(type=='include'){
			this.include.push(tag_id);
		} else {
			this.exclude.push(tag_id);
		}
		for (let i in this.tagsList){
			if(this.tagsList[i].tag_id==tag_id){
				this.tagsList[i].selected = true;
			}
		}
	}

	getTagName(tag_id){
		return this.tagsListAll.filter(ae=>ae.tag_id==tag_id)[0].tag_name;
	}

	getTagColor(tag_id){
		return this.tagsListAll.filter(ae=>ae.tag_id==tag_id)[0].color;
	}

	isTagActive(tag_id){
		return this.tagsListAll.filter(ae=>ae.tag_id==tag_id)[0].is_active;
	}

	removeTag(type,tag_id){
		if(type=='include'){
			this.include = this.include.filter(ae=>ae!=tag_id);
			this.includeDiv.nativeElement.value = "null";
		} else {
			this.exclude = this.exclude.filter(ae=>ae!=tag_id);
			this.excludeDiv.nativeElement.value = "null";
		}
		for (let i in this.tagsList){
			if(this.tagsList[i].tag_id==tag_id){
				this.tagsList[i].selected = false;
			}
		}
	}

	moveAddTag(tag_id){
		this.includeAdd.push(tag_id);
		this.addSelected(tag_id);
		
	}

	addSelected(tag_id){
		for (let i in this.tagsListCopy){
			if(this.tagsListCopy[i].tag_id==tag_id){
				this.tagsListCopy[i].selected = true;
			}
		}
	}

	removeTagAdd(tag_id){
		this.includeAdd = this.includeAdd.filter(ae=>ae!=tag_id);
		this.removeSelected(tag_id);
	}

	removeSelected(tag_id){
		for (let i in this.tagsListCopy){
			if(this.tagsListCopy[i].tag_id==tag_id){
				this.tagsListCopy[i].selected = false;
			}
		}
	}

	saveTag(tags){
		this.isAddingTag = true;
		this.dataService.addTag({
			BotID: this.botID,
			UserID: this.userDetail.UserID,
			tag_ids: tags,
			agent_id: this.customerID,
			// addTag: this.customerID
		}).subscribe(
		res=>{
			if(res.status_code==200){
				showNotification('success','Tagged Successfully!');
			}
			else {
				showNotification('danger',res.status);
			}
			this.isAddingTag = false;
		},
		error=>{
			console.log(error);
			this.isAddingTag = false;
			showNotification('danger',error);
		})
	}

	getSafeUrl(url) {
		return this.sanitizer.bypassSecurityTrustResourceUrl(url)
	}

	changeIframe(url) {
		return this.getSafeUrl(url);
	}

	getLAImage(adent_id,attr){
		let la_detail = [];
		la_detail = this.agentProfiles.filter(ae=>ae.agent_id==adent_id);
		if(la_detail.length>0){
			if(attr == 'img'){
				return this.agentProfiles[0].image_url;
			} else {
				return this.agentProfiles[0].agent_name;
			}
		} else {
			if(attr == 'img'){
				return '/assets/img/default-avatar.png';
			} else {
				return 'Unknown Agent';
			}
		}
	}

	getTagDetails(id,attr){
		let tags = [];
		tags = this.tagsListAll.filter(ae=>ae.tag_id==id);
		if(tags.length>0){
			if(attr == 'name'){
				return tags[0].tag_name;
			} else {
				return tags[0].color;
			}
		} else {
			if(attr == 'name'){
				return 'No tag found';
			} else {
				return '#ccc';
			}
		}
	}


}
