import { Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Http, Response, RequestOptions, Request, RequestMethod, Headers } from '@angular/http';
import { environment } from '../../../environments/environment';
import 'rxjs/add/operator/map';

import { throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class AuthService {

	private get_url = environment.apiUrl;
	private canvas_url = environment.canvasUrl;

	constructor(private router: Router, private _http: Http) { }

	public isLoggedIn() {
		return localStorage.getItem('ACCESS_TOKEN') !== null;
	}

	public logout() {
		// localStorage.removeItem('ACCESS_TOKEN');
		localStorage.clear();
		this.router.navigate(['login']);
	}

	public login(userInfo) {
		let headers = new Headers({ 'Content-Type': 'application/json' });
		let options = new RequestOptions({ headers: headers });
		let body = JSON.stringify(userInfo);
		return this._http.post(this.get_url + '/login/', body, options)
			.pipe(map((res: Response) => res.json()), retry(2));
	}

	public forgetSendMail(userInfo) {
		let headers = new Headers({ 'Content-Type': 'application/json' });
		let options = new RequestOptions({ headers: headers });
		let body = JSON.stringify(userInfo);
		return this._http.post(this.get_url + '/forget_password_email/', body, options)
			.pipe(map((res: Response) => res.json()), retry(2));
	}

	public confirmMail(data) {
		let headers = new Headers({ 'Content-Type': 'application/json' });
		let options = new RequestOptions({ headers: headers });
		let body = JSON.stringify(data);
		return this._http.post(this.get_url + '/confirm_password/', body, options)
			.pipe(map((res: Response) => res.json()), retry(2));
	}

	public resetPassword(data) {
		let headers = new Headers({ 'Content-Type': 'application/json' });
		let options = new RequestOptions({ headers: headers });
		let body = JSON.stringify(data);
		return this._http.post(this.get_url + '/save_password/', body, options)
			.pipe(map((res: Response) => res.json()), retry(2));
	}

	getTokenFromMail(mail) {
		return this._http.get(`${this.get_url}/get_sso_token/?sso_email=${mail}`)
			.pipe(map((res: Response) => res.json()), retry(2));
	}

	getCustomerDetails(token) {
		return this._http.get(`${this.get_url}/sso_login/?sso_token=${token}`)
			.pipe(map((res: Response) => res.json()), retry(2));
	}
}
