<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card card-height">
					<div class="card-body">
						<div class="row">
							<div class="col-xs-6">
								<button type="button" class="btn btn-gradient btn-sm add-tags" (click)="addMemoryModal.show();isEdit=false;clearAddMem();"><i *ngIf="!isAdding" class="fa fa-plus"></i>&nbsp;Add Variable</button>
							</div>
							<div class="col-xs-6">
								<select class="form-control w30bots" (change)="changeBot(bot.value)" #bot>
									<option *ngFor="let item of botList" value="{{item.BotID}}" [selected]="item.BotID==botID">{{item.BotName}}</option>
								</select>
							</div>
						</div>
						<div class="row">
							<div class="tags-list" *ngIf="memVarList?.length>0">
								<p>Memory Variables</p>
								<div class="colors" *ngFor="let item of memVarList">
									<span>{{item.display_name}}</span>
									<div class="actions">
										<a *ngIf="item.bot_id!=-1" (click)="editMemory(item)" ><i class="fa fa-pencil-square-o blue" aria-hidden="true"></i></a>
										<!-- <a (click)="deleteMemory(item.variable_id,false);">
											<i class="fa fa-times red" aria-hidden="true"></i>
										</a> -->
									</div>
								</div>
							</div>
							<div class="empty-container" *ngIf="memVarList?.length==0">
								No Tags
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>


<div bsModal #addMemoryModal="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1"
role="dialog" aria-labelledby="dialog-auto-name">
<div class="modal-dialog modal-md">
	<div class="modal-content">
		<form  [formGroup]="addMemory" (ngSubmit)="submitMem(addMemory)" ngNativeValidate>
			<div class="modal-header">
				<h5 class="modal-title"><span *ngIf="!isEdit">Add</span><span *ngIf="isEdit">Edit</span> Variable</h5>
				<button type="button" class="close" (click)="addMemoryModal.hide();clearAddMem();isEdit=false;">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="row addform">
					<div class="col-xs-12">
						<div class="form-group">
							<label>Variable name</label>
							<input class="form-control" placeholder="Name" type="text" formControlName="display_name" required>
							<div class="error small mt10" *ngIf="isUniqueMem(addMemory.value.display_name)">Variable name must be unique</div>
							<div class="info small mt10" *ngIf="!checkMemRegex(addMemory.value.display_name) && addMemory.value.display_name">Atleast 3 characters.<br>First character must be an Alphabet.</div>
							<!-- <div class="error" *ngIf="isUniqueName(addMemory.value.tag_name)">Tag name must be unique</div> -->
						</div>
					</div>
					<div class="col-xs-6">
						<div class="form-group">
							<label>Type</label>
							<select class="form-control" type="text" formControlName="variable_type" [attr.disabled]="isEdit ? '' : null">
								<option *ngFor="let item of memVarTypes" [value]="item.value">{{item.name}}</option>
							</select>
						</div>
					</div>
					<div class="col-xs-6">
						<div class="form-group">
							<label>Expiry <small>(in sec)</small></label>
							<input class="form-control" placeholder="Expiry" type="number" min="1" formControlName="expiry" required>
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-secondary" (click)="addMemoryModal.hide();clearAddMem();isEdit=false;"><i class="fa fa-times" aria-hidden="true"></i>Cancel</button>
				<button type="submit" class="btn btn-gradient">
					<i *ngIf="isAddingMemVar" class="fa fa-spinner fa-spin fa-pulse"></i>
					<i *ngIf="!isAddingMemVar" class="fa fa-check"></i>
					<span *ngIf="!isEdit">Save</span><span *ngIf="isEdit">Edit</span>
				</button>
				<!-- [attr.disabled]="!isUniqueName(addTag.value.tag_name) ? null : '' " -->
			</div>
		</form>
		
	</div>
</div>
</div>