import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormsModule, FormControl } from '@angular/forms';
import { AuthService } from './auth.service'
import { Router, ActivatedRoute } from '@angular/router';
import { showNotification } from '../../services/notification';



@Component({
	selector: 'app-account',
	templateUrl: './account.component.html',
	styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {
	public loginForm: FormGroup;
	public forgetForm: FormGroup;
	public resetPwdForm: FormGroup;
	public approved = false;
	public isLogging: boolean = false;
	public isLoginForm: boolean = true;
	public isForgetForm: boolean = false;
	public isPasswordForm: boolean = false;
	public isforgetSubmitted: boolean = false;
	public isCheckMail: boolean = false;
	public sendmail: boolean = false;
	public isErrorDiv: boolean = false;
	public password_change_success: boolean = false;
	public showPassword: boolean = false;
	public confirmed_password: string;
	public forget_email: string;
	public url: string;
	constructor(private router: Router, private route: ActivatedRoute, private formBuilder: FormBuilder, private authService: AuthService) { }

	ngOnInit(): void {

		this.route.data.subscribe(v => { this.url = v.page });

		this.loginForm = this.formBuilder.group({
			email: ['', [Validators.required]],
			password: ['', Validators.required]
		});

		this.forgetForm = this.formBuilder.group({
			emailid: ['', [Validators.required, Validators.email]]
		});

		this.resetPwdForm = this.formBuilder.group({
			password: ['', [Validators.required, Validators.pattern('((?=.*[a-z])(?=.*[A-Z]).{8,30})')]]
		});

		if (this.url == 'login') {
			this.isLoginForm = true;
			this.isForgetForm = false;
			this.isPasswordForm = false;
		} else if (this.url == 'passwordrest') {
			this.isLoginForm = false;
			this.isForgetForm = false;
			this.isPasswordForm = false;
			this.route.queryParams.subscribe(params => {
				let token = params['token'];
				if (token) {
					this.authService.confirmMail({ "token": token }).subscribe(
						data => {
							if (data == false) {
								this.isLoginForm = false;
								this.isForgetForm = true;
								this.isPasswordForm = false;
								this.isErrorDiv = true;
							} else {
								this.isLoginForm = false;
								this.isForgetForm = false;
								this.isPasswordForm = true;
								this.forget_email = data;
								showNotification('success', '<span>Email confirmed successfully! Change password to continue.</span>');
							}
						},
						error => {
							console.log(error);
						})
				} else {
					showNotification('error', '<span>Invalid Token</span>');
				};
			});
		}
	}

	loginFn() {
		this.isLogging = true;
		// localStorage.setItem('ACCESS_TOKEN', '123456');
		// this.router.navigateByUrl('/home');
		this.authService.login(this.loginForm.value).subscribe((response) => {
			if (response.success == true) {
				this.approved = true;
				localStorage.setItem('ACCESS_TOKEN', response.data['token']);
				localStorage.setItem('show_admin_management_page', response.data['permissions'].show_admin_management_page);
				localStorage.setItem('show_contacts_page', response.data['permissions'].show_contacts_page);
				localStorage.setItem('show_tickets_page', response.data['permissions'].show_tickets_page);
				localStorage.setItem('show_reports_page', response.data['permissions'].show_reports_page);
				localStorage.setItem('show_spectate_page', response.data['permissions'].show_spectate_page);
				localStorage.setItem('agent_type', response.data['details'].type);
				localStorage.setItem('Customer_ID', response.data['customer_id']);
				localStorage.setItem('agent_imgurl', response.data['details'].image_url);
				localStorage.setItem('Customer_Type', response.data['details'].type);
				localStorage.setItem('Customer_Name', response.data['Customer_Name']);
				localStorage.setItem('Customer_Email', response.data['emailid']);
				this.router.navigateByUrl('/home');
			} else {
				showNotification('warning', response.message);
			}
			this.isLogging = false;
		},
			(error) => {
				console.log(error);
				this.isLogging = false;
				showNotification('danger', error);
			})
	}

	forgetFn = () => {
		this.isforgetSubmitted = true;
		if (this.forgetForm.valid) {
			this.authService.forgetSendMail(this.forgetForm.value).subscribe((data) => {
				if (data == true) {
					this.isCheckMail = true;
					this.sendmail = true;
					this.isforgetSubmitted = false;
					this.isPasswordForm = false;
					this.isLoginForm = false;
					this.isForgetForm = true;
					// this.router.navigateByUrl('/login');
				}
				else alert("Try Again");
			},
				(error) => {
					console.log(error);
				})
		}
	}

	resetPwdFn(form) {
		form.value.emailid = this.forget_email;
		this.authService.resetPassword(form.value).subscribe((data) => {
			if (data == true) {
				this.isPasswordForm = false;
				this.isLoginForm = true;
				this.isForgetForm = false;
				this.password_change_success = true;
				showNotification('success', '<span>Password Changed Successfully!');
			}
			else alert("Try Again");
		},
			(error) => {
				console.log(error);
			})
	}
}
