import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-filter-rule',
  templateUrl: './filter-rule.component.html',
  styleUrls: ['./filter-rule.component.scss']
})
export class FilterRuleComponent implements OnInit {

  @Input() botID: string;
  @Input() memoryVars: any = [];
  @Input() filterData: any;
  addRulesForm: FormGroup;
  customerID: number = parseInt(localStorage.getItem('Customer_ID'), 10);
  customerType: number = parseInt(localStorage.getItem('Customer_Type'), 10);
  isEdit = false;
  isAdding = false;
  operations = ['<', '<=', '>', '>=', '==', '!=', 'in', '!in'];
  errorMessage = '';

  constructor(public modalRef: BsModalRef, private fb: FormBuilder, private modalService: BsModalService) { }


  ngOnInit(): void {
    this.resetForm(this.filterData);
  }

  closeModal(data?) {
    if (data) this.modalService.setDismissReason(data);
    this.modalRef.hide();
  }

  resetForm(data?: any): void {
    this.isEdit = data ? true : false;
    this.addRulesForm = this.fb.group({
      'bot_id': new FormControl(this.botID || null),
      'condition': new FormArray([]),
    });
    data?.condition.forEach(ao => {
      (this.addRulesForm.get('condition') as FormArray).push(
        this.fb.group({
          'mem_var': [ao?.mem_var, [Validators.required]],
          'operator': [ao?.operator, [Validators.required]],
          'value': [ao?.value, [Validators.required]],
          'next_operator': [ao?.next_operator || null]
        }));
    });
    if (!data) this.addCondition();

  }

  submitRule(form: FormGroup) {
    if (form.valid) {
      this.closeModal(form.value);
      this.errorMessage = '';
    } else {
      this.errorMessage = 'Fill all fields!';
    }
  }


  get conditions(): FormArray {
    return this.addRulesForm.get("condition") as FormArray
  }

  newCondition(): FormGroup {
    return this.fb.group({
      'mem_var': [null, [Validators.required]],
      'operator': [null, [Validators.required]],
      'value': [null, [Validators.required]],
      'next_operator': [null]
    })
  }

  addCondition() {
    this.conditions.push(this.newCondition());
  }

  removeCondition(i: number) {
    this.conditions.removeAt(i);
  }

}
