import { Component, OnInit, ElementRef, ViewChild} from '@angular/core';
import { showNotification } from '../services/notification';
import { ServicesService } from '../services/services.service';
import moment from 'moment';
import * as data from '../../assets/js/countrycodes.json';
import { trigger, transition, animate, style, state, group } from '@angular/animations';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
	selector: 'app-tickets',
	templateUrl: './tickets.component.html',
	styleUrls: ['./tickets.component.scss','../user/user.component.scss'],
	animations: [
	trigger('slideInOut', [
		transition(':enter', [
			style({transform: 'translateX(-100%)'}),
			animate('200ms ease-in', style({transform: 'translateX(0%)'}))
			]),
		transition(':leave', [
			animate('200ms ease-out', style({transform: 'translateX(-100%)'}))
			])
		]),
	trigger('slideDown', [
		state('in', style({height: '*', opacity: 0})),
		transition(':leave', [
			style({height: '*', opacity: 1}),

			group([
				animate(50, style({height: 0})),
				animate('100ms ease-in-out', style({'opacity': '0'}))
				])

			]),
		transition(':enter', [
			style({height: '0', opacity: 0}),

			group([
				animate(50, style({height: '*'})),
				animate('200ms ease-in-out', style({'opacity': '1'}))
				])

			])
		])
	]
})
export class TicketsComponent implements OnInit {

	public botID:number = parseInt(localStorage.getItem('botID'), 10);
	public ticketsCounts = {
		"openTicketsCount" : 0,
		"inprogressTicketsCount" : 0,
		"closedTicketsCount" : 0,
		"channelDetails" : 0
	}
	public channelId = 'all';
	public selType = '';
	public pageNo = 1;
	public offset = "16";
	public selectedTicketId:number;
	public pages = 1;
	public tickets = [];
	public agentProfiles = [];
	public isFetching:boolean = false;
	public isFetchDetails:boolean = false;
	public showLog:boolean = false;
	public showDetails:boolean = false;
	public showTickets:boolean = false;
	public isOpen:boolean = false;
	public isProgress:boolean = false;
	public isClosed:boolean = false;
	public isSearched:boolean = false;
	public isEditStatus:boolean = false;
	public selected:boolean[]=[false];
	public chatlog = [];
	public customerTickets = [];
	public tagList = [];
	public channels = [];
	tagsList = [];
	customerID:number = parseInt(localStorage.getItem('Customer_ID'), 10);
	customerType:number = parseInt(localStorage.getItem('Customer_Type'), 10);
	country: any = (data as any).default;
	userDetail:  any = {};
	ticketDetail:  any = {};
	selected_date: any = {startDate:moment().subtract(700, 'days'),endDate:moment()};
	ranges: any = {
		'Today': [moment(), moment()],
		'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
		'Last 7 Days': [moment().subtract(6, 'days'), moment()],
		'Last 30 Days': [moment().subtract(29, 'days'), moment()]
	}
	locale: any = {
		applyLabel: 'Select', 
		format: 'MM/DD/YYYY',
		daysOfWeek: ["Su","Mo","Tu","We","Th","Fr","Sa"],
		monthNames: ["January","February","March","April","May","June",
		"July","August","September","October","November","December"],
	}
	@ViewChild('chatBox') private chatBox: ElementRef;

	constructor(private dataService: ServicesService,  private sanitizer: DomSanitizer) { }

	ngOnInit(): void {
		this.getTicketsData();
		this.getChannels();
		this.getAllTags();
	}

	getTicketsData(){
		if(this.selected_date.startDate !=null && this.selected_date.endDate !=null){
			this.dataService.getTotalTicketDetails({
				BotID:this.botID,
				from_date:moment(this.selected_date.startDate).format('YYYY-MM-DD'),
				to_date:moment(this.selected_date.endDate).format('YYYY-MM-DD'),
				time_offset:moment(this.selected_date.startDate).format('ZZ'),
				channel_id: this.channelId
			}).subscribe(
			data=>{
				if(data.status_code==200){
					this.ticketsCounts = {
						"openTicketsCount" : data.data.openTicketsCount,
						"inprogressTicketsCount" : data.data.inprogressTicketsCount,
						"closedTicketsCount" : data.data.closedTicketsCount,
						"channelDetails" : data.data.channelDetails,
					}
					this.tickets = [];
					this.isSearched = false;
					this.pages = 1;
					this.offset = '16';
				} else {
					showNotification('warning',data.status);
				}
			},
			error=>{
				console.log(error);
				showNotification('danger',error);
			})
		}
	}

	getTicketCat(){
		this.isFetching = true;
		this.dataService.getTicketCategoryList({
			"BotID":this.botID,
			"filterType" : this.selType,
			"page_no": this.pageNo,
			"offset": parseInt(this.offset),
			"from_date":moment(this.selected_date.startDate).format('YYYY-MM-DD'),
			"to_date":moment(this.selected_date.endDate).format('YYYY-MM-DD'),
			"time_offset":moment(this.selected_date.startDate).format('ZZ'),
			"channel_id": this.channelId
		}).subscribe(
		data=>{
			if(data.status_code==200){
				this.tickets = data.data;
				this.isSearched = true;
				this.pages = data.no_of_pages;
			} else {
				showNotification('warning',data.status);
			}
			this.isFetching = false;
		},
		error=>{
			this.isFetching = false;
			console.log(error);
			showNotification('danger',error);
		})
	}

	ticketDetails(i,ticketid){
		this.chatlog = [];
		this.isFetchDetails = true;
		this.selectedTicketId = ticketid;
		this.selected[i] = true;
		this.dataService.getTicketDetails({
			"TicketID":ticketid,
			"BotID":this.botID
		}).subscribe(
		res=>{
			if(res.status_code==200){
				this.ticketDetail = res.data.TicketDetails;
				this.showLog = true;
				this.showTickets = true;
				this.userDetail = res.data.CustomerDetails[0];
				this.customerTickets = res.data.CustomerTickets;
				this.chatlog = res.data.CustomerLog; //test data
				this.agentProfiles = res.data.agent_profiles;
				// this.scrollToBottom();
				this.tickets = [];
			} else {
				showNotification('warning',res.status);
			}
			this.selected[i] = false;
			this.isFetchDetails = false;
		},
		error=>{
			console.log(error);
			this.selected[i] = false;
			this.isFetchDetails = false;
			showNotification('danger',error);
		})
	}

	scrollToBottom(): void {
		if(this.chatlog.length>0){
			setTimeout (() => {
				this.chatBox.nativeElement.scrollTop = this.chatBox.nativeElement.scrollHeight;
			}, 50);
		}
	}

	getChannels(){
		this.dataService.getChannels().subscribe(
			data=>{
				if(data.status_code==200){
					this.channels = data.data;
				} else {
					showNotification('warning',data.status);
				}
			},
			error=>{
				console.log(error);
				showNotification('danger',error);
			})
	}

	counter() {
		return new Array(this.pages);
	}

	seperateString(string){
		var splitted = string.split("_$_"); 
		return splitted
	}

	getCountryCode(country){
		let place = this.country.filter(ae=>ae.name==country);
		if(place.length==1){
			return place[0].code;
		} else{ return "" }
	}

	tickCat(){
		setTimeout (() => {
			this.getTicketCat();
		}, 500);
	}

	changeTicketStatus(event){
		this.dataService.changeTicketStatus({
			TicketID: this.selectedTicketId,
			status:event.target.value
		}).subscribe(
		data=>{
			if(data.status_code==200){
				showNotification('success','<span>Ticket status changed successfully!</span>');
				this.isEditStatus = false;
				this.ticketDetail.status = event.target.value;
			} else {
				showNotification('warning',data.status);
			}
		},
		error=>{
			console.log(error);
			showNotification('danger',error);
		})
	}

	getSafeUrl(url) {
		return this.sanitizer.bypassSecurityTrustResourceUrl(url)
	}

	changeIframe(url) {
		return this.getSafeUrl(url);
	}

	getLAImage(adent_id,attr){
	let la_detail = [];
	la_detail = this.agentProfiles.filter(ae=>ae.agent_id==adent_id);
	if(la_detail.length>0){
		if(attr == 'img'){
			return this.agentProfiles[0].image_url;
		} else {
			return this.agentProfiles[0].agent_name;
		}
	} else {
		if(attr == 'img'){
			return '/assets/img/default-avatar.png';
		} else {
			return 'Unknown Agent';
		}
	}
}


getAllTags(){
	let data = {
		bot_id: this.botID,
		AgentID:this.customerID,
		AgentType:this.customerType
	}
	this.dataService.getTagsList(data).subscribe(
		res=>{
			if(res.status_code==200){
				this.tagsList = [];
				for(let i in res.data){
					if(res.data[i].is_active){
						this.tagsList.push({
							id : res.data[i].tag_id,
							itemName : res.data[i].tag_name,
							color: res.data[i].color
						})
					}
				}
			}
		},
		error=>{
			console.log(error);
			showNotification('warning',"Oops! Error while loading Tag List!");
		})
}

getTagDetails(id,attr){
		let tags = [];
		tags = this.tagsList.filter(ae=>ae.id==id);
		if(tags.length>0){
			if(attr == 'name'){
				return tags[0].itemName;
			} else {
				return tags[0].color;
			}
		} else {
			if(attr == 'name'){
				return 'No tag found';
			} else {
				return '#ccc';
			}
		}
	}

}
