<div class="main-content chat-main">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12 row bdb">
				<div class="col-md-3">
					<div class="d-flex">
						<div class="input-group no-border sel-bot mb-2 w-100">
							<select class="form-select form-control" [(ngModel)]="selectedDeptID"
								(change)="changeDepartment()">
								<option [value]="null" disabled selected hidden>Department Name</option>
								<option *ngFor="let item of departments" value="{{item.DepartmentID}}">
									{{item.DepartmentName}}
								</option>
							</select>
							<div class="input-group-append">
								<div class="input-group-text">
									<i class="fa fa-chevron-circle-down" aria-hidden="true"></i>
								</div>
							</div>
						</div>
						<div class="dept-info">
							<a [tooltip]="'Access to tickets is limited to requested departments only!'"><i
									class="fa fa-info"></i></a>
						</div>
					</div>
					<!-- <select class="form-select form-control" [(ngModel)]="selectedDeptID" (change)="changeDepartment()">
						<option [value]="null" disabled selected hidden>Department Name</option>
						<option *ngFor="let item of departments" value="{{item.DepartmentID}}">{{item.DepartmentName}}
						</option>
					</select> -->
				</div>
				<div class="col-md-3" *ngIf="selected_user">
					<h6 class="name mb-0">
						{{getUserSpec('name',
						selected_user) ?
						getUserSpec('name', selected_user)
						: ((getUserSpec('email',
						selected_user) ?
						getUserSpec('email',selected_user)
						: getUserSpec('userid',
						selected_user)))}}
						<!-- {{selected_user}}
						<pre>{{seluserList|json}}</pre> -->
					</h6>
					<small class="text-success" *ngIf="checkIfOnline(selected_user)">Online</small>
				</div>
				<!-- <div [ngClass]="{'col-md-offset-3' : !selected_user}" class="col-md-3 form-group">
					<div class="form-group tyn-pill">
						<div class="form-control-wrap">
							<div class="form-control-icon start">
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
									class="bi bi-search" viewBox="0 0 16 16">
									<path
										d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z">
									</path>
								</svg>
							</div>
							<input type="text" class="form-control form-control-solid" id="search"
								placeholder="Search contact / chat">
						</div>
					</div>
				</div> -->
				<div class="col-md-3 text-center pd-t-10">
					<button *ngIf="selected_user && [5, 9].includes(this.getUserSpec('channelid', this.selected_user))"
						class="btn btn-green btn-sm" (click)="openTemplateModal()">Send
						Template</button>
				</div>
				<div class="col-md-3 row">
					<div class="col-md-12 form-group d-flex align-items-center"
						*ngIf="chatlog?.length>0 && searchFilter == 'progress'">
						<div class="d-flex align-items-center w-100">
							<label class="mr15">Manage&nbsp;Ticket</label>
							<div class="input-group no-border sel-bot mb-2">
								<select class="form-control" [(ngModel)]="selectedStatus"
									(change)="closeChat(selectElement.value, false)" #selectElement>
									<option [value]="null" disabled selected hidden>Status</option>
									<option value="handover">Handover</option>
									<option value="resolved">Resolve</option>
								</select>
								<div class="input-group-append">
									<div class="input-group-text">
										<i class="fa fa-chevron-circle-down" aria-hidden="true"></i>
									</div>
								</div>
							</div>
						</div>
						<div class="ml15">
							<a (click)="holdChatModal.show()" tooltip="Hold Chat" placement="auto"
								class="d-flex align-items-center"><i class="fa fa-pause text-warning"></i> Hold</a>
						</div>
					</div>
					<div class="col-md-12 row agent-dept" *ngIf="selectedStatus == 'handover'">
						<div class="text-right">
							<a class="status-close" (click)="selectedStatus=null"><i
									class="fa fa-times text-danger"></i></a>
						</div>
						<div class="col-md-12 filters d-flex justify-content-center">
							<div class="radio-check">
								<input id="progress" [(ngModel)]="selectedStatusType" type="radio" name="agent-dept"
									[value]="'agent'">
								<label for="progress">Agent</label>

								<input id="open" [(ngModel)]="selectedStatusType" type="radio" name="agent-dept"
									[value]="'department'">
								<label for="open">Department</label>
							</div>
						</div>
						<div class="col-md-12 form-group d-flex align-items-center"
							*ngIf="selectedStatusType == 'agent'">
							<div class="d-flex align-items-center w-100">
								<label class="mr15">Select&nbsp;Agent</label>
								<select class="form-control" (change)="handoverChat(false, selectAgent.value)"
									#selectAgent>
									<option [value]="null" disabled selected hidden>Select Agent</option>
									<option *ngFor="let item of agents"
										[value]="item.liveagent_id +'_'+item.department_id">{{item.full_name}}
										&nbsp;[{{item.department_name}}]
									</option>
								</select>
							</div>
						</div>
						<div class="col-md-12 form-group d-flex align-items-center"
							*ngIf="selectedStatusType == 'department'">
							<div class="d-flex align-items-center w-100">
								<label class="mr15">Select&nbsp;Department</label>
								<select class="form-control"
									(change)="handoverChat(false, null, selectDepartment.value)" #selectDepartment>
									<option [value]="null" disabled selected hidden>Department</option>
									<option *ngFor="let item of listHandoverDepartments()" [value]="item.DepartmentID">
										{{item.DepartmentName}}
									</option>
								</select>
							</div>
						</div>
					</div>

				</div>

			</div>
			<div class="col-md-3 col-xs-3">
				<div class="listing">
					<button class="link dropdown-toggle pull-right filter" [ngClass]="{ 'bg-filterOn': filterOn }"
						(click)="checkFilters();">
						<i class="fa fa-filter" style="font-size:20px"></i>
					</button>
					<button *ngIf="usersList.length && !isAssigned" class="link dropdown-toggle pull-right filter me-2"
						(click)="checkClearBox();">
						<i class="fa fa-times-circle" style="font-size:20px"></i>
					</button>

					<tabset type="pills" #tabset>
						<tab (selectTab)="isAssigned=true;clearFIlters();" [active]="isAssigned">
							<ng-container *ngTemplateOutlet="showFilter && isAssigned ? filterBox : ''"></ng-container>
							<ng-template tabHeading>
								<button class="btn btn-sm d-flex" [ngClass]="{'btn-gradient':isAssigned}"
									(click)="searchFilter = 'progress';">Engaged
									<span class="list-count"
										*ngIf="(searchFilter == 'progress') ? seluserList.length : ((searchFilter == 'closed') ? selClosedUserList.length : ((searchFilter == 'open') ? selHandedOverUsersList.length : false))">
										{{(searchFilter == 'progress') ? seluserList.length : ((searchFilter ==
										'closed') ? selClosedUserList.length : ((searchFilter == 'handed_over') ?
										selHandedOverUsersList.length : 0))}}
									</span>
								</button>
							</ng-template>
							<small
								*ngIf="(searchFilter == 'progress') ? !seluserList.length : ((searchFilter == 'closed') ? !selClosedUserList.length : ((searchFilter == 'open') ? !selHandedOverUsersList.length : false))"
								class="text-warning m-auto d-block w-100 text-center mt-3">No {{(searchFilter ==
								'progress') ? "Assigned" : ((searchFilter == 'closed') ? "Closed" : ((searchFilter
								==
								'handed_over') ? "Hand Over" : false))}}
								Users
							</small>
							<div class="row">
								<div class="col-md-12 filters">
									<div class="radio-check">
										<input id="progress" [(ngModel)]="searchFilter" type="radio" name="is_prod"
											[value]="'progress'" (change)="filterUsers(searchFilter)">
										<label for="progress">In-Progress</label>

										<input id="handed_over" [(ngModel)]="searchFilter" type="radio" name="is_prod"
											[value]="'handed_over'" (change)="filterUsers(searchFilter)">
										<label for="handed_over">Handed Over</label>

										<input id="closed" [(ngModel)]="searchFilter" type="radio" name="is_prod"
											[value]="'closed'" (change)="filterUsers(searchFilter)">
										<label for="closed">Closed</label>
									</div>
								</div><!-- /.col-lg-6 -->
							</div><!-- /.row -->
							<div class="tyn-aside tyn-aside-base"
								*ngFor="let item of (searchFilter == 'progress') ? seluserList : ((searchFilter == 'closed') ? selClosedUserList : selHandedOverUsersList)">
								<div class="tyn-aside-body" data-simplebar="init"
									(click)="selectSelUser(item.userid, item.is_new)">
									<div class="simplebar-wrapper" style="margin: 0px;">
										<div class="simplebar-height-auto-observer-wrapper">
											<div class="simplebar-height-auto-observer"></div>
										</div>
										<div class="simplebar-mask">
											<div class="simplebar-offset" style="right: 0px; bottom: 0px;">
												<div class="simplebar-content-wrapper" tabindex="0" role="region"
													aria-label="scrollable content"
													style="height: 100%; overflow: hidden scroll;">
													<div class="simplebar-content" style="padding: 0px;">
														<div class="tab-content">
															<div class="tab-pane show active" id="all-chats"
																tabindex="0" role="tabpanel">
																<ul class="tyn-aside-list">
																	<li class="tyn-aside-item js-toggle-main"
																		[class.selected]="item.selected || item.userid == selected_user">
																		<div class="tyn-media-group">
																			<div class="tyn-media">
																				<span>{{getFirstLetter(item.userid)}}
																				</span>
																				<i *ngIf="!item.disconnect"
																					class="fa fa-circle status"></i>
																			</div>
																			<div class="tyn-media-col">
																				<div class="tyn-media-row">
																					<h6 class="name">
																						{{getUserSpec('name',
																						item.userid) ?
																						getUserSpec('name',
																						item.userid)
																						: ((getUserSpec('email',
																						item.userid) ?
																						getUserSpec('email',item.userid)
																						: item.userid))}}
																					</h6>
																				</div>
																				<div class="tyn-media-row has-dot-sap"
																					*ngIf="getUserSpec('phone', item.userid)">
																					<p class="number">
																						{{getUserSpec('phone',
																						item.userid)}}</p>
																				</div>
																			</div>
																			<div class="tyn-media-time">
																				<div class="tyn-media-date"
																					*ngIf="getUserSpec('timestamp', item.userid)">
																					<h6 class="date">
																						{{getUserSpec('timestamp',
																						item.userid)}}</h6>
																				</div>
																				<div class="tyn-media-icons">
																					<img width="20"
																						src="https://canvas.creyoface.com/assets/images/countries/{{getCountryCode(item.userid) | lowercase}}.png">
																					<!-- <i class="fa fa-whatsapp"></i> -->
																					<img width="20"
																						src="/assets/img/channels/{{getUserSpec('channelid',item.userid)}}.png">
																				</div>
																			</div>
																		</div>
																		<div class="d-flex msg-info">
																			<div class="m10">
																				{{getUserSpec('ticket_name',item.userid)}}{{getUserSpec('department',item.userid)}}
																			</div>
																			<div class="float-right d-flex"
																				[tooltip]="'Priority: ' + getUserSpec('ticket_priority', item.userid)">
																				<small class="prior-lines"
																					*ngFor="let _ of [].constructor(5); let i = index"
																					[ngStyle]="{ 'background-color': (i+1) <= getUserSpec('ticket_priority', item.userid) ? getUserSpec('ticket_colour', item.userid) : 'rgb(196, 196, 196)','height': (-4 * (i + 1) * (i + 1)) + 'px'}">
																				</small>

																			</div>
																			<!-- <div class="m10 pd05" [style.background-color]="getUserSpec('ticket_colour',item.userid)">{{getUserSpec('ticket_name',item.userid)}}</div> -->
																		</div>
																	</li>
																</ul>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div><!-- .tyn-aside-body -->
							</div>
						</tab>
						<tab (selectTab)="isAssigned=false;clearFIlters();" [active]="!isAssigned">
							<ng-container *ngTemplateOutlet="showFilter && !isAssigned ? filterBox : ''"></ng-container>
							<ng-container *ngTemplateOutlet="showClearBox ? clearBox : ''"></ng-container>
							<ng-template tabHeading>
								<button class="btn btn-sm d-flex" [ngClass]="{'btn-gradient':!isAssigned}">Not Engaged
									<span class="list-count"
										*ngIf="usersList.length">{{usersList.length}}</span></button>
							</ng-template>
							<small *ngIf="usersList.length==0" class="text-warning w-100 text-center mt-3">No
								users</small>
							<div class="tyn-aside tyn-aside-base" *ngFor="let uid of usersList">
								<div class="tyn-aside-body" data-simplebar="init"
									(click)="selectUser(uid);searchFilter = 'progress';">
									<div class="simplebar-wrapper" style="margin: 0px;">
										<div class="simplebar-height-auto-observer-wrapper">
											<div class="simplebar-height-auto-observer"></div>
										</div>
										<div class="simplebar-mask">
											<div class="simplebar-offset" style="right: 0px; bottom: 0px;">
												<div class="simplebar-content-wrapper" tabindex="0" role="region"
													aria-label="scrollable content"
													style="height: 100%; overflow: hidden scroll;">
													<div class="simplebar-content" style="padding: 0px;">
														<div class="tab-content">
															<div class="tab-pane show active" id="all-chats"
																tabindex="0" role="tabpanel">
																<ul class="tyn-aside-list">
																	<li class="tyn-aside-item js-toggle-main active">
																		<div class="tyn-media-group">
																			<div class="tyn-media">
																				<span>{{getFirstLetter(uid)}}</span>
																				<i
																					class="status fa fa-circle active"></i>
																			</div>
																			<div class="tyn-media-col">
																				<div class="tyn-media-row">
																					<h6 class="name">
																						{{getUserSpec('name', uid) ?
																						getUserSpec('name', uid) :
																						((getUserSpec('email', uid) ?
																						getUserSpec('email',uid) :
																						getUserSpec('userid', uid)))}}
																					</h6>
																				</div>
																				<div class="tyn-media-row has-dot-sap"
																					*ngIf="getUserSpec('phone', uid)">
																					<p class="number">
																						{{getUserSpec('phone', uid)}}
																					</p>
																				</div>
																			</div>
																			<div class="tyn-media-time">
																				<div class="tyn-media-date"
																					*ngIf="uid?.timestamp">
																					<h6 class="date">
																						{{getUserSpec('timestamp',
																						uid)}}</h6>
																				</div>
																				<div class="tyn-media-icons">
																					<img width="20"
																						src="https://canvas.creyoface.com/assets/images/countries/{{getCountryCode(uid) | lowercase}}.png">
																					<!-- <i class="fa fa-whatsapp"></i> -->
																					<img width="20"
																						src="/assets/img/channels/{{getUserSpec('channelid',uid)}}.png">
																				</div>
																			</div>
																		</div>
																		<div class="d-flex msg-info">
																			<div class="m10">
																				{{getUserSpec('ticket_name',uid)}}{{getUserSpec('department',uid)}}
																			</div>
																			<div class="float-right d-flex"
																				[tooltip]="'Priority: ' + getUserSpec('ticket_priority', uid)">
																				<small class="prior-lines"
																					*ngFor="let _ of [].constructor(5); let i = index"
																					[ngStyle]="{ 'background-color': (i+1) <= getUserSpec('ticket_priority', uid) ? getUserSpec('ticket_colour', uid) : 'rgb(196, 196, 196)','height': (-4 * (i + 1) * (i + 1)) + 'px'}">
																				</small>
																			</div>
																			<!-- <div class="m10 pd05" [style.background-color]="getUserSpec('ticket_colour',uid)">2{{getUserSpec('ticket_name',uid)}}</div> -->
																		</div>
																	</li>
																</ul>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div><!-- .tyn-aside-body -->
							</div>

						</tab>
					</tabset>
				</div>
			</div>
			<div class="col-md-6 col-xs-6 pdlr5">
				<main class="msger-chat nodata" *ngIf="chatlog?.length==0">
					<div class="selUserMsg" *ngIf="selected_user==0" style="color: red;">Select a Ticket to View the
						Chat !</div>
					<div class="selUserMsg" *ngIf="isfetchingLog"><i class="fa fa-spin fa-spinner fa-pulse"></i>
					</div>
				</main>
				<main class="msger-chat" [ngClass]="{'chatbox-show': searchFilter=='progress'}"
					*ngIf="chatlog?.length>0" #chatBox>
					<div *ngFor="let chat of chatlog">
						<div class="msg left-msg" *ngIf="chat.sender=='bot'">
							<div class="dflx mtop-25"
								*ngIf="chat.message.cardtypeid!=undefined && chat.message.cardtypeid!=16 && chat.message.cardtypeid != 8">
								<div class="msg-img"
									style="background-image: url(https://canvas.creyoface.com/assets/img/creyoface.gif)">
								</div>
								<!-- [style.background]="'url('+ userDetails.botlogo +')'" -->
								<div class="msg-bubble bot-bubble">
									<div class="msg-text">
										<div *ngIf="chat.message.cardtypeid == 1 && chat.message.feedbacktypeid == 1"
											[innerHTML]="chat.message.message"></div> <!-- msg card -->
										<div *ngIf="chat.message.cardtypeid == 39 && chat.message.feedbacktypeid == 1">
											<carousel>
												<slide *ngFor="let item of chat.message.message; let i=index;">
													<img src="{{ item }}" alt="slide">
												</slide>
											</carousel>
										</div> <!-- image card -->
										<div *ngIf="chat.message.cardtypeid == 38 && chat.message.feedbacktypeid == 1">
											<iframe height="120" [src]="changeIframe(chat.message.message)">
											</iframe>
										</div>
										<!-- video card -->
										<div *ngIf="chat.message.cardtypeid == 37 && chat.message.feedbacktypeid == 1">
											<audio controls>
												<source src="{{chat.message.message}}" type="audio/mpeg">
											</audio>
										</div> <!-- audio card -->
										<div *ngIf="chat.message.cardtypeid == 2" [innerHTML]="chat.message.message">
											<!-- choice card -->
											<div class="choices">
												<div *ngFor="let opt of chat.message.choices" [innerHTML]="opt.key">
												</div>
											</div>
										</div>
										<!-- <div *ngIf="chat.message.cardtypeid == 3 && chat.message.feedbacktypeid == 0"
											[innerHTML]="chat.message.message"></div> -->
										<div *ngIf="chat.message.cardtypeid == 3 && chat.message.feedbacktypeid <= 10"
											[innerHTML]="chat.message.message"></div>
										<!-- userip feedback = 0 card -->
										<div *ngIf="chat.message.cardtypeid == 3 && chat.message.feedbacktypeid > 10"
											class="formcard">
											<!-- userip feedback > 0 card -->
											<div *ngFor="let item of chat.message.message">
												<span><input type="text" placeholder="{{item.element}}" disabled></span>
											</div>
										</div> <!-- userip feedback = 0 card -->
										<!-- <div *ngIf="chat.message.cardtypeid == 3 && chat.message.feedbacktypeid > 0">{{chat.message.message}}</div> -->
										<div *ngIf="chat.message.cardtypeid == 4 && chat.message.feedbacktypeid == 1"
											[innerHTML]="chat.message.message">
											<br>
											<div class="dflx feedback">
												<i class="fa fa-star" aria-hidden="true"></i>
												<i class="fa fa-star" aria-hidden="true"></i>
												<i class="fa fa-star" aria-hidden="true"></i>
												<i class="fa fa-star" aria-hidden="true"></i>
												<i class="fa fa-star" aria-hidden="true"></i>
											</div>
										</div> <!-- feedback card -->
										<div *ngIf="chat.message.cardtypeid == 4 && chat.message.feedbacktypeid == 2"
											[innerHTML]="chat.message.message"></div> <!-- feedback card -->
										<div *ngIf="chat.message.cardtypeid == 4 && chat.message.feedbacktypeid == 3"
											[innerHTML]="chat.message.message"></div> <!-- feedback card -->
										<div *ngIf="chat.message.cardtypeid == 4 && chat.message.feedbacktypeid == 4"
											[innerHTML]="chat.message.message"></div> <!-- feedback card -->
										<div *ngIf="chat.message.cardtypeid == 14" [innerHTML]="chat.message.message">
										</div> <!-- date card -->
										<div *ngIf="chat.message.cardtypeid == 10">
											<!-- carousal card -->
											<carousel [noWrap]="noWrap" [interval]="2000">
												<slide *ngFor="let item of chat.message.data | keyvalue">
													<img src="{{chat.message.data[item.key].img_url}}" alt="slide">
													<div class="caption text-center">
														<span class="heading"
															[innerHTML]="chat.message.data[item.key].heading"></span><br>
														<span class="subheading"
															[innerHTML]="chat.message.data[item.key].subheading"></span>
													</div>
													<div class="car-buttons text-center"
														*ngFor="let btns of chat.message.data[item.key].choices">
														<span [innerHTML]="btns.key"></span>
													</div>
												</slide>
											</carousel>
										</div>

										<div class="pax-card" *ngIf="chat.message.cardtypeid == 31">
											<img class="bg_imgurl" width="100" src="/assets/img/ic_send_pax.svg"
												alt="bgimg">
											<p class="heading" [innerHTML]="chat.message.message[0].heading"></p>
											<p class="subheading" [innerHTML]="chat.message.message[0].sub_heading">
											</p>
											<p class="description" [innerHTML]="chat.message.message[0].description">
											</p>
										</div>
										<div class="seat-selection" *ngIf="chat.message.cardtypeid == 32">
											<img class="bg_imgurl" width="150"
												src="{{chat.message.message.message.bg_imgurl}}" alt="bgimg">
											<p class="heading" [innerHTML]="chat.message.message.message.heading">
											</p>
											<p class="subheading"
												[innerHTML]="chat.message.message.message.sub_heading"></p>
											<p class="description"
												[innerHTML]="chat.message.message.message.description"></p>
											<div class="seats">
												<div class="row" *ngFor="let item of [].constructor(5);">
													<div *ngFor="let item of [].constructor(6);" class="col-xs-2">
														<img src="/assets/img/seat.png" width="20">
													</div>
												</div>
											</div>
										</div>
										<div *ngIf="chat.message.cardtypeid == 18 && chat.message.feedbacktypeid==1">
											<span class="car-rep-button" [innerHTML]="chat.message.message"></span>
										</div>
										<div *ngIf="chat.message.cardtypeid == 18 && chat.message.feedbacktypeid==0">
											<a [href]="chat.message.message" target="_blank" download>Download
												File</a>
										</div>
									</div>
									<div class="msg-info"
										*ngIf="chat.message.cardtypeid!=undefined && chat.message.cardtypeid!=16 && chat.message.cardtypeid != 8">
										<div class="msg-info-time">{{chat.time | date:'medium'}}</div>
									</div>
								</div>
							</div>
							<div class="tagsList" *ngIf="chat.cardtypeid == 27">
								<div class="include" *ngIf="chat.message.include.length>0"
									[ngClass]="{'m-auto':chat.message.exclude.length==0, 'dnone':chat.message.include.length==0 }">
									<span *ngFor="let item of chat.message.include"
										[style.background]="getTagDetails(item,'color')">
										{{getTagDetails(item,'name')}}
									</span>
								</div>
								<div class="exclude" *ngIf="chat.message.exclude.length>0"
									[ngClass]="{'m-auto':chat.message.include.length==0, 'dnone':chat.message.exclude.length==0 }">
									<span *ngFor="let item of chat.message.exclude"
										[style.background]="getTagDetails(item,'color')">
										{{getTagDetails(item,'name')}}
									</span>
								</div>
							</div>
							<!-- tags card -->

						</div>
						<div *ngIf="chat.isgoal" class="w-100 text-center goal">
							<span *ngIf="chat.goalstatus=='start'" title="Goal Name" class="goalstart"> <img
									src="/assets/img/start.png" width="25"> &nbsp;{{chat.goalname}} <span
									class="status">Started</span></span>
							<span *ngIf="chat.goalstatus=='milestone'" title="Goal: {{chat.goalname}}"
								class="goalmilestone"> <img src="/assets/img/leader.png" width="25">
								&nbsp;{{chat.goalname}} - {{chat.milestone}}</span>
							<span *ngIf="chat.goalstatus=='end'" title="Goal Name" class="goalend"> <img
									src="/assets/img/target.png" width="25"> &nbsp;{{chat.goalname}} <span
									class="status">Completed</span></span>
						</div>

						<div class="msg left-msg" *ngIf="chat.sender=='live_agent' && chat.isLive==undefined">
							<div class="note-log" *ngIf="chat.is_private_note">{{chat.note}}</div>
							<div class="mtop-25" *ngIf="chat.cardtypeid==undefined">
								<div class="dflx" *ngIf="chat.message?.message">
									<div class="msg-img" style="background-image: url({{agent_img}})">
									</div>
									<div class="msg-bubble liveagent" *ngIf="chat.message?.agent_id">
										<div class="msg-text">
											<div [innerHTML]="chat.message?.message"></div>
										</div>
										<div class="msg-info">
											<div class="msg-info-time">{{chat.time | date:'medium'}}</div>
										</div>
									</div>
								</div>
								<!-- <div class="note-log" *ngIf="chat.is_private_note">
									<div [innerHTML]="chat.note"></div>
								</div> -->
								<div class="tagsList w-100" *ngIf="chat.cardtypeid == 27">
									<div class="include m-auto" *ngIf="chat.message.include.length>0">
										<span *ngFor="let item of chat.message.include"
											[style.background]="getTagDetails(item,'color')">
											{{getTagDetails(item,'name')}}
										</span>
									</div>
									<div class="include m-auto text-danger" *ngIf="!chat.message.include.length">
										<small>Tag Removed</small>
									</div>
								</div>
							</div>
							<div class="mtop-25" *ngIf="chat.cardtypeid == 27">
								<!-- <div class="msg-img"
									[style.background-image]="'url(' + getLAImage(customerID,'img') + ')'"
									[title]="getLAImage(customerID,'name')">
									<span>{{getLAImage(customerID,'name')}}</span>
								</div> -->
								<div class="tagsList w-100" *ngIf="chat.cardtypeid == 27">
									<div class="include m-auto" *ngIf="chat.message.include.length>0">
										<span *ngFor="let item of chat.message.include"
											[style.background]="getTagDetails(item,'color')">
											{{getTagDetails(item,'name')}}
										</span>
									</div>
									<div class="include m-auto text-danger" *ngIf="!chat.message.include.length">
										<small>Tag Removed</small>
									</div>
								</div>
							</div>
						</div>

						<div class="msg right-msg" *ngIf="chat.sender=='live_agent' && chat.isLive">
							<div class="note-log" *ngIf="chat.is_private_note">{{chat.note}}</div>
							<div class="dflxrev mtop-25" *ngIf="chat.message">
								<div class="msg-img" style="background-image: url({{agent_img}})">
								</div>
								<div class="msg-bubble liveagent">
									<div class="msg-text">
										<div>{{chat.message}}</div>
									</div>
								</div>
							</div>
							<div class="dflxrev mtop-25" *ngIf="chat.link">
								<div class="msg-img" style="background-image: url({{agent_img}})">
								</div>
								<div class="msg-bubble liveagent">
									<div class="msg-text" *ngIf="chat.isImage">
										<img src="{{ chat.link }}" alt="slide" width="150">
									</div>
									<div class="msg-file" *ngIf="!chat.isImage">
										<div><i class="fa fa-file-o" aria-hidden="true"></i></div>
										<a href="{{ chat.link }}" alt="slide" target="_blank">View File</a>
									</div>
								</div>
							</div>
							<div class="msg-info">
								<div class="msg-info-time text-center">{{chat.time | date:'medium'}}</div>
							</div>
						</div>

						<div class="msg left-msg" *ngIf="chat.sender=='user' && chat.isLive">
							<div class="dflx mtop-25">
								<div class="msg-img"
									style="background-image: url(https://canvas.creyoface.com/assets/img/creyoface.gif)">
								</div>
								<div class="msg-bubble">
									<div class="msg-text" *ngIf="chat.message_type == 'text'">
										<div>{{chat.message}}</div>
									</div>
									<div class="msg-text" *ngIf="chat.message_type == 'image'">
										<img src="{{ chat.message }}" alt="WA Image" width="150">
									</div>
									<div class="msg-file"
										*ngIf="['file', 'location', 'document'].includes(chat.message_type)">
										<div><i class="fa fa-file-o" aria-hidden="true"></i></div>
										<a href="{{ chat.message }}" alt="WA File" target="_blank">View
											{{chat.message_type == 'location' ? 'Location':'File'}}</a>
									</div>
									<div class="msg-text" *ngIf="chat.message_type == 'video'">
										<iframe height="120" [src]="changeIframe(chat.message)">
										</iframe>
									</div>
									<div class="msg-text" *ngIf="chat.message_type == 'audio'">
										<audio controls>
											<source src="{{chat.message}}" type="audio/mpeg">
										</audio>
									</div>

									<div class="msg-info">
										<div class="msg-info-time">{{chat.time | date:'medium'}}</div>
									</div>
								</div>
							</div>
						</div>

						<div class="msg right-msg"
							*ngIf="chat.sender == 'user' && chat.message && chat.isLive==undefined">
							<div class="dflxrev mtop-25">
								<div class="msg-img" style="background-image: url(/assets/img/default-avatar.png)">
								</div>
								<!-- [style.background]="'url('+ userDetails.botlogouser +')'" -->
								<div class="msg-bubble">
									<div class="msg-text"
										*ngIf="chat.cardtypeid == 1 || chat.cardtypeid == 2 || (chat.cardtypeid == 3 && chat.feedbacktypeid ==0 || chat.cardtypeid == -1)"
										[innerHTML]="chat.message">
									</div>

									<div class="msg-text" *ngIf="chat.cardtypeid == 4 && chat.feedbacktypeid ==1"
										[innerHTML]="chat.message"><i class="fa fa-star" aria-hidden="true"></i>
									</div>
									<div class="msg-text"
										*ngIf="chat.cardtypeid == 4 && (chat.feedbacktypeid == 3 || chat.feedbacktypeid == 4)"
										[innerHTML]="chat.message">
									</div>

									<ng-container *ngIf="chat.cardtypeid == 26">
										<div class="msg-text" *ngIf="chat.message_type == 'text'"
											[innerHTML]="chat.message">
										</div>
										<div class="msg-text" *ngIf="chat.message_type == 'image'">
											<img src="{{ chat.message }}" alt="WA Image" width="150">
										</div>
										<div class="msg-file"
											*ngIf="['file', 'location', 'document'].includes(chat.message_type)">
											<div><i class="fa fa-file-o" aria-hidden="true"></i></div>
											<a href="{{ chat.message }}" alt="WA File" target="_blank">View
												{{chat.message_type == 'location' ?
												'Location':'File'}}</a>
										</div>
										<div class="msg-text" *ngIf="chat.message_type == 'video'">
											<iframe height="120" [src]="changeIframe(chat.message)">
											</iframe>
										</div>
										<div class="msg-text" *ngIf="chat.message_type == 'audio'">
											<audio controls>
												<source src="{{chat.message}}" type="audio/mpeg">
											</audio>
										</div>
									</ng-container>

									<div class="msg-text" *ngIf="chat.cardtypeid == 14">
										<!-- date -->
										<span *ngIf="chat.message.from">{{chat.message.from}}</span>
										<span *ngIf="chat.message.to"> - {{chat.message.to}}</span>
									</div>

									<div class="msg-text" *ngIf="chat.cardtypeid == 4 && chat.feedbacktypeid == 2">
										<!-- date -->
										<i *ngIf="chat.message == '0'" class="fa fa-thumbs-down" aria-hidden="true"></i>
										<i *ngIf="chat.message == '1'" class="fa fa-thumbs-up" aria-hidden="true"></i>
									</div>

									<div class="car-reply" *ngIf="chat.cardtypeid == 10">
										<!-- carousal -->
										<span class="car-rep-head" [innerHTML]="chat.message.data.heading"></span><br>
										<span class="car-rep-subhead"
											[innerHTML]="chat.message.data.subheading"></span><br>
										<span class="car-rep-button" [innerHTML]="chat.message.choice"></span>
									</div>

									<div *ngIf="chat.cardtypeid == 3 && chat.feedbacktypeid >= 10">
										<div *ngFor="let item of chat.message | keyvalue">
											<span>{{item.key}} : <b>{{item.value}}</b></span>
										</div>
									</div>

									<div
										*ngIf="chat.cardtypeid == 3 && chat.feedbacktypeid < 10 && chat.feedbacktypeid > 0">
										<div [innerHTML]="chat.message">
										</div>
									</div>

									<div *ngIf="chat.cardtypeid == 18 && chat.feedbacktypeid == 1">
										<a [href]="chat.message" target="_blank" download>Uploaded File</a>
									</div>

									<div class="msg-text" *ngIf="chat.cardtypeid == 31">
										<div *ngFor="let item of chat.message; let i=index;" class="seat-selected">
											<span *ngFor="let pax of item | keyvalue;">{{pax.key}} :
												{{pax.value}}</span>
										</div>
									</div>

									<div class="msg-text" *ngIf="chat.cardtypeid == 32">
										<div *ngFor="let item of chat.message" class="seat-selected">
											<span>{{item.label_key}}</span>
										</div>
									</div>

									<div class="msg-info">
										<div class="msg-info-time">{{chat.time | date:'medium'}}</div>
									</div>
								</div>

							</div>
						</div>
					</div>
				</main>
				<div class="mt-2 chat-tab" *ngIf="startChat && searchFilter=='progress'">
					<tabset [justified]="true" #replyTabs>
						<tab heading="Reply">
							<div class="chat-container">
								<div class="chat-input">
									<textarea placeholder="Type message & enter to send."
										[mentionConfig]="mentionConfig" [(ngModel)]="newMessage"
										(keyup)="$event.keyCode == 13 && sendMessage()" rows="3"
										[readonly]="isfetchingLog"></textarea>
									<button class="btn-emoji" #emojiButton (click)="toggleEmojiPicker()">😀</button>
									<input type="file" id="attach-file" (change)="getFile($event)" accept="*"
										style="display:none" />
									<button class="btn-attach" (click)="uploadImg()"><i class="fa"
											[ngClass]="{'fa-spin fa-spinner fa-pulse': isUploading, 'fa-paperclip': !isUploading}"
											aria-hidden="true"></i></button>
									<div class="emoji-box" #emojiBox>
										<emoji-mart class="emoji-mart" set="google" (emojiSelect)="addEmoji($event)"
											title="Pick your emoji…" *ngIf="showEmojiPicker"></emoji-mart>
									</div>
									<button (click)="sendMessage()" class="send-btn"><i class="fa fa-paper-plane"
											aria-hidden="true"></i></button>
								</div>
							</div>
						</tab>
						<tab heading="Private Notes">
							<div class="chat-container">
								<div class="chat-input">
									<textarea class="pvt-note" placeholder="Type note & enter to send" #pvtnote
										rows="3"></textarea>
									<button (click)="addPvtNote(pvtnote.value)" class="send-btn"><i
											class="fa fa-sticky-note" aria-hidden="true"></i></button>
								</div>
							</div>
						</tab>
						<tab heading="Add Tags">
							<div class="user-details mt-2" *ngIf="startChat">
								<angular2-multiselect [data]="tagsList" [settings]="categoriesSettings"
									[(ngModel)]="selectedTags">
								</angular2-multiselect>
								<div class="mtop10 text-right">
									<button (click)="saveUserTags()" class="btn-sm btn btn-gradient" type="submit"><i
											*ngIf="isaddTags" class="fa fa-spin fa-pulse fa-spinner"
											aria-hidden="true"></i>
										Save</button>
								</div>
							</div>
						</tab>
					</tabset>
				</div>

			</div>
			<div class="col-md-3 col-xs-3 pdlr5 details-pane">
				<div class="user-details" *ngIf="startChat">
					<div class="panel panel-default">
						<div class="panel-body">
							<form class="form-horizontal" [formGroup]="leadForm"
								(ngSubmit)="onLeadSubmit(leadForm.value)">
								<div class="form-group">
									<div class="col-sm-12 lead-col">
										<span>
											<i class="fa fa-user grad-i" aria-hidden="true"></i>
										</span>
										<p *ngIf="!leadFields[0]">{{leadForm.value.name ? leadForm.value.name : 'Add
											Name'}}</p>
										<input *ngIf="leadFields[0]" type="text" class="form-control"
											formControlName="name" placeholder="Name" name="name" autocomplete="off">
										<a *ngIf="!leadFields[0] && searchFilter=='progress'"
											(click)="leadFields[0]=true">
											<i class="fa {{leadForm.value.name ? 'fa-pencil' : 'fa-plus'}} text-primary"
												aria-hidden="true"></i>
										</a>
										<a *ngIf="leadFields[0]" (click)="onLeadSubmit(leadForm.value)">
											<i class="fa fa-check text-success" aria-hidden="true"></i>
										</a>
									</div>
								</div>
								<div class="form-group">
									<div class="col-sm-12 lead-col">
										<span>
											<i class="fa fa-phone grad-i" aria-hidden="true"></i>
										</span>
										<p *ngIf="!leadFields[1]">{{leadForm.value.phone ? leadForm.value.phone : 'Add
											Phone'}}</p>
										<input *ngIf="leadFields[1]" type="tel" class="form-control"
											formControlName="phone" placeholder="Phone" name="phone" autocomplete="off">
										<a *ngIf="!leadFields[1] && searchFilter=='progress'"
											(click)="leadFields[1]=true">
											<i class="fa {{leadForm.value.phone ? 'fa-pencil' : 'fa-plus'}} text-primary"
												aria-hidden="true"></i>
										</a>
										<a *ngIf="leadFields[1]" (click)="onLeadSubmit(leadForm.value)">
											<i class="fa fa-check text-success" aria-hidden="true"></i>
										</a>
									</div>
								</div>
								<div class="form-group">
									<div class="col-sm-12 lead-col">
										<span>
											<i class="fa fa-envelope grad-i" aria-hidden="true"></i>
										</span>
										<p *ngIf="!leadFields[2]">{{leadForm.value.email ? leadForm.value.email : 'Add
											Email'}}</p>
										<input *ngIf="leadFields[2]" type="tel" class="form-control"
											formControlName="email" placeholder="Email" name="email" autocomplete="off">
										<a *ngIf="!leadFields[2] && searchFilter=='progress'"
											(click)="leadFields[2]=true">
											<i class="fa {{leadForm.value.email ? 'fa-pencil' : 'fa-plus'}} text-primary"
												aria-hidden="true"></i>
										</a>
										<a *ngIf="leadFields[2]" (click)="onLeadSubmit(leadForm.value)">
											<i class="fa fa-check text-success" aria-hidden="true"></i>
										</a>
									</div>
								</div>
								<div class="form-group">
									<div class="col-sm-12 lead-col">
										<span>
											<i class="fa fa-building grad-i" aria-hidden="true"></i>
										</span>
										<p *ngIf="!leadFields[3]">{{leadForm.value.company ? leadForm.value.company :
											'Add Company'}}</p>
										<input *ngIf="leadFields[3]" type="tel" class="form-control"
											formControlName="company" placeholder="Company" name="company"
											autocomplete="off">
										<a *ngIf="!leadFields[3] && searchFilter=='progress'"
											(click)="leadFields[3]=true">
											<i class="fa {{leadForm.value.company ? 'fa-pencil' : 'fa-plus'}} text-primary"
												aria-hidden="true"></i>
										</a>
										<a *ngIf="leadFields[3]" (click)="onLeadSubmit(leadForm.value)">
											<i class="fa fa-check text-success" aria-hidden="true"></i>
										</a>
									</div>
								</div>
								<div class="form-group">
									<div class="col-sm-12 lead-col">
										<span>
											<i class="fa fa-map-marker grad-i" aria-hidden="true"></i>
										</span>
										<p *ngIf="!leadFields[4]">{{leadForm.value.location ? leadForm.value.location :
											'Add Location'}}</p>
										<input *ngIf="leadFields[4]" type="tel" class="form-control"
											formControlName="location" placeholder="Location" name="location"
											autocomplete="off">
										<a *ngIf="!leadFields[4] && searchFilter=='progress'"
											(click)="leadFields[4]=true">
											<i class="fa {{leadForm.value.location ? 'fa-pencil' : 'fa-plus'}} text-primary"
												aria-hidden="true"></i>
										</a>
										<a *ngIf="leadFields[4]" (click)="onLeadSubmit(leadForm.value)">
											<i class="fa fa-check text-success" aria-hidden="true"></i>
										</a>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>

				<div class="user-details" *ngIf="startChat">
					<div class="panel panel-default">
						<div class="panel-heading cpr" (click)="showDetails=!showDetails"><b>Conversation Info</b>
							<a class="pull-right">
								<i *ngIf="!showDetails" class="fa fa-angle-down" aria-hidden="true"></i>
								<i *ngIf="showDetails" class="fa fa-angle-up" aria-hidden="true"></i>
							</a>
						</div>
						<div class="panel-body" *ngIf="showDetails" [@slideInOut]>

							<div class="col-sm-8 userdetail-col" *ngIf="userDetail.url">
								<span>
									<i class="fa fa-globe grad-i" title="Channel" aria-hidden="true"></i>
								</span>
								<p>{{userDetail.url}}</p>
							</div>

							<div class="col-sm-8 userdetail-col" *ngIf="userDetail.browser">
								<span>
									<i class="fa fa-window-maximize grad-i" title="Browser" aria-hidden="true"></i>
								</span>
								<p>{{userDetail.browser}}</p>
							</div>

							<div class="col-sm-8 userdetail-col" *ngIf="userDetail.os">
								<span>
									<i class="fa fa-desktop grad-i" title="OS" aria-hidden="true"></i>
								</span>
								<p>{{userDetail.os}}</p>
							</div>

							<div class="col-sm-8 userdetail-col" *ngIf="userDetail.country">
								<span>
									<i class="fa fa-map-marker grad-i" title="Country" aria-hidden="true"></i>
								</span>
								<p>{{userDetail.country}}</p>
							</div>

							<div class="col-sm-8 userdetail-col" *ngIf="userDetail.continent">
								<span>
									<i class="fa fa-map grad-i" title="Continent" aria-hidden="true"></i>
								</span>
								<p>{{userDetail.continent}}</p>
							</div>
						</div>
					</div>
				</div>


				<div class="user-details" *ngIf="startChat">
					<div class="panel panel-default">
						<div class="panel-heading"><b>User Attributes</b>
							<div class="pull-right">
								<a (click)="showmoreDetails=true;isaddLead=true;isaddMemory=false;isLeadEdit=false;clearMoreLeadForm();"
									class="add-lead-btn">
									<i class="fa fa-plus black" aria-hidden="true"></i> Lead
								</a>&nbsp;
								<a (click)="showmoreDetails=true;isaddLead=false;isaddMemory=true;clearMemForm();"
									class="float-right add-lead-btn">
									<i class="fa fa-plus black" aria-hidden="true"></i> Variable
								</a>
							</div>
						</div>
						<div class="panel-body" *ngIf="showmoreDetails" [@slideInOut]>
							<ul>

								<li *ngFor="let item of leadDataList">{{item.storage_variable}} :
									<span>{{item.value}}</span>
									<a (click)="editData(item)" class="pull-right">
										<i class="fa fa-pencil-square-o green" aria-hidden="true"></i>
									</a>
								</li>
								<form [formGroup]="leadmoreForm" (ngSubmit)="leadMoreSubmit(leadmoreForm)"
									*ngIf="isaddLead">
									<ul class="add-lead">
										<li class="dflx">
											<select class="form-control" formControlName="storage_variable">
												<option [value]="null" [selected]="true" disabled selected hidden>Select
													Variable
												</option>
												<option *ngFor="let item of starredMemVarsList"
													[value]="item.internal_name">{{item.display_name}}</option>
											</select> : <input type="text" placeholder="Value" formControlName="value"
												class="w50">
										</li>
									</ul>
									<button class="btn-sm btn btn-gradient pull-right" type="submit"><i
											*ngIf="leadloader" class="fa fa-spin fa-pulse fa-spinner"
											aria-hidden="true"></i> Save</button>
								</form>
								<form [formGroup]="addMemForm" (ngSubmit)="addMemSubmit(addMemForm)"
									*ngIf="isaddMemory">
									<ul class="add-lead">
										<li class="dflx">
											<input type="text" placeholder="Name" formControlName="display_name"
												class="w50">:
											<select class="form-control" formControlName="variable_type">
												<option [value]="null" [selected]="true" disabled selected hidden>Type
												</option>
												<option *ngFor="let item of memVarTypes" [value]="item.value">
													{{item.name}}</option>
											</select>
										</li>
									</ul>
									<button class="btn-sm btn btn-gradient float-right" type="submit"><i
											*ngIf="leadloader" class="fa fa-spin fa-pulse fa-spinner"
											aria-hidden="true"></i> Save</button>
								</form>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- <div bsModal #closeChatModal="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog"
	aria-labelledby="dialog-auto-name">
	<div class="modal-dialog modal-sm">
		<div class="modal-content">
			<div class="modal-header">
				<h4 class="modal-title text-center">Close Chat</h4>
				<button type="button" class="close" (click)="closeChatModal.hide()">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body text-center">
				<p>Is the issue resolved?</p>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-red" (click)="closeChat('inprogress', false)">
					<i class="fa fa-times"></i> In-Progress</button>
				<button type="button" class="btn btn-green" (click)="closeChat('resolved', false)">Resolved</button>
			</div>
		</div>
	</div>
</div> -->

<div bsModal #holdChatModal="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog"
	aria-labelledby="dialog-auto-name">
	<div class="modal-dialog modal-sm">
		<div class="modal-content">
			<div class="modal-header">
				<h4 class="modal-title text-center">Hold Chat</h4>
				<button type="button" class="close" (click)="holdChatModal.hide()">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<form class="form-horizontal" [formGroup]="holdForm" (ngSubmit)="onHoldSubmit(holdForm.value)">
				<div class="modal-body">
					<div class="row">
						<div class="col-sm-12">
							<label>Message</label>
							<textarea rows="4" formControlName="hold_message" placeholder="Hold Message"
								class="form-control" autocomplete="off" required></textarea>
						</div>
						<div class="col-sm-12 mt-2">
							<label>Time <small>(min)</small></label>
							<select class="form-control" formControlName="hold_time">
								<option value="null" selected disabled>Select Time</option>
								<option *ngFor="let item of [].constructor(15);let i=index;" [value]="i+1">
									{{i+1}}</option>
							</select>
						</div>
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-secondary" (click)="holdChatModal.hide()">
						<i class="fa fa-times"></i> Close</button>
					<button type="submit" class="btn-sm btn btn-gradient"><i class="fa fa-pause" aria-hidden="true"></i>
						Hold</button>
				</div>
			</form>
		</div>
	</div>
</div>


<div bsModal #resolveModal="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog"
	aria-labelledby="dialog-auto-name">
	<div class="modal-dialog modal-sm">
		<div class="modal-content">
			<div class="modal-header">
				<h4 class="modal-title text-center">Resolve Ticket</h4>
				<button type="button" class="close" (click)="resolveModal.hide()">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body text-center">
				<p>Are you sure you want to close this ticket?</p>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-secondary" (click)="resolveModal.hide()">
					<i class="fa fa-times"></i> Close</button>
				<button type="button" class="btn-sm btn btn-gradient" (click)="resolveChat()"><i class="fa fa-check"
						aria-hidden="true"></i>
					Resolve</button>
			</div>
		</div>
	</div>
</div>

<ng-template #filterBox>
	<div class="row filter-row">
		<div class="col-md-12 filter-box">
			<input class="form-control" type="text" placeholder="Name / Phone / Email" [(ngModel)]="filterLead"
				autocomplete="off">
			<!-- <button class="btn-sm search"><i class="fa fa-search" style="font-size:24px"></i></button> -->
		</div>
		<div class="col-md-12 filter-box">
			<input type="text" class="form-control" ngxDaterangepickerMd [(ngModel)]="filterDate"
				[alwaysShowCalendars]="true" [ranges]="ranges" [showClearButton]="true" [locale]="locale"
				[placeholder]="'Select Date Range'" #filterPicker />
			<!-- <button class="btn-sm calendar" (click)="openDatepicker()"><i class="fa fa-calendar"
					style="font-size:24px"></i></button> -->
		</div>
		<div class="col-md-6 filter-box pe5px">
			<input class="form-control" type="text" placeholder="Ticket Name" [(ngModel)]="filterTicketName"
				autocomplete="off">
			<!-- <button class="btn-sm search"><i class="fa fa-search" style="font-size:24px"></i></button> -->
		</div>
		<div class="col-md-6 filter-box ps-0">
			<select class="form-select form-control" [(ngModel)]="filterTicketPriority">
				<option [value]="null" disabled selected hidden>Select Priority</option>
				<option *ngFor="let priority of priorityList" [value]="priority.id">
					{{priority.name}}
				</option>
			</select>
			<a class="d-flex m-auto" *ngIf="filterTicketPriority" (click)="filterTicketPriority=null;"><i
					class="fa fa-times text-danger" style="font-size:15px"></i></a>
		</div>
		<div class="col-md-12 filter-box">
			<angular2-multiselect [data]="tagsList" [settings]="categoriesSettings" [(ngModel)]="filterTags">
			</angular2-multiselect>
			<!-- <button class="btn-sm tag"><i class="fa fa-tag" style="font-size:24px"></i></button> -->
		</div>
		<div class="col-md-12 filter-box">
			<select class="form-select form-control" [(ngModel)]="filterChannel">
				<option [ngValue]="null" disabled selected hidden>Select filterChannel</option>
				<option [ngValue]="0">All Channels</option>
				<option *ngFor="let item of channelsList" [ngValue]="item.id">
					{{ item.name }}
				</option>
			</select>
			<a class="d-flex m-auto" *ngIf="filterTicketPriority" (click)="filterTicketPriority=null;"><i
					class="fa fa-times text-danger" style="font-size:15px"></i></a>
		</div>
		<div class="col-md-12 text-right">
			<button class="btn btn-sm mt-1" (click)="openFilterRuleModal()"
				[ngClass]="{'btn-gradient': filterFormData}">
				Filter by Condition </button>
		</div>
		<div class="col-md-12 text-right" *ngIf="!isAssigned">
			<small class="text-warning">Applying a filter will halt all new escalations!</small>
		</div>
		<div class="col-md-12 footer-box">
			<!-- <button class="btn btn-sm apply" (click)="applyFilter()"
				[attr.disabled]="(filterLead || filterDate?.startDate || filterDate?.endDate || filterTags.length>0 || filterTicketName || filterTicketPriority || filterChannel || filterFormData?.condition.length) ? null : ''">Apply</button> -->
			<button class="btn btn-sm apply" (click)="applyFilter()">Apply</button>
			<button class="btn btn-sm cancel" (click)="clearFIlters()">Clear</button>
		</div>
	</div>
</ng-template>

<ng-template #clearBox>
	<div class="row clear-row">
		<div class="col-md-12 clear-box">
			Are you sure to close {{usersList.length}} tickets ?
		</div>
		<div class="col-md-12 footer-box">
			<button class="btn btn-sm btn-warning apply" (click)="clearUsers()">Yes</button>
			<button class="btn btn-sm cancel" (click)="showClearBox = false;">No</button>
		</div>
	</div>
</ng-template>