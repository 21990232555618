<div class="container-fluid">
	<div class="row">
		<button *ngIf="permissions?.is_agent_create" type="button" class="btn btn-green btn-sm add-agent-btn"
			(click)="addAgentModal.show()"><i *ngIf="!isAdding" class="fa fa-plus"></i>&nbsp;Add Agent</button>
		<div class="col-md-6 col-sm-6">
			<div class="manage-table">
				<div class="card scroll-table">
					<table class="table table-borderless agent-table">
						<thead>
							<tr>
								<th>Agent</th>
								<th>Type</th>
								<th class="text-center">Departments</th>
								<th>Status</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor=" let agent of agents; let i=index" [ngClass]="{'selected':selected[i]}"
								(click)="selectAgent(i,agent)">
								<td><img src="{{agent.AgentURL}}" width="50"
										height="50">&nbsp;<b>{{agent.AgentName}}</b>

								</td>
								<td>
									<b *ngIf="agent.AgentType==2" class="text-primary">Admin</b>
									<b *ngIf="agent.AgentType==3" class="text-warning">Agent</b>
								</td>
								<td class="pl-2">
									<span class="dept-list"
										*ngFor="let item of agent.Departments">{{item.DepartmentName}} [
										{{item.BotName}} ]</span>
									<span class="label label-danger mtop20" *ngIf="agent.Departments?.length==0">No
										department</span>
								</td>
								<td>
									<div class="status">
										<b *ngIf="agent.IsActive" class="text-success">Active</b>
										<b *ngIf="!agent.IsActive" class="text-warning">Inactive</b>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
		<div class="col-md-6 col-sm-6">
			<div class="mtop10 card scroll-table" *ngIf="(agentDetails | json) != '{}' && !isFetching">
				<div class="card-header text-center dflx">
					<div class="dflx mauto">
						<div class="image-badge">
							<img class="profile-image" [src]="agentDetails.image_url" width="50" height="50">
						</div>
						<div class="name-details">
							<i *ngIf="agentDetails.type==1 || agentDetails.type==2" class="fa fa-certificate"
								aria-hidden="true" title="Admin"></i>
							<i *ngIf="agentDetails.type==3" class="fa fa-user-circle-o" aria-hidden="true"
								title="Agent"></i> &nbsp;
							{{agentDetails.full_name}}
							<!-- <sub *ngIf="agentDetails.type==1" class="text-primary">(Super Admin)</sub>
							<sub *ngIf="agentDetails.type==2" class="text-primary">(Admin)</sub>
							<sub *ngIf="agentDetails.type==3" class="text-warning">(Agent)</sub> -->
							<div class="w-100 text-right" *ngIf="agentDetails.type!=1 && permissions?.is_agent_delete">
								<div class="status">
									<input type="checkbox" id="status--" name="set-name" class="switch-input"
										[checked]="agentDetails.is_active" (change)="changeAccStatus($event)">
									<label for="status--" class="switch-label">
										<span class="toggle--on">Active</span>
										<span class="toggle--off">Inactive</span>
									</label>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="card-body">
					<div class="row">
						<div class="profile-tab">
							<tabset>
								<tab heading="Profile">
									<div class="profile">
										<div class="form-group">
											<label>Email</label>
											<input class="form-control" placeholder="Email" type="email"
												[value]="agentDetails.email" disabled>
										</div>
										<div class="form-group">
											<label>Phone</label>
											<input class="form-control" placeholder="Phone" type="text"
												[value]="agentDetails.phone" disabled>
										</div>
										<div class="form-group text-right" *ngIf="permissions?.is_password_reset">
											<button type="button" class="btn btn-gradient btn-sm"
												(click)="resetPwdModal.show()">
												<i class="fa fa-pencil-square-o"></i>
												Reset Password
											</button>
										</div>
										<div class="form-group"
											*ngIf="agentDetails.type==3 && permissions?.is_change_departments">
											<label>Change Department</label>
											<div class="row">
												<div class="col-xs-8">
													<!-- <select class="form-control" [(ngModel)]="deptAgent">
														<option *ngFor="let item of deptSortList"
															value="{{item.id}},{{item.BotID}}">{{item.itemName}}
														</option>
													</select> -->
													<angular2-multiselect [data]="deptSortList" [settings]="settings"
														[(ngModel)]="deptAgent">
													</angular2-multiselect>
												</div>
												<div class="col-xs-4">
													<button type="button" class="btn btn-gradient btn-sm pull-right"
														(click)="changeDepartmentAgent(deptAgent)">
														<i class="fa fa-pencil-square-o"
															*ngIf="!isChangeDepartment"></i>
														<i class="fa fa-spin fa-spinner" *ngIf="isChangeDepartment"></i>
														Change
													</button>
												</div>
											</div>
										</div>
										<div class="form-group"
											*ngIf="agentDetails.type==2 && permissions?.is_change_departments">
											<label>Change Departments</label>
											<div class="row">
												<div class="col-xs-8">
													<angular2-multiselect [data]="deptList" [settings]="settings"
														[(ngModel)]="selectedDepts">
													</angular2-multiselect>
												</div>
												<div class="col-xs-4">
													<button class="btn btn-gradient btn-sm pull-right" type="button"
														(click)="changeDepartmentsAdmin(selectedDepts)">
														<i class="fa fa-pencil-square-o"
															*ngIf="!isChangeDepartment"></i>
														<i class="fa fa-spin fa-spinner" *ngIf="isChangeDepartment"></i>
														Change
													</button>
												</div>
											</div>
										</div>
										<!-- <div class="form-group" *ngIf="agentDetails.type==2">
											
										</div>
										<div class="form-group" *ngIf="agentDetails.type==3">
											
										</div> -->
									</div>
								</tab>
								<tab heading="Permissions">
									<div class="profile">
										<form [formGroup]="permissionForm"
											(ngSubmit)="changePermission(permissionForm.value,agentDetails.id)"
											ngNativeValidate>
											<div class="row border-row" *ngIf="agentDetails.type!=3">
												<div class="col-xs-6">
													<div class="form-group fgroup">
														<div class="checkbox">
															<input id="is_admin_create" type="checkbox"
																formControlName="is_admin_create">
															<label for="is_admin_create"><span>Create
																	Admin</span></label>
														</div>
													</div>
												</div>
												<div class="col-xs-6">
													<div class="form-group fgroup">
														<div class="checkbox">
															<input id="is_admin_delete" type="checkbox"
																formControlName="is_admin_delete">
															<label for="is_admin_delete"><span>Delete
																	Admin</span></label>
														</div>
													</div>
												</div>
											</div>
											<div class="row border-row" *ngIf="agentDetails.type!=3">
												<div class="col-xs-6">
													<div class="form-group fgroup">
														<div class="checkbox">
															<input id="is_agent_create" type="checkbox"
																formControlName="is_agent_create">
															<label for="is_agent_create"><span>Create
																	Agent</span></label>
														</div>
													</div>
												</div>
												<div class="col-xs-6">
													<div class="form-group fgroup">
														<div class="checkbox">
															<input id="is_agent_delete" type="checkbox"
																formControlName="is_agent_delete">
															<label for="is_agent_delete"><span>Delete
																	Agent</span></label>
														</div>
													</div>
												</div>
											</div>
											<div class="row border-row" *ngIf="agentDetails.type!=3">
												<div class="col-xs-6">
													<div class="form-group fgroup">
														<div class="checkbox">
															<input id="is_change_departments" type="checkbox"
																formControlName="is_change_departments">
															<label for="is_change_departments"><span>Change
																	Departments</span></label>
														</div>
													</div>
												</div>
												<div class="col-xs-6">
													<div class="form-group fgroup">
														<div class="checkbox">
															<input id="is_password_reset" type="checkbox"
																formControlName="is_password_reset">
															<label for="is_password_reset"><span>Password
																	Reset</span></label>
														</div>
													</div>
												</div>
											</div>
											<div class="row border-row">
												<div class="col-xs-6">
													<div class="form-group fgroup">
														<div class="checkbox">
															<input id="is_quickreply_create" type="checkbox"
																formControlName="is_quickreply_create">
															<label for="is_quickreply_create"><span
																	title="Create Quick-Reply">Create
																	Quick-Reply</span></label>
														</div>
													</div>
												</div>
												<div class="col-xs-6">
													<div class="form-group fgroup">
														<div class="checkbox">
															<input id="is_quickreply_delete" type="checkbox"
																formControlName="is_quickreply_delete">
															<label for="is_quickreply_delete"><span
																	title="Delete Quick-Reply">Delete
																	Quick-Reply</span></label>
														</div>
													</div>
												</div>
											</div>
											<div class="row border-row" *ngIf="agentDetails.type!=3">
												<div class="col-xs-6">
													<div class="form-group fgroup">
														<div class="checkbox">
															<input id="is_tag_create" type="checkbox"
																formControlName="is_tag_create">
															<label for="is_tag_create"><span title="Create Tag">Create
																	Tag</span></label>
														</div>
													</div>
												</div>
											</div>
											<div class="row border-row">
												<div class="col-xs-6" *ngIf="agentDetails.type!=3">
													<div class="form-group fgroup">
														<div class="checkbox">
															<input id="show_admin_management_page" type="checkbox"
																formControlName="show_admin_management_page">
															<label for="show_admin_management_page"><span
																	title="Show Admin Management Page">Management
																	Page</span></label>
														</div>
													</div>
												</div>
												<div class="col-xs-6">
													<div class="form-group fgroup">
														<div class="checkbox">
															<input id="show_tickets_page" type="checkbox"
																formControlName="show_tickets_page">
															<label for="show_tickets_page"><span
																	title="Show Tickets Page">Tickets
																	Page</span></label>
														</div>
													</div>
												</div>
												<div class="col-xs-6">
													<div class="form-group fgroup">
														<div class="checkbox">
															<input id="show_contacts_page" type="checkbox"
																formControlName="show_contacts_page">
															<label for="show_contacts_page"><span
																	title="Show Contacts Page">Contacts
																	Page</span></label>
														</div>
													</div>
												</div>
												<div class="col-xs-6">
													<div class="form-group fgroup">
														<div class="checkbox">
															<input id="show_reports_page" type="checkbox"
																formControlName="show_reports_page">
															<label for="show_reports_page"
																title="Show Reports Page"><span>Reports
																	Page</span></label>
														</div>
													</div>
												</div>
												<div class="col-xs-6">
													<div class="form-group fgroup">
														<div class="checkbox">
															<input id="show_spectate_page" type="checkbox"
																formControlName="show_spectate_page">
															<label for="show_spectate_page"><span
																	title="Show Spectate Page">Spectate
																	Page</span></label>
														</div>
													</div>
												</div>
											</div>
											<!-- <div class="col-xs-6">
											</div> -->
											<button type="submit" class="btn btn-gradient btn-sm pull-right">
												<i class="fa fa-floppy-o" *ngIf="!isPermission"></i>
												<i class="fa fa-spin fa-spinner fa-pulse" *ngIf="isPermission"></i>
												Save</button>
										</form>
									</div>
								</tab>
							</tabset>
						</div>
					</div>
				</div>
			</div>
			<div class="agent-loading text-center" *ngIf="isFetching">
				<i class="fa fa-spinner fa-pulse"></i>
			</div>
		</div>
	</div>
</div>

<div bsModal #addAgentModal="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog"
	aria-labelledby="dialog-auto-name">
	<div class="modal-dialog modal-md">
		<div class="modal-content">
			<form [formGroup]="addAgentForm" (ngSubmit)="addAgent(addAgentForm.value)" ngNativeValidate>
				<div class="modal-header">
					<div class="header">
						<h4 class="title mg0">Add Agent</h4>
						<button type="button" class="close" (click)="addAgentModal.hide();clearAgentForm();">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
				</div>
				<div class="modal-body">
					<div class="row">
						<div class="col-xs-6">
							<div class="form-group">
								<label>Name</label>
								<input class="form-control" placeholder="Name" type="text" formControlName="AgentName"
									required>
							</div>
						</div>
						<div class="col-xs-6">
							<div class="form-group">
								<label>Type</label>
								<select class="form-control" formControlName="AgentType">
									<option value="2" *ngIf="permissions?.is_admin_create">Admin</option>
									<option value="3">Agent</option>
								</select>
							</div>
						</div>
						<div class="col-xs-6">
							<div class="form-group">
								<label>Email</label>
								<input class="form-control" placeholder="Email" (change)="checkEmail($event)"
									type="text" formControlName="AgentEmail" required>
								<span class="error red" *ngIf="isDupeEmail">Email already registered! Try
									another.</span>
							</div>
						</div>
						<div class="col-xs-6">
							<div class="form-group">
								<label>Phone</label>
								<input class="form-control" placeholder="Phone" type="text" formControlName="phone"
									required>
							</div>
						</div>
						<div class="col-xs-6">
							<div class="form-group password-show">
								<label>Password</label>
								<input [type]="showPassword ? 'text' : 'password'" class="form-control"
									placeholder="Password" #password name="password" formControlName="password">
								<i class="fa fa-eye eyes" (click)="showPassword=!showPassword"
									*ngIf="!showPassword"></i>
								<i class="fa fa-eye-slash eyes" (click)="showPassword=!showPassword"
									*ngIf="showPassword"></i>
							</div>
						</div>
						<div class="col-xs-6" *ngIf="addAgentForm.value.AgentType==2">
							<div class="form-group">
								<label>Department</label>
								<angular2-multiselect [data]="deptList" [settings]="settings"
									formControlName="departments_admin" required>
								</angular2-multiselect>
							</div>
						</div>
						<div class="col-xs-6" *ngIf="addAgentForm.value.AgentType==3">
							<div class="form-group">
								<label>Department</label>
								<angular2-multiselect [data]="deptSortList" [settings]="settings"
									formControlName="department_agent">
								</angular2-multiselect>
								<span class="red" *ngIf="deptSortList.length==0">Please add a department to add an
									agent</span>
							</div>
						</div>
						<div class="col-xs-12"><a (click)="showPermissions=!showPermissions"><span
									*ngIf="!showPermissions">Show</span><span *ngIf="showPermissions">Hide</span>
								Permissions</a></div>
						<div class="col-xs-12" *ngIf="showPermissions">
							<div class="row border-row" *ngIf="addAgentForm.value.AgentType!=3">
								<div class="col-xs-6">
									<div class="form-group fgroup">
										<div class="checkbox">
											<input id="is_admin_create" type="checkbox"
												formControlName="is_admin_create">
											<label for="is_admin_create"><span>Create Admin</span></label>
										</div>
									</div>
								</div>
								<div class="col-xs-6">
									<div class="form-group fgroup">
										<div class="checkbox">
											<input id="is_admin_delete" type="checkbox"
												formControlName="is_admin_delete">
											<label for="is_admin_delete"><span>Delete Admin</span></label>
										</div>
									</div>
								</div>
							</div>
							<div class="row border-row" *ngIf="addAgentForm.value.AgentType!=3">
								<div class="col-xs-6">
									<div class="form-group fgroup">
										<div class="checkbox">
											<input id="is_agent_create" type="checkbox"
												formControlName="is_agent_create">
											<label for="is_agent_create"><span>Create Agent</span></label>
										</div>
									</div>
								</div>
								<div class="col-xs-6">
									<div class="form-group fgroup">
										<div class="checkbox">
											<input id="is_agent_delete" type="checkbox"
												formControlName="is_agent_delete">
											<label for="is_agent_delete"><span>Delete Agent</span></label>
										</div>
									</div>
								</div>
							</div>
							<div class="row border-row" *ngIf="addAgentForm.value.AgentType!=3">
								<div class="col-xs-6">
									<div class="form-group fgroup">
										<div class="checkbox">
											<input id="is_change_departments" type="checkbox"
												formControlName="is_change_departments">
											<label for="is_change_departments"><span>Change Departments</span></label>
										</div>
									</div>
								</div>
								<div class="col-xs-6">
									<div class="form-group fgroup">
										<div class="checkbox">
											<input id="is_password_reset" type="checkbox"
												formControlName="is_password_reset">
											<label for="is_password_reset"><span>Password Reset</span></label>
										</div>
									</div>
								</div>
							</div>
							<div class="row border-row">
								<div class="col-xs-6">
									<div class="form-group fgroup">
										<div class="checkbox">
											<input id="is_quickreply_create" type="checkbox"
												formControlName="is_quickreply_create">
											<label for="is_quickreply_create"><span title="Show Spectate Page">Create
													Quick-Reply</span></label>
										</div>
									</div>
								</div>
								<div class="col-xs-6">
									<div class="form-group fgroup">
										<div class="checkbox">
											<input id="is_quickreply_delete" type="checkbox"
												formControlName="is_quickreply_delete">
											<label for="is_quickreply_delete"><span title="Show Spectate Page">Delete
													Quick-Reply</span></label>
										</div>
									</div>
								</div>
							</div>
							<div class="row border-row" *ngIf="addAgentForm.value.AgentType!=3">
								<div class="col-xs-6">
									<div class="form-group fgroup">
										<div class="checkbox">
											<input id="is_tag_create" type="checkbox" formControlName="is_tag_create">
											<label for="is_tag_create"><span>Create Tag</span></label>
										</div>
									</div>
								</div>
							</div>
							<div class="row border-row">
								<div class="col-xs-6" *ngIf="agentDetails.type!=3">
									<div class="form-group fgroup">
										<div class="checkbox">
											<input id="show_admin_management_page" type="checkbox"
												formControlName="show_admin_management_page">
											<label for="show_admin_management_page"><span
													title="Show Admin Management Page">Management Page</span></label>
										</div>
									</div>
								</div>
								<div class="col-xs-6">
									<div class="form-group fgroup">
										<div class="checkbox">
											<input id="show_tickets_page" type="checkbox"
												formControlName="show_tickets_page">
											<label for="show_tickets_page"><span title="Show Tickets Page">Tickets
													Page</span></label>
										</div>
									</div>
								</div>
								<div class="col-xs-6">
									<div class="form-group fgroup">
										<div class="checkbox">
											<input id="show_contacts_page" type="checkbox"
												formControlName="show_contacts_page">
											<label for="show_contacts_page"><span title="Show Contacts Page">Contacts
													Page</span></label>
										</div>
									</div>
								</div>
								<div class="col-xs-6">
									<div class="form-group fgroup">
										<div class="checkbox">
											<input id="show_reports_page" type="checkbox"
												formControlName="show_reports_page">
											<label for="show_reports_page" title="Show Reports Page"><span>Reports
													Page</span></label>
										</div>
									</div>
								</div>
								<div class="col-xs-6">
									<div class="form-group fgroup">
										<div class="checkbox">
											<input id="show_spectate_page" type="checkbox"
												formControlName="show_spectate_page">
											<label for="show_spectate_page"><span title="Show Spectate Page">Spectate
													Page</span></label>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-secondary" (click)="addAgentModal.hide();clearAgentForm();"><i
							class="fa fa-times" aria-hidden="true"></i>Cancel</button>
					<button type="submit" class="btn btn-gradient" [attr.disabled]="isDupeEmail ? '': null">
						<i *ngIf="isAdding" class="fa fa-spinner fa-spin fa-pulse"></i>
						<i *ngIf="!isAdding" class="fa fa-check"></i>
						Save
					</button>
				</div>
			</form>
		</div>
	</div>
</div>


<div bsModal #resetPwdModal="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog"
	aria-labelledby="dialog-auto-name">
	<div class="modal-dialog modal-md">
		<div class="modal-content">
			<div class="modal-header">
				<div class="header">
					<h4 class="title mg0">Reset Password</h4>
				</div>
			</div>
			<div class="modal-body">
				<div class="row">
					<div class="col-sm-offset-3 col-sm-6 col-xs-12">
						<div class="form-group">
							<label>New Password</label>
							<div class="dflx newpwd">
								<input class="form-control" placeholder="New Password"
									type="{{ hidePwd ? 'password' : 'text' }}" [(ngModel)]="reset_pwd">
								<a (click)="hidePwd=!hidePwd" *ngIf="hidePwd"><i class="fa fa-eye"></i></a>
								<a (click)="hidePwd=!hidePwd" *ngIf="!hidePwd"><i class="fa fa-eye-slash"></i></a>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-secondary" (click)="resetPwdModal.hide();reset_pwd=''"><i
						class="fa fa-times" aria-hidden="true"></i>Cancel</button>
				<button type="submit" class="btn btn-gradient" [disabled]="reset_pwd==''"
					(click)="resetPassword(reset_pwd)">
					<i *ngIf="isResetting" class="fa fa-spinner fa-spin fa-pulse"></i>
					<i *ngIf="!isResetting" class="fa fa-pencil-square-o"></i>
					&nbsp;Change
				</button>
			</div>
		</div>
	</div>
</div>