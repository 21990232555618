<div class="main-content tickets-page">
    <div class="container-fluid">
        <div class="row" *ngIf="showLog" [@slideInOut]>
            <div class="col-md-12">
                <div class="card fix-ticklog">
                 <div class="header">
                    <p class="category"><a (click)="showLog=!showLog;tickCat()"><i class="fa fa-arrow-circle-left" aria-hidden="true"></i>&nbsp;Back</a></p>
                </div>
                <div class="row">
                    <div class="col-md-offset-2 col-md-6 col-xs-8">
                        <main class="ticket-chat" *ngIf="chatlog.length>0" #chatBox>
                            <a *ngIf="chatEnabled" href="javascript:void(0)" class="close-chat" (click)="closeChat()"><i class="fa fa-times"
                                aria-hidden="true"></i></a>
                                <div *ngFor="let chat of chatlog">
                                    <div class="msg left-msg" *ngIf="chat.sender=='bot'">
                                        <div class="dflx mtop-25"
                                        *ngIf="chat.message.cardtypeid!=undefined && chat.message.cardtypeid!=16 && chat.message.cardtypeid != 8">
                                        <div class="msg-img" style="background-image: url(https://canvas.creyoface.com/assets/img/creyoface.gif)"></div>
                                        <!-- [style.background]="'url('+ userDetails.botlogo +')'" -->
                                        <div class="msg-bubble">
                                            <div class="msg-text">
                                                <div *ngIf="chat.message.cardtypeid == 1 && chat.message.feedbacktypeid == 1"
                                                [innerHTML]="chat.message.message"></div> <!-- msg card -->
                                                <div
                                                *ngIf="chat.message.cardtypeid == 36 && chat.message.feedbacktypeid == 1">
                                                <carousel [noWrap]="noWrap"
                                                [interval]="2000">
                                                <slide *ngFor="let item of chat.message.message; let i=index;">
                                                    <img src="{{ item }}" alt="slide">
                                                </slide>
                                            </carousel>
                                        </div> <!-- image card -->
                                        <div
                                        *ngIf="chat.message.cardtypeid == 38 && chat.message.feedbacktypeid == 1">
                                        <!-- <iframe height="120" [src]="chat.message.message"> </iframe> -->
                                        <iframe height="120" [src]="changeIframe(chat.message.message)"> </iframe>


                                    </div>
                                    <!-- video card -->
                                    <div
                                    *ngIf="chat.message.cardtypeid == 37 && chat.message.feedbacktypeid == 1">
                                    <audio controls>
                                        <source src="{{chat.message.message}}" type="audio/mpeg"></audio>
                                        </div> <!-- audio card -->
                                        <div *ngIf="chat.message.cardtypeid == 2"
                                        [innerHTML]="chat.message.message">
                                        <!-- choice card -->
                                        <div class="choices">
                                            <div *ngFor="let opt of chat.message.choices" [innerHTML]="opt.key">
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div *ngIf="chat.message.cardtypeid == 3 && chat.message.feedbacktypeid == 0"
                                        [innerHTML]="chat.message.message"></div> -->
                                        <div *ngIf="chat.message.cardtypeid == 3 && chat.message.feedbacktypeid <= 10"
                                        [innerHTML]="chat.message.message"></div>
                                        <!-- userip feedback = 0 card -->
                                        <div *ngIf="chat.message.cardtypeid == 3 && chat.message.feedbacktypeid > 10"
                                            class="formcard">
                                            <!-- userip feedback > 0 card -->
                                            <div *ngFor="let item of chat.message.message">
                                                <span><input type="text" placeholder="{{item.element}}" disabled></span>
                                            </div>
                                        </div> <!-- userip feedback = 0 card -->
                                        <!-- <div *ngIf="chat.message.cardtypeid == 3 && chat.message.feedbacktypeid > 0">{{chat.message.message}}</div> -->
                                        <div *ngIf="chat.message.cardtypeid == 4 && chat.message.feedbacktypeid == 1"
                                        [innerHTML]="chat.message.message">
                                        <br>
                                        <div class="dflx feedback">
                                            <i class="fa fa-star" aria-hidden="true"></i>
                                            <i class="fa fa-star" aria-hidden="true"></i>
                                            <i class="fa fa-star" aria-hidden="true"></i>
                                            <i class="fa fa-star" aria-hidden="true"></i>
                                            <i class="fa fa-star" aria-hidden="true"></i>
                                        </div>
                                    </div> <!-- feedback card -->
                                    <div *ngIf="chat.message.cardtypeid == 4 && chat.message.feedbacktypeid == 2"
                                    [innerHTML]="chat.message.message"></div> <!-- feedback card -->
                                    <div *ngIf="chat.message.cardtypeid == 4 && chat.message.feedbacktypeid == 3"
                                    [innerHTML]="chat.message.message"></div> <!-- feedback card -->
                                    <div *ngIf="chat.message.cardtypeid == 4 && chat.message.feedbacktypeid == 4"
                                    [innerHTML]="chat.message.message"></div> <!-- feedback card -->
                                    <div *ngIf="chat.message.cardtypeid == 14"
                                    [innerHTML]="chat.message.message"></div> <!-- date card -->
                                    <div *ngIf="chat.message.cardtypeid == 10">
                                        <!-- carousal card -->
                                        <carousel [noWrap]="noWrap"
                                        [interval]="2000">
                                        <slide *ngFor="let item of chat.message.data | keyvalue">
                                            <img src="{{chat.message.data[item.key].img_url}}" alt="slide">
                                            <div class="caption text-center">
                                                <span class="heading"
                                                [innerHTML]="chat.message.data[item.key].heading"></span><br>
                                                <span class="subheading"
                                                [innerHTML]="chat.message.data[item.key].subheading"></span>
                                            </div>
                                            <div class="car-buttons text-center"
                                            *ngFor="let btns of chat.message.data[item.key].choices">
                                            <span [innerHTML]="btns.key"></span>
                                        </div>
                                    </slide>
                                </carousel>
                            </div>
                            <div *ngIf="chat.message.cardtypeid == 18 && chat.message.feedbacktypeid==1">
                                <span class="car-rep-button" [innerHTML]="chat.message.message"></span>
                            </div>
                            <div *ngIf="chat.message.cardtypeid == 18 && chat.message.feedbacktypeid==0">
                                <a [href]="chat.message.message" target="_blank" download>Download File</a>
                            </div>
                            <div class="pax-card" *ngIf="chat.message.cardtypeid == 31">
                                <img class="bg_imgurl" width="100" src="/assets/img/ic_send_pax.svg" alt="bgimg">
                                <p class="heading"[innerHTML]="chat.message.message[0].heading"></p>
                                <p class="subheading"[innerHTML]="chat.message.message[0].sub_heading"></p>
                                <p class="description"[innerHTML]="chat.message.message[0].description"></p>
                            </div>
                            <div class="seat-selection" *ngIf="chat.message.cardtypeid == 32">
                                <img class="bg_imgurl" width="150" src="{{chat.message.message.message.bg_imgurl}}" alt="bgimg">
                                <p class="heading"[innerHTML]="chat.message.message.message.heading"></p>
                                <p class="subheading"[innerHTML]="chat.message.message.message.sub_heading"></p>
                                <p class="description"[innerHTML]="chat.message.message.message.description"></p>
                                <div class="seats">
                                    <div class="row" *ngFor="let item of [].constructor(5);">
                                        <div *ngFor="let item of [].constructor(6);" class="col-xs-2"><img src="/assets/img/seat.png" width="20"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tagsList" *ngIf="chat.cardtypeid == 27">
                        <div class="include" [ngClass]="{'m-auto':chat.message.exclude.length==0, 'dnone':chat.message.include.length==0 }">
                            <span *ngFor="let item of chat.message.include" [style.background]="getTagDetails(item,'color')">
                                {{getTagDetails(item,'name')}}
                            </span>
                        </div>
                        <div class="exclude" [ngClass]="{'m-auto':chat.message.include.length==0, 'dnone':chat.message.exclude.length==0 }">
                            <span *ngFor="let item of chat.message.exclude" [style.background]="getTagDetails(item,'color')">
                                {{getTagDetails(item,'name')}}
                            </span>
                        </div>
                    </div>
                <div class="msg-info"
                *ngIf="chat.message.cardtypeid!=undefined && chat.message.cardtypeid!=16 && chat.message.cardtypeid != 8">
                <div class="msg-info-time">{{chat.time | date:'medium'}}</div>
            </div>
        </div>
        <div *ngIf="chat.isgoal" class="w-100 text-center goal">
            <span *ngIf="chat.goalstatus=='start'" title="Goal Name" class="goalstart"> <img
                src="/assets/img/start.png" width="25"> &nbsp;{{chat.goalname}} <span
                class="status">Started</span></span>
                <span *ngIf="chat.goalstatus=='milestone'" title="Goal: {{chat.goalname}}"
                class="goalmilestone"> <img src="/assets/img/leader.png" width="25">
                &nbsp;{{chat.goalname}} - {{chat.milestone}}</span>
                <span *ngIf="chat.goalstatus=='end'" title="Goal Name" class="goalend"> <img
                    src="/assets/img/target.png" width="25"> &nbsp;{{chat.goalname}} <span
                    class="status">Completed</span></span>
                </div>

                <div class="msg left-msg" *ngIf="chat.sender=='live_agent' && chat.isLive==undefined">
                    <div class="dflx mtop-25" *ngIf="chat.cardtypeid==undefined">
                            <div class="msg-img"
                            [style.background-image]="'url(' + getLAImage(chat.message.agent_id,'img') + ')'" [title]="getLAImage(chat.message.agent_id,'name')">
                            <span>{{getLAImage(chat.message.agent_id,'name')}}</span>
                        </div>
                        <div class="msg-bubble liveagent">
                            <div class="msg-text">
                                <div [innerHTML]="chat.message.message"></div>
                            </div>
                        </div>
                    </div>
                    <div class="mtop-25" *ngIf="chat.cardtypeid == 27">
                                <div class="msg-img"
                                [style.background-image]="'url(' + getLAImage(customerID,'img') + ')'" [title]="getLAImage(customerID,'name')">
                                <span>{{getLAImage(customerID,'name')}}</span>
                            </div>
                            <div class="tagsList w-100">
                                <div class="include m-auto">
                                    <span *ngFor="let item of chat.message.include" [style.background]="getTagDetails(item,'color')">
                                        {{getTagDetails(item,'name')}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    <div class="msg-info">
                        <div class="msg-info-time">{{chat.time | date:'medium'}}</div>
                    </div>
                </div>

           <!--      <div class="msg right-msg" *ngIf="chat.sender=='live_agent' && chat.isLive">
                    <div class="dflxrev mtop-25">
                        <div class="msg-img"
                        style="background-image: url({{agent_img}})">
                    </div>
                    <div class="msg-bubble liveagent">
                        <div class="msg-text">
                            <div>{{chat.message}}</div>
                        </div>
                    </div>
                </div>
                <div class="msg-info">
                    <div class="msg-info-time">{{chat.time | date:'medium'}}</div>
                </div>
            </div>
 -->
            <div class="msg left-msg" *ngIf="chat.sender=='user' && chat.isLive">
                <div class="dflx mtop-25">
                    <div class="msg-img"
                    style="background-image: url(https://canvas.creyoface.com/assets/img/creyoface.gif)">
                </div>
                <div class="msg-bubble">
                    <div class="msg-text">
                        <div>{{chat.message}}</div>
                    </div>
                </div>
            </div>
            <div class="msg-info">
                <div class="msg-info-time">{{chat.time | date:'medium'}}</div>
            </div>
        </div>

        <div class="msg right-msg"
        *ngIf="chat.sender == 'user' && chat.message && chat.isLive==undefined">
        <div class="dflxrev mtop-25">
            <div class="msg-img" style="background-image: url(https://canvas.creyoface.com/assets/img/creyoface.gif)"></div>
            <!-- [style.background]="'url('+ userDetails.botlogouser +')'" -->
            <div class="msg-bubble">
                <div class="msg-text"
                *ngIf="chat.cardtypeid == 1 || chat.cardtypeid == 2 || (chat.cardtypeid == 3 && chat.feedbacktypeid ==0 || chat.cardtypeid == -1)"
                [innerHTML]="chat.message">
            </div>

            <div class="msg-text" *ngIf="chat.cardtypeid == 4 && chat.feedbacktypeid ==1"
            [innerHTML]="chat.message"><i class="fa fa-star" aria-hidden="true"></i>
        </div>
        <div class="msg-text"
        *ngIf="chat.cardtypeid == 4 && (chat.feedbacktypeid == 3 || chat.feedbacktypeid == 4)"
        [innerHTML]="chat.message">
    </div>

    <div class="msg-text" *ngIf="chat.cardtypeid == 26" [innerHTML]="chat.message"></div>

    <div class="msg-text" *ngIf="chat.cardtypeid == 14">
        <!-- date -->
        <span *ngIf="chat.message.from">{{chat.message.from}}</span>
        <span *ngIf="chat.message.to"> - {{chat.message.to}}</span>
    </div>

    <div class="msg-text" *ngIf="chat.cardtypeid == 4 && chat.feedbacktypeid == 2">
        <!-- date -->
        <i *ngIf="chat.message == '0'" class="fa fa-thumbs-down"
        aria-hidden="true"></i>
        <i *ngIf="chat.message == '1'" class="fa fa-thumbs-up"
        aria-hidden="true"></i>
    </div>

    <div class="car-reply" *ngIf="chat.cardtypeid == 10">
        <!-- carousal -->
        <span class="car-rep-head"
        [innerHTML]="chat.message.data.heading"></span><br>
        <span class="car-rep-subhead"
        [innerHTML]="chat.message.data.subheading"></span><br>
        <span class="car-rep-button" [innerHTML]="chat.message.choice"></span>
    </div>

    <div *ngIf="chat.cardtypeid == 3 && chat.feedbacktypeid >= 10">
        <div *ngFor="let item of chat.message | keyvalue">
            <span>{{item.key}} : <b>{{item.value}}</b></span>
        </div>
    </div>

    <div *ngIf="chat.cardtypeid == 3 && chat.feedbacktypeid < 10 && chat.feedbacktypeid != 0">
        <div [innerHTML]="chat.message">
        </div>
    </div>

    <div *ngIf="chat.cardtypeid == 18 && chat.feedbacktypeid == 1">
        <a [href]="chat.message" target="_blank" download>Uploaded File</a>
    </div> 

    <div class="msg-text" *ngIf="chat.cardtypeid == 31">
        <div *ngFor="let item of chat.message; let i=index;" class="seat-selected">
            <span *ngFor="let pax of item | keyvalue;">{{pax.key}} : {{pax.value}}</span>
        </div>
    </div>

    <div class="msg-text" *ngIf="chat.cardtypeid == 32">
        <div *ngFor="let item of chat.message" class="seat-selected">
            <span>{{item.label_key}}</span>
        </div>
    </div>

</div>

</div>
<div class="msg-info">
    <div class="msg-info-time">{{chat.time | date:'medium'}}</div>
</div>
</div>
</div>
</main>
<br>
</div>
<div class="col-md-3 col-xs-3 pdlr5">

    <div class="user-details">
        <div class="card panel panel-default">
            <div class="panel-body">
                <ul>
                    <li class="text-center tfont" *ngIf="!isEditStatus"><b>#{{ticketDetail.id}}</b>&nbsp;
                        <small *ngIf="ticketDetail.status=='open'"><i class="fa fa-circle text-danger"></i> Open </small>
                        <small *ngIf="ticketDetail.status=='progress'"><i class="fa fa-circle text-warning"></i> ress </small>
                        <small *ngIf="ticketDetail.status=='closed'"><i class="fa fa-circle text-success"></i> Closed </small>
                        <a (click)="isEditStatus=true"><i class="fa fa-pencil-square-o" aria-hidden="true"></i></a>
                    </li>
                    <li class="text-center tfont edit" *ngIf="isEditStatus"><b>#{{ticketDetail.id}}</b>&nbsp;
                        <div class="dflx">
                            <select class="form-control" (change)="changeTicketStatus($event)">
                                <option  [attr.selected]="ticketDetail.status =='open' ? true : null" value="open">Open</option>
                                <option [attr.selected]="ticketDetail.status =='progress' ? true : null" value="progress">In-Progress</option>
                                <option [attr.selected]="ticketDetail.status =='closed' ? true : null" value="closed">Closed</option>
                            </select>
                            <a (click)="isEditStatus=false"><i class="fa fa-pencil-square-o" aria-hidden="true"></i></a>
                        </div>
                    </li>
                    <li class="text-center" *ngIf="ticketDetail.name">Name: <b>{{ticketDetail.name}}</b></li>
                    <li class="text-center" *ngIf="ticketDetail.email">Email: <b>{{ticketDetail.email}}</b></li>
                    <li class="text-center" *ngIf="ticketDetail.phone">Phone: <b>{{ticketDetail.phone}}</b></li>
                    <li *ngIf="ticketDetail.created_at">Created at <b>{{ticketDetail.created_at | date:'medium'}}</b></li>
                    <li *ngIf="ticketDetail.closed_at">Closed at <b>{{ticketDetail.closed_at | date:'medium'}}</b></li>
                </ul>
            </div>
        </div>
    </div>

    <div class="user-details">
        <div class="card panel panel-default">
            <div class="panel-heading text-center"><b>Details</b>
                <a class="pull-right" (click)="showDetails=!showDetails;showTickets=false;">
                    <i *ngIf="!showDetails" class="fa fa-bars" aria-hidden="true"></i>
                    <i *ngIf="showDetails" class="fa fa-times" aria-hidden="true"></i>
                </a>
            </div>
            <div class="panel-body" *ngIf="showDetails" [@slideDown]>
                <ul>
                    <li *ngIf="userDetail?.UserName">Name : <span>{{userDetail.UserName}}</span></li>
                    <li *ngIf="userDetail?.Company">Company : <span>{{userDetail.Company}}</span></li>
                    <li *ngIf="userDetail?.Phone">Phone : <span>{{userDetail.Phone}}</span></li>
                    <li *ngIf="userDetail?.ChannelName">Channel : <span>{{userDetail.ChannelName}}</span></li>
                    <li *ngIf="userDetail?.email" title="{{userDetail['email']}}">Email : <span><a href="{{userDetail['email']}}" target="_blank">{{userDetail['email'].length > 25 ? userDetail['email'].slice(0, 25) + '...' : userDetail['email']}}</a></span></li>
                    <li *ngIf="userDetail?.Url" title="{{userDetail['Url']}}">URL : <span><a href="{{userDetail['Url']}}" target="_blank">{{userDetail['Url'].length > 30 ? userDetail['Url'].slice(0, 30) + '...' : userDetail['Url']}}</a></span></li>
                    <li *ngIf="userDetail?.Browser">Browser : <span>{{userDetail.Browser}}</span></li>
                    <li *ngIf="userDetail?.Country">Country : <span>{{userDetail.Country}}</span></li>
                    <li *ngIf="userDetail?.Continent">Continent : <span>{{userDetail.Continent}}</span></li>
                    <li *ngIf="!userDetail?.UserName && !userDetail?.Company && !userDetail?.Phone && !userDetail?.ChannelName && !userDetail?.email && !userDetail?.Url && !userDetail?.Browser && !userDetail?.Country && !userDetail?.Continent"
                    class="text-center">Not provided</li>
                </ul>
            </div>
        </div>
    </div>

    <div class="previous-tickets">
        <div class="card panel panel-default">
            <div class="panel-heading text-center"><b>Tickets by this user</b>
                <a class="pull-right" (click)="showTickets=!showTickets;showDetails=false;">
                    <i *ngIf="!showTickets" class="fa fa-bars" aria-hidden="true"></i>
                    <i *ngIf="showTickets" class="fa fa-times" aria-hidden="true"></i>
                </a>
            </div>
            <div class="panel-body scroll-tickets" *ngIf="showTickets" [@slideDown]>
                <ul>
                    <li *ngFor="let item of customerTickets; let i=index;">
                        <div class="card bcard">
                            <div class="header">
                                <h4 class="title tfont"><b>#{{item.id}}&nbsp;</b>
                                    <a class="pull-right ticket-list" (click)="ticketDetails(i,item.id)">
                                        <i class="fa fa-eye" *ngIf="!selected[i]"></i>
                                        <i class="fa fa-spin fa-spinner fa-pulse" *ngIf="selected[i]"></i>
                                    </a>
                                    <small *ngIf="item.status=='open'"><i class="fa fa-circle text-danger"></i> Open </small>
                                    <small *ngIf="item.status=='progress'"><i class="fa fa-circle text-warning"></i> In-Progress </small>
                                    <small *ngIf="item.status=='closed'"><i class="fa fa-circle text-success"></i> Closed </small>
                                </h4>

                                <p class="category"><small>Created at : {{item.created_at | date:'medium'}}</small></p>
                            </div>
                            <div class="footer">
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
</div>
</div>
</div>
</div>
<!-- top portion down -->
<div class="row" *ngIf="!showLog" [@slideInOut]>
    <div class="col-md-12">
        <div class="card counts">
            <div class="content all-icons">
                <div class="dflx">
                    <div class="form-group w20 padr10">
                        <small class="sort-label"> Date:&nbsp;</small>
                        <input type="text"
                        class="form-control sortby"
                        ngxDaterangepickerMd
                        [(ngModel)]="selected_date"
                        (change)="getTicketsData()"
                        [alwaysShowCalendars]="true"
                        [ranges]="ranges"
                        [showClearButton]="true"
                        [locale]="locale"
                        placeholder="Select date range..."/>
                    </div>
                    <div class="form-group w20">
                        <small class="sort-label"> Channel:&nbsp;</small>
                        <select class="form-control sortby" [(ngModel)]="channelId" (change)="getTicketsData()">
                            <option value="all">All</option>
                            <option *ngFor="let item of channels" value="{{item.id}}">{{item.name}}</option>
                        </select>
                    </div>
                </div>
                <div class="row">
                  <div class="font-icon-list col-lg-offset-3 col-md-offset-2 col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6" (click)="selType='open';getTicketCat();isOpen=true;isProgress=false;isClosed=false;">
                    <div class="font-icon-detail grad" [ngClass]="{'selected':isOpen}">
                        <div class="dflx">
                            <div class="mauto">
                                <i class="pe-7s-close-circle text-danger"></i> 
                            </div>
                            <div class="mauto pd05">
                                <h4 class="title">{{ticketsCounts.openTicketsCount}}</h4>
                                <p class="category">Open</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6" (click)="selType='progress';getTicketCat();isOpen=false;isProgress=true;isClosed=false;">
                    <div class="font-icon-detail grad" [ngClass]="{'selected':isProgress}">
                        <div class="dflx">
                            <div class="mauto">
                                <i class="pe-7s-ticket text-warning" aria-hidden="true"></i> 
                            </div>
                            <div class="mauto pd05">
                                <h4 class="title">{{ticketsCounts.inprogressTicketsCount}}</h4>
                                <p class="category">In-Progress</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6" (click)="selType='closed';getTicketCat();isOpen=false;isProgress=false;isClosed=true;">
                    <div class="font-icon-detail grad" [ngClass]="{'selected':isClosed}">
                        <div class="dflx">
                            <div class="mauto">
                                <i class="pe-7s-check text-success"></i>
                            </div>
                            <div class="mauto pd05">
                                <h4 class="title">{{ticketsCounts.closedTicketsCount}}</h4>
                                <p class="category"> Closed</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="col-md-12 fix-tickets">
    <div class="w-100 text-center" *ngIf="isFetching"><i class="fa fa-spin fa-spinner fa-pulse"></i></div>
    <div class="row tickets" *ngIf="!isFetching">
        <div class="col-md-12 col-sm-12 col-xs-12 dgrid" *ngIf="tickets.length>0">
            <div class="dflx mauto">
                <div class="form-group w120 padr10">
                    <small class="sort-label"> Page:&nbsp;</small>
                    <select class="form-control sortby" [(ngModel)]="pageNo" (change)="getTicketCat()">
                        <option *ngFor='let in of counter();let i = index' value="{{i+1}}">{{i+1}}</option>
                    </select>
                </div>
                <div class="form-group w120">
                    <small class="sort-label"> Results:&nbsp;</small>
                    <select class="form-control sortby" [(ngModel)]="offset" (change)="getTicketCat()">
                        <option value="16">16</option>
                        <option value="48">48</option>
                        <option value="80">80</option>
                        <option value="120">160</option>
                        <option value="500">500</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="col-md-12 col-sm-12 col-xs-12" *ngIf="tickets.length==0 && isSearched">
            <h5 class="text-center mtop125 red">No Tickets Found</h5>
        </div>
        <div class="col-md-3 col-sm-4 col-xs-6" *ngFor="let item of tickets; let i=index;">
            <div class="card">
                <div class="header">
                    <h4 class="title"><b><img class="countryflag" src="https://www.countryflags.io/{{getCountryCode(item.Country)}}/flat/32.png" onerror="this.src='/assets/img/flag.png';" height="20">&nbsp;#{{item.TicketID}}</b>
                        <a (click)="ticketDetails(i,item.TicketID)" class="ticket-eye"><i class="fa fa-eye" *ngIf="!selected[i]"></i>
                            <i class="fa fa-spin fa-spinner fa-pulse" *ngIf="selected[i]"></i></a>
                            <small *ngIf="item.Status=='open'"><i class="fa fa-circle text-danger"></i>Open</small>
                            <small *ngIf="item.Status=='progress'"><i class="fa fa-circle text-warning"></i>In-Progress </small>
                            <small *ngIf="item.Status=='closed'"><i class="fa fa-circle text-success"></i>Closed </small>
                        </h4>
                        <p class="category">Name : 
                            <b *ngIf="item?.cf_name">{{item?.cf_name}}</b>
                            <b *ngIf="!item?.cf_name"> ----- </b>
                        </p>
                        <p class="category">Phone : 
                            <b *ngIf="item?.cf_phone">{{item?.cf_phone}}</b>
                            <b *ngIf="!item?.cf_phone"> ----- </b>
                        </p>
                        <p class="category">Email : 
                            <b *ngIf="item?.cf_email" title="{{item['cf_email']}}">{{item['cf_email'].length > 25 ? item['cf_email'].slice(0, 25) + '...' : item['cf_email']}}</b>
                            <b *ngIf="!item?.cf_email"> ----- </b>
                        </p>
                        <p class="category">Channel : 
                            <i class="fa fa-globe" *ngIf="item?.ChannelId==1" title="WEB"></i>
                            <i class="fa fa-facebook" *ngIf="item?.ChannelId==2" title="FACEBOOK"></i>
                            <i class="fa fa-telegram" *ngIf="item?.ChannelId==3" title="TELEGRAM"></i>
                            <i class="fa fa-comments-o" *ngIf="item?.ChannelId==4" title="ZENDESK"></i>
                            <i class="fa fa-whatsapp" *ngIf="item?.ChannelId==5" title="WHATSAPP"></i>
                            <i class="fa fa-question-circle" *ngIf="item?.ChannelId!=1 && item?.ChannelId!=2 &&  item?.ChannelId!=3 &&  item?.ChannelId!=4 &&  item?.ChannelId!=5" title="OTHERS"></i>
                            <b *ngIf="!item?.ChannelName"> ----- </b>
                        </p>
                    </div>
                    <div class="content list">
                        <div class="footer">
                            <p class="category">Created at : {{item.Date | date:'medium'}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
</div>



