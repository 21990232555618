import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ServicesService } from '../../services/services.service';
import { showNotification } from '../../services/notification';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators, AbstractControl } from '@angular/forms';

@Component({
	selector: 'app-qreplies',
	templateUrl: './qreplies.component.html',
	styleUrls: ['./qreplies.component.scss']
})
export class QrepliesComponent implements OnInit {

	public botID: number = parseInt(localStorage.getItem('botID'), 10);
	public customerID: number = parseInt(localStorage.getItem('Customer_ID'), 10);
	public customerType: number = parseInt(localStorage.getItem('Customer_Type'), 10);
	replies: any[];
	public isFetchReplies: boolean = false;
	public isAdding: boolean = false;
	public isEdit: boolean = false;
	public isDeleting: boolean = false;
	public isDuplicate: boolean = false;
	public addQreply: FormGroup;
	public selectedQkey = '';
	public loadingBots: boolean = false;
	public selectedBotid: number = this.botID;
	public botList = [];
	permissions: any;
	@ViewChild('addQreplyModal', { static: false }) addQreplyModal: ModalDirective;
	@ViewChild('deleteQreplyModal', { static: false }) deleteQreplyModal: ModalDirective;
	mentionConfig = {}
	// @Input() mentionConfig = { mentions: [] };

	constructor(private dataService: ServicesService, private _fb: FormBuilder) { }

	ngOnInit(): void {
		// this.getAllReplies();
		this.clearAddQreply();
	}

	clearAddQreply() {
		this.addQreply = this._fb.group({
			'AgentID': new FormControl(this.customerID),
			'AgentType': new FormControl(this.customerType),
			'quick_key': new FormControl(''),
			'quick_value': new FormArray([])
		});
		this.onAddVar();
	}

	getAllReplies(botid) {
		this.getBotList();
		this.isFetchReplies = true;
		this.dataService.getAllQuickReplies({
			BotID: botid,
			AgentID: this.customerID
		}).subscribe(
			res => {
				if (res.status_code == 200) {
					this.replies = res.data;
					this.permissions = res.permissions;

					this.mentionConfig = new Object();
					this.mentionConfig = { mentions: [] };

					for (let i in this.replies) {
						this.mentionConfig['mentions'].push({
							items: this.replies[i].quick_value,
							triggerChar: this.replies[i].quick_key,
							maxItems: 10,
							returnTrigger: false,
							mentionSelect: this.onMentionSelect
						});
					}

					if (this.replies.length == 0) {
						showNotification('warning', '<span>No Replies Found!</span>');
					}
				} else if (res.status_code == 401) {
					this.dataService.logout();
				} else {
					showNotification('warning', res.status);
				}
				this.isFetchReplies = false;
			}, error => {
				console.log(error);
				this.isFetchReplies = false;
				showNotification('danger', error);
			})
	}

	get variablesArray() {
		return <FormArray>this.addQreply.get('quick_value');
	}

	onAddVar(): void {
		this.variablesArray.push(new FormControl(''));
	}

	onRemoveVar = (index) => {
		this.variablesArray.removeAt(index);
	}

	submitQreply(data) {
		this.isAdding = true;
		data.BotID = this.botID;
		if (!this.isEdit) {
			this.dataService.createQuickReply(data).subscribe(
				res => {
					if (res.status_code == 200) {
						this.getAllReplies(this.botID);
						this.clearAddQreply();
						this.addQreplyModal.hide();
						showNotification('success', '<span>Added Successfully!</span>');
					} else if (res.status_code == 401) {
						this.dataService.logout();
					} else {
						showNotification('warning', res.status);
					}
					this.isAdding = false;
				}, error => {
					console.log(error);
					this.isAdding = false;
					showNotification('danger', error);
				})
		} else {
			this.dataService.editQuickReply(data).subscribe(
				res => {
					if (res.status_code == 200) {
						this.getAllReplies(this.botID);
						this.clearAddQreply();
						this.addQreplyModal.hide();
						showNotification('success', '<span>Edited Successfully!</span>');
						this.isEdit = false;
					} else if (res.status_code == 401) {
						this.dataService.logout();
					} else {
						showNotification('warning', res.status);
					}
					this.isAdding = false;
				}, error => {
					console.log(error);
					this.isAdding = false;
					showNotification('danger', error);
				})
		}
	}

	editQreply(row) {
		this.addQreply = this._fb.group({
			'AgentID': new FormControl(this.customerID),
			'AgentType': new FormControl(this.customerType),
			'id': new FormControl(row.id),
			'quick_key': new FormControl(row.quick_key),
			'quick_value': new FormArray([])
		});
		for (let i in row.quick_value) {
			this.variablesArray.push(new FormControl(row.quick_value[i]));
		}
		this.isEdit = true;
		this.addQreplyModal.show();
	}

	onMentionSelect(selection) {
		// return selection.label;
		return `${selection.label}`;
	}

	deleteQreply() {
		this.isDeleting = true;
		let data = {
			BotID: this.botID,
			AgentID: this.customerID,
			AgentType: this.customerType,
			quick_key: this.selectedQkey
		}
		this.dataService.deleteQuickReply(data).subscribe(
			res => {
				if (res.status_code == 200) {
					this.deleteQreplyModal.hide();
					this.getAllReplies(this.botID);
					showNotification('success', '<span>Deleted Successfully!</span>');
				} else if (res.status_code == 401) {
					this.dataService.logout();
				} else {
					showNotification('warning', res.status);
				}
				this.isDeleting = false;
			}, error => {
				console.log(error);
				this.isDeleting = false;
				showNotification('danger', error);
			})
	}

	checkDuplicate(event) {
		for (let i in this.replies) {
			if (this.replies[i].quick_key == event.target.value) {
				this.isDuplicate = true;
				showNotification('warning', '<span>Duplicate key not allowed! Try another.');
			} else if (event.target.value.length > 1) {
				this.isDuplicate = true;
				showNotification('warning', '<span>Only single character is allowed.');
			} else {
				this.isDuplicate = false;

			}
		}

	}

	getBotList() {
		this.loadingBots = true;
		this.dataService.getBotList({
			CustomerID: this.customerID,
			CustomerType: this.customerType
		})
			.subscribe((res) => {
				if (res.status_code == 200) {
					this.botList = res.data;
				}
				this.loadingBots = false;
			}
				,
				(error) => {
					this.loadingBots = false;
					console.log("Bot list error");
				}
			);
	}

	getQreplyofBot(event) {
		this.botID = event.target.value;
		this.getAllReplies(event.target.value);
	}

}
