<div class="main-content people">
	<div class="container-fluid">
		<div class="row" *ngIf="showLog" [@slideInOut]>
			<div class="col-md-12">
				<div class="card fix-peoplog">
					<div class="header">
						<p class="category"><a (click)="showLog=!showLog;includeAdd=[];"><i class="fa fa-arrow-circle-left" aria-hidden="true"></i>&nbsp;Back</a></p>
					</div>
					<div class="row">
						<div class="col-md-offset-2 col-md-6 col-xs-8">
							<main class="ticket-chat" *ngIf="chatlog.length>0" #chatBox>
								<a *ngIf="chatEnabled" href="javascript:void(0)" class="close-chat" (click)="closeChat()"><i class="fa fa-times"
									aria-hidden="true"></i></a>
									<div *ngFor="let chat of chatlog">
										<div class="msg left-msg" *ngIf="chat.sender=='bot'">
											<div class="dflx mtop-25"
											*ngIf="chat.message.cardtypeid!=undefined && chat.message.cardtypeid!=16 && chat.message.cardtypeid != 8">
											<div class="msg-img" style="background-image: url(https://canvas.creyoface.com/assets/img/creyoface.gif)"></div>
											<!-- [style.background]="'url('+ userDetails.botlogo +')'" -->
											<div class="msg-bubble">
												<div class="msg-text">
													<div *ngIf="chat.message.cardtypeid == 1 && chat.message.feedbacktypeid == 1"
													[innerHTML]="chat.message.message"></div> <!-- msg card -->
													<div
													*ngIf="chat.message.cardtypeid == 36 && chat.message.feedbacktypeid == 1">
													<carousel [noWrap]="noWrap"
													[interval]="2000">
													<slide *ngFor="let item of chat.message.message; let i=index;">
														<img src="{{ item }}" alt="slide">
													</slide>
												</carousel>
											</div> <!-- image card -->
											<div
											*ngIf="chat.message.cardtypeid == 38 && chat.message.feedbacktypeid == 1">
											<!-- <iframe height="120" [src]="chat.message.message"> </iframe> -->
											<iframe height="120" [src]="changeIframe(chat.message.message)"> </iframe>


										</div>
										<!-- video card -->
										<div
										*ngIf="chat.message.cardtypeid == 37 && chat.message.feedbacktypeid == 1">
										<audio controls>
											<source src="{{chat.message.message}}" type="audio/mpeg"></audio>
											</div> <!-- audio card -->
											<div *ngIf="chat.message.cardtypeid == 2"
											[innerHTML]="chat.message.message">
											<!-- choice card -->
											<div class="choices">
												<div *ngFor="let opt of chat.message.choices" [innerHTML]="opt.key">
												</div>
											</div>
										</div>
                                    <!-- <div *ngIf="chat.message.cardtypeid == 3 && chat.message.feedbacktypeid == 0"
                                    	[innerHTML]="chat.message.message"></div> -->
                                    	<div *ngIf="chat.message.cardtypeid == 3 && chat.message.feedbacktypeid <= 10"
                                    	[innerHTML]="chat.message.message"></div>
                                    	<!-- userip feedback = 0 card -->
                                    	<div *ngIf="chat.message.cardtypeid == 3 && chat.message.feedbacktypeid > 10"
                                    		class="formcard">
                                    		<!-- userip feedback > 0 card -->
                                    		<div *ngFor="let item of chat.message.message">
                                    			<span><input type="text" placeholder="{{item.element}}" disabled></span>
                                    		</div>
                                    	</div> <!-- userip feedback = 0 card -->
                                    	<!-- <div *ngIf="chat.message.cardtypeid == 3 && chat.message.feedbacktypeid > 0">{{chat.message.message}}</div> -->
                                    	<div *ngIf="chat.message.cardtypeid == 4 && chat.message.feedbacktypeid == 1"
                                    	[innerHTML]="chat.message.message">
                                    	<br>
                                    	<div class="dflx feedback">
                                    		<i class="fa fa-star" aria-hidden="true"></i>
                                    		<i class="fa fa-star" aria-hidden="true"></i>
                                    		<i class="fa fa-star" aria-hidden="true"></i>
                                    		<i class="fa fa-star" aria-hidden="true"></i>
                                    		<i class="fa fa-star" aria-hidden="true"></i>
                                    	</div>
                                    </div> <!-- feedback card -->
                                    <div *ngIf="chat.message.cardtypeid == 4 && chat.message.feedbacktypeid == 2"
                                    [innerHTML]="chat.message.message"></div> <!-- feedback card -->
                                    <div *ngIf="chat.message.cardtypeid == 4 && chat.message.feedbacktypeid == 3"
                                    [innerHTML]="chat.message.message"></div> <!-- feedback card -->
                                    <div *ngIf="chat.message.cardtypeid == 4 && chat.message.feedbacktypeid == 4"
                                    [innerHTML]="chat.message.message"></div> <!-- feedback card -->
                                    <div *ngIf="chat.message.cardtypeid == 14"
                                    [innerHTML]="chat.message.message"></div> <!-- date card -->
                                    <div *ngIf="chat.message.cardtypeid == 10">
                                    	<!-- carousal card -->
                                    	<carousel [noWrap]="noWrap"
                                    	[interval]="2000">
                                    	<slide *ngFor="let item of chat.message.data | keyvalue">
                                    		<img src="{{chat.message.data[item.key].img_url}}" alt="slide">
                                    		<div class="caption text-center">
                                    			<span class="heading"
                                    			[innerHTML]="chat.message.data[item.key].heading"></span><br>
                                    			<span class="subheading"
                                    			[innerHTML]="chat.message.data[item.key].subheading"></span>
                                    		</div>
                                    		<div class="car-buttons text-center"
                                    		*ngFor="let btns of chat.message.data[item.key].choices">
                                    		<span [innerHTML]="btns.key"></span>
                                    	</div>
                                    </slide>
                                </carousel>
                            </div>
                            <div *ngIf="chat.message.cardtypeid == 18 && chat.message.feedbacktypeid==1">
                            	<span class="car-rep-button" [innerHTML]="chat.message.message"></span>
                            </div>
                            <div *ngIf="chat.message.cardtypeid == 18 && chat.message.feedbacktypeid==0">
                            	<a [href]="chat.message.message" target="_blank" download>Download File</a>
                            </div>
                            <div class="pax-card" *ngIf="chat.message.cardtypeid == 31">
                            	<img class="bg_imgurl" width="100" src="/assets/img/ic_send_pax.svg" alt="bgimg">
                            	<p class="heading"[innerHTML]="chat.message.message[0].heading"></p>
                            	<p class="subheading"[innerHTML]="chat.message.message[0].sub_heading"></p>
                            	<p class="description"[innerHTML]="chat.message.message[0].description"></p>
                            </div>
                            <div class="seat-selection" *ngIf="chat.message.cardtypeid == 32">
                            	<img class="bg_imgurl" width="150" src="{{chat.message.message.message.bg_imgurl}}" alt="bgimg">
                            	<p class="heading"[innerHTML]="chat.message.message.message.heading"></p>
                            	<p class="subheading"[innerHTML]="chat.message.message.message.sub_heading"></p>
                            	<p class="description"[innerHTML]="chat.message.message.message.description"></p>
                            	<div class="seats">
                            		<div class="row" *ngFor="let item of [].constructor(5);">
                            			<div *ngFor="let item of [].constructor(6);" class="col-xs-2"><img src="/assets/img/seat.png" width="20"></div>
                            		</div>
                            	</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tagsList" *ngIf="chat.cardtypeid == 27">
                	<div class="include" [ngClass]="{'m-auto':chat.message.exclude.length==0, 'dnone':chat.message.include.length==0 }">
                		<span *ngFor="let item of chat.message.include" [style.background]="getTagDetails(item,'color')">
                			{{getTagDetails(item,'name')}}
                		</span>
                	</div>
                	<div class="exclude" [ngClass]="{'m-auto':chat.message.include.length==0, 'dnone':chat.message.exclude.length==0 }">
                		<span *ngFor="let item of chat.message.exclude" [style.background]="getTagDetails(item,'color')">
                			{{getTagDetails(item,'name')}}
                		</span>
                	</div>
                </div>
                <div class="msg-info"
                *ngIf="chat.message.cardtypeid!=undefined && chat.message.cardtypeid!=16 && chat.message.cardtypeid != 8">
                <div class="msg-info-time">{{chat.time | date:'medium'}}</div>
            </div>
        </div>
        <div *ngIf="chat.isgoal" class="w-100 text-center goal">
        	<span *ngIf="chat.goalstatus=='start'" title="Goal Name" class="goalstart"> <img
        		src="/assets/img/start.png" width="25"> &nbsp;{{chat.goalname}} <span
        		class="status">Started</span></span>
        		<span *ngIf="chat.goalstatus=='milestone'" title="Goal: {{chat.goalname}}"
        		class="goalmilestone"> <img src="/assets/img/leader.png" width="25">
        	&nbsp;{{chat.goalname}} - {{chat.milestone}}</span>
        	<span *ngIf="chat.goalstatus=='end'" title="Goal Name" class="goalend"> <img
        		src="/assets/img/target.png" width="25"> &nbsp;{{chat.goalname}} <span
        		class="status">Completed</span></span>
        	</div>

        	<div class="msg left-msg" *ngIf="chat.sender=='live_agent' && chat.isLive==undefined">
        		<div class="dflx mtop-25" *ngIf="chat.cardtypeid==undefined">
        			<div class="msg-img"
        			[style.background-image]="'url(' + getLAImage(chat.message.agent_id,'img') + ')'" [title]="getLAImage(chat.message.agent_id,'name')">
        			<span>{{getLAImage(chat.message.agent_id,'name')}}</span>
        		</div>
        		<div class="msg-bubble liveagent">
        			<div class="msg-text">
        				<div [innerHTML]="chat.message.message"></div>
        			</div>
        		</div>
        	</div>
        	<div class="mtop-25" *ngIf="chat.cardtypeid == 27">
        		<div class="msg-img"
        		[style.background-image]="'url(' + getLAImage(customerID,'img') + ')'" [title]="getLAImage(customerID,'name')">
        		<span>{{getLAImage(customerID,'name')}}</span>
        	</div>
        	<div class="tagsList w-100">
        		<div class="include m-auto">
        			<span *ngFor="let item of chat.message.include" [style.background]="getTagDetails(item,'color')">
        				{{getTagDetails(item,'name')}}
        			</span>
        		</div>
        	</div>
        </div>
        <div class="msg-info">
        	<div class="msg-info-time">{{chat.time | date:'medium'}}</div>
        </div>
    </div>

           <!--      <div class="msg right-msg" *ngIf="chat.sender=='live_agent' && chat.isLive">
                    <div class="dflxrev mtop-25">
                        <div class="msg-img"
                        style="background-image: url({{agent_img}})">
                    </div>
                    <div class="msg-bubble liveagent">
                        <div class="msg-text">
                            <div>{{chat.message}}</div>
                        </div>
                    </div>
                </div>
                <div class="msg-info">
                    <div class="msg-info-time">{{chat.time | date:'medium'}}</div>
                </div>
            </div>
        -->
        <div class="msg left-msg" *ngIf="chat.sender=='user' && chat.isLive">
        	<div class="dflx mtop-25">
        		<div class="msg-img"
        		style="background-image: url(https://canvas.creyoface.com/assets/img/creyoface.gif)">
        	</div>
        	<div class="msg-bubble">
        		<div class="msg-text">
        			<div>{{chat.message}}</div>
        		</div>
        	</div>
        </div>
        <div class="msg-info">
        	<div class="msg-info-time">{{chat.time | date:'medium'}}</div>
        </div>
    </div>

    <div class="msg right-msg"
    *ngIf="chat.sender == 'user' && chat.message && chat.isLive==undefined">
    <div class="dflxrev mtop-25">
    	<div class="msg-img" style="background-image: url(https://canvas.creyoface.com/assets/img/creyoface.gif)"></div>
    	<!-- [style.background]="'url('+ userDetails.botlogouser +')'" -->
    	<div class="msg-bubble">
    		<div class="msg-text"
    		*ngIf="chat.cardtypeid == 1 || chat.cardtypeid == 2 || (chat.cardtypeid == 3 && chat.feedbacktypeid ==0 || chat.cardtypeid == -1)"
    		[innerHTML]="chat.message">
    	</div>

    	<div class="msg-text" *ngIf="chat.cardtypeid == 4 && chat.feedbacktypeid ==1"
    	[innerHTML]="chat.message"><i class="fa fa-star" aria-hidden="true"></i>
    </div>
    <div class="msg-text"
    *ngIf="chat.cardtypeid == 4 && (chat.feedbacktypeid == 3 || chat.feedbacktypeid == 4)"
    [innerHTML]="chat.message">
</div>

<div class="msg-text" *ngIf="chat.cardtypeid == 26" [innerHTML]="chat.message"></div>

<div class="msg-text" *ngIf="chat.cardtypeid == 14">
	<!-- date -->
	<span *ngIf="chat.message.from">{{chat.message.from}}</span>
	<span *ngIf="chat.message.to"> - {{chat.message.to}}</span>
</div>

<div class="msg-text" *ngIf="chat.cardtypeid == 4 && chat.feedbacktypeid == 2">
	<!-- date -->
	<i *ngIf="chat.message == '0'" class="fa fa-thumbs-down"
	aria-hidden="true"></i>
	<i *ngIf="chat.message == '1'" class="fa fa-thumbs-up"
	aria-hidden="true"></i>
</div>

<div class="car-reply" *ngIf="chat.cardtypeid == 10">
	<!-- carousal -->
	<span class="car-rep-head"
	[innerHTML]="chat.message.data.heading"></span><br>
	<span class="car-rep-subhead"
	[innerHTML]="chat.message.data.subheading"></span><br>
	<span class="car-rep-button" [innerHTML]="chat.message.choice"></span>
</div>

<div *ngIf="chat.cardtypeid == 3 && chat.feedbacktypeid >= 10">
	<div *ngFor="let item of chat.message | keyvalue">
		<span>{{item.key}} : <b>{{item.value}}</b></span>
	</div>
</div>

<div *ngIf="chat.cardtypeid == 3 && chat.feedbacktypeid < 10 && chat.feedbacktypeid != 0">
	<div [innerHTML]="chat.message">
	</div>
</div>

<div *ngIf="chat.cardtypeid == 18 && chat.feedbacktypeid == 1">
	<a [href]="chat.message" target="_blank" download>Uploaded File</a>
</div> 

<div class="msg-text" *ngIf="chat.cardtypeid == 31">
	<div *ngFor="let item of chat.message; let i=index;" class="seat-selected">
		<span *ngFor="let pax of item | keyvalue;">{{pax.key}} : {{pax.value}}</span>
	</div>
</div>

<div class="msg-text" *ngIf="chat.cardtypeid == 32">
	<div *ngFor="let item of chat.message" class="seat-selected">
		<span>{{item.label_key}}</span>
	</div>
</div>

</div>

</div>
<div class="msg-info">
	<div class="msg-info-time">{{chat.time | date:'medium'}}</div>
</div>
</div>
</div>
</main>
<br>
</div>
<div class="col-md-3 col-xs-3 pdlr5 fix-height">
	<div class="add-tag">
		<div class="card panel panel-default">
			<div class="panel-heading text-center"><b><i class="fa fa-tag"></i>&nbsp;Tags List</b>
				<a class="pull-right" (click)="isAddTag=!isAddTag" title="Add/Edit Tags">
					<span class="add-tag" *ngIf="!isAddTag"><i class="fa fa-plus" aria-hidden="true"></i>Add</span>
					<i *ngIf="isAddTag" class="fa fa-times" aria-hidden="true"></i>
				</a>
			</div>
			<div class="panel-body">
				<!-- <div class="w-100 text-center mb10"><button (click)="isAddTag=!isAddTag" class="btn btn-sm btn-gradient">Add/Edit Tag</button></div> -->
				<div class="tag-drop" *ngIf="isAddTag">
					<select class="form-control" (change)="moveAddTag(addTagDiv.value)" #addTagDiv>
						<option value="null" selected disabled>Select tag</option>
						<option *ngFor="let item of tagsListCopy" [value]="item.tag_id" [attr.disabled]="item.selected ? '' : null">{{item.tag_name}}</option>
					</select>
				</div>
				<div class="tags" *ngIf="includeAdd?.length>0">
					<span *ngFor="let item of includeAdd" [style.background]="getTagColor(item)" [ngClass]="{ 'strike': !isTagActive(item)}">{{getTagName(item)}}&nbsp;<a *ngIf="isAddTag" (click)="removeTagAdd(item)"><i class="fa fa-trash"></i></a></span>
				</div>
				<div class="w-100 text-right mtop10" *ngIf="includeAdd?.length>0 && isAddTag">
					<button (click)="saveTag(includeAdd)" class="btn btn-sm btn-gradient">
						<i class="fa fa-floppy-o" *ngIf="!isAddingTag"></i>
						<i class="fa fa-spin fa-spinner fa-pulse" *ngIf="isAddingTag"></i>
						Save
					</button>
				</div>
			</div>
		</div>
	</div>
	
	<div class="user-details">
		<div class="card panel panel-default">
			<div class="panel-heading text-center"><b>Details</b>
				<a class="pull-right" (click)="showDetails=!showDetails">
					<i *ngIf="!showDetails" class="fa fa-bars" aria-hidden="true"></i>
					<i *ngIf="showDetails" class="fa fa-times" aria-hidden="true"></i>
				</a>
			</div>
			<div class="panel-body" *ngIf="showDetails" [@slideDown]>
				<ul>
					<li *ngIf="userDetail?.UserName">Name : <span>{{userDetail.UserName}}</span></li>
					<li *ngIf="userDetail?.Company">Company : <span>{{userDetail.Company}}</span></li>
					<li *ngIf="userDetail?.Phone">Phone : <span>{{userDetail.Phone}}</span></li>
					<li *ngIf="userDetail?.ChannelName">Channel : <span>{{userDetail.ChannelName}}</span></li>
					<li *ngIf="userDetail?.email" title="{{userDetail['email']}}">Email : <span><a href="{{userDetail['email']}}" target="_blank">{{userDetail['email'].length > 35 ? userDetail['email'].slice(0, 30) + '...' : userDetail['email']}}</a></span></li>
					<li *ngIf="userDetail?.Url" title="{{userDetail['Url']}}">URL : <span><a href="{{userDetail['Url']}}" target="_blank">{{userDetail['Url'].length > 35 ? userDetail['Url'].slice(0, 30) + '...' : userDetail['Url']}}</a></span></li>
					<li *ngIf="userDetail?.Browser">Browser : <span>{{userDetail.Browser}}</span></li>
					<li *ngIf="userDetail?.Country">Country : <span>{{userDetail.Country}}</span></li>
					<li *ngIf="userDetail?.Continent">Continent : <span>{{userDetail.Continent}}</span></li>
					<li *ngIf="!userDetail?.Browser && !userDetail?.Country && !userDetail?.Continent && !userDetail?.UserName && !userDetail?.Company && !userDetail?.Phone && !userDetail?.ChannelName && !userDetail?.Url"
					class="text-center">Not provided</li>
				</ul>
			</div>
		</div>
	</div>

	<div class="previous-tickets">
		<div class="card panel panel-default">
			<div class="panel-heading text-center"><b>Tickets by this user</b>
				<a class="pull-right" (click)="showTickets=!showTickets">
					<i *ngIf="!showTickets" class="fa fa-bars" aria-hidden="true"></i>
					<i *ngIf="showTickets" class="fa fa-times" aria-hidden="true"></i>
				</a>
			</div>
			<div class="panel-body scroll-tickets" *ngIf="showTickets" [@slideDown]>
				<ul>
					<li *ngFor="let item of customerTickets; let i=index;">
						<div class="card bcard">
							<div class="header">
								<h4 class="title tfont"><b>#{{item.id}}</b>&nbsp;
									<a class="pull-right ticket-list" (click)="ticketDetails(i,item.id)">
										<i class="fa fa-eye" *ngIf="!selected[i]"></i>
										<i class="fa fa-spin fa-spinner fa-pulse" *ngIf="selected[i]"></i>
									</a>
									<small *ngIf="item.status=='open'"><i class="fa fa-circle text-danger"></i> Open </small>
									<small *ngIf="item.status=='progress'"><i class="fa fa-circle text-warning"></i> In Progress </small>
									<small *ngIf="item.status=='closed'"><i class="fa fa-circle text-success"></i> Closed </small>
								</h4>

								<p class="category"><small>Created at : {{item.created_at | date:'medium'}}</small></p>
							</div>
							<div class="footer">
							</div>
						</div>
					</li>
				</ul>
			</div>
		</div>
	</div>
</div>
</div>
</div>
</div>
</div>
<!-- top portion down -->
<div class="row" *ngIf="!showLog" [@slideInOut]>
	<div class="col-md-12">
		<div class="card">
			<div class="content spad">
				<form class="search" [formGroup]="searchForm" (ngSubmit)="searchPeople(searchForm)" ngNativeValidate>
					<div class="row">
						<div class="col-md-3 col-sm-4">
							<div class="form-group">
								<label>Date</label>
								<input type="text"
								class="form-control"
								ngxDaterangepickerMd
								formControlName="from_to_date"
								[alwaysShowCalendars]="true"
								[ranges]="ranges"
								[showClearButton]="true"
								[locale]="locale"
								placeholder="Select date range..."/>
							</div>
						</div>
						<div class="col-md-3 col-sm-4">
							<div class="form-group">
								<label>Email</label>
								<input type="email" class="form-control" placeholder="Email" formControlName="email">
							</div>
						</div>
						<div class="col-md-2 col-sm-4">
							<div class="form-group">
								<label>Phone</label>
								<input type="text" class="form-control" placeholder="Number" formControlName="phone">
							</div>
						</div>
						<div class="col-md-2 col-sm-4">
							<div class="form-group">
								<label>Keyword</label>
								<input type="text" class="form-control" placeholder="Keyword" formControlName="keyword">
							</div>
						</div>
						<div class="col-md-1 col-sm-4">
							<button type="submit" class="btn btn-primary mtop25 grad" [disabled]="!searchForm.valid">
								<!-- <i *ngIf="isSearching" class="fa fa-spin fa-spinner fa-pulse" aria-hidden="true"></i> -->
								<i class="fa fa-search" aria-hidden="true"></i> 
							</button>
						</div>
					</div>
				</form>
				<div class="advanced_search">
					<a (click)="isAdvSearch=!isAdvSearch">Advanced Search</a>
					<div *ngIf="isAdvSearch" class="mtop15">
						<div class="row">
							<div class="col-md-6 col-sm-6">
								<div class="grouped">
									<div class="dflx">
										<div class="tag-drop mauto">
											<label>Include Tags</label>
											<div class="ml15">
												<select class="form-control" (change)="moveTag('include',includeDiv.value)" #includeDiv>
													<option value="null" selected disabled>Select tag</option>
													<option *ngFor="let item of tagsList" [value]="item.tag_id" [attr.disabled]="item.selected ? '' : null">{{item.tag_name}}</option>
												</select>
											</div>
										</div>
										<div class="dflx">
											<label class="rel-label">Relation</label>
											<div class="relation">
												<div>
													<input type="radio" [(ngModel)]="relation" id="or" name="relation" value="or">
													<label for="or">OR</label>
												</div>
												<div>
													<input type="radio" [(ngModel)]="relation" id="and" name="relation" value="and">
													<label for="and">AND</label>
												</div>
											</div>
										</div>
									</div>
									<div class="tags" *ngIf="include?.length>0">
										<span *ngFor="let item of include" [style.background]="getTagColor(item)">{{getTagName(item)}}&nbsp;<a (click)="removeTag('include',item)"><i class="fa fa-trash"></i></a></span>
									</div>
								</div>
							</div>

							<div class="col-md-6 col-sm-6">
								<div class="grouped">
									<div class="dflx tag-drop">
										<label>Exclude Tags</label>
										<div class="ml15">
											<select class="form-control" (change)="moveTag('exclude',excludeDiv.value)" #excludeDiv>
												<option value="null" selected disabled>Select tag</option>
												<option *ngFor="let item of tagsList" [value]="item.tag_id" [attr.disabled]="item.selected ? '' : null">{{item.tag_name}}</option>
											</select>
										</div>
									</div>
									<div class="tags" *ngIf="exclude?.length>0">
										<span *ngFor="let item of exclude" [style.background]="getTagColor(item)">{{getTagName(item)}}&nbsp;<a (click)="removeTag('exclude',item)"><i class="fa fa-trash"></i></a></span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="col-md-12 fix-result">
		<div class="w-100 text-center" *ngIf="isSearching"><i class="fa fa-spin fa-spinner fa-pulse"></i></div>
		<div class="row searched" *ngIf="!isSearching">
			<div class="col-md-12 col-sm-12 col-xs-12 dgrid" *ngIf="people.length>0">
				<div class="dflx mauto">
					<div class="form-group w120 padr10">
						<small class="sort-label"> Page:&nbsp;</small>
						<select class="form-control sortby" [(ngModel)]="pageNo" (change)="searchPeople(searchForm)">
							<option *ngFor='let in of counter();let i = index' value="{{i+1}}">{{i+1}}</option>
						</select>
					</div>
					<div class="form-group w120">
						<small class="sort-label"> Results:&nbsp;</small>
						<select class="form-control sortby" [(ngModel)]="offset" (change)="searchPeople(searchForm)">
							<option value="12">12</option>
							<option value="36">36</option>
							<option value="60">60</option>
							<option value="120">120</option>
							<option value="600">600</option>
						</select>
					</div>
				</div>
			</div>
			<div class="col-md-3 col-sm-4 col-xs-6" *ngFor="let item of people; let i=index;">
				<div class="card people-list">
					<div class="header">
						<h4 class="title">
							<b>#{{item?.CustomerID}}&nbsp;<img class="countryflag" src="https://www.countryflags.io/{{getCountryCode(item.Country)}}/flat/32.png" onerror="this.src='/assets/img/flag.png';" height="20"></b>
							<a (click)="customerDetails(i,item.CustomerID)" class="people-eye">
								<i class="fa fa-eye" *ngIf="!selected[i]"></i>
								<i class="fa fa-spin fa-spinner fa-pulse" *ngIf="selected[i]"></i></a>
							</h4>
							<p class="category">Name : 
								<b *ngIf="item?.CustomerName">{{item?.CustomerName}}</b>
								<b *ngIf="!item?.CustomerName"> ----- </b>
							</p>
							<p class="category">Email : 
								<b *ngIf="item?.CustomerEmail" title="{{item['CustomerEmail']}}">{{item['CustomerEmail'].length > 25 ? item['CustomerEmail'].slice(0, 25) + '...' : item['CustomerEmail']}}</b>
								<b *ngIf="!item?.CustomerEmail"> ----- </b>
							</p>
							<!-- {{item?.CustomerEmail}} -->
							<!-- <p class="category" title="{{item['CustomerUrl']}}">Url : <b><a *ngIf="item?.CustomerUrl" href="{{item['CustomerUrl']}}" target="_blank"> {{item['CustomerUrl'].length > 35 ? item['CustomerUrl'].slice(0, 30) + '...' : item['CustomerUrl']}}</a></b></p> -->
							<p class="category">Channel : 
								<i class="fa fa-globe" *ngIf="item?.ChannelId==1" title="WEB"></i>
								<i class="fa fa-facebook" *ngIf="item?.ChannelId==2" title="FACEBOOK"></i>
								<i class="fa fa-telegram" *ngIf="item?.ChannelId==3" title="TELEGRAM"></i>
								<i class="fa fa-comments-o" *ngIf="item?.ChannelId==4" title="ZENDESK"></i>
								<i class="fa fa-whatsapp" *ngIf="item?.ChannelId==5" title="WHATSAPP"></i>
								<i class="fa fa-question-circle" *ngIf="item?.ChannelId!=1 && item?.ChannelId!=2 &&  item?.ChannelId!=3 &&  item?.ChannelId!=4 &&  item?.ChannelId!=5" title="OTHERS"></i>
								<b *ngIf="!item?.ChannelName"> ----- </b>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>

	</div>
</div>
</div>



