import { Component, OnInit, ViewChild } from '@angular/core';
import { showNotification } from 'app/services/notification';
import { ServicesService } from 'app/services/services.service';
import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-assign-rules',
  templateUrl: './assign-rules.component.html',
  styleUrls: ['./assign-rules.component.scss']
})
export class AssignRulesComponent implements OnInit {

  botID: number = parseInt(localStorage.getItem('botID'), 10);
  customerID: number = parseInt(localStorage.getItem('Customer_ID'), 10);
  CustomerType: number = parseInt(localStorage.getItem('Customer_Type'), 10);
  agents: any = [];
  departments: any = [];
  departmentsDrop: any = [];
  memoryVars: any = [];
  isAdding = false;
  assignedRules: any = [];
  selectedRule: any;
  isDeleting = false;
  isFetching = false;
  @ViewChild('addAssignRuleModal', { static: false }) addAssignRuleModal: ModalDirective;
  @ViewChild('deleteRuleModal', { static: false }) deleteRuleModal: ModalDirective;
  modalRef?: BsModalRef;

  constructor(private dataService: ServicesService, private modalService: BsModalService) { }

  ngOnInit(): void {
    this.getBotDepartments();
    this.getAgents();
    this.getMemVars();
    this.getAssignRulesList();
  }

  getBotDepartments() {
    this.dataService.getBotDepartmentsList({
      CustomerID: this.customerID,
      CustomerType: this.CustomerType
    }).subscribe(
      res => {
        if (res.status_code == 200) {
          this.departments = res.data.filter(ae => ae.BotID == this.botID);
          this.departmentsDrop = this.departments.filter(ae => ae.DepartmentName != 'ADMIN');
        } else {
          showNotification('warning', res.status);
        }
      },
      error => {
        showNotification('danger', error);
      })
  }

  getAgents() {
    this.dataService.getAgentsList({
      CustomerID: this.customerID,
      CustomerType: this.CustomerType,
      BotID: this.botID
    }).subscribe(
      res => {
        if (res.status_code == 200) {
          // this.agents = res.data.filter(ae => ae.IsActive);
          this.agents = res.data;
        } else {
          showNotification('warning', res.status);
        }
      }, error => {
        showNotification('danger', error);
      })
  }

  getMemVars() {
    this.dataService.getMemVarList({
      bot_id: this.botID,
      AgentID: this.customerID,
      AgentType: this.CustomerType
    }).subscribe(
      res => {
        this.memoryVars = res.data;
      },
      error => {
        showNotification('warning', error);
      })
  }

  getAssignRulesList() {
    this.isFetching = true;
    this.dataService.getAssignRules(this.botID).subscribe(
      res => {
        this.isFetching = false;
        this.assignedRules = res;
      },
      error => {
        this.isFetching = true;
        showNotification('warning', error);
      })
  }

  showDepartment(id) {
    const department = this.departments.find(ae => ae.DepartmentID === id);
    return department?.DepartmentName || `<p class="text-danger text-center">Not Found</p>`;
  }

  showAgent(id) {
    const agent = this.agents.find(ae => ae.AgentID === id);
    return agent?.AgentName || `<p class="text-danger text-center">Not Found</p>`;
  }

  showMemoryVar(internal_name) {
    const memory = this.memoryVars.find(ae => ae.internal_name === internal_name);
    return memory?.display_name || '-';
  }

  openModal(template: any) {
    this.modalRef = this.modalService.show(template, {
      animated: true,
      backdrop: 'static',
      keyboard: false,
      class: "assign-modal"
    });
  }

  closeModal(status) {
    if (status) this.getAssignRulesList();
    this.modalRef?.hide();
  }

  deleteRule(data) {
    this.isDeleting = true;
    this.dataService.deleteAssignRules(data).subscribe(
      () => {
        showNotification('success', 'Deleted successfully!');
        this.getAssignRulesList();
        this.deleteRuleModal.hide();
        this.isDeleting = false;
      },
      error => {
        console.log(error);
        this.isDeleting = false;
        showNotification('danger', error);
      })
  }

  assignRuleMsg() {
    showNotification('warning', 'There should be at least one department other than <b>ADMIN</b> to create a rule!');
  }

}
