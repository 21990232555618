import { Component, OnInit, ViewChild } from '@angular/core';
import { ServicesService } from '../../services/services.service';
import { showNotification } from '../../services/notification';
import { BsModalService, BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators, AbstractControl } from '@angular/forms';

@Component({
	selector: 'app-agents',
	templateUrl: './agents.component.html',
	styleUrls: ['./agents.component.css'],

})
export class AgentsComponent implements OnInit {

	botID: number = parseInt(localStorage.getItem('botID'), 10);
	customerID: number = parseInt(localStorage.getItem('Customer_ID'), 10);
	CustomerType: number = parseInt(localStorage.getItem('Customer_Type'), 10);
	agents: any = [];
	departments: any = [];
	agentDetails: any = {};
	selected: boolean[] = [false];
	isFetching: boolean = false;
	isPermission: boolean = false;
	isChangeDepartment: boolean = false;
	isAdding: boolean = false;
	permissionForm: FormGroup;
	addAgentForm: FormGroup;
	deptList = [];
	deptSortList = [];
	selectedDepts = [];
	settings = {};
	settingsSingle = {};
	deptAgent;
	reset_pwd = '';
	isResetting: boolean = false;
	isDupeEmail: boolean = false;
	showPassword: boolean = false;
	showPermissions: boolean = false;
	hidePwd: boolean = true;
	permissions: any;
	@ViewChild('addAgentModal', { static: false }) addAgentModal: ModalDirective;
	@ViewChild('resetPwdModal', { static: false }) resetPwdModal: ModalDirective;

	constructor(private dataService: ServicesService, private _fb: FormBuilder) { }

	ngOnInit(): void {
		this.getBotDepartments();
		this.settings = {
			text: "Select Departments",
			classes: "myclass custom-class",
			singleSelection: false,
			lazyLoading: true,
			maxHeight: 200,
			position: 'top',
			autoPosition: false
		};

		this.settingsSingle = {
			text: "Select Department",
			classes: "myclass custom-class",
			singleSelection: true,
			lazyLoading: true
		};

		this.clearPermissionForm();
		this.clearAgentForm();
	}

	clearAgentForm() {
		this.addAgentForm = this._fb.group({
			'AgentName': ['', [Validators.required]],
			'AgentType': ['2', [Validators.required]],
			'AgentEmail': ['', [Validators.required]],
			'phone': ['', [Validators.required]],
			'department_agent': [[], [Validators.required]],
			'departments_admin': [[], [Validators.required]],
			'password': ['', [Validators.required]],
			'is_admin_create': [false, [Validators.required]],
			'is_admin_delete': [false, [Validators.required]],
			'is_agent_create': [false, [Validators.required]],
			'is_agent_delete': [false, [Validators.required]],
			'is_change_departments': [false, [Validators.required]],
			'is_password_reset': [false, [Validators.required]],
			'show_admin_management_page': [true, [Validators.required]],
			'show_tickets_page': [true, [Validators.required]],
			'show_contacts_page': [true, [Validators.required]],
			'show_reports_page': [true, [Validators.required]],
			'show_spectate_page': [true, [Validators.required]],
			'is_quickreply_create': [false, [Validators.required]],
			'is_quickreply_delete': [false, [Validators.required]],
			'is_tag_create': [false, [Validators.required]],
		});
		this.isDupeEmail = false;
	}

	clearPermissionForm() {
		this.permissionForm = this._fb.group({
			'is_admin_create': [false, [Validators.required]],
			'is_admin_delete': [false, [Validators.required]],
			'is_agent_create': [false, [Validators.required]],
			'is_agent_delete': [false, [Validators.required]],
			'is_change_departments': [false, [Validators.required]],
			'is_password_reset': [false, [Validators.required]],
			'show_admin_management_page': [false, [Validators.required]],
			'show_tickets_page': [false, [Validators.required]],
			'show_contacts_page': [false, [Validators.required]],
			'show_reports_page': [false, [Validators.required]],
			'show_spectate_page': [false, [Validators.required]],
			'is_quickreply_create': [false, [Validators.required]],
			'is_quickreply_delete': [false, [Validators.required]],
			'is_tag_create': [false, [Validators.required]],
		});

	}

	getAgents() {
		this.dataService.getAgentsList({
			CustomerID: this.customerID,
			CustomerType: this.CustomerType,
			BotID: this.botID
		}).subscribe(
			res => {
				if (res.status_code == 200) {
					this.agents = res.data;
					this.permissions = res.permissions;
					// this.agents = [{"AgentID":2,"AgentType":2,"AgentName":"Kruz","AgentURL":"abc","DepartmentName":"sales"}]
				} else if (res.status_code == 401) {
					this.dataService.logout();
				} else {
					showNotification('warning', res.status);
				}
			}, error => {
				console.log(error);
				showNotification('danger', error);
			})
	}

	selectAgent(i, agents) {
		this.isFetching = true;
		for (let j in this.agents) {
			if (i == j) { this.selected[j] = true; }
			else { this.selected[j] = false; }
		}
		this.dataService.getAgentDetails({
			AgentID: agents.AgentID,
			AgentType: agents.AgentType
		}).subscribe(
			res => {
				if (res.status_code == 200) {
					this.agentDetails = res.data;
					this.permissionForm = this._fb.group({
						'is_admin_create': [this.agentDetails.is_admin_create, [Validators.required]],
						'is_admin_delete': [this.agentDetails.is_admin_delete, [Validators.required]],
						'is_agent_create': [this.agentDetails.is_agent_create, [Validators.required]],
						'is_agent_delete': [this.agentDetails.is_agent_delete, [Validators.required]],
						'is_change_departments': [this.agentDetails.is_change_departments, [Validators.required]],
						'is_password_reset': [this.agentDetails.is_password_reset, [Validators.required]],
						'show_admin_management_page': [this.agentDetails.show_admin_management_page, [Validators.required]],
						'show_tickets_page': [this.agentDetails.show_tickets_page, [Validators.required]],
						'show_contacts_page': [this.agentDetails.show_contacts_page, [Validators.required]],
						'show_reports_page': [this.agentDetails.show_reports_page, [Validators.required]],
						'show_spectate_page': [this.agentDetails.show_spectate_page, [Validators.required]],
						'is_quickreply_create': [this.agentDetails.is_quickreply_create, [Validators.required]],
						'is_quickreply_delete': [this.agentDetails.is_quickreply_delete, [Validators.required]],
						'is_tag_create': [this.agentDetails.is_tag_create, [Validators.required]],
					});
					if (this.agentDetails.type == 3) {
						// this.deptAgent = this.agentDetails.departments[0].id + ',' + this.agentDetails.departments[0].BotID;
						this.deptAgent = this.agentDetails.departments;
					} else {
						this.selectedDepts = this.agentDetails.departments;
					}
				} else {
					showNotification('warning', res.status);
				}
				this.isFetching = false;
			},
			error => {
				console.log(error);
				this.isFetching = false;
				showNotification('danger', error);
			})
	}

	getBotDepartments() {
		this.dataService.getBotDepartmentsList({
			CustomerID: this.customerID,
			CustomerType: this.CustomerType
		}).subscribe(
			res => {
				if (res.status_code == 200) {
					this.departments = res.data;
					this.deptList = [];
					this.deptSortList = [];
					for (let i in this.departments) {
						this.deptList.push({
							id: this.departments[i].DepartmentID,
							itemName: this.departments[i].DepartmentName + ' (' + this.departments[i].BotName + ')',
							BotID: this.departments[i].BotID
						})
					}
					this.deptSortList = this.deptList.filter(ae => ae.itemName.substring(0, 5) !== "ADMIN");
				} else {
					showNotification('warning', res.status);
				}
			},
			error => {
				console.log(error);
				showNotification('danger', error);
			})
	}

	changePermission(permissions, agentid) {
		this.isPermission = true;
		permissions.AgentID = agentid;
		this.dataService.saveAgentPermissions(permissions).subscribe(
			res => {
				if (res.status_code == 200) {
					showNotification('success', 'Permissions changed successfully!');
				} else {
					showNotification('warning', res.status);
				}
				this.isPermission = false;
			},
			error => {
				console.log(error);
				this.isPermission = false;
				showNotification('danger', error);
			})
	}

	addAgent(agent) {
		if (agent.AgentType == 3 && agent.department_agent.length == 0) {
			showNotification('warning', '<span>You must add a department to add an agent</span>');
		} else if (agent.AgentType == 2 && agent.departments_admin.length == 0) {
			showNotification('warning', '<span>You must select a department to continue</span>');
		} else {
			this.isAdding = true;
			agent.CustomerID = this.customerID;
			agent.AgentType = parseInt(agent.AgentType);
			this.dataService.addAgent(agent).subscribe(
				res => {
					if (res.status_code == 200) {
						showNotification('success', 'Added successfully!');
						this.getAgents();
						this.clearAgentForm();
						this.addAgentModal.hide();
						this.isAdding = false;
					} else {
						this.isAdding = false;
						showNotification('warning', res.status);
					}
				},
				error => {
					console.log(error);
					this.isAdding = false;
					showNotification('danger', error);
				})
		}
	}

	changeDepartmentsAdmin(depts) {
		this.isChangeDepartment = true;
		let data = {
			AgentID: this.agentDetails.id,
			DepartmentList: depts
		}
		this.dataService.changeDepartmentAdmin(data).subscribe(
			res => {
				if (res.status_code == 200) {
					this.getAgents();
					showNotification('success', 'Changed successfully!');
					this.isChangeDepartment = false;
				} else {
					showNotification('warning', res.status);
					this.isChangeDepartment = false;
				}
			},
			error => {
				console.log(error);
				this.isChangeDepartment = false;
				showNotification('danger', error);
			})
	}

	changeDepartmentAgent(dept) {
		console.log(dept);
		this.isChangeDepartment = true;
		let data = {
			AgentID: this.agentDetails.id,
			DepartmentList: dept
		}
		this.dataService.changeDepartmentAgent(data).subscribe(
			res => {
				if (res.status_code == 200) {
					this.getAgents();
					showNotification('success', 'Changed successfully!');
					this.isChangeDepartment = false;
				} else {
					showNotification('warning', res.status);
					this.isChangeDepartment = false;
				}
			},
			error => {
				console.log(error);
				this.isChangeDepartment = false;
				showNotification('danger', error);
			})
	}

	resetPassword(pwd) {
		this.isResetting = true;
		this.dataService.resetPassword({
			AgentID: this.agentDetails.id,
			Password: pwd
		}).subscribe(
			res => {
				if (res.status_code == 200) {
					showNotification('success', 'Password Changed Successfully!');
					this.isResetting = false;
					this.reset_pwd = '';
					this.resetPwdModal.hide();
				} else {
					showNotification('warning', res.status);
					this.isResetting = false;
				}
			},
			error => {
				console.log(error);
				this.isResetting = false;
				showNotification('danger', error);
			})
	}

	changeAccStatus(event) {
		if (this.agentDetails.type != 3 && this.permissions?.is_admin_delete == false) {
			showNotification('warning', "OOPS! You don't have permission to change status of an Admin.");
		} else {
			this.dataService.changeAgentStatus({
				AgentID: this.agentDetails.id,
				is_active: event.target.checked
			}).subscribe(
				res => {
					if (res.status_code == 200) {
						showNotification('success', 'Status Changed Successfully!');
					} else {
						showNotification('warning', res.status);
					}
				},
				error => {
					console.log(error);
					showNotification('danger', error);
				})
		}
	}

	checkEmail(event) {
		this.dataService.checkEmail({
			email: event.target.value,
		}).subscribe(
			res => {
				if (res.status_code == 200) {
					if (res.email_exists) { this.isDupeEmail = true; }
					else { this.isDupeEmail = false; }

				} else {
					showNotification('warning', res.status);
				}
			},
			error => {
				console.log(error);
				showNotification('danger', error);
			})

	}

}
