<div class="container-fluid">
    <div class="row mt-4 mtop20">
        <div class="col-md-12 col-sm-12 text-right">
            <button type="button" class="btn btn-green btn-sm add-agent-btn" *ngIf="departments.length > 1"
                (click)="selectedRule=null;openModal(assignModal);">
                <i *ngIf="!isAdding" class="fa fa-plus"></i>&nbsp;Add&nbsp;Assign&nbsp;Rule</button>
            <button type="button" class="btn btn-secondary btn-sm add-agent-btn" *ngIf="departments.length <= 1"
                (click)="assignRuleMsg()">
                <i class="fa fa-plus"></i>&nbsp;Add&nbsp;Assign&nbsp;Rule</button>
        </div>
        <div class="col-md-12 col-sm-12">
            <div class="manage-table">
                <div class="card mtop20">
                    <table class="table table-bordered agent-table text-center">
                        <thead>
                            <tr class="centered-tr">
                                <th>No.</th>
                                <th>Rule Name</th>
                                <th>Department</th>
                                <th>Rule</th>
                                <th>Agent</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let rule of assignedRules; let i=index">
                                <td>{{i+1}}</td>
                                <td [innerHTML]="rule.name || '<span class=text-danger>-</span>'"></td>
                                <td [innerHTML]="showDepartment(rule.department_id)"></td>
                                <td>
                                    <div *ngFor="let item of rule.condition" class="condition">
                                        <div class="single">
                                            <div>{{showMemoryVar(item.mem_var)}}</div>
                                            <div>{{item.operator}}</div>
                                            <div>{{item.value}}</div>
                                        </div>
                                        <div *ngIf="item.next_operator" class="next_operator">{{item.next_operator}}
                                        </div>
                                    </div>
                                </td>
                                <td [innerHTML]="showAgent(rule.liveagent_id)"></td>
                                <td>
                                    <a class="btn me-1" (click)="selectedRule=rule;openModal(assignModal);"><i
                                            class="fa fa-pencil-square-o"></i></a>
                                    <a class="btn" (click)="selectedRule=rule;deleteRuleModal.show();">
                                        <i class="fa fa-trash text-danger" aria-hidden="true"></i>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <p *ngIf="!assignedRules.length && !isFetching" class="text-warning text-center pb-2">No Rules to
                        Show!</p>
                    <div class="w-100 text-center pb-3" *ngIf="isFetching">
                        <i class="fa fa-spin fa-spinner fa-pulse"></i> Fetching Rules!
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #assignModal>
    <app-add-rules [rule]="selectedRule" [departments]="departmentsDrop" [agents]="agents" [memoryVars]="memoryVars"
        (close)="closeModal($event)"></app-add-rules>
</ng-template>

<div bsModal #deleteRuleModal="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="dialog-auto-name">
    <div class="modal-dialog modal-md">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Delete Rule</h5>
                <button type="button" class="close" (click)="deleteRuleModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body text-center">
                <p>Are you sure to delete ?</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn" (click)="deleteRule(selectedRule?.id)">
                    <i *ngIf="!isDeleting" class="fa fa-trash-o"></i>
                    <i *ngIf="isDeleting" class="fa fa-spin fa-spinner fa-pulse"></i>
                    Yes</button>
                <button type="button" class="btn btn-gradient" (click)="deleteRuleModal.hide()"><i class="fa fa-times"
                        aria-hidden="true"></i>No</button>
            </div>
        </div>
    </div>
</div>