import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../login/auth.service';
import { showNotification } from 'app/services/notification';

@Component({
  selector: 'app-login-redirect',
  templateUrl: './login-redirect.component.html',
  styleUrls: ['./login-redirect.component.scss']
})
export class LoginRedirectComponent implements OnInit {

  constructor(private route: ActivatedRoute, private auth: AuthService, private router: Router) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.getCustomerDetails(params._);
    });
  }

  // getTokenWithMail(mail) {
  //   this.auth.getTokenFromMail(mail).subscribe((res) => {
  //     if (res?.token) this.getCustomerDetails(res?.token);
  //     else this.router.navigate(['login']);
  //   });
  // }

  getCustomerDetails(token) {
    this.auth.getCustomerDetails(token).subscribe((response) => {
      if (response.success == true) {
        localStorage.setItem('ACCESS_TOKEN', response.data['token']);
        localStorage.setItem('show_admin_management_page', response.data['permissions'].show_admin_management_page);
        localStorage.setItem('show_contacts_page', response.data['permissions'].show_contacts_page);
        localStorage.setItem('show_tickets_page', response.data['permissions'].show_tickets_page);
        localStorage.setItem('show_reports_page', response.data['permissions'].show_reports_page);
        localStorage.setItem('show_spectate_page', response.data['permissions'].show_spectate_page);
        localStorage.setItem('agent_type', response.data['details'].type);
        localStorage.setItem('Customer_ID', response.data['customer_id']);
        localStorage.setItem('agent_imgurl', response.data['details'].image_url);
        localStorage.setItem('Customer_Type', response.data['details'].type);
        localStorage.setItem('Customer_Name', response.data['Customer_Name']);
        localStorage.setItem('Customer_Email', response.data['emailid']);
        this.router.navigateByUrl('/home');
      } else {
        showNotification('warning', 'Invalid User! Try Login with your Credenials!');
        this.router.navigate(['login']);
      }
    });
  }

}