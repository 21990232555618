import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ServicesService } from '../services/services.service';
import { showNotification } from '../services/notification';
import { trigger, style, animate, transition, state, group } from '@angular/animations';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import * as data from '../../assets/js/countrycodes.json';
import { DomSanitizer } from '@angular/platform-browser';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators, AbstractControl } from '@angular/forms';

@Component({
	selector: 'app-unified-inbox',
	templateUrl: './unified-inbox.component.html',
	styleUrls: ['./unified-inbox.component.scss', '../user/user.component.scss']
})
export class UnifiedInboxComponent implements OnInit {

	newMessage: string;
	chatlog: any[] = [];
	chatlogList: any = {};
	agentProfiles = [];
	unreadCount: any = {};
	leadDetails: any = {};
	userDetails: any = {};
	userDetail: any = {};
	usersList: any[] = [];
	usersListDetails: any[] = [];
	seluserList: any[] = [];
	userTags = [];
	selectedTags = [];
	selectedUser: boolean[] = [false];
	current_user: string = localStorage.getItem('Customer_Email');
	botID: number = parseInt(localStorage.getItem('botID'), 10);
	selected_bot = localStorage.getItem('botName');
	agent_img = localStorage.getItem('agent_imgurl');
	customerID: number = parseInt(localStorage.getItem('Customer_ID'), 10);
	customerType: number = parseInt(localStorage.getItem('Customer_Type'), 10);
	customerName: string = localStorage.getItem('Customer_Name');
	departments: any = [];
	selected_user: any = 0;
	selected_channel: any = 1;
	startChat: boolean = false;
	isfetchingLog: boolean = false;
	isaddTags: boolean = false;
	showDetails: boolean = false;
	showHold: boolean = false;
	showLeadDetails: boolean = false;
	showTagDetails: boolean = false;
	tagsList = [];
	isAdminDepartment: boolean = false;
	mentionConfig = {};
	selectedDeptID: number;
	activeDeptID: number;
	selected_nodeid: number;
	replies: any[];
	pingTime;
	pingCounter: number = 0;
	pingTimeMilli: number = 180000;
	leadForm: FormGroup;
	addMemForm: FormGroup;
	holdForm: FormGroup;
	starredMemVarsList = [];
	country: any = (data as any).default;
	memVarTypes = [
		{ name: 'String', value: 'string' },
		{ name: 'Integer', value: 'integer' },
		{ name: 'Decimal', value: 'decimal' },
		{ name: 'Boolean', value: 'boolean' },
		{ name: 'Dictionary', value: 'dictionary' },
		{ name: 'List', value: 'list' },
	];
	@ViewChild('chatBox') private chatBox: ElementRef;
	@ViewChild('closeChatModal', { static: false }) closeChatModal: ModalDirective;
	constructor(private dataService: ServicesService, private _fb: FormBuilder, private router: Router, private sanitizer: DomSanitizer) { }

	public leadDataList = [];
	public leadmoreForm: FormGroup;
	showmoreDetails: boolean = false;
	leadloader: boolean = false;
	isLeadEdit: boolean = false;
	isaddLead: boolean = false;
	isaddMemory: boolean = false;
	categoriesSettings;
	isAssigned = true;

	ngOnInit(): void {
		this.categoriesSettings = {
			text: "Select tags",
			enableCheckAll: true,
			enableSearchFilter: true,
			lazyLoading: true
		};
		this.getAllReplies();
		this.getDepartments();
		this.getStarMemVars();
		this.getAllTags();
		this.leadForm = this._fb.group({
			'name': ['', [Validators.required]],
			'email': ['', [Validators.required]],
			'phone': ['', [Validators.required]],
			'company': ['', [Validators.required]],
			'location': ['', [Validators.required]],
		});
		this.holdForm = this._fb.group({
			'hold_message': ['', [Validators.required]],
			'hold_time': ['', [Validators.required]]
		});
	}

	getSafeUrl(url) {
		return this.sanitizer.bypassSecurityTrustResourceUrl(url)
	}

	changeIframe(url) {
		return this.getSafeUrl(url);
	}

	connectChat() {
		this.dataService.onMessageLA(this.botID, this.current_user, this.selectedDeptID, this.customerID, this.selected_channel).subscribe((message: any) => {
			console.log("LA--->", message);
			if (message.pong) {
				this.pingCounter = 0;
				clearInterval(this.pingTime);
				this.pingTime = setInterval(() => this.sendPing(), this.pingTimeMilli);
			} else {
				this.playAudio();
				if (this.pingTime) {
					clearInterval(this.pingTime);
					this.pingTime = setInterval(() => this.sendPing(), this.pingTimeMilli);
				}
				if (message.isQueue && message.disconnect) { //when a user in userlist diconnects
					const index = this.usersList.indexOf(message.userid);
					this.usersListDetails = this.usersListDetails.filter(ae => ae.userid != message.userid);
					if (index > -1) { this.usersList.splice(index, 1); }
				} else if (message.isQueue) { //when new user connects
					var exist = -1;
					for (let i in this.seluserList) {
						if (this.seluserList[i].userid == message.userid) { exist = parseInt(i); }
					}
					if (exist == -1) {
						this.usersList.push(message.userid);
						this.usersListDetails.push(message);
					} else { //when a user in seluserlist diconnects
						this.seluserList[exist].disconnect = false;
						this.dataService.sendMessageLA({
							selected: true,
							userid: this.seluserList[exist].userid,
							channelid: this.getUserSpec('channelid', this.seluserList[exist].userid),
							channel_details: this.getUserSpec('channel_details', this.seluserList[exist].userid)
						});
					}
				}

				if (message.isLive && message.type == 'log' && !message.reconnect) {//get log of selected user
					this.showDetails = true;
					this.showLeadDetails = true;
					this.userDetails[message.userid] = message.message.userdetails;
					this.leadDetails[message.userid] = message.message.lead;
					this.userDetail = message.message.userdetails;
					this.leadForm = this._fb.group({
						'name': [this.leadDetails[message.userid].name, [Validators.required]],
						'email': [this.leadDetails[message.userid].email, [Validators.required]],
						'phone': [this.leadDetails[message.userid].phone, [Validators.required]],
						'company': [this.leadDetails[message.userid].company, [Validators.required]],
						'location': [this.leadDetails[message.userid].location, [Validators.required]],
					});
					this.isfetchingLog = false;
					this.chatlog = [...message.message.log];
					this.agentProfiles = message.message.agent_profiles;
					for (let i in this.chatlog) {
						if (this.chatlog[i].message?.cardtypeid == 1 && this.chatlog[i].message?.feedbacktypeid == 2) {
							var imgs = this.chatlog[i].message.message.split("_$_");
							this.chatlog[i].message.message = imgs;
						}
					}
					this.chatlogList[message.userid] = this.chatlog;
					this.unreadCount[message.userid] = 0; //unread count initialise
				} else if (message.isLive && message.type == 'log' && message.reconnect) {
					this.showDetails = true;
					this.showLeadDetails = true;
					this.userDetails[message.userid] = message.message.userdetails;
					this.leadDetails[message.userid] = message.message.lead;
					this.leadForm = this._fb.group({
						'name': [this.leadDetails[message.userid].name, [Validators.required]],
						'email': [this.leadDetails[message.userid].email, [Validators.required]],
						'phone': [this.leadDetails[message.userid].phone, [Validators.required]],
						'company': [this.leadDetails[message.userid].company, [Validators.required]],
						'location': [this.leadDetails[message.userid].location, [Validators.required]],
					}); //reconecting user log
					var log = [...message.message.log];
					for (let i in log) {
						if (log[i].message.cardtypeid == 1 && log[i].message.feedbacktypeid == 2) {
							var imgs = log[i].message.message.split("_$_");
							log[i].message.message = imgs;
						}
					}
					this.chatlogList[message.userid] = log;
					for (let i in this.seluserList) {
						if (this.seluserList[i].userid == message.userid) {
							this.seluserList[i].disconnect = false;
						}
					}
				}

				if (message.auto_close) {
					this.closeChat('resolved', true);
				}

				if (message.isLive && message.disconnect) { //if selected user disconnects
					for (let i in this.seluserList) {
						if (this.seluserList[i].userid == message.userid) {
							this.seluserList[i].disconnect = true;
						}
					}
				}

				if (message.isLive && message.type == 'text') { //live chat message
					let date = new Date();
					let data = {
						// 'time': {$date: date},
						'time': date,
						'sender': "user",
						'isLive': true,
						'message': message.message
					}
					this.chatlogList[message.userid].push(data);
					if (message.userid != this.selected_user) {
						this.unreadCount[message.userid]++; //unread comment increment 
					}
				}
			}
			this.scrollToBottom();
		});
	}

	sendMessage() {
		let date = new Date();
		this.dataService.sendMessageLA({
			'isLive': true,
			'userid': this.selected_user,
			'message': this.newMessage,
			'node_id': this.getUserSpec('node_id', this.selected_user),
			// 'agentName': 'KRUZALMIGHTY',
			'agentName': this.customerName,
			'agent_img': this.agent_img,
			'channelid': this.getUserSpec('channelid', this.selected_user),
			'channel_details': this.getUserSpec('channel_details', this.selected_user)
		});
		let data = {
			'time': date,
			// 'time': {$date: date},
			'sender': "live_agent",
			'isLive': true,
			'message': this.newMessage
		}
		this.chatlogList[this.selected_user].push(data);
		// this.chatlog.push(data);
		this.newMessage = '';
		this.scrollToBottom();
	}

	selectUser(uid) {
		this.chatlog = [];
		this.isfetchingLog = true;
		this.selected_user = uid;
		this.getLeadData(this.selected_user);
		this.getTags(this.selected_user);
		this.startChat = true;
		if (this.seluserList.length < 6) {
			this.dataService.sendMessageLA({
				selected: true,
				userid: uid,
				agentName: this.customerName,
				agent_img: this.agent_img,
				ticket_id: this.getUserSpec('ticket_id', this.selected_user),
				channelid: this.getUserSpec('channelid', this.selected_user),
				channel_details: this.getUserSpec('channel_details', this.selected_user)
			});
			this.seluserList.push({
				userid: uid,
				selected: true,
				disconnect: false
			});
			for (let i in this.seluserList) {
				if (this.seluserList[i].userid != uid) {
					this.seluserList[i].selected = false;
				}
			}
			const index = this.usersList.indexOf(uid);
			if (index > -1) { this.usersList.splice(index, 1); }
		} else {
			alert("Maximum 4 users are allowed")
		}
	}

	scrollToBottom(): void {
		if (this.chatlog.length > 0) {
			setTimeout(() => {
				this.chatBox.nativeElement.scrollTop = this.chatBox.nativeElement.scrollHeight;
			}, 50);
		}
	}

	selectSelUser(uid) {
		this.selected_user = uid;
		this.getLeadData(uid);
		this.getTags(uid);
		this.unreadCount[uid] = 0; //unread count reset
		this.chatlog = this.chatlogList[uid]
		for (let i in this.seluserList) {
			if (this.seluserList[i].userid == uid) { this.seluserList[i].selected = true; }
			else { this.seluserList[i].selected = false; }
		}
		this.leadForm = this._fb.group({
			'name': [this.leadDetails[uid].name, [Validators.required]],
			'email': [this.leadDetails[uid].email, [Validators.required]],
			'phone': [this.leadDetails[uid].phone, [Validators.required]],
			'company': [this.leadDetails[uid].company, [Validators.required]],
			'location': [this.leadDetails[uid].location, [Validators.required]],
		});
		this.userDetail = this.userDetails[uid];
		this.startChat = true;
		this.scrollToBottom();
	}

	closeChat(resolve, auto_close) {
		let status = false;
		if (resolve == 'resolved') { status = true; }
		this.dataService.sendMessageLA({
			auto_close: auto_close,
			disconnect: true,
			userid: this.selected_user,
			isResolved: status,
			ticket_id: this.getUserSpec('ticket_id', this.selected_user),
			channelid: this.getUserSpec('channelid', this.selected_user),
			channel_details: this.getUserSpec('channel_details', this.selected_user)
		});
		if (auto_close) {
			showNotification('warning', '<span>User ' + this.selected_user + ' has been disconnected due to no response from live agent!</span>');
		}
		this.startChat = false;
		this.chatlog = [];
		this.seluserList = this.seluserList.filter(ae => ae.userid != this.selected_user);
		this.unreadCount[this.selected_user] = 0;
		this.chatlogList[this.selected_user] = [];
		this.leadDetails[this.selected_user] = {};
		this.leadForm = this._fb.group({
			'name': ['', [Validators.required]],
			'email': ['', [Validators.required]],
			'phone': ['', [Validators.required]],
			'company': ['', [Validators.required]],
			'location': ['', [Validators.required]],
		});
		this.userDetails[this.selected_user] = {};
		this.userDetail = {};
		this.selected_user = 0;
		this.selected_nodeid = 0;
		this.closeChatModal.hide();
	}

	seperateString(string) {
		var splitted = string.split("_$_");
		return splitted
	}

	playAudio() {
		let audio = new Audio();
		audio.src = "../../assets/audio/notification.mp3";
		audio.load();
		audio.play();
	}

	onLeadSubmit(value) {
		this.dataService.sendMessageLA({
			userid: this.selected_user,
			channelid: this.getUserSpec('channelid', this.selected_user),
			channel_details: this.getUserSpec('channel_details', this.selected_user),
			lead: value
		});
		showNotification('success', '<span>Details updated successfully</span>');
	}

	getAllReplies() {
		this.dataService.getAllQuickReplies({
			BotID: this.botID,
			AgentID: this.customerID
		}).subscribe(
			res => {
				if (res.status_code == 200) {
					this.replies = res.data;

					this.mentionConfig = new Object();
					this.mentionConfig = { mentions: [] };

					for (let i in this.replies) {
						this.mentionConfig['mentions'].push({
							items: this.replies[i].quick_value,
							triggerChar: this.replies[i].quick_key,
							maxItems: 10,
							returnTrigger: false,
							dropUp: true,
							mentionSelect: this.onMentionSelect
						});
					}
				} else if (res.status_code == 401) {
					this.dataService.logout();
				} else {
					showNotification('warning', res.status);
				}
			}, error => {
				console.log(error);
				showNotification('danger', error);
			})
	}

	onMentionSelect(selection) {
		// return selection.label;
		return `${selection.label}`;
	}

	getDepartments() {
		this.dataService.getDepartmentsList({
			CustomerID: this.customerID,
			CustomerType: this.customerType
		}).subscribe(
			res => {
				if (res.status_code == 200) {
					this.departments = res.data.filter(ae => ae.BotID == this.botID);
					console.log(this.departments);
					this.selectedDeptID = this.departments[0].DepartmentID;
					if (this.departments[0].DepartmentName == 'ADMIN') { this.isAdminDepartment = true; }
					else { this.isAdminDepartment = false; }
					this.holdForm = this._fb.group({
						'hold_message': [this.departments[0].hold_message, [Validators.required]],
						'hold_time': [this.departments[0].hold_time, [Validators.required]]
					});
					this.showHold = true;
					this.activeDeptID = this.selectedDeptID;
					// this.selected_channel = res.data.channelid ?? 1;
					console.log(this.departments)
					this.connectChat();
					this.pingTime = setInterval(() => this.sendPing(), this.pingTimeMilli);
				} else if (res.status_code == 401) {
					this.dataService.logout();
				} else {
					showNotification('warning', res.status);
				}
			}, error => {
				console.log(error);
				showNotification('danger', error);
			})
	}

	changeDepartment() {
		this.terminateSocket();
		let selDept = this.departments.filter(ae => ae.DepartmentID == this.selectedDeptID);
		if (selDept[0].DepartmentName == 'ADMIN') { this.isAdminDepartment = true; }
		else { this.isAdminDepartment = false; }
		this.holdForm = this._fb.group({
			'hold_message': [selDept[0].hold_message, [Validators.required]],
			'hold_time': [selDept[0].hold_time, [Validators.required]]
		});
		setTimeout(() => {
			this.chatlog = [];
			this.chatlogList = [];
			this.usersList = [];
			this.usersListDetails = [];
			this.seluserList = [];
			this.startChat = false;
			this.connectChat();
			this.activeDeptID = this.selectedDeptID;
		}, 1000);
	}

	getTile(uid) {
		let user = this.usersListDetails.filter(ae => ae.userid == uid)[0];
		let title = user.requested_department_name + '(' + user.requested_department_id + ')';
		return title
	}

	terminateSocket() {
		this.dataService.sendMessageLA({
			terminate: true,
			botID: this.botID,
			department: this.activeDeptID
		});
	}

	getCountryCode(uid) {
		let user = this.usersListDetails.filter(ae => ae.userid == uid)[0];
		let place = this.country.filter(ae => ae.name == user.country);
		if (place.length == 1) {
			return place[0].code;
		} else { return "" }
	}

	getUserSpec(param, uid) {
		const user = this.usersListDetails.find(ae => ae.userid === uid);
		console.log(user);
		if (!user) {
			return null; // Handle the case where user is not found
		}
		switch (param) {
			case 'node_id':
				return user.node_id;
			case 'name':
				console.log(user.cf_name || user.userid);
				return user.cf_name || user.userid;
			case 'phone':
				return user.cf_phone;
			case 'email':
				return user.cf_email;
			case 'department':
				return user.requested_department_name;
			case 'ticket_id':
				return user.ticket_id;
			case 'channelid':
				return user.channelid;
			case 'channel_details':
				return user.channel_details;
			case 'timestamp':
				return user.timestamp;
			default:
				return null; // Handle the case where param is not recognized
		}
	}

	sendPing() {
		if (this.pingCounter == 3) {
			this.connectChat();
			this.pingCounter = 0;
		} else {
			this.dataService.sendMessageLA({
				ping: true,
			});
			this.pingCounter++;
		}
	}

	getLeadData(userid) {
		this.dataService.getTempStoreValues({ bot_id: this.botID, user_id: userid }).subscribe(
			data => {
				this.leadDataList = data.data;
				if (this.leadDataList.length > 0) { this.showmoreDetails = true; }
			},
			error => {
				console.log(error);
			})
	}

	clearMoreLeadForm() {
		this.leadmoreForm = this._fb.group({
			storage_variable: ['null', Validators.required],
			value: ['', Validators.required]
		})
	}

	clearMemForm() {
		this.addMemForm = this._fb.group({
			display_name: ['', Validators.required],
			variable_type: ['null', Validators.required]
		})
	}

	leadMoreSubmit(form) {
		form.value.user_id = this.selected_user;
		form.value.bot_id = this.botID;
		this.leadloader = true;
		if (form.valid) {
			if (!this.isLeadEdit) {
				this.dataService.addTempStoreValues(form.value).subscribe(
					data => {
						this.leadloader = false;
						this.isaddLead = false;
						this.getLeadData(this.selected_user);
						this.clearMoreLeadForm();
						showNotification('success', "Lead Added!");
					},
					error => {
						console.log(error);
						showNotification('warning', "Error Adding Lead Data");
						this.leadloader = false;
					})
			} else {
				this.dataService.editTempStoreValues(form.value).subscribe(
					data => {
						this.leadloader = false;
						this.isaddLead = false;
						this.isLeadEdit = false;
						this.getLeadData(this.selected_user);
						this.clearMoreLeadForm();
						showNotification('success', "Lead Edited!");
					},
					error => {
						console.log(error);
						showNotification('warning', "Error Adding Lead Data");
						this.leadloader = false;
					})
			}
		}
	}

	addMemSubmit(form) {
		form.value.bot_id = this.botID;
		form.value.expiry = 2;
		this.leadloader = true;
		if (form.valid) {
			this.dataService.addStarredMemVars(form.value).subscribe(
				data => {
					this.leadloader = false;
					this.isaddMemory = false;
					this.getStarMemVars();
					this.clearMemForm();
					showNotification('success', "Storage Variable Added!");
				},
				error => {
					console.log(error);
					showNotification('warning', "Error Adding Storage Variables!");
					this.leadloader = false;
				})
		}
	}

	getStarMemVars() {
		this.dataService.getStarRatedStorageVars(this.botID).subscribe(
			data => {
				if (data.status_code) {
					this.starredMemVarsList = data.data;
				} else {
					showNotification('warning', "Error Getting Storage Variables!");
				}
			},
			error => {
				console.log(error);
				showNotification('warning', "Error Getting Storage Variables!");
			})
	}

	editData(data) {
		this.isaddLead = true;
		this.isLeadEdit = true;
		this.leadmoreForm = this._fb.group({
			storage_variable: [data.storage_variable, Validators.required],
			value: [data.value, Validators.required]
		})
	}

	getAllTags() {
		let data = {
			bot_id: this.botID,
			AgentID: this.customerID,
			AgentType: this.customerType
		}
		this.dataService.getTagsList(data).subscribe(
			res => {
				if (res.status_code == 200) {
					this.tagsList = [];
					for (let i in res.data) {
						if (res.data[i].is_active) {
							this.tagsList.push({
								id: res.data[i].tag_id,
								itemName: res.data[i].tag_name,
								color: res.data[i].color
							})
						}
					}
				}
			},
			error => {
				console.log(error);
				showNotification('warning', "Oops! Error while loading Tag List!");
			})
	}

	getTags(userid) {
		this.dataService.getUserTags({
			bot_id: this.botID,
			user_id: userid,
			AgentID: this.customerID,
			AgentType: this.customerType
		}).subscribe(
			data => {
				this.userTags = data.cf_tags;
				this.showTagDetails = true;
				this.selectedTags = [];
				for (let i in data.cf_tags) {
					for (let j in this.tagsList) {
						if (data.cf_tags[i] == this.tagsList[j].id) {
							this.selectedTags.push({
								id: this.tagsList[j].id,
								itemName: this.tagsList[j].itemName
							});
						}
					}
				}
				console.log("this.selectedTags", this.selectedTags);
			},
			error => {
				console.log(error);
				showNotification('warning', "Error Adding Storage Variable!");
				this.leadloader = false;
			})

	}

	saveUserTags() {
		this.isaddTags = true;
		let cf_tags = [];
		for (let i in this.selectedTags) {
			cf_tags.push(this.selectedTags[i].id);
		}
		this.dataService.addUserTags({
			bot_id: this.botID,
			user_id: this.selected_user,
			agent_id: this.customerID,
			cf_tags: cf_tags
		}).subscribe(
			res => {
				if (res.status_code == 200) {
					showNotification('success', "Tags Changed Successfully!");
					this.chatlog.push({
						cardtypeid: 27,
						feedbacktypeid: 1,
						message: {
							include: cf_tags,
							exclude: []
						},
						sender: "live_agent",
						time: new Date()
					});
					this.scrollToBottom();
				} else {
					showNotification('warning', "Oops! Error while saving tags!");
				}
				this.isaddTags = false;
			},
			error => {
				this.isaddTags = false;
				console.log(error);
				showNotification('warning', "Oops! Error while saving tags!");
			})
	}

	getLAImage(adent_id, attr) {
		let la_detail = [];
		la_detail = this.agentProfiles.filter(ae => ae.agent_id == adent_id);
		if (la_detail.length > 0) {
			if (attr == 'img') {
				return this.agentProfiles[0].image_url;
			} else {
				return this.agentProfiles[0].agent_name;
			}
		} else {
			if (attr == 'img') {
				return '/assets/img/default-avatar.png';
			} else {
				return 'Unknown Agent';
			}
		}
	}

	getTagDetails(id, attr) {
		let tags = [];
		tags = this.tagsList.filter(ae => ae.id == id);
		if (tags.length > 0) {
			if (attr == 'name') {
				return tags[0].itemName;
			} else {
				return tags[0].color;
			}
		} else {
			if (attr == 'name') {
				return 'No tag found';
			} else {
				return '#ccc';
			}
		}
	}

	onHoldSubmit(data) {
		this.dataService.sendMessageLA({
			hold_message: data.hold_message,
			hold_time: data.hold_time,
			userid: this.selected_user,
			channelid: this.getUserSpec('channelid', this.selected_user),
		});
	}

	getFirstLetter(uid) {
		const user = this.usersListDetails.find(ae => ae.userid === uid);
		const string = user.cf_name || uid;
		if (typeof string === "string" && string.length > 0) {
			return string[0];
		} else {
			return "";
		}
	}


	ngOnDestroy() {
		this.terminateSocket();
	}
}
