import { Component, OnInit, ViewChild,ElementRef} from '@angular/core';
import { ServicesService } from '../../services/services.service';
import { showNotification } from '../../services/notification';
import moment from 'moment';
declare var google: any;

@Component({
	selector: 'app-performance',
	templateUrl: './performance.component.html',
	styleUrls: ['./performance.component.scss','../dashboard/dashboard.component.scss']
})
export class PerformanceComponent implements OnInit {

	options = {};
	public botID:number = parseInt(localStorage.getItem('botID'), 10);
	public customerID:number = parseInt(localStorage.getItem('Customer_ID'), 10);
	public customerType:number = parseInt(localStorage.getItem('Customer_Type'), 10);
	@ViewChild('gChart1', { static: false }) gChart1: ElementRef;
	@ViewChild('gChart2', { static: false }) gChart2: ElementRef;
	@ViewChild('gChart3', { static: false }) gChart3: ElementRef;
	@ViewChild('gChart4', { static: false }) gChart4: ElementRef;
	@ViewChild('gChart5', { static: false }) gChart5: ElementRef;
	@ViewChild('gChart6', { static: false }) gChart6: ElementRef;
	@ViewChild('gChart7', { static: false }) gChart7: ElementRef;
	@ViewChild('gChart8', { static: false }) gChart8: ElementRef;
	current_perfomance_attend = 0;
	previous_perfomance_attend  = 0;
	percent_perfomance_attend=0;

	current_perfomance_closed  = 0;
	previous_perfomance_closed  = 0;
	percent_perfomance_closed  = 0;

	current_perfomance_total  = 0;
	previous_perfomance_total  = 0;
	percent_perfomance_total  = 0;

	agentID  = 0;
	public agentList=[];
	responded_count_agent = 0;
	positive_count_agent = 0;
	passive_count_agent = 0;
	negative_count_agent = 0;
	responded_count_bot = 0;
	positive_count_bot = 0;
	passive_count_bot = 0;
	negative_count_bot = 0;

	selected_date: any;
	ranges: any = {
		'Today': [moment(), moment()],
		'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
		'Last 7 Days': [moment().subtract(6, 'days'), moment()],
		'Last 30 Days': [moment().subtract(29, 'days'), moment()]
	}
	locale: any = {
		applyLabel: 'Select', 
		format: 'MM/DD/YYYY',
		daysOfWeek: ["Su","Mo","Tu","We","Th","Fr","Sa"],
		monthNames: ["January","February","March","April","May","June",
		"July","August","September","October","November","December"],
	}

	constructor(private dataService: ServicesService) { }

	ngOnInit(): void {
		this.options = {
			// width: 600,
			// height: 400,
			bar: {groupWidth: "95%"},
			legend: { position: "none" },
		};
		setTimeout(()=>{    
			this.selected_date  = {startDate:moment().subtract(7, 'days'),endDate:moment()};
			this.getAgentsDashboard();
		}, 1000);

	}

	getChartData(){
		if(this.selected_date?.startDate !=null && this.selected_date?.endDate !=null){
			this.getAttendedTickets(this.agentID);
			this.getClosedTickets(this.agentID);
			this.getTotalTickets(this.agentID);
			this.splitClosedTickets(this.agentID);
			this.getTimes(this.agentID);
			this.splitChannels(this.agentID);
			this.getWeekdayResponseTimes(this.agentID);
			this.getWeekdayTicketCounts(this.agentID);
			this.getCsat(this.agentID);
			this.splitDepartments(this.agentID);
		}
	}

	getAgentsDashboard(){
		if(this.agentList.length==0){
			this.dataService.getAgentsDashboard({
				CustomerID:this.customerID,
				CustomerType:this.customerType
			}).subscribe(
			data=>{
				if(data.status_code == 200){
					this.agentList = data.data;
					if(this.agentList.length>0){
						this.agentID = this.agentList[0].AgentID;
						this.getChartData();
					} else {
						showNotification('warning','<span>No Agents Found!</span>');
					}
				} else {
					showNotification('warning',data.status);
				}
			},
			error=>{
				console.log(error);
			})
		}
	}

	getAttendedTickets(agentID){
		this.current_perfomance_attend = 0;
		this.previous_perfomance_attend = 0;
		this.dataService.getAgentsAttendedTickets({
			AgentID:agentID,
			from_date:moment(this.selected_date.startDate).format('YYYY-MM-DD'),
			to_date:moment(this.selected_date.endDate).format('YYYY-MM-DD'),
			time_offset:moment(this.selected_date.startDate).format('ZZ')
		}).subscribe(
		data=>{
			if(data.status_code == 200){
				this.current_perfomance_attend = data.data.current_perfomance;
				this.previous_perfomance_attend = data.data.previous_perfomance;
				if(this.previous_perfomance_attend==0&&this.current_perfomance_attend!=0){this.percent_perfomance_attend=100}
					else if(this.current_perfomance_attend==0&&this.previous_perfomance_attend!=0){this.percent_perfomance_attend=-100}
						else if(this.current_perfomance_attend == this.previous_perfomance_attend){this.percent_perfomance_attend = 0} 
							else { this.percent_perfomance_attend = ((this.current_perfomance_attend - this.previous_perfomance_attend)/this.previous_perfomance_attend)*100;}
					} else {
						showNotification('warning',data.status);
					}
				},
				error=>{
					console.log(error);
				})
	}

	getTotalTickets(agentID){
		this.current_perfomance_total = 0;
		this.previous_perfomance_total = 0;
		this.dataService.getAgentsTotalTickets({
			AgentID:agentID,
			from_date:moment(this.selected_date.startDate).format('YYYY-MM-DD'),
			to_date:moment(this.selected_date.endDate).format('YYYY-MM-DD'),
			time_offset:moment(this.selected_date.startDate).format('ZZ')
		}).subscribe(
		data=>{
			if(data.status_code == 200){
				this.current_perfomance_total = data.data.current_total_tickets;
				this.previous_perfomance_total = data.data.previous_total_tickets;
				if(this.previous_perfomance_total==0&&this.current_perfomance_total!=0){this.percent_perfomance_total=100}
					else if(this.current_perfomance_total==0&&this.previous_perfomance_total!=0){this.percent_perfomance_total=-100}
						else if(this.current_perfomance_total == this.previous_perfomance_total){this.percent_perfomance_total = 0} 
							else { this.percent_perfomance_total = ((this.current_perfomance_total - this.previous_perfomance_total)/this.previous_perfomance_total)*100;}
					} else {
						showNotification('warning',data.status);
					}
				},
				error=>{
					console.log(error);
				})
	}

	getClosedTickets(agentID){
		this.current_perfomance_closed = 0;
		this.previous_perfomance_closed = 0;
		this.dataService.getAgentsClosedTickets({
			AgentID:agentID,
			from_date:moment(this.selected_date.startDate).format('YYYY-MM-DD'),
			to_date:moment(this.selected_date.endDate).format('YYYY-MM-DD'),
			time_offset:moment(this.selected_date.startDate).format('ZZ')
		}).subscribe(
		data=>{
			if(data.status_code == 200){
				this.current_perfomance_closed = data.data.current_perfomance;
				this.previous_perfomance_closed = data.data.previous_perfomance;
				if(this.previous_perfomance_closed==0&&this.current_perfomance_closed!=0){this.percent_perfomance_closed=100}
					else if(this.current_perfomance_closed==0&&this.previous_perfomance_closed!=0){this.percent_perfomance_closed=-100}
						else if(this.current_perfomance_closed == this.previous_perfomance_closed){this.percent_perfomance_closed = 0} 
							else { this.percent_perfomance_closed = ((this.current_perfomance_closed - this.previous_perfomance_closed)/this.previous_perfomance_closed)*100;}
					} else {
						showNotification('warning',data.status);
					}
				},
				error=>{
					console.log(error);
				})
	}

	splitClosedTickets(agentID){
		this.dataService.splitAgentsClosedTickets({
			AgentID:agentID,
			from_date:moment(this.selected_date.startDate).format('YYYY-MM-DD'),
			to_date:moment(this.selected_date.endDate).format('YYYY-MM-DD'),
			time_offset:moment(this.selected_date.startDate).format('ZZ')
		}).subscribe(
		data=>{
			if(data.status_code == 200){
				if(data.data.length>0){
					let options = {
						is3D: true,
						legend: { position: 'bottom', alignment: 'center' },
						colors:['#2f9fdb','#e95b54','#85509a','#3caf84','#fbce4a']
					};
					let chartData = new google.visualization.PieChart(this.gChart1.nativeElement);
					let dynaVisual = google.visualization.arrayToDataTable(data.data);
					chartData.draw(dynaVisual, options);
				} else {
					this.gChart1.nativeElement.innerHTML = '<div class="noDataContainer"><div class="noData red"><i class="fa fa-exclamation-circle" aria-hidden="true"></i> Not enough data</div></div>';
				}
			} else {
				showNotification('warning',data.status);
			}
		},
		error=>{
			console.log(error);
		})
	}

	getTimes(agentID){
		this.dataService.getAgentsResponseTimes({
			AgentID:agentID,
			from_date:moment(this.selected_date.startDate).format('YYYY-MM-DD'),
			to_date:moment(this.selected_date.endDate).format('YYYY-MM-DD'),
			time_offset:moment(this.selected_date.startDate).format('ZZ')
		}).subscribe(
		data=>{
			if(data.status_code == 200){
				if(data.data.length>0){
					let chartData = new google.visualization.BarChart(this.gChart2.nativeElement);
					let dynaVisual = google.visualization.arrayToDataTable(data.data);
					chartData.draw(dynaVisual, this.options);
				} else {
					this.gChart2.nativeElement.innerHTML = '<div class="noDataContainer1"><div class="noData red"><i class="fa fa-exclamation-circle" aria-hidden="true"></i> Not enough data</div></div>';
				}
			} else {
				showNotification('warning',data.status);
			}
		},
		error=>{
			console.log(error);
		})
	}

	getChatDurations(){
		this.dataService.getAgentsChatDuration({
			AgentID:this.agentID,
			from_date:moment(this.selected_date.startDate).format('YYYY-MM-DD'),
			to_date:moment(this.selected_date.endDate).format('YYYY-MM-DD'),
			time_offset:moment(this.selected_date.startDate).format('ZZ')
		}).subscribe(
		data=>{
			if(data.status_code == 200){
				if(data.data.length>0){
					let chartData = new google.visualization.BarChart(this.gChart2.nativeElement);
					let dynaVisual = google.visualization.arrayToDataTable(data.data);
					chartData.draw(dynaVisual, this.options);
				} else {
					this.gChart2.nativeElement.innerHTML = '<div class="noDataContainer1"><div class="noData red"><i class="fa fa-exclamation-circle" aria-hidden="true"></i> Not enough data</div></div>';
				}
			} else {
				showNotification('warning',data.status);
			}
		},
		error=>{
			console.log(error);
		})
	}

	getWaitTimeServed(){
		this.dataService.getAgentsWaitTimeServed({
			AgentID:this.agentID,
			from_date:moment(this.selected_date.startDate).format('YYYY-MM-DD'),
			to_date:moment(this.selected_date.endDate).format('YYYY-MM-DD'),
			time_offset:moment(this.selected_date.startDate).format('ZZ')
		}).subscribe(
		data=>{
			if(data.status_code == 200){
				if(data.data.length>0){
					let chartData = new google.visualization.BarChart(this.gChart2.nativeElement);
					let dynaVisual = google.visualization.arrayToDataTable(data.data);
					chartData.draw(dynaVisual, this.options);
				} else {
					this.gChart2.nativeElement.innerHTML = '<div class="noDataContainer1"><div class="noData red"><i class="fa fa-exclamation-circle" aria-hidden="true"></i> Not enough data</div></div>';
				}
			} else {
				showNotification('warning',data.status);
			}
		},
		error=>{
			console.log(error);
		})
	}

	getWaitTimeMissed(){
		this.dataService.getAgentsWaitTimeMissed({
			AgentID:this.agentID,
			from_date:moment(this.selected_date.startDate).format('YYYY-MM-DD'),
			to_date:moment(this.selected_date.endDate).format('YYYY-MM-DD'),
			time_offset:moment(this.selected_date.startDate).format('ZZ')
		}).subscribe(
		data=>{
			if(data.status_code == 200){
				if(data.data.length>0){
					let chartData = new google.visualization.BarChart(this.gChart2.nativeElement);
					let dynaVisual = google.visualization.arrayToDataTable(data.data);
					chartData.draw(dynaVisual, this.options);
				} else {
					this.gChart2.nativeElement.innerHTML = '<div class="noDataContainer1"><div class="noData red"><i class="fa fa-exclamation-circle" aria-hidden="true"></i> Not enough data</div></div>';
				}
			} else {
				showNotification('warning',data.status);
			}
		},
		error=>{
			console.log(error);
		})
	}

	splitChannels(agentID){
		this.dataService.splitAgentsChannels({
			AgentID:agentID,
			from_date:moment(this.selected_date.startDate).format('YYYY-MM-DD'),
			to_date:moment(this.selected_date.endDate).format('YYYY-MM-DD'),
			time_offset:moment(this.selected_date.startDate).format('ZZ')
		}).subscribe(
		data=>{
			if(data.status_code == 200){
				if(data.data.length>0){
					let options = {
						is3D: true,
						legend: { position: 'bottom', alignment: 'center' },
						colors:['#fbce4a','#3caf84','#2f9fdb','#85509a','#e95b54']
					};
					let chartData = new google.visualization.PieChart(this.gChart3.nativeElement);
					let dynaVisual = google.visualization.arrayToDataTable(data.data);
					chartData.draw(dynaVisual, options);
				} else {
					this.gChart3.nativeElement.innerHTML = '<div class="noDataContainer"><div class="noData red"><i class="fa fa-exclamation-circle" aria-hidden="true"></i> Not enough data</div></div>';
				}
			} else {
				showNotification('warning',data.status);
			}
		},
		error=>{
			console.log(error);
		})
	}

	loadChart(chart){
		if(chart=='rt'){
			this.getTimes(this.agentID);
		}
		if(chart=='cd'){
			this.getChatDurations();
		}
		if(chart=='wts'){
			this.getWaitTimeServed();
		}
		if(chart=='wtm'){
			this.getWaitTimeMissed();
		}
	}

	getWeekdayResponseTimes(agentID){
		this.dataService.getAgentsWeekdayResponseTimes({
			AgentID:agentID,
			from_date:moment(this.selected_date.startDate).format('YYYY-MM-DD'),
			to_date:moment(this.selected_date.endDate).format('YYYY-MM-DD'),
			time_offset:moment(this.selected_date.startDate).format('ZZ')
		}).subscribe(
		data=>{
			if(data.status_code == 200){
				if(data.data.length>0){
					let chartData = new google.visualization.BarChart(this.gChart4.nativeElement);
					let dynaVisual = google.visualization.arrayToDataTable(data.data);
					chartData.draw(dynaVisual, this.options);
				} else {
					this.gChart4.nativeElement.innerHTML = '<div class="noDataContainer"><div class="noData red"><i class="fa fa-exclamation-circle" aria-hidden="true"></i> Not enough data</div></div>';
				}
			} else {
				showNotification('warning',data.status);
			}
		},
		error=>{
			console.log(error);
		})
	}

	getWeekdayTicketCounts(agentID){
		this.dataService.getAgentsWeekdayTicketCounts({
			AgentID:agentID,
			from_date:moment(this.selected_date.startDate).format('YYYY-MM-DD'),
			to_date:moment(this.selected_date.endDate).format('YYYY-MM-DD'),
			time_offset:moment(this.selected_date.startDate).format('ZZ')
		}).subscribe(
		data=>{
			if(data.status_code == 200){
				if(data.data.length>0){
					let options = {
						legend: { position: 'top', maxLines: 3 },
						bar: { groupWidth: '75%' },
						isStacked: true,
						series: {
							0: {
								color: 'red'
							},
							1: {
								color: 'green'
							},
							2: {
								color: 'orange',
							}
						}
					}
					let chartData = new google.visualization.ColumnChart(this.gChart5.nativeElement);
					let dynaVisual = google.visualization.arrayToDataTable(data.data);
					chartData.draw(dynaVisual, options);
				} else {
					this.gChart5.nativeElement.innerHTML = '<div class="noDataContainer"><div class="noData red"><i class="fa fa-exclamation-circle" aria-hidden="true"></i> Not enough data</div></div>';
				}
			} else {
				showNotification('warning',data.status);
			}
		},
		error=>{
			console.log(error);
		})
	}


	agentPercent = 0;
	botPercent = 0;
	getCsat(agentID){
		this.responded_count_agent = 0;
		this.positive_count_agent = 0;
		this.passive_count_agent = 0;
		this.negative_count_agent = 0;
		this.responded_count_bot = 0;
		this.positive_count_bot = 0;
		this.passive_count_bot = 0;
		this.negative_count_bot = 0;
		this.current_perfomance_attend = 0;
		this.previous_perfomance_attend = 0;
		this.dataService.getAgentsCsat({
			AgentID:agentID,
			from_date:moment(this.selected_date.startDate).format('YYYY-MM-DD'),
			to_date:moment(this.selected_date.endDate).format('YYYY-MM-DD'),
			time_offset:moment(this.selected_date.startDate).format('ZZ')
		}).subscribe(
		data=>{
			if(data.status_code == 200){
				// let options = {
					// 	width: 300, height: 110,
					// 	redFrom: 0, redTo: 60,
					// 	yellowFrom:60, yellowTo: 80,
					// 	greenFrom:80, greenTo: 100,
					// 	minorTicks: 10
					// };
					// 	var guage_data = [];
					// if(Object.keys(data.data).length === 0){
						// 	guage_data = [
						// 	['Label', 'Value'],
						// 	['Agent', 0],
						// 	['Bot', 0]
						// 	];
						// } else {
							// 	guage_data = [
							// 	['Label', 'Value'],
							// 	['Agent', data.data.average_rating_agent],
							// 	['Bot', data.data.average_rating_bot]
							// 	];
							// 	this.responded_count_agent = data.data.responded_count_agent;
							// 	this.positive_count_agent = data.data.positive_count_agent;
							// 	this.passive_count_agent = data.data.passive_count_agent;
							// 	this.negative_count_agent = data.data.negative_count_agent;
							// 	this.responded_count_bot = data.data.responded_count_bot;
							// 	this.positive_count_bot = data.data.positive_count_bot;
							// 	this.passive_count_bot = data.data.passive_count_bot;
							// 	this.negative_count_bot = data.data.negative_count_bot;
							// }
							// let chartData = new google.visualization.PieChart(this.gChart7.nativeElement);
							// let dynaVisual = google.visualization.arrayToDataTable(guage_data);
							// chartData.draw(dynaVisual, options);
							var options = {
								title: '',
								titleTextStyle:{ 
									color: '#333333',
									fontSize: 10,
									bold: true,
								},
								backgroundColor: "transparent",
								height: 125,
								chartArea: {
									top: 15
								},
								colors: ['#338106', '#bfbfbf'],
								legend:'none',
								pieHole: 0.5,
								pieStartAngle: 270,
								pieSliceText: 'none',
								tooltip : {
									trigger: 'none'
								},
								slices: {
									2: {
										color: 'transparent'
									}
								},
								theme: 'maximized',
								width: 100
							};
							var agent_data = [];
							var bot_data = [];
							if(Object.keys(data.data).length === 0){
								agent_data = [
								['Label', 'Value'],
								['Agent', 0],
								['', 100],
								['', 100]
								];
								bot_data = [
								['Label', 'Value'],
								['Bot', 0],
								['', 100],
								['', 100]
								];
								this.agentPercent = 0;
								this.botPercent = 0;
							} else {
								agent_data = [
								['Label', 'Value'],
								['Agent', data.data.average_rating_agent],
								[null, 100 - data.data.average_rating_agent],
								[null, 100]
								];
								bot_data = [
								['Label', 'Value'],
								['Bot', data.data.average_rating_bot],
								[null, 100 - data.data.average_rating_bot],
								[null, 100]
								];
								this.agentPercent = data.data.average_rating_agent;
								this.botPercent = data.data.average_rating_bot;
								this.responded_count_agent = data.data.responded_count_agent;
								this.positive_count_agent = data.data.positive_count_agent;
								this.passive_count_agent = data.data.passive_count_agent;
								this.negative_count_agent = data.data.negative_count_agent;
								this.responded_count_bot = data.data.responded_count_bot;
								this.positive_count_bot = data.data.positive_count_bot;
								this.passive_count_bot = data.data.passive_count_bot;
								this.negative_count_bot = data.data.negative_count_bot;
							}
							let chartData = new google.visualization.PieChart(this.gChart7.nativeElement);
							let dynaVisual = google.visualization.arrayToDataTable(agent_data);

							let chartData1 = new google.visualization.PieChart(this.gChart8.nativeElement);
							let dynaVisual1 = google.visualization.arrayToDataTable(bot_data);
							// if(Object.keys(data.data).length === 0){
							// 	options.title = "Agent 0%";
							// } else {
							// 	options.title = "Agent "+ Math.round(data.data.average_rating_agent * 10) / 10+"%";
							// }
							chartData.draw(dynaVisual, options);
							// if(Object.keys(data.data).length === 0){
							// 	options.title = "ChatBot 0%";
							// } else {
							// 	options.title = "ChatBot "+ Math.round(data.data.average_rating_bot * 10) / 10 +"%";
							// }
							chartData1.draw(dynaVisual1, options);
						} else {
							showNotification('warning',data.status);
						}
					},
					error=>{
						console.log(error);
					})
}

splitDepartments(agentID){
	this.dataService.splitDepartments({
		AgentID:agentID,
		from_date:moment(this.selected_date.startDate).format('YYYY-MM-DD'),
		to_date:moment(this.selected_date.endDate).format('YYYY-MM-DD'),
		time_offset:moment(this.selected_date.startDate).format('ZZ')
	}).subscribe(
	data=>{
		if(data.status_code == 200){
			if(data.data.length>0){
				let options = {
					is3D: true,
					legend: { position: 'bottom', alignment: 'center' },
					colors:['#fbce4a','#3caf84','#2f9fdb','#85509a','#e95b54']
				};
				let chartData = new google.visualization.PieChart(this.gChart6.nativeElement);
				let dynaVisual = google.visualization.arrayToDataTable(data.data);
				chartData.draw(dynaVisual, options);
			} else {
				this.gChart6.nativeElement.innerHTML = '<div class="noDataContainer"><div class="noData red"><i class="fa fa-exclamation-circle" aria-hidden="true"></i> Not enough data</div></div>';
			}
		} else {
			showNotification('warning',data.status);
		}
	},
	error=>{
		console.log(error);
	})
}

ngAfterViewInit() {
	google.charts.load('current', { 'packages': ['corechart','gauge']});
}
}
