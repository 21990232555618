<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card card-height">
					<div class="card-body">
						<div class="row">
							<div class="col-xs-6">
								<button *ngIf="isTagCreate" type="button" class="btn btn-gradient btn-sm add-tags" (click)="addTagsModal.show()"><i *ngIf="!isAdding" class="fa fa-plus"></i>&nbsp;Add Tag</button>
							</div>
							<div class="col-xs-6">
								<select class="form-control w30bots" (change)="changeBot(bot.value)" #bot>
									<option *ngFor="let item of botList" value="{{item.BotID}}" [selected]="item.BotID==botID">{{item.BotName}}</option>
								</select>
							</div>
						</div>
						<div class="row">
							<div class="tags-list" *ngIf="tagsListActive?.length>0">
								<p>Active Tags</p>
								<div class="colors" *ngFor="let item of tagsListActive" [style.background]="item.color">
									<span>{{item.tag_name}}</span>
									<div class="actions">
										<a (click)="editTag(item)" ><i class="fa fa-pencil-square-o blue" aria-hidden="true"></i></a>
										<a (click)="deleteTag(item.tag_id,false);" *ngIf="isTagActive(item.tag_id)">
											<i class="fa fa-times red" aria-hidden="true"></i>
										</a>
									</div>
								</div>
							</div>
							<div class="tags-list" *ngIf="tagsListInactive?.length>0">
								<p>Inactive Tags</p>
								<div class="colors" *ngFor="let item of tagsListInactive" [style.background]="item.color">
									<span>{{item.tag_name}}</span>
									<div class="actions">
										<a (click)="editTag(item)" ><i class="fa fa-pencil-square-o blue" aria-hidden="true"></i></a>
										<a (click)="deleteTag(item.tag_id,true);" *ngIf="!isTagActive(item.tag_id)">
											<i class="fa fa-check green" aria-hidden="true"></i>
										</a>
									</div>
								</div>
							</div>
							<div class="empty-container" *ngIf="tagsList?.length==0">
								No Tags
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div bsModal #addTagsModal="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1"
role="dialog" aria-labelledby="dialog-auto-name">
<div class="modal-dialog modal-md">
	<div class="modal-content">
		<form  [formGroup]="addTag" (ngSubmit)="submitTag(addTag)" ngNativeValidate>
			<div class="modal-header">
				<h5 class="modal-title"><span *ngIf="!isEdit">Add</span><span *ngIf="isEdit">Edit</span> Tag</h5>
				<button type="button" class="close" (click)="addTagsModal.hide();clearAddTag();isEdit=false;">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="row addform">
					<div class="col-xs-6">
						<div class="form-group">
							<label>Tag name</label>
							<input class="form-control" placeholder="Name" type="text" formControlName="tag_name" required>
							<div class="error" *ngIf="isUniqueName(addTag.value.tag_name)">Tag name must be unique</div>
						</div>
					</div>
					<div class="col-xs-6">
						<div class="form-group">
							<label>Tag color</label><br>
							<div class="dflx">
								<input class="form-control" #ignoredInput
								[(ngModel)]="selectedColor"
								formControlName="color"
								[cpPresetColors]="['#FFD700', '#C0C0C0', '#cd7f32']"
								[cpIgnoredElements]="[ignoredButton, ignoredInput]"
								[(cpToggle)]="toggle"
								[(colorPicker)]="selectedColor" (colorPickerChange)="showColor()"/>
								<div class="presets" #ignoredButton (click)="toggle=!toggle"><div [style.background]="selectedColor"></div></div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-secondary" (click)="addTagsModal.hide();clearAddTag();isEdit=false;"><i class="fa fa-times" aria-hidden="true"></i>Cancel</button>
				<button type="submit" class="btn btn-gradient" [attr.disabled]="!isUniqueName(addTag.value.tag_name) ? null : '' ">
					<i *ngIf="isAdding" class="fa fa-spinner fa-spin fa-pulse"></i>
					<i *ngIf="!isAdding" class="fa fa-check"></i>
					<span *ngIf="!isEdit">Save</span><span *ngIf="isEdit">Edit</span>
				</button>
			</div>
		</form>
		
	</div>
</div>
</div>