declare var $: any;
export function showNotification(type, message) {
    // const type = ['','info','success','warning','danger'];
    let notifylength = document.querySelectorAll('.alert');
    if (notifylength.length !== 0) {
        return false;
    }
    $.notify({
        icon: "pe-7s-note2",
        message: message
    }, {
        type: type,
        timer: 1000,
        placement: {
            from: 'top',
            align: 'center'
        }
    });
}
