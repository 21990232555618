<div class="sub-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-sm-12 tab-ag-dp">
				<tabset type="pills">
					<tab (selectTab)="agents.getAgents();agents.getBotDepartments();agentTab=true;deptTab=false;qreplyTab=false;tagsTab=false;memTab=false;" *ngIf="customerType!=3">
						<ng-template tabHeading>
                            <button class="btn btn-sm" [ngClass]="{'btn-gradient':agentTab}">Agents</button>
                        </ng-template>
						<app-agents #agents></app-agents>
					</tab>
					<tab (selectTab)="departments.getDepartments();departments.getBotList();agentTab=false;deptTab=true;qreplyTab=false;tagsTab=false;memTab=false;" *ngIf="customerType!=3">
						<ng-template tabHeading>
                            <button class="btn btn-sm" [ngClass]="{'btn-gradient':deptTab}">Department</button>
                        </ng-template>
						<app-departments #departments></app-departments>
					</tab>
					<tab (selectTab)="qreplies.getAllReplies(botID);agentTab=false;deptTab=false;qreplyTab=true;tagsTab=false;memTab=false;" *ngIf="customerType!=4">
						<ng-template tabHeading>
                            <button class="btn btn-sm" [ngClass]="{'btn-gradient':qreplyTab}">Quick Replies</button>
                        </ng-template>
						<app-qreplies #qreplies></app-qreplies>
					</tab>
					<tab (selectTab)="tags.getTagList();agentTab=false;deptTab=false;qreplyTab=false;tagsTab=true;memTab=false;" *ngIf="customerType!=4">
						<ng-template tabHeading>
                            <button class="btn btn-sm" [ngClass]="{'btn-gradient':tagsTab}">Tags</button>
                        </ng-template>
						<app-tags #tags></app-tags>
					</tab>
					<tab (selectTab)="memory.getMemVars();agentTab=false;deptTab=false;qreplyTab=false;tagsTab=false;memTab=true;" *ngIf="customerType!=4">
						<ng-template tabHeading>
                            <button class="btn btn-sm" [ngClass]="{'btn-gradient':memTab}">Memory Variables</button>
                        </ng-template>
						<app-memory #memory></app-memory>
					</tab>
				</tabset>
			</div>
		</div>
	</div>
</div>
