<div class="main-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-4">
                <div class="card card-user userdetails pic-name" *ngIf="!isFetching">
                    <div class="card-body">
                        <div class="author"><img alt="..." width="450" height="450" class="avatar border-gray" src="{{profileDetails.image_url}}">
                            <h5 class="title">{{profileDetails.full_name}}</h5>
                        </div>
                        <p class="description text-center">{{profileDetails.email}}</p>
                    </div>
                </div>
                <div class="card card-user pseudo-div mauto" *ngIf="isFetching">
                    <i class="fa fa-spin fa-pulse fa-spinner"></i>
                </div>
            </div>
            <div class="col-md-8">
                <div class="card card-user userdetails">
                    <div class="card-header">
                        <h4 class="card-title prof-title">Edit Profile</h4>
                    </div>
                    <div class="card-body edit">
                        <form [formGroup]="profileForm" (ngSubmit)="changeProfile(profileForm.value)">
                            <div class="row">
                                <div class="col-md-5 px-1">
                                    <div class="form-group"><label>Name</label><input class="form-control" placeholder="Username" type="text" formControlName="full_name"></div>
                                </div>
                                <div class="col-md-7 pl-1">
                                    <div class="form-group"><label for="exampleInputEmail1">Email</label><input class="form-control" placeholder="Email" type="email" formControlName="email" disabled></div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-3 pr-1">
                                    <div class="form-group"><label>Phone</label><input class="form-control" placeholder="Phone" type="text" formControlName="phone"></div>
                                </div>
                                <div class="col-md-9 pl-1">
                                    <div class="form-group">
                                        <label>Image Url</label>
                                        <div class="dflx browse">
                                            <input class="form-control" placeholder="Image Url" type="url" formControlName="image_url">
                                            <input type="file" id="imgupload" (change)="getFile($event)" accept="image/*" style="display:none"/> 
                                            <button type="button"  name="imagefile" class="btn btn-browse " (click)="imageUpload()">Browse</button>
                                            <img (click)="imageUpload()" src="{{profileForm.value.image_url}}" width="40" height="40">
                                        </div>
                                        <div class="col-sm-12" *ngIf="uploading">
                                            <div class="mb-2">
                                                <progressbar max="{{total_kb}}" [value]="uploaded_kb" type="success" [striped]="true" [animate]="true"><i>{{uploaded_kb/1000| number:'1.0-0'}}kb / {{total_kb/1000| number:'1.0-0'}}kb</i></progressbar>
                                          </div>
                                      </div>
                                  </div>

                              </div>
                          </div>
                          <div class="row text-right">
                            <div class="update ml-auto mr-auto"><button class="btn btn-gradient" type="submit"><i *ngIf="isChanging" class="fa fa-spinner fa-pulse fa-spin"></i>Update Profile</button></div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
</div>