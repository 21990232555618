<nav class="navbar navbar-default">
    <div class="container-fluid">
        <div class="navbar-header">
            <button type="button" class="navbar-toggle" data-toggle="collapse" (click)="sidebarToggle()">
                <span class="sr-only">Toggle navigation</span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
            </button>
            <a class="navbar-brand" href="#">{{getTitle()}}</a>
        </div>
        <div class="collapse navbar-collapse">
            <ul class="nav navbar-nav navbar-left">
                <li>
                    <a href="#" class="dropdown-toggle" data-toggle="dropdown">
                        <p class="hidden-lg hidden-md">{{getTitle()}}</p>
                    </a>
                </li>
            </ul>

            <ul class="nav navbar-nav navbar-right d-flex align-items-center">
                <li *ngIf="showBots">
                    <div class="input-group no-border sel-bot">
                        <select *ngIf="loadingBots" class="form-control changebot" disabled="true">
                            <option selected>Loading bots...</option>
                        </select>
                        <select *ngIf="!loadingBots && botList.length==0" class="form-control changebot"
                            disabled="true">
                            <option selected>No bots available</option>
                        </select>
                        <select *ngIf="!loadingBots && botList.length>0" class="form-control changebot"
                            (change)="selectBot($event.target.value)">
                            <option *ngFor="let bot of botList; let i = index" value="{{bot.BotID}},{{bot.BotName}}"
                                [selected]="bot.BotID == botID">{{bot.BotName}}</option>
                        </select>
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <i class="fa fa-chevron-circle-down" *ngIf="!loadingBots && botList.length>0"
                                    aria-hidden="true"></i>
                                <i class="fa fa-exclamation-triangle" *ngIf="!loadingBots && botList.length==0"
                                    aria-hidden="true"></i>
                                <i class="fa fa-spin fa-spinner fa-pulse" *ngIf="loadingBots"></i>
                            </div>
                        </div>
                    </div>
                </li>
                <!-- <li>
                    <a href="#">
                        <p>Account</p>
                    </a>
                </li>
                <li class="dropdown">
                    <a href="#" class="dropdown-toggle" data-toggle="dropdown">
                        <p>
                            Dropdown
                            <b class="caret"></b>
                        </p>
                    </a>
                    <ul class="dropdown-menu">
                        <li><a href="#">Action</a></li>
                        <li><a href="#">Another action</a></li>
                        <li><a href="#">Something</a></li>
                        <li><a href="#">Another action</a></li>
                        <li><a href="#">Something</a></li>
                        <li class="divider"></li>
                        <li><a href="#">Separated link</a></li>
                    </ul>
                </li> -->
                <li class="dropdown" *ngIf="customerType!=1">
                    <a href="#" class="dropdown-toggle" data-toggle="dropdown">
                        <i class="fa fa-bell"></i>
                        <!-- <b class="caret"></b> -->
                        <span *ngIf="notifyCount>0" class="notification hidden-sm hidden-xs">{{notifyCount}}</span>
                        <p *ngIf="notifyCount>0" class="hidden-lg hidden-md">
                            {{notifyCount}} Notifications
                            <!-- <b class="caret"></b> -->
                        </p>
                    </a>
                    <ul class="dropdown-menu" *ngIf="notifications?.length>0">
                        <p class="text-right">
                            <a href="javascript:void(0)" (click)="markRead('all');$event.stopPropagation()">Mark all
                                read</a>
                        </p>
                        <li *ngFor="let item of notifications" [ngClass]="{'unread':!item.status}">
                            <i class="fa fa-bullhorn" [ngClass]="{'green':!item.status,'red':item.status}"
                                aria-hidden="true"></i>
                            <a href="javascript:void(0)"
                                (click)="markRead('single',item.id,item.status);$event.stopPropagation()">
                                <p>{{item.text}}</p>
                                <small>{{item.created_at | date:'short'}}</small>
                            </a>
                        </li>
                    </ul>
                </li>
                <li>
                    <a routerLink="/profile" title="Profile" class="profile-round">
                        <!-- <img class="user_img" src="{{agent_img}}" width="30" height="30"> -->
                        <!-- <i class="fa fa-user" aria-hidden="true"></i> -->
                        <span>{{customerChar}}</span>
                    </a>
                </li>
                <li>
                    <a href="#" (click)="logOut()" title="Logout">
                        <i class="fa fa-sign-out" aria-hidden="true"></i>
                    </a>
                </li>
                <li class="separator hidden-lg hidden-md"></li>
            </ul>
        </div>
    </div>
</nav>