<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-8">
				<div class="card card-height">
					<div class="card-body">
						<div class="row">
							<div class="col-xs-6">
								<button *ngIf="permissions?.is_quickreply_create" type="button" class="btn btn-gradient btn-sm add-qreply" (click)="addQreplyModal.show()"><i *ngIf="!isAdding" class="fa fa-plus"></i>&nbsp;Add Quick Reply</button>
							</div>
							<div class="col-xs-6">
								<select class="form-control w30bots" (change)="getQreplyofBot($event)">
									<option *ngFor="let item of botList" value="{{item.BotID}}" [selected]="item.BotID==botID">{{item.BotName}}</option>
								</select>
							</div>
						</div>
						<div class="content table-responsive table-full-width">
							<table class="table table-hover">
								<thead>
									<tr>
										<th>Key</th>
										<th>Messages</th>
										<th>Action</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let msg of replies; let i=index">
										<td><b>{{msg.quick_key}}</b></td>
										<td class="msgitems"><span *ngFor="let item of msg.quick_value">{{item}}</span></td>
										<td>
											<a (click)="editQreply(msg)"><i class="fa fa-pencil-square-o green" aria-hidden="true"></i></a>
											<a *ngIf="permissions?.is_quickreply_delete" (click)="selectedQkey=msg.quick_key;deleteQreplyModal.show()"><i class="fa fa-trash-o red" aria-hidden="true"></i></a>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-4">
				<div class="card">
					<div class="header">
						<h5 class="text-center">Test Quick Replies</h5>
					</div>
					<div class="card-body qtext">
						<textarea
						[mentionConfig]="mentionConfig"
						class="form-control" rows="3" #checkreplies></textarea>
					</div>
					<div class="footer">
						<button type="button" class="btn btn-gradient btn-sm pull-right mtop10" (click)="checkreplies.value=''"><i class="fa fa-trash"></i>&nbsp;Clear</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div bsModal #addQreplyModal="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1"
role="dialog" aria-labelledby="dialog-auto-name">
<div class="modal-dialog modal-md">
	<div class="modal-content">
		<form  [formGroup]="addQreply" (ngSubmit)="submitQreply(addQreply.value)" ngNativeValidate>
			<div class="modal-header">
				<h5 class="modal-title"><span *ngIf="!isEdit">Add</span><span *ngIf="isEdit">Edit</span> Quick Reply</h5>
				<button type="button" class="close" (click)="addQreplyModal.hide();clearAddQreply();isEdit=false;">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="row addform">
					<div class="col-xs-offset-3 col-xs-6">
						<div class="form-group">
							<label>Key</label>
							<input class="form-control" placeholder="Key" type="text" formControlName="quick_key" pattern="[A-Za-z0-9*@!#%&()^~{}]{1}" title="Only single character is allowed" required (keyup)="checkDuplicate($event)">
						</div>
					</div>
					<div class="col-xs-12">
						<div class="form-group">
							<label>Message&nbsp;<a class="add pull-right" tooltip="Add Message" (click)="onAddVar()"><i class="fa fa-plus-circle green" aria-hidden="true"></i></a></label>
							<div *ngFor="let control of variablesArray.controls; index as i" class="msg">
								<input class="form-control" type="text" placeholder="Message" [formControl]="variablesArray.controls[i]" required />
								<a *ngIf="variablesArray.length>1" class="delete" (click)="onRemoveVar(i)"><i class="fa fa-trash red" aria-hidden="true"></i></a>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-secondary" (click)="addQreplyModal.hide();clearAddQreply();isEdit=false;"><i class="fa fa-times" aria-hidden="true"></i>Cancel</button>
				<button type="submit" class="btn btn-gradient"  [attr.disabled]="!isDuplicate ? null : '' ">
					<i *ngIf="isAdding" class="fa fa-spinner fa-spin fa-pulse"></i>
					<i *ngIf="!isAdding" class="fa fa-check"></i>
					<span *ngIf="!isEdit">Save</span><span *ngIf="isEdit">Edit</span>
				</button>
			</div>
		</form>
		
	</div>
</div>
</div>

<div bsModal #deleteQreplyModal="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1"
role="dialog" aria-labelledby="dialog-auto-name">
<div class="modal-dialog modal-md">
	<div class="modal-content">
		<div class="modal-header">
			<h5 class="modal-title">Delete Quick Reply</h5>
			<button type="button" class="close" (click)="deleteQreplyModal.hide()">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<div class="modal-body text-center">
			<p>Are you sure to delete "<b>{{selectedQkey}}</b>" key from Quick Reply?</p>
		</div>
		<div class="modal-footer">
			<button type="button" class="btn" (click)="deleteQreply()">
				<i *ngIf="!isDeleting" class="fa fa-trash-o red"></i>
				<i *ngIf="isDeleting" class="fa fa-spin fa-spinner fa-pulse"></i>
			Yes</button>
			<button type="button" class="btn btn-gradient" (click)="deleteQreplyModal.hide()"><i class="fa fa-times" aria-hidden="true"></i>No</button>
		</div>
	</div>
</div>
</div>
