import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

	public isDashboard:boolean = false;
	public isPerformance:boolean = true;
	public isDepartment:boolean = false;
	public customerType:number = parseInt(localStorage.getItem('Customer_Type'), 10);

	constructor() { }

	ngOnInit(): void {
	}



}
