import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NguiMapModule } from '@ngui/map';

import { AdminLayoutRoutes } from './admin-layout.routing';

import { HomeComponent } from '../../home/home.component';
import { TagsComponent } from '../../management/tags/tags.component';


import { TabsModule } from 'ngx-bootstrap/tabs';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { MentionModule } from 'angular-mentions';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CKEditorModule } from 'ckeditor4-angular';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { ColorPickerModule } from 'ngx-color-picker';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { PickerModule } from '@ctrl/ngx-emoji-mart';

// import { DataTablesModule } from 'angular-datatables';
import { UserComponent } from '../../user/user.component';
import { SpectateComponent } from '../../spectate/spectate.component';
import { ManagementComponent } from '../../management/management.component';
import { AgentsComponent } from '../../management/agents/agents.component';
import { DepartmentsComponent } from '../../management/departments/departments.component';
import { PeopleComponent } from '../../people/people.component';
import { TicketsComponent } from '../../tickets/tickets.component';
import { ProfileComponent } from '../../profile/profile.component';
import { QrepliesComponent } from '../../management/qreplies/qreplies.component';
import { PerformanceComponent } from '../../home/performance/performance.component';
import { DepartmentComponent } from '../../home/department/department.component';
import { DashboardComponent } from '../../home/dashboard/dashboard.component';
import { MemoryComponent } from '../../management/memory/memory.component';
import { UnifiedInboxComponent } from '../../unified-inbox/unified-inbox.component';
import { UsersListComponent } from '../../unified-inbox/users-list/users-list.component';
import { AssignRulesComponent } from 'app/assign-rules/assign-rules.component';
import { AddRulesComponent } from 'app/assign-rules/add-rules/add-rules.component';
import { SendTemplatesComponent } from 'app/user/send-templates/send-templates.component';
import { FilterRuleComponent } from 'app/user/filter-rule/filter-rule.component';
import { BusinessHoursComponent } from 'app/management/departments/business-hours/business-hours.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(AdminLayoutRoutes),
        FormsModule,
        ReactiveFormsModule,
        TabsModule.forRoot(),
        ModalModule.forRoot(),
        NgxDaterangepickerMd.forRoot(),
        // DataTablesModule,
        AngularMultiSelectModule,
        ProgressbarModule.forRoot(),
        CarouselModule.forRoot(),
        MentionModule,
        CKEditorModule,
        TooltipModule.forRoot(),
        PopoverModule.forRoot(),
        ColorPickerModule,
        NgCircleProgressModule.forRoot({
            // set defaults here
            radius: 90,
            outerStrokeWidth: 10,
            innerStrokeWidth: 8,
            outerStrokeColor: "#232C71",
            innerStrokeColor: "#B9347B",
            subtitleFontSize: "13",
            subtitleFontWeight: "800",
            animationDuration: 300,
        }),
        NguiMapModule.forRoot({ apiUrl: 'https://maps.google.com/maps/api/js?key=YOUR_KEY_HERE' }),
        PickerModule
    ],
    declarations: [
        HomeComponent,
        UserComponent,
        SpectateComponent,
        ManagementComponent,
        AgentsComponent,
        DepartmentsComponent,
        PeopleComponent,
        TicketsComponent,
        ProfileComponent,
        QrepliesComponent,
        PerformanceComponent,
        DepartmentComponent,
        DashboardComponent,
        TagsComponent,
        MemoryComponent,
        UnifiedInboxComponent,
        UsersListComponent,
        AssignRulesComponent,
        AddRulesComponent,
        SendTemplatesComponent,
        FilterRuleComponent,
        BusinessHoursComponent
    ]
})

export class AdminLayoutModule { }
