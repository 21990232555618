import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { AgentsComponent } from './agents/agents.component';
import { QrepliesComponent } from './qreplies/qreplies.component';
import { TagsComponent } from './tags/tags.component';
import { MemoryComponent } from './memory/memory.component';
import { ViewEncapsulation } from '@angular/core';

@Component({
	selector: 'app-management',
	templateUrl: './management.component.html',
	styleUrls: ['./management.component.scss'],
	encapsulation : ViewEncapsulation.None
	// directives: [AgentsComponent]
})
export class ManagementComponent implements OnInit {

	public agentTab:boolean = true;
	public deptTab:boolean = false;
	public qreplyTab:boolean = false;
	public tagsTab:boolean = false;
	public memTab:boolean = false;
	botID:number = parseInt(localStorage.getItem('botID'), 10);
	public customerType:number = parseInt(localStorage.getItem('Customer_Type'), 10);
	@ViewChild('agents') agents:AgentsComponent;
	@ViewChild('qreplies') qreplies:QrepliesComponent;
	@ViewChild('tags') tags:QrepliesComponent;
	@ViewChild('memory') memory:MemoryComponent;


	constructor() { }

	ngOnInit(): void {
	}

	ngAfterViewInit() {
		if(this.customerType!=3){
			this.agents.getAgents();
		} else {
			this.qreplies.getAllReplies(this.botID);
		}
	}
}
